@import "../../../styles/common";

.single-orderbook {
  @include themify($themes) {
    height: 100%;

    .table-container {
      width         : 100%;
      overflow-x    : hidden;
      padding-bottom: 5px;
    }

    .header,
    .sell,
    .buy {
      padding    : 0 5px;
      line-height: 20px;
    }

    .header {

      color: themed("n_layout_text_active");

    }

    .matched-price {
      line-height: 28px;
      font-size  : $font-size-medium;


      border: 1px solid themed("n_layout_border");

    }

    .vol-container {
      width     : 60px;
      text-align: right;
    }

    .sell,
    .buy {
      .row {
        position: relative;
        height  : 20px;
        cursor  : pointer;

        .slider {
          position: absolute;
          top     : 1px;
          right   : 0;
          height  : 18px;
          width   : 50%;
        }
      }
    }

    .buy .row {
      &:hover {
        background: rgba(themed("n_layout_bg_block"), 0.03);

        .slider {
          background: rgba(themed("n_layout_bg_block"), 0.4);
        }
      }

      &:active {
        background: rgba(themed("n_layout_bg_block"), 0.3);

        .slider {
          background: rgba(themed("n_layout_bg_block"), 0.7);
        }
      }

      .slider {
        background: rgba(themed("n_layout_bg_block"), 0.1);
      }
    }

    .sell .row {
      &:hover {
        background: rgba(themed("n_layout_bg_block"), 0.03);

        .slider {
          background: rgba(themed("n_layout_bg_block"), 0.4);
        }
      }

      &:active {
        background: rgba(themed("n_layout_bg_block"), 0.3);

        .slider {
          background: rgba(themed("n_layout_bg_block"), 0.7);
        }
      }

      .slider {
        background: rgba(themed("n_layout_bg_block"), 0.1);
      }
    }
  }
}