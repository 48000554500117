@import "../../../../styles/common";

.i-flex {
    @include themify($themes) {
        padding-bottom: 5px;

        .title {
            margin-bottom : 22px;
            display       : flex;
            flex-direction: row;
            gap           : 16px;
            cursor        : pointer;
            font-family   : 'Helvetica Neue Bold';
            font-weight   : 700; // Heading 6 - B (6)
            font-size     : 16px;
            line-height   : 24px;
        }

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }

            .active {}

            .not-active {
                color: themed('n_layout_text_not_active');
            }

            .text-detail {
                position       : absolute;
                right          : 5px;
                top            : -5px;
                text-decoration: underline;
                cursor         : pointer;
            }

        }

        .block-right {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;
            width        : 100%;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            .container-block {
                display          : flex;
                flex-direction   : row;
                // gap           : 10px;

                .block-item {
                    height   : auto;
                    // width : 50%;
                }

                .row-title {
                    height            : 36px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed('n_layout_border');
                }

                .row-item {
                    height         : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .row-item-flex {
                    line-height    : 24px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed('n_layout_border');
                    padding      : 10px;
                    border-radius: 4px;
                }
            }
        }

        .pl-10 {
            padding-left: 10px;
        }

        .p-10 {
            padding-left : 10px;
            padding-right: 10px;
        }

        .block-table {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding-top  : 10px;
            margin-top   : 5px;
        }

        .btn-withdraw {
            background: themed("n_layout_primary");
            padding   : 3px 9px;
            color     : themed("n_text_custom");
        }

        .customer-account-lookup-paging {
            height: 450px;
        }

    }
}