@import "../../../../styles/common";

.symbol-suggest-trade {
  @include themify($themes) {
    max-height   : 300px;
    // position  : relative;
    min-width    : 120px;
    width        : min-content;
    display      : flex;
    border-bottom: 1px solid themed("n_layout_border");

    .btn-icon {}

    .suggest-input-container {
      // position: relative;


      .react-autosuggest__container {
        // position: relative;

        .react-autosuggest__input {
          border          : none;
          text-overflow   : ellipsis;
          width           : 100%;
          font-size       : inherit;
          text-transform  : uppercase;
          background-color: themed("n_layout_bg_body_block");
          color           : themed("n_layout_text_active");
          border-bottom   : 1px solid themed("n_layout_border");
          text-align      : left;
          font-weight     : 700;
          font-size       : 20px;
          line-height     : 33px;

          &:focus-visible {
            border       : none;
            border-bottom: 1px solid themed("n_layout_border");
            outline      : none;
          }

          &::placeholder {
            color         : themed('n_layout_text_not_active');
            text-align    : center;
            text-transform: uppercase;
            font-weight   : 700;
            font-size     : 20px;
            line-height   : 30px;
          }
        }

        .react-autosuggest__suggestions-container {
          z-index         : 200;
          position        : absolute;
          max-height      : 200px;
          overflow-y      : auto;
          overflow-x      : hidden;
          background-color: themed("n_layout_bg_block");


          .react-autosuggest__suggestions-list {
            padding         : 0px;
            background-color: themed("n_layout_bg_block");
            margin-bottom   : 0px !important;
          }

          .react-autosuggest__suggestion {
            padding            : 2px 2px 2px 5px;
            text-align         : left;
            // text-align      : center;
            white-space        : nowrap;

            .suggest-item {
              max-width       : 390px;
              height          : auto;
              line-height     : 26px;
              // text-overflow: ellipsis;
              white-space     : normal;
              // overflow     : hidden;
            }

            &:hover,
            &.react-autosuggest__suggestion--highlighted {
              cursor          : pointer;
              color           : themed('n_layout_text_secondary_1');
              background-color: themed("n_layout_active_bg");
            }
          }
        }
      }
    }
  }
}