@import "../../../styles/common";

.browser-confirm-modal {

  @include themify($themes) {
    .modal-content {
      background: themed("n_layout_bg_block");
    }

    .header {
      background: themed("n_layout_bg_block");

      .title {
        color: themed("n_layout_text_active");
      }

      button {
        color: themed("n_layout_text_active");

        &:hover {
          background: themed("n_layout_bg_block");
        }
      }
    }

    .body {
      color     : themed("n_layout_text_active");
      background: themed("n_layout_bg_block");
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 700px !important;
    width    : 700px !important;
  }

  .header {
    height: 30px;

    .title {
      font-size  : $font-size-medium;
      line-height: 30px;
      padding    : 0 10px;
    }

    button {
      background : transparent;
      border     : none;
      font-size  : $font-size-medium;
      height     : 30px;
      line-height: 30px;
      padding    : 0 10px;
      text-align : center;
      width      : auto;

      i {
        line-height: 30px;
      }

      .fa-times {
        font-size: $font-size-big + 2px;
      }
    }
  }

  .body {
    padding: 5px 10px;

    .desc-content {
      p {
        text-indent: 0.25in;
        margin-top : 1rem;
      }
    }
  }


  .footer-condition {
    padding: 0px 10px 5px 10px;

    .confirm {
      margin-bottom: 10px;
    }

    .custom-btn {
      font-size: $font-size-small;
      width    : 100px;
      height   : 28px;
      padding  : 0;
      border   : none;
    }

    .btn-cancel {
      margin-right: 10px;
    }
  }

  .custom-form-group {
    margin: 5px 0;

    .custom-form-control.readonly {
      height    : auto;
      min-height: 28px;
    }
  }
}