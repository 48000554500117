@import "../../../../styles/common";

.modal-bank-body {
    @include themify($themes) {
        background: themed('n_layout_bg_block');

        border-bottom-right-radius: 4px;
        border-bottom-left-radius : 4px;

        padding: 20px;

        .modal-property {
            border-bottom  : 1px solid themed('n_layout_border');
            height         : 48px;
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            align-items    : center;

            .label {
                margin: 0;
            }

            .btn-refresh,
            .btn-confirm {
                width: 100%;
            }
        }

        .col-add {
            height         : 48px;
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            align-items    : center;

            .label {
                margin: 0;
            }

            .btn-refresh,
            .btn-confirm {
                width: 100%;
            }
        }

        .btn-delete-bank {
            height      : 48px;
            margin-top  : 16px;
            width       : 100%;
            margin-left : 0px;
            margin-right: 0px;
        }

        .btn-container {
            width: 100%;

            .btn {
                width: 100%;
            }
        }
    }
}