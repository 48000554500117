@import "../../../styles/common";

.customer-dealt-sell {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .customer-dealt-sell-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }
    }
}