@import "../../styles/common.scss";


.trade-auth2 {
  @include themify($themes) {
    .trade-auth-btn-container{
      .btn-action {
        text-transform: uppercase !important;
      }
    }
    .trade-auth {
      margin-top: 12px;
      .btn-action {
        margin     : 5px 0;
        padding    : 0 15px;
        border     : none;
        font-size  : $font-size-regular;
        line-height: 28px;
        width      : 100px;
        @include border-radius(0);
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;

        &[disabled] {
          opacity: 0.5;
        }
      }


      .custom-form-group, .custom-form-group-effect{
        margin-bottom: 12px;

        &.save-auth{
          border: none;
          text-align: right;
        }
      }

      .btn-re-send-otp-new {
        text-decoration: underline;
        justify-content: flex-end;
        width          : 100%;
        // margin         : 5px 0;
        // padding        : 0 5px;
        height         : 28px;
        border         : none;
        font-size      : $font-size-regular;
        line-height    : 28px;
        @include border-radius(0);
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
        cursor       : pointer;
        display      : flex;
        color        : themed("n_btn_active");

        &[disabled] {
          opacity: 0.5;
        }
      }

      .btn-re-send-otp {
        width      : 100%;
        margin     : 5px 0;
        padding    : 0 5px;
        height     : 28px;
        border     : none;
        font-size  : $font-size-regular;
        line-height: 28px;
        @include border-radius(0);
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
        cursor       : pointer;
        display      : flex;
        color        : themed("n_btn_active");

        &[disabled] {
          opacity: 0.5;
        }
      }

      .custom-form-control {
        height: 100%;

        &[disabled] {
          background-color      : themed("n_layout_bg_block");
          // padding-top     : 5px;
          // padding-bottom  : 5px;
          // height             : 43px;
        }
      }

      .ca-nocaloaded {}

      .pin-input-container {
        // padding-top   : 5px;
        // padding-bottom: 5px;

        &.editable {

          &.enable-edit {
            .custom-form-control {
              // background-color: red;
            }
          }

          .btn-edit,
          .btn-save {
            border    : none;
            background: transparent;
          }

          .btn-edit {
            color: themed("n_price_ref_text");
          }

          .btn-save {
            color: themed("n_layout_primary");
          }

          &.editing {
            .custom-form-control {
              // background-color: #fc0390;
            }
          }
        }

        &.editable {
          position: relative;

          .btn-edit,
          .btn-save {
            position   : absolute;
            top        : -6px;
            right      : 0;
            width      : 28px;
            height     : 28px;
            border     : none;
            background : transparent;
            line-height: 28px;
            font-size  : 14px;
            margin-top : 0px;
            //position: relative;

            i {
              position   : absolute;
              // top     : 6px;
              left       : 3px;
              width      : 0px;
            }
          }

          .btn-save {
            display: none;
          }

          &.editing {
            .btn-edit {
              display: none;
            }

            .btn-save {
              display: block;
            }
          }
        }

      }
    }

    .serial-container {
      padding     : 5px;
      margin-left : 0px;
      margin-right: 0px;
      margin-top  : 10px;
  
      div {
        padding: 0px;
      }
    }

    .container-confirm-auth {
      display: flex;
      gap    : 5px;
      padding: 10px 0px 0px 0px;

      .icon-confirm {
        height       : 20px;
        width        : 20px;
        border-radius: 50%;
        border       : 2px solid themed('n_layout_increase_text');

        i {
          font-weight: 600;
          font-size  : 10px;
        }
      }
    }

    .trade-auth-btn-container {
      margin : 10px 0px;
      display: flex;
      gap    : 10px;

      .btn-action {
        width : 50%;
        height: 40px;

        // border: 1px solid white !important;

        &:focus-visible, &:focus{
          border: 1px solid white;
        }
      }
    }

    .block-note-msg {
      margin: 10px 0px 0px 0px;
    }
  }
}