@import "../../../../styles/common";

.trade-auth-blb {
    max-width: 550px;

    width: 550px;

    @include themify($themes) {
        .normal-order-cancel {
            .basic-table {
                tbody {
                    tr {
                        td {
                            height       : 40px;
                            border-bottom: 1px solid themed("n_table_border");
                        }
                    }
                }
            }
        }

        .custom-row-oco {
            border-bottom: 1px solid themed("n_layout_border");

            .custom-row-group {}
        }
    }

    .trade-auth-btn-container {
        margin : 10px 0px;
        display: flex;
        gap    : 10px;

        .btn-action {
            width : 50%;
            height: 40px;
        }
    }
}

.putthrough-trade-auth {
    // overflow: scroll;
    margin-left  : 0px;
    margin-right : 0px;
    // display: flex;
    grid-gap     : 5px;
    gap          : 5px;
    padding-left : 5px;
    padding-right: 5px;
    width        : 70vw;

    &.short-msg {
        width: 550px !important;
    }

    .rc-input-number-qtty-input,
    .rc-input-number-price-input {
        text-align: left !important;
    }

    .rc-input-number-price-handler,
    .rc-input-number-qtty-handler {
        display: none !important;
    }


    @include themify($themes) {
        .block-info-account {
            padding: 10px;
            border : 1px solid themed("n_layout_border");
            ;
            border-radius: 10px;
            height       : 100%;
            margin-left  : 0px;
            margin-right : 0px;
            // flex: 1;

            @media (min-width: 992px) {
                &.col-lg-4 {
                    max-width: 32.33333%; // max width của widget không tròn 100% (33 -> 32 để vừa chiều ngang)
                }

                &.col-lg-6 {
                    max-width: 48.8%; // max width của widget không tròn 100% (33 -> 32 để vừa chiều ngang)
                }
            }

            input:read-only {
                color: themed("n_layout_text_not_active");
            }
        }

        .block-info-stock {
            padding: 10px;
            border : 1px solid themed("n_layout_border");
            ;
            border-radius: 10px;
            margin-left  : 0px;
            margin-right : 0px;
            height       : 100%;
            // flex: 1;

            .capacity-container {
                width    : 100%;
                // margin: 10px 0px 0px 0px;

                .info-capacity {
                    .row-capacity {
                        display        : flex;
                        justify-content: space-between;

                        &.index {
                            display   : block;
                            text-align: center;

                            .capacity-content {
                                display: block;
                            }
                        }

                        .capacity-content {
                            display: flex;

                            .label {
                                color: themed("n_layout_text_not_active");
                            }

                            .value {
                                color: themed("n_layout_text_active");

                                .fill-qtty {
                                    cursor: pointer;
                                }
                            }

                            &.capacity-price {
                                gap   : 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .type-view {
                display: flex;
                width  : 100%;

                &:first-child {
                    margin-top: 0px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                .content {
                    height: 32px;
                }

                .content-left {
                    width      : 30%;
                    display    : flex;
                    align-items: center;
                }

                .content-right {
                    width: 70%;
                }
            }

            .present-order {
                .content-right {
                    gap: 10px;

                    .select-present {
                        display        : flex;
                        align-items    : center;
                        justify-content: center;
                        white-space    : nowrap;
                        border         : 1px solid themed("n_layout_border");
                        flex           : 1 0 0;
                        height         : 100%;
                        cursor         : pointer;
                        border-radius  : $border_radius;

                        &:focus-visible {
                            box-shadow: themed("n_layout_box_shadow_bg") 1px 1px 1px;
                            border    : none;
                            outline   : none;
                        }
                    }
                }
            }

            .clear-day-input {

                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin            : 0;
                }

                /* Firefox */
                input[type="number"] {
                    -moz-appearance: textfield;
                }
            }
        }

        .block-info-ppse {
            height       : 100%;
            margin-left  : 0px;
            margin-right : 0px;
            padding-left : 0px;
            padding-right: 0px;
            // flex: 1;

            .block-info-ppse-content {
                padding: 10px;
                border : 1px solid themed("n_layout_border");
                ;
                border-radius: 10px;

                .ppse-item {
                    margin-left  : 0px;
                    margin-right : 0px;
                    padding      : 0px;
                    height       : 80px;
                    border-bottom: 1px solid themed("n_table_border");

                    .input-label {
                        color: themed("n_layout_text_not_active");
                    }
                }

            }

            .btn-container {
                margin-left : 0px;
                margin-right: 0px;
                margin-top  : 10px;

                div {
                    padding-left : 5px;
                    padding-right: 0px;
                }

                button {
                    width     : 100% !important;
                    height    : 40px !important;
                    background: linear-gradient(90deg, #16aabb 0%, #1a7ecb 100%);
                }
            }
        }

        // .block-info-account {
        //     padding: 10px;
        // }

        // .block-info-stock{
        //     padding: 10px;
        // }

        // .block-info-ppse{
        //     padding: 10px;
        // }
        .input-item {
            margin-left  : 0px;
            margin-right : 0px;
            padding      : 0px;
            height       : 40px;
            margin-bottom: 20px;

            &.symbol-input {
                height: unset;

                .custom-form-group {
                    border-bottom: none;
                }

                margin-bottom: 16px;
            }

            .search-symbol-quick-order-market-alert {
                padding-left : 0px;
                padding-right: 0px;
                margin-bottom: 11px;
            }

            .block-info-symbol-putthrough-order {
                padding         : 6px 8px;
                background-color: themed("n_layout_btn_bg");
                width           : 100%;
                border-radius   : $border-radius;
                height          : auto;
                min-height      : 40px;
                cursor          : pointer;

                .info-symbol {
                    width: 100%;

                    .custom-row {
                        display        : flex;
                        justify-content: space-between;
                    }
                }
            }

            .customer-input {
                border-bottom: 1px solid themed("n_layout_border");
                ;

                .custom-form-group {
                    border-bottom: none;
                }
            }

            .account-input {
                border-bottom: 1px solid themed("n_layout_border");
                ;

                .custom-form-group-select {
                    border-bottom: none;

                    .custom-form-select {
                        border-bottom: none;
                    }
                }
            }

            .input-label {
                // padding-top: 10px;
            }

            // div{
            //     padding: 0px;
            // }

            .custom-form-group {
                // padding-bottom: 10px;
            }

            .custom-form-control {
                // padding-bottom: 10px;
            }

            .input-label {
                color: themed("n_layout_text_not_active");
            }
        }

        .title {
            padding: 10px 10px 10px 15px;
        }
    }
}