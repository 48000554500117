.resizesensor-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .resizesensor-trigger {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    > div {
      background: #eee;
      overflow: auto;
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .resizesensor-contract:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}
