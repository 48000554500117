@import "../../../../styles/common";

.order-history {
    @include themify($themes) {
        height: 100%;

        .table-ex-container{
            height: calc(100% - 50px);
        }

        .confirm-all-container{
            margin-right: 5px;
            button{
                width: 120px;
            }
        }

        .order-history-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .container-table {
            height  : 100%;
            overflow: scroll;
        }

        .bold {
            font-weight: bold;
        }

        .action-container {
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            padding-bottom : 10px;

            .btn-confirm {
                min-width: 80px
            }
        }

        .suggest-input-container {
            input {
                &::placeholder {
                    color: themed("n_layout_text_active");
                }
            }
        }

        .securities-transaction-report-content {
            display       : flex;
            flex-direction: column;
            height        : 100%;
        }

        .search-container {
            margin   : 0 auto 8px auto;
            max-width: 590px;

            .custom-form-group {
                width: 100%;
            }

        }

        .container-table {
            height  : 100%;
            overflow: hidden !important;
        }

        .table-show {
            padding: 0px;
        }

        .table-container {
            height           : 100%;
            max-width        : 100%;
            // overflow      : auto;
            padding-bottom   : 5px;
            width            : 100%;

            table {
                height: 100%;
                width : 100%;

                thead {
                    border: 1px solid themed("n_table_border");

                    th {
                        background : themed('n_table_header');
                        padding-top: 10px;
                        color      : themed('n_layout_text_active');
                        border     : 1px solid themed("n_table_border");


                        .custom-form-control {
                            height       : 28px !important;
                            margin-top   : 5px;
                            color        : themed('n_layout_text_active');
                            border-radius: 2px;
                            margin       : 4px;
                            background   : themed('n_table_filter_bg');
                            width        : 100%;
                            border       : none;
                            outline      : none;

                            &:focus-visible {
                                border : none;
                                outline: none;
                            }
                        }
                    }
                }

                tbody {
                    tr:nth-child(odd) td {
                        // background: themed("n_table_row_even");
                        background: themed("n_table_row");
                    }

                    tr:nth-child(even) td {
                        // background: themed("n_table_row_odd");
                        background: themed("n_table_row");
                    }

                    tr {

                        &:nth-child(odd):not(.add-symbol-row) {
                            // background: lighten(themed("n_layout_bg_block"), 3);
                        }

                        // border : 1px solid themed("n_table_border");


                        &:hover:not(.add-symbol-row) {}

                        .btn {
                            background: themed("n_layout_primary");
                            padding   : 4px;
                        }

                        td {
                            border    : 1px solid themed("n_table_border");
                            // padding: 5px;
                        }
                    }
                }
            }

        }


    }
}