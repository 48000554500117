@import "../../../../styles/common";

.condition-order-tooltip-poper{
    opacity: 1 !important;
    .condition-order-tooltip {
        @include themify($themes) {
            background: themed('tooltip-bg-custom') !important;
            color: themed('n_text_custom') !important;
        }
        text-align: left !important;
    }

    
}
.condition-order {
    @include themify($themes) {
        .type-view {
            .content-left {
                width: 35% !important;
            }

            .content-right {
                // display: flex;
                width: 60% !important;
                // justify-content: center !important;
                // text-align: center !important;

                // &.order-type, &.row-select-order-condition{
                //     width: 65% !important; 
                // }

                // .row-input{
                //     width: 70% !important;
                // }
            }
        }

        .row-select-order-condition {
            display: flex;
            gap    : 10px;

            .custom-form-select-option {
                width: 100%;

                .custom-control-select-option {
                    width           : 100%;
                    max-width       : unset;
                    // background-color: themed("n_layout_bg");
                    background-color: themed('n_form_bg');
                    border          : 1px solid themed("input_border_normal");
                    color           : themed('n_layout_text_near_active') !important;
                }
            }

            .container-settings {
                background   : themed("n_layout_btn_bg");
                padding      : 5px;
                border-radius: 4px;
            }
        }
    }
}