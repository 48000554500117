@import "../styles/common.scss";

.error-container {
  @include themify($themes) {
    width          : 100vw;
    height         : 100vh;
    display        : flex;
    flex-direction : column;
    justify-content: center;


    .image {
      text-align: center;

      img {
        height: 200px;
        width : auto;
      }

      svg {
        height: 200px;
        width : auto;
        fill  : themed("n_layout_text_active");
      }
    }

    .message {
      margin-top: 10px;
      font-size : $font-size-big;
      text-align: center;
      color     : themed("n_layout_text_active");
    }

    .action {
      margin-top: 20px;
      text-align: center;

      .btn {
        text-transform: uppercase;
      }
    }

  }
}