@import "../../../../../styles/common";

.modal-company-detail-news {
    @include themify($themes) {
        max-width: 800px;
        margin   : 0 auto;

        .modal-content {
            .header {
                height : unset;
                padding: 5px 20px 0px 20px
            }

            .body {
                padding       : 10px 10px 10px 20px;
                overflow      : unset !important;
                display       : flex;
                flex-direction: column;
                gap           : 5px;
                font-size     : 13px !important;

                .body-top {}

                .body-content {
                    overflow     : auto;
                    height       : 100%;
                    padding-right: 10px;
                    margin-top: 0 !important;

                    .content {
                        text-align: justify;

                        p {
                            margin-bottom: 0px !important;
                        }

                        hr {
                            margin-top   : 0px !important;
                            margin-bottom: 0px !important;
                        }

                        img {
                            width : 100% !important;
                            height: auto !important;
                        }

                        // p {
                        //     color: themed("n_layout_text_near_active");
                        // }

                        .link-ref {
                            cursor    : pointer;
                            text-align: end;

                            .title {
                                border-bottom: 1px solid themed("n_layout_text_near_active");
                            }

                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
    }
}