@import "../../../styles/common";

.order-book-quick-order-container {
    @include themify($themes) {
        .block-1 {
            // background-image   : url("../../../assets/images_new/bg_quickoder.png");
            margin-top: 10px;
            // background   : linear-gradient(180deg, #3A4FAC 0%, #9E44E4 100%);
            background   : #F27801;
            background-position: right top;
            background-repeat  : no-repeat;
            display            : block;
            flex-direction     : column;
            justify-content    : space-between;
            position           : relative;
            background-size    : cover;
            height: 108px;
            border-radius: 4px;

            .row-block-1 {
                display        : flex;
                justify-content: space-between;
                padding        : 8px 10px;
                color          : themed("n_text_custom");
            }


            .block-wrap {
                background-color: themed("n_layout_bg_block");
                border-radius   : 15px 15px 0px 0px;

                .block-content {
                    padding: 10px 10px 0px 10px;

                    .block-2 {
                        margin: 10px 0px 0px 0px;

                        .list-status-filter {
                            display        : flex;
                            justify-content: space-between;

                            // Bỏ nếu môi giới cho phép hiển thị sổ lệnh điều kiện
                            &.broker{
                                justify-content: start;
                                gap: 10px;
                            }

                            .status-item {
                                padding      : 5px 8px;
                                background   : themed("n_layout_btn_bg");
                                border-radius: $border_radius;
                                cursor       : pointer;
                            }
                        }
                    }


                    .block-3 {
                        margin  : 10px 0px 0px 0px;
                        overflow: auto;
                        height  : calc(100vh - 360px);

                        &.content-filter {
                            height: calc(100vh - 385px);
                        }

                        .table-order-book {
                            .basic-table {
                                // transition        : all 2s;
                                // -webkit-transition: all 2s;
                                border    : none;
                                background: themed("n_table_bg");

                                thead {
                                    tr {
                                        padding      : 10px 0px;
                                        border-bottom: 1px solid themed("n_table_border");
                                        position     : sticky;
                                        top          : 0;
                                        z-index      : 2;
                                        background   : themed("n_table_bg");
                                    }
                                }

                                tbody {
                                    tr {
                                        cursor: pointer;

                                        td {
                                            padding      : 10px 0px;
                                            border-bottom: 1px solid themed("n_table_border");

                                            .status-order {
                                                position: relative;

                                                .status-icon {
                                                    position     : absolute;
                                                    background   : #FC944D;
                                                    border-radius: 50%;
                                                    height       : 8px;
                                                    width        : 8px;
                                                    top          : 4px;
                                                    left         : -15px;
                                                }
                                            }
                                        }
                                    }

                                    .block-info-detail-order {
                                        border-radius: 10px;

                                        &.active {}

                                        &.not-active {
                                            display: none;
                                        }

                                        td {

                                            .info-detail-order {
                                                padding: 5px 0px;

                                                .wrap-content {
                                                    width        : 100%;
                                                    display      : flex;
                                                    background   : themed("n_layout_active_bg");
                                                    border-radius: $border_radius;

                                                    .content-order {
                                                        width  : 50%;
                                                        padding: 0px 8px;

                                                        .row-content {
                                                            display        : flex;
                                                            padding        : 2px 0px;
                                                            justify-content: space-between;
                                                            border-bottom  : 1px solid themed("n_table_border");
                                                        }
                                                    }
                                                }

                                                .action-order {
                                                    margin-top: 10px;

                                                    .container-btn {
                                                        display: flex;
                                                        gap    : 10px;

                                                        .btn-action {
                                                            width: 50%;
                                                            color: themed("n_layout_text_active");

                                                            &.btn-fix {
                                                                background: themed("n_layout_primary");
                                                            }

                                                            &.btn-cancel {
                                                                background: themed("n_layout_reduce_bg");
                                                            }

                                                            &:disabled {
                                                                background: themed("n_layout_btn_bg");
                                                            }

                                                            &.full{
                                                                width: 100%;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .block-4 {
                        // position: fixed;
                        // bottom  : 20px;
                        // width   : calc(100% - 20px);
                        margin-top: -15px;

                        .container-btn {
                            display: flex;
                            gap    : 10px;

                            .btn-action {
                                width: 50%;
                            }
                        }

                    }
                }
            }
        }
    }

    .scroll-order-book {
        height: calc(100vh - 405px) !important
    }
}