@import "../styles/common";



.fss.isMobile {
  // overflow      : hidden !important;
  display       : flex;
  flex-direction: column;

  .content-container {
    // height       : calc(100vh + 160px + 50px) !important;
    overflow     : hidden !important;
    padding      : (160px+80px) 0px 0px 0px;
    // padding: 135px 0px 0px 0px;
    // height    : calc(100vh - 10px) !important;

    .priceboard-content {
      padding-bottom: 10px !important;
    }
  }

  .content-container:has(.open-account-ekyc-container) {
    padding: 0px !important;
    .open-account-register-title{
      text-align: center;
      margin-top: 0px;
      height: 50px;
    }
    .open-account-register-step-container{
      height: calc(100% - 85px);
      width: calc(100% - 10px) !important;
      margin: 5px;
      .open-account-ekyc-left-step{
        padding: 5px 5px 10px 5px !important;

        .text-step{
          margin-left: 5px !important;
          margin-right: 5px !important;
        }
      }
      .open-account-ekyc-right-step{
        padding-left: 10px !important;
      }
      .form-container{
        margin-bottom: 45px !important;
      }
      
      .custom-form-group.col-12{
        display: flex;
      }
      .custom-form-group:has(.invalid-feedback){
        margin-bottom: 25px !important;
      }
      
    }
  }

  .content-container:has(.container-modal-screen) {
    // content         : ""; // ::before and ::after both require content
    // position        : absolute;
    // top             : 0;
    // left            : 0;
    // width              : 100%;
    // height             : 100%;
    // background-color   : red;
    // opacity         : .7;

    >div {
      display: none;
    }

    .container-modal-screen {
      display: block;
    }
  }

  .content-container-top-moblie-logout {
    padding-top: 190px !important;
  }

  .scroll-moblie {
    flex-wrap: nowrap;
    overflow : scroll;
  }

  .scroll-x-moblie {
    // flex-wrap : nowrap;
    // overflow-x: scroll;
    // overflow-y: inherit;
  }

  .scroll-y-moblie {
    flex-wrap : nowrap;
    overflow-x: inherit;
    overflow-y: scroll;

  }
}

#content {
  height: 100vh !important;

  @include themify($themes) {
    background: themed("n_layout_bg");
  }

  display  : flex;
  flex-grow: 1;
}



.main-container {
  display       : flex;
  flex-grow     : 1;
  flex-direction: column;
  max-width     : 100%;
}


.bodyContainer {
  // display: flex;
  // height : 100%;

  display: inline-flex;
  height : 100vh;
}

.bodyContainer-x {
  display   : inline-flex;
  height    : 100%;
  margin-top: 40px;
}

.menuSideBar {
  width : 300px;
  height: 100%;
}

.content-container {
  // margin-left: 5px;
  overflow : auto !important;
  padding  : 50px 0px 0px 0px;
  display  : flex;
  flex-grow: 1;
  width    : calc(100% - 75px); // 75px là chiều dài menutool
  height   : 100%;
  margin   : 0;

  scrollbar-width: thin;

  // padding-top: 40px;
  // height: calc(100% + 40px);
  // height: calc(100% - 360px);
  @include themify($themes) {
    background: themed("n_layout_bg");
  }
}

.content-container-x {
  // margin-left: 5px;
  overflow : auto;
  padding  : 10px;
  display  : flex;
  flex-grow: 1;
  width    : calc(100% - 300px);
  height   : calc(100vh - 46px);
  margin   : 0;

  scrollbar-width: thin;

  // padding-top: 40px;
  // height: calc(100% + 40px);
  // height: calc(100% - 360px);
  @include themify($themes) {
    background: themed("n_layout_bg");
  }
}

.app-loading {
  width   : 100%;
  height  : 100vh;
  position: relative;
}

.footerPage {
  background: #232c3a;
  height    : 40px;
  margin-top: 40px;
  z-index   : 998;
}

.popupFooter {
  background: red;
  display   : contents;
}

.content-body {
  // overflow      : auto;
  width : 100%;
  height: 100%;

  padding-bottom: 10px;
}


// từ 767px trở xuống
@include media-breakpoint-down(sm) {
  .content-container {
    padding: 135px 5px 5px 5px;
  }
}

// từ 991px trở xuống
@include media-breakpoint-down(md) {}

// từ 1199px trở xuống
@include media-breakpoint-down(lg) {}