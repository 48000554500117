@import "../../styles/common";

.custom-row-group {
    @include themify($themes) {
        border-bottom: 1px solid themed("n_layout_border");

        .custom-row-form {
            padding: 14px 0px;

            .label {
                color      : themed('n_layout_text_not_active');
                font-weight: 400;
                font-size  : 14px;
                line-height: 20px;
                text-align : left !important;
                height     : 100%;
                align-items: center;
                display    : flex;
                word-break : break-word;

            }

            .value {
                color      : themed('n_layout_text_active');
                font-weight: 400;
                font-size  : 14px;
                line-height: 20px;
                text-align : right !important;
                word-break : break-word;

            }
        }
    }

    &.hidden-line {
        border-bottom: none !important;
    }
}