@import "../../../../../styles/common";

.condition-order-tpsl {
    @include themify($themes) {
        .wrap-border-block {
            position     : relative;
            border-radius: $border_radius;
            border       : 1px solid themed("n_layout_border");
            margin       : 15px 0px;

            .title-block {
                position  : absolute;
                top       : -10px;
                left      : 5px;
                background: themed("n_layout_bg_block");

                span {
                    padding: 0px 5px;
                }
            }

            .type-view {
                padding: 10px;

                &.last-type-view {
                    margin     : 0px;
                    padding-top: 0px;
                }
            }

        }


        .type-view {
            display: flex;
            margin : 10px 0px;

            &:first-child {
                margin-top: 0px;
            }

            &:last-child {
                margin-bottom: 0px;
            }

            .content {
                height: 32px;
            }

            .content-left {
                width      : 30%;
                display    : flex;
                align-items: center;
            }

            .content-right {
                width: 70%;
            }
        }

        .show-info {
            .content {
                .row-input {
                    height          : 100%;
                    display         : flex;
                    justify-content : start;
                    align-items     : center;
                    // background-color: themed('n_form_bg');
                    background-color: transparent;
                    border-radius   : $border_radius;

                    .value {
                        padding-left: 5px;
                    }
                }
            }
        }

        .price-order {
            .content-right {
                display: flex;
                gap    : 10px;

                .row-input {
                    width : 100%;
                    height: 100%;

                }

                .row-note {
                    width        : 40%;
                    gap          : 5px;
                    display      : flex;
                    height       : 100%;
                    align-items  : center;
                    margin-bottom: 3.5px;
                }
            }
        }


        .present-order {
            margin-top: 0px;

            .content-right,
            .content-full,
            .content-present-broker {
                gap: 5px;

                .select-present {
                    align-items       : center;
                    justify-content   : center;
                    white-space       : nowrap;
                    border            : 1px solid themed("n_layout_border");
                    // height         : 100%;
                    height            : 24px; // Theo web cũ APG
                    cursor            : pointer;
                    border-radius     : $border_radius;
                    color             : themed("n_layout_text_not_active");
                    // flex           : 1 0 0;
                    // display        : flex;
                    // width: 50px;
                    padding           : 2px 5px 2px 5px;
                    background-color  : themed("btn_background_custom");

                    &:focus-visible {
                        box-shadow: themed("n_layout_box_shadow_bg") 1px 1px 1px;
                        border    : none;
                        outline   : none;
                    }

                    &.active {
                        border: 1px solid themed("n_layout_text_active");
                        color : themed("n_layout_text_active");
                    }
                }
            }

        }

        .select-date {
            .content-right {
                display        : flex;
                justify-content: space-between;
            }
        }

        .action-container {
            width  : 100%;
            display: flex;
            gap    : 10px;

            .btn-action {
                height        : 40px;
                width         : 100%;
                border        : none;
                outline       : unset;
                color         : themed("n_text_custom");
                text-transform: uppercase;
            }

            .btn-cancel-input {
                padding      : 9px 13px;
                border-radius: 5px;
            }

            .btn-reset-input {
                padding      : 9px 13px;
                border-radius: 5px;
            }
        }

        .resizesensor-wrapper {
            height: 400px;
        }

    }
}