@import "../../../styles/common";

.detail-page {
    .full-screen-marketindex-detail-page {
        height: 100%;
    }
}

.isMobile {
    .index-detail-page {
        padding-top: 0px;
    }
}

.index-detail-page {
    @include themify($themes) {
        // overflow-x: hidden;
        height     : calc(100vh - 40px);
        width      : 100%;
        // overflow: auto;
        padding    : 10px;

        .item-info {
            display        : flex;
            height         : 44px;
            flex-direction : row;
            justify-content: space-between;
            border-bottom  : 1px solid themed("n_layout_border");
            align-items    : center;
        }


        &.full-screen {
            height: 100%;

            .block {
                max-height: unset !important;
                min-height: unset !important;
            }

        }

        .full-screen {
            height: 100%;
        }

        .right-block {
            // padding-left: 0px !important;
        }


        .block {
            background   : themed("n_bg_block");
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;
            max-height   : 500px;
            min-height   : 440px;

            .h-row {
                height: 44px;
            }

            .h-header-row {
                height: 38px;
            }

            .line-row {
                padding: 0 5px !important;

                .line {
                    border-bottom: 1px solid themed("n_layout_border");
                }
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 13px;
            }

            .item-info {
                display        : flex;
                height         : 44px;
                flex-direction : row;
                justify-content: space-between;
                border-bottom  : 1px solid themed("n_layout_border");
                align-items    : center;
            }

            .left-block-info {
                padding-right: 8px !important;
            }

            .right-block-info {
                padding-left: 8px !important;
            }
        }


        .text-ceil-price {
            color: themed("n_price_ceil_text") !important;
        }

        .text-floor-price {
            color: themed("n_price_floor_text") !important;
        }

        .text-ref-price {
            color: themed("n_price_ref_text") !important;
        }

        .text-priceboard-green {
            color: themed("n_layout_increase_text") !important;
        }

        .text-priceboard-red {
            color: themed("n_layout_reduce_text") !important;
        }

        .line {
            background-color: themed("n_table_border");
            height          : 1px;
            width           : 100%;
            margin-bottom   : 10px;
        }

        .no-padding {
            padding: 0px !important;
        }

        .pr-10 {
            padding-right: 10px;
        }

        .no-padding-right {
            padding-right: 0px !important;
        }

        .market-info-news-item {
            cursor          : pointer;
            // border-bottom: 1px solid themed("n_table_border");
            margin-bottom   : 10px;

            // div {
            //     height: 57px;
            //     margin-bottom: 10px;
            // }

            img {
                border-radius: 4px;
                width        : 100%;
                height       : 58px;
            }

            p {
                margin-bottom: 0px;
            }

            .news-header {
                min-height: 40px;
                color     : themed("n_layout_text_near_active");
            }

            .news-date {
                font-weight: 400;
                font-size  : 12px;
                line-height: 150%;
                color      : themed("n_layout_text_not_active");
            }
        }

        .button-container {
            button {
                width        : 100%;
                height       : 39px;
                border-radius: 4px;
            }

            div {
                text-align: center;
            }

            .sell-btn {
                background: linear-gradient(90deg, #ff4444 0%, #c20606 100%);
            }

            .buy-btn {
                background: linear-gradient(90deg, #26e07c 0%, #00b854 100%);
            }
        }

        .back-btn-container {
            button {
                width        : 100%;
                height       : 20px;
                border-radius: 15px;
                background   : themed("n_layout_active_bg");
            }
        }

        .market-info-news-detail {
            .detail-header {
                color: themed("n_layout_text_near_active");
            }

            .line {
                margin: 0px 15px 10px 15px;
            }

            .detail-content {
                margin-bottom: 10px;

                img {
                    width: 100%;
                }

                p {
                    margin: 0px;
                }
            }

            .detail-title {
                margin: 10px 0px 10px 0px;

                p {
                    margin: 0px;
                }

                .post-date {
                    // font-weight: 400;
                    // font-size: 12px;
                    // line-height: 150%;
                    color: rgba(255, 255, 255, 0.4);
                }
            }
        }

        .load-more {
            color : themed("n_layout_text_not_active");
            cursor: pointer;
        }

        .custom-form-group {
            text-align: right;
        }

        .text-ceil-price {
            color: themed("n_price_ceil_text") !important;
        }

        .text-floor-price {
            color: themed("n_price_floor_text") !important;
        }

        .text-ref-price {
            color: themed("n_price_ref_text") !important;
        }

        .title {
            // margin-bottom: 22px;
            padding-bottom: 10px;

            &.tech-chart {
                display: inline-flex;
                float  : left;
            }
        }

        .action-btn {
            display: inline-flex;
            float  : right;

            border        : none;
            font-size     : $font-size-medium;
            // line-height: 36px;
            padding       : 0;
            text-align    : center;
            user-select   : none;

            &.active {
                color: themed("n_layout_text_active");
            }

            :hover {
                color: themed("n_layout_text_active");
            }
        }

        .label {
            display      : inline-block;
            margin-bottom: 0px;
            font-weight  : 400; // Body Medium - Regular
            font-size    : 14px;
            line-height  : 20px;
            color        : themed("n_layout_text_not_active");
        }

        .last-info-row>div {
            padding-bottom: 10px;
        }

        .block {
            background   : themed("n_bg_block");
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 13px;
            }
        }

        .block-header {
            background   : themed("n_bg_block");
            border-radius: 4px;
            height       : 100%;
        }

        .block-right {
            background   : themed("n_bg_block");
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;
            width        : 100%;

            .container-block {
                display       : flex;
                flex-direction: row;
                gap           : 10px;

                .block-item {
                    height: auto;
                    width : 50%;
                }

                .row-title {
                    height            : 36px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed("n_table_border");
                }

                .row-item {
                    line-height    : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .row-item-flex {
                    line-height    : 24px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed("n_table_border");
                    padding      : 10px;
                    border-radius: 4px;
                }
            }
        }

        .near-active-txt {
            color: themed("n_layout_text_near_active");
        }

        .stock-container {
            // height       : 430px;
            // max-height   : 430px;
            // margin-bottom: 10px;
            // overflow: auto;
            height        : 100%;
            max-height    : 500px;
            min-height    : 440px;
            padding-bottom: 0px;

            .symbol-item-container {
                overflow    : auto;
                margin-left : 0px;
                margin-right: 0px;
                height      : calc(100% - 82px); //82px Chiều cao của title và tab 

                &.non-exchange {
                    // Cho chỉ số không nằm trong list sàn (HOSE,HNX,UPCOM)
                    margin-top: 10px;
                    height    : calc(100% - 50px); //21.01 Chiều cao của title 
                }
            }

            // padding-top: 0px;

            .title {
                // padding-top: 10px;
                // position: sticky;
                // top: 0px;
                // background: themed("n_bg_block");
                // z-index: 2;
            }

            .tab-container {
                // top: 31.01px;
                // z-index: 2;
            }
        }

        .style-desc {
            text-overflow: ellipsis;
            white-space  : nowrap;
            overflow     : hidden;
            cursor       : pointer;
        }

        .symbol-item {
            z-index      : 1;
            min-height   : 56px;
            margin-left  : 0px;
            margin-right : 0px;
            border-bottom: 1px solid themed("n_layout_border");
            ;
            margin-bottom: 10px;
            padding-left : 5px !important;
            padding-right: 5px;

            div {
                padding: 0px;

                p {
                    margin-bottom: 5px;
                }
            }

            .symbol-exchange {
                color: themed("n_layout_text_near_active");
            }
        }

        .tab-container {
            margin-right : 0px;
            margin-left  : 0px;
            z-index      : 1;
            position     : sticky;
            top          : 0px;
            margin-bottom: 20px;
            padding-top  : 10px;
            height       : 35px;
            background   : themed("n_bg_block");
            flex-wrap    : nowrap;
            height       : auto;

            .tab-item {
                cursor       : pointer;
                padding-left : 5px;
                padding-right: 5px;

                &.active {
                    color        : themed(n_layout_text_secondary_1);
                    border-bottom: 2px solid themed(n_layout_text_secondary_1);
                }
            }
        }

        .news-container {
            // height       : 430px;
            // max-height   : 430px;
            height     : 100%;
            max-height : 500px;
            min-height : 440px;
            overflow   : auto;
            padding-top: 0px;

            // margin-bottom: 10px;
        }

        .horizontal-menu {
            height       : 100%;
            padding-right: -8px !important;

            &.arrow-hidden {
                .scroll-menu-arrow {
                    display: none;
                }
            }
        }

        .match-order-detail-by-cus-modal-frpb-market-index {
            background-color: themed("n_layout_bg_block");
            color           : themed("n_layout_text_active");
            margin          : 0px;
            height          : calc(100% - 40px);
            overflow        : scroll;

            .row {
                margin-bottom: 0;
            }

            .header-info {
                padding: 5px 20px;

                .custom-row {
                    display: flex;

                    .header-info-label {
                        width  : 50%;
                        padding: 0;
                    }

                    .active {
                        color: themed("n_layout_text_active");

                        body.theme-white &,
                        body.theme-classic & {
                            color: themed("n_layout_text_active");
                        }
                    }
                }
            }

            .header-info-label {
                font-weight: bold;
                padding    : 5px 5px 5px 25px;
                line-height: 30px;
            }

            .scrollbars-content {
                max-height: 400px !important;
                height    : 100%;

                div:first-child {
                    max-height: 400px !important;
                    height    : 100%;
                }
            }

            table {
                color   : themed("n_layout_text_near_active");
                //margin: 0 15px 15px 0px;
                width   : 100%;

                thead {
                    tr {
                        &.info-row {
                            td {}
                        }
                    }

                    th {
                        line-height: 40px;
                        padding    : 0 5px;
                        white-space: nowrap;
                        background : themed("n_bg_block");
                    }

                    .header-info {
                        top: 1px;
                    }

                    .header-table {
                        // border-bottom: 1px solid themed("n_table_border");
                        position: sticky;
                        top     : 0px;
                    }
                }

                tbody {
                    td {
                        // border-right: 1px solid themed("n_table_border");
                        line-height: 32px;
                        padding    : 0 5px;
                        white-space: nowrap;

                        body.theme-white &,
                        body.theme-classic & {
                            border-right: 1px solid red;
                        }
                    }

                    tr {
                        height: 40px;

                        td {
                            // border-bottom: 1px solid themed("n_table_border");
                        }

                        &.total {
                            &:hover {
                                cursor: pointer;
                            }

                            .active {
                                font-weight: bold;
                                color      : themed("n_table_text_active");

                                body.theme-white &,
                                body.theme-classic & {
                                    color: themed("n_layout_border");
                                }
                            }
                        }

                        &.total-sum {
                            td {
                                border        : none;
                                position      : sticky;
                                bottom        : -1px;
                                font-weight   : bold;
                                padding-bottom: 5px;
                                background    : #1e1e1e;

                                color: #fc0390;
                            }
                        }

                        // &:nth-child(odd) {
                        //   background: themed('basic-table-header-bg');
                        // }
                    }
                }
            }
        }
    }
}



@include media-breakpoint-down(md) {
    .index-detail-page {
        @include themify($themes) {
            .pr-10 {
                padding-right: 5px !important;
            }

            .block-chart {
                padding-bottom: 10px !important;
            }

            .last-info-row>div {
                padding-bottom: 10px !important;
            }

            .last-info-row {
                .block-news-container {
                    padding-bottom: 15px !important;
                }
            }
        }
    }
}