@import "../../../../styles/common";

.modal-cancel-draft-order {
    @include themify($themes) {
        max-width: 600px;
        margin: auto;
        height: auto;
        right: 0px;

        .body {
            background: themed("n_layout_bg_block");
            padding: 20px;

            .table-sell-all-order{
                height: calc(100vh - 230px) !important;
            }

            .wrap-body {
                .info-detail {
                    display: flex;
                    gap: 15px;
                    margin: 20px 0px;

                    .row-content {
                        display: flex;
                        gap: 5px;
                    }
                }

                .action-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px 0px 10px 0px;
                    margin-bottom: 10px;

                    .container-select {
                        gap: 4px;
                        width: 100vw;
                        overflow: auto;
                    }

                    .btn-search{
                        border: solid 1px transparent;
                    }
                }

                .basic-table {
                    // transition        : all 2s;
                    // -webkit-transition: all 2s;
                    border: none;
                    width: 100%;

                    thead {
                        tr {
                            background: themed("n_table_header");
                            position: sticky;
                            top: 0px;

                            // z-index      : 10;

                            th {
                                padding: 5px 0px;
                                border-top: 1px solid themed("n_table_border");
                                border-left: 1px solid themed("n_table_border");
                                border-right: 1px solid themed("n_table_border");
                            }

                            // th:first-child {
                            //     border-left: none;
                            // }

                            // th:last-child {
                            //     border-right: none;
                            // }
                        }

                        // .dx-datagrid-checkbox-size {
                        //     text-align: center;
                        //     .dx-checkbox-icon::before {
                        //         color: themed("n_table_tick_box") !important;
                        //     }

                        //     .dx-checkbox-icon::after {
                        //         color: themed("n_table_tick_box") !important;
                        //     }

                        //     .dx-checkbox-icon {
                        //         // css để ẩn giao diện [-] nếu return false
                        //         background-color: themed("n_table_header") !important;
                        //         border: 2px solid themed("n_table_check_box") !important;
                        //     }

                        //     .dx-checkbox-icon::before {
                        //         // css để ẩn giao diện [-] nếu return false
                        //         content: none;
                        //     }

                        //     .dx-checkbox-icon::after {
                        //         // css để ẩn giao diện [-] nếu return false
                        //         content: none;
                        //     }
                        // }
                    }

                    tbody {
                        tr {
                            background: themed("n_table_row_even");
                            border: 1px solid themed("n_table_border");

                            td {
                                padding: 5px 5px;
                                border-bottom: 1px solid themed("n_table_border");
                                border-left: 1px solid themed("n_table_border");
                                border-right: 1px solid themed("n_table_border");
                            }
                        }

                        tr:nth-child(odd) td {
                            background: themed("n_table_row");
                        }

                        tr:nth-child(even) td {
                            background: themed("n_table_row");
                        }

                        .btn-refresh {
                            color: themed("n_layout_text_active");
                            background-color: themed("n_layout_btn_bg");
                            border-radius: 2px;
                            padding: 4px 10px;
                        }

                        .btn-confirm {
                            color: themed("n_layout_text_active");
                            background: themed("n_layout_primary");
                            border-radius: 2px;
                            padding: 4px 10px;
                        }

                        td.edit-col {
                            cursor: pointer;
                        }
                    }
                }

                .btn-container {
                    width: 100%;
                    height: 40px;
                    gap: 10px;
                    display: flex;
                    margin-top: 10px;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
