@import "../../../../styles/common";

.fss.isMobile {
  .trade-auth-quick-order-modal {
    width: 95% !important;
  }
}


.trade-auth-quick-order-modal {
  @include themify($themes) {
    // min-width: 350px;
    // max-width: 500px ;
    // position : fixed;
    // margin   : auto;
    // height   : 100%;
    // right    : 0px;
  }
}

.trade-auth-normal-order-modal,.trade-auth-quick-order-modal {
  max-width: 425px !important;
}