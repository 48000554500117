@import "../../styles/common";

.custom-form-group-select {
    @include themify($themes) {
        .custom-form-select {
            border          : 1px solid themed("input_border_normal") !important;
            border-radius   : 4px;

            &:focus{
                border: 1px solid themed("input_hover_border_bottom") !important;
            }

            &:hover{
                border: 1px solid themed("input_hover_border_bottom") !important;
            }

            &.disable-select {
                background-image: none;
                opacity         : 1 !important;
            }
        }
    }
}