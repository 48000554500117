@import "../../../../styles/common";

.trading-money-bidv {
    @include themify($themes) {
        // width    : 500px !important;
        // max-width: 500px !important;
        z-index: 2000;

        .body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }


            .content {
                max-height: 40vh;
                overflow  : auto;
                margin    : 0 auto;
            }

            // .container-btn {
            //     margin-top: 16px;
            //     display   : flex;
            //     gap       : 8px;
            //     flex-wrap : nowrap;
            //     width     : 100%;
            // }
        }

        .custom-footer {
            background: themed('n_layout_bg_block');

            height         : 80px;
            color          : themed('n_layout_text_active');
            width          : 100%;
            align-items    : center;
            display        : flex;
            padding        : 0px 20px;
            justify-content: space-between;

            .btn {
                width  : 100%;
                padding: 0px 34px;
            }
        }

        .custom-icon-reload {
            cursor  : pointer;
            position: absolute;
            right   : 5px;
            top     : -5px;
        }

    }
}