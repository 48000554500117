@import "../../styles/common";

.rc-input-number-qtty {
  @include themify($themes) {
    overflow           : hidden;
    display            : block;
    margin             : 0;
    padding            : 0;
    width              : 100%;
    height             : 32px;
    vertical-align     : middle;
    background-color   : themed("n_layout_bg_block");
    // border             : 1px solid themed("n_layout_border");
    border             : 1px solid themed("input_border_normal");
    // transition      : all .3s;
    position           : relative;
    // border             : none;
    // border-bottom      : 1px solid themed("n_layout_btn_bg");
    // border      : 1px solid themed("n_layout_btn_bg");
    border-radius: 4px;

    &.rc-input-number-qtty-disabled:hover {
      // border-color: themed("n_layout_border");
      border             : 1px solid themed("input_border_normal") !important;
    }

    &:hover {
      // border-bottom: 1px solid themed("input_hover_border_bottom") !important;
      border: 1px solid themed("input_hover_border_bottom") !important;
    }

    &-focused {
      // border-bottom: 1px solid themed("input_hover_border_bottom");
      border: 1px solid themed("input_hover_border_bottom");
    }

    &-disabled {
      &:hover {
        border-color: themed("n_layout_border");
      }
    }

    .rc-input-number-qtty-handler-up-inner,
    .rc-input-number-qtty-handler-down-inner {
      color: themed("n_layout_text_active");

      &:hover {
        color: themed("n_layout_text_active");
      }
    }

    .rc-input-number-qtty-input {
      color: themed("n_layout_text_active");
    }

    &:hover {
      .rc-input-number-qtty-handler {
        i {
          display: inline-block;
        }
      }
    }

    &-disabled {
      background: transparent;
      cursor    : not-allowed;

      .rc-input-number-qtty-input {
        cursor: not-allowed;
      }

      .rc-input-number-qtty-handler {
        opacity: 0.4;
      }
    }

    .rc-input-number-qtty-handler {
      display     : block;
      height      : 13px;
      line-height : 13px;
      overflow    : hidden;
      text-align  : center;
      touch-action: none;

      i {
        display: none;
      }
    }

    .rc-input-number-qtty-handler-up-inner,
    .rc-input-number-qtty-handler-down-inner {
      user-select        : none;
      -webkit-user-select: none;
    }

    .rc-input-number-qtty-input-wrap {
      height  : 100%;
      overflow: hidden;
    }

    .rc-input-number-qtty-input {
      width              : 100%;
      outline            : none;
      -moz-appearance    : textfield;
      line-height        : 26px;
      text-align         : center;
      color              : themed("n_layout_text_active");
      height             : 100%;
      border             : 0;
      border-radius      : 0;
      padding            : 0 5px;
      // background-color   : themed("n_layout_bg_block");
      background-color   : themed("n_layout_bg");
      // background-color   : transparent;
      // transition      : all .3s;
    }

    .rc-input-number-qtty-handler-wrap {
      float        : right;
      height       : 100%;
      // transition: all .3s;
      width        : 20px;
    }

    .rc-input-number-qtty-handler-up {
      padding-top   : 1px;
      // transition : all .3s;
    }

    .rc-input-number-qtty-handler-down {
      // transition: all .3s;
    }

    .rc-input-number-qtty-handler-down-disabled,
    .rc-input-number-qtty-handler-up-disabled {
      opacity: 0.4;
    }

    .btn-plus {
      position: absolute;
      right    : 2px;
      top: 3px;
    }

    .btn-minus {
      position: absolute;
      left    : 2px;
      top: 3px;
    }
  }
}