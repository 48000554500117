@import "../../styles/common";

.pricetype-select-input-suggest {
  max-height: 300px;
  position: relative;
  width: 100%;

  #suggestion-pricetype-box {
    @include themify($themes) {
      background-color: themed("n_layout_bg_block");
      border: 1px solid themed("n_layout_border");

      .suggest-item {
        border-bottom: 1px solid themed("n_layout_border");

        &.suggest-active {
          //background-color: themed("n_layout_text_active");
          background-color: themed("n_layout_bg");
        }

        &:hover {
          //background-color: themed("n_layout_text_active");
          background-color: themed("n_layout_bg_hover");
        }
      }
    }

    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;

    width: 100%;
    z-index: 22;

    .suggest-item {
      height: 26px;
      padding: 2px 2px 2px 5px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .rc-input-number {
    @include themify($themes) {
      overflow: hidden;
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 28px;
      vertical-align: middle;
      background-color: themed("n_layout_bg_block");
      border: 1px solid themed("n_layout_border");
      border-radius: 5px;
      // transition   : all .3s;

      &:hover {
        border-color: themed("n_layout_border");
        .rc-input-number-handler {
          i {
            display: inline-block;
          }
        }
      }

      &-focused {
        border-color: themed("n_layout_border");
        box-shadow: 0 0 0 2px rgba(themed("n_layout_box_shadow_bg"), 0.2);
      }

      &-disabled {
        cursor: not-allowed;
        background: transparent;

        &:hover {
          border-color: themed("n_layout_border");
        }

        .rc-input-number-input {
          cursor: not-allowed;
        }

        .rc-input-number-handler {
          opacity: 0.4;
        }
      }

      .rc-input-number-handler {
        text-align: center;
        line-height: 13px;
        height: 13px;
        overflow: hidden;
        display: block;
        touch-action: none;

        i {
          display: none;
        }
      }

      .rc-input-number-handler-up-inner,
      .rc-input-number-handler-down-inner {
        color: themed("n_layout_text_active");
        user-select: none;
        -webkit-user-select: none;

        &:hover {
          color: themed("n_layout_text_hover");
        }
      }

      .rc-input-number-disabled:hover {
        border-color: themed("n_layout_border");
      }

      .rc-input-number-input-wrap {
        overflow: hidden;
        height: 100%;
      }

      .rc-input-number-input {
        width: 100%;
        outline: none;
        -moz-appearance: textfield;
        line-height: 26px;
        color: themed("n_layout_text_active");
        height: 100%;
        border: 0;
        border-radius: 0;
        padding: 0 5px;
        background-color: themed("n_layout_bg_block");
        // background-color: transparent;
        // transition      : all .3s;
      }

      .rc-input-number-handler-wrap {
        float: right;
        width: 20px;
        height: 100%;
        // transition: all .3s;
      }

      .rc-input-number-handler-up {
        padding-top: 1px;
        // transition : all .3s;
      }

      .rc-input-number-handler-down {
        // transition: all .3s;
      }

      .rc-input-number-handler-down-disabled,
      .rc-input-number-handler-up-disabled {
        opacity: 0.4;
      }
    }
  }

  .rc-input-number-price {
    @include themify($themes) {
      overflow           : hidden;
      display            : block;
      margin             : 0;
      padding            : 0;
      width              : 100%;
      height             : 32px;
      vertical-align     : middle;
      background-color   : themed("n_layout_bg_block");
      // border             : 1px solid themed("n_layout_border");
      border             : 1px solid themed("input_border_normal");
      // transition      : all .3s;
      position           : relative;
      // border             : none;
      // border-bottom      : 1px solid themed("n_layout_btn_bg");
      // border             : 1px solid themed("n_layout_btn_bg");
      border-radius: 4px;

      &.rc-input-number-price-disabled:hover {
        // border-color: themed("n_layout_border");
        border             : 1px solid themed("input_border_normal") !important;
      }
  
  
  
      &:hover {
        // border-bottom: 1px solid themed("input_hover_border_bottom") !important;
        border: 1px solid themed("input_hover_border_bottom") !important;
  
        .rc-input-number-price-handler {
          i {
            display: inline-block;
          }
        }
      }
  
      &-focused {
        // border-bottom: 1px solid themed("input_hover_border_bottom");
        border: 1px solid themed("input_hover_border_bottom") !important;
      }
  
      // &-focused {
      //   border-color: themed("n_layout_border");
      //   box-shadow  : 0 0 0 2px rgba(themed("n_layout_box_shadow_bg"), 0.2);
      // }
  
      &-disabled {
        cursor    : not-allowed;
        background: transparent;
  
        &:hover {
          border-color: themed("n_layout_border");
        }
  
        .rc-input-number-price-input {
          cursor: not-allowed;
        }
  
        .rc-input-number-price-handler {
          opacity: 0.4;
        }
      }
  
      .rc-input-number-price-handler {
        text-align  : center;
        line-height : 13px;
        // height      : 13px;
        overflow    : hidden;
        display     : block;
        touch-action: none;
  
        i {
          display: none;
        }
      }
  
      .rc-input-number-price-handler-up-inner,
      .rc-input-number-price-handler-down-inner {
        color              : themed("n_layout_text_active");
        user-select        : none;
        -webkit-user-select: none;
  
        &:hover {
          color: themed("n_layout_border");
        }
      }
  
      .rc-input-number-price-handler-down-inner {
        padding-top: 1.5px;
      }
  
  
      .rc-input-number-price-input-wrap {
        overflow: hidden;
        height  : 100%;
      }
  
      .rc-input-number-price-input {
        width              : 100%;
        outline            : none;
        -moz-appearance    : textfield;
        line-height        : 26px;
        text-align         : center;
        color              : themed("n_layout_text_active");
        height             : 100%;
        border             : 0;
        border-radius      : 0;
        padding            : 0 5px;
        // background-color   : themed("n_layout_bg_block");
        background-color   : themed("n_layout_bg");
        // background-color: transparent;
        // transition      : all .3s;
      }
  
      .rc-input-number-price-handler-wrap {
        float        : right;
        width        : 20px;
        height       : 100%;
        // transition: all .3s;
      }
  
      .rc-input-number-price-handler-up {
        padding-top   : 1px;
        // transition : all .3s;
      }
  
      .rc-input-number-price-handler-down {
        // transition: all .3s;
      }
  
      .rc-input-number-price-handler-down-disabled,
      .rc-input-number-price-handler-up-disabled {
        opacity: 0.4;
      }
  
      .btn-plus {
        position: absolute;
        right    : 2px;
        top: 2px;
      }
  
      .btn-minus {
        position: absolute;
        left    : 2px;
        top: 2px;
      }
    }
  }
}
