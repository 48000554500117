@import "../../../../styles/common";

.catalog-lookup {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .node-container{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            flex-wrap: wrap;

            p{
                color   : themed('n_layout_text_active');
                a{
                    color: themed('n_layout_primary');
                    text-decoration: underline;
                    text-decoration-color: themed('n_layout_primary');
                }
            }
        }

        .catalog-lookup-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .container-table {
            height  : 100%;
            overflow: scroll;
        }

        .btn {
            background: themed("n_layout_primary");
            padding   : 6px 10px;
            height    : 100%;
        }

        .action-container {
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            padding-bottom : 10px;
        }

        .suggest-input-container {
            input {
                &::placeholder {
                    color: themed("n_layout_text_active");
                }
            }
        }

        .securities-transaction-report-content {
            display       : flex;
            flex-direction: column;
            height        : 100%;
        }

        .search-container {
            margin   : 0 auto 8px auto;
            max-width: 590px;

            .custom-form-group {
                width: 100%;
            }

        }

        .container-table {
            height  : 100%;
            overflow: scroll;
        }

        .basic-table {
            // transition        : all 2s;
            // -webkit-transition: all 2s;
            border: none;
            width : 100%;

            thead {
                tr {
                    background: themed("n_table_header");

                    th {
                        padding     : 5px 0px;
                        border-left : 1px solid themed("n_table_border");
                        border-right: 1px solid themed("n_table_border");
                    }

                    th:first-child {
                        border-left: none;
                    }

                    th:last-child {
                        border-right: none;
                    }

                }
            }

            tbody {
                tr {
                    cursor    : pointer;
                    background: themed("n_table_row_even");
                    border    : 1px solid themed("n_table_border");


                    td {
                        padding      : 10px 10px;
                        border-bottom: 1px solid themed("n_table_border");
                        border-left  : 1px solid themed("n_table_border");
                        border-right : 1px solid themed("n_table_border");

                        .status-order {
                            position: relative;

                            .status-icon {
                                position     : absolute;
                                background   : #FC944D;
                                border-radius: 50%;
                                height       : 8px;
                                width        : 8px;
                                top          : 7px;
                                left         : -15px;
                            }
                        }
                    }

                    td:first-child {
                        border-left: none;
                    }

                    td:last-child {
                        border-right: none;
                    }
                }

                tr:nth-child(odd) td {
                    //background: themed("n_table_row_even");
                    background: themed("n_table_row");
                }

                tr:nth-child(even) td {
                    //background: themed("n_table_row_odd");
                    background: themed("n_table_row");
                }

            }
        }


    }
}