@import "../../styles/common";

.tab-menu {
  @include themify($themes) {
    height : 100%;
    display: flex !important;

    .tab-item {
      cursor : pointer;
      display: flex;
      height : 100%;

      .wrap-tab-item:has(.active-tab) {
        border-color: themed("n_layout_text_secondary_1");

        .title-name {
          color: themed("n_layout_text_secondary_1");
        }
      }

      .wrap-tab-item {
        border-bottom: 2px solid;
        border-color : transparent;

        .title-name {
          color  : themed('n_layout_text_not_active');
          padding: 5px 10px;
          display: flex;
        }

        .title-line {
          border-right: 1px solid themed("n_layout_line");
          height      : 60%;
        }
      }
    }
  }
}