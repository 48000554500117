@import "../../styles/common";

.asset-total {
    --gap: 10px;

    height        : 100%;
    display       : flex;
    flex-direction: column;
    gap           : var(--gap);
    padding       : 5px;

    @include themify($themes) {

        .asset-total-investment-portfolio {
            width            : 100%;
            // min-height: 200px;
            row-gap          : 10px;
            border-radius    : 5px;
            // padding-bottom: 5px;

            .title {
                padding     : 10px;
                padding-left: 15px;

                font-family: 'Helvetica Neue Bold';
                font-weight: 700; // Heading 6 - B (6)
                font-size  : 16px;
                line-height: 24px;
            }

            .combo-account-selector {
                display: flex;
                gap    : 10px;
                padding: 10px 10px 0px 10px;

                .custom-form-group {
                    padding      : 8px 20px;
                    background   : #3D4044;
                    border-radius: 4px;

                    .custom-form-control {
                        background-color: #3D4044;
                    }
                }
            }

            .open-positions-new.retryable-content {
                height: auto;

                .TableDevExtreme.open-positions-table-new {
                    max-height: 390px !important;
                }
            }
        }

        .asset-block {
            width            : 100%;
            // height        : 100%;
            display          : flex;
            gap              : var(--gap);
            padding-bottom   : 10px;

            .asset-total-asset {
                padding         : 10px;
                //padding-bottom: 50px;
                border-radius   : 4px;

                width       : calc(68% - var(--gap)/2);
                //height    : 100%;
                //min-height: 650px;

                .title {
                    padding-left  : 5px;
                    padding-bottom: 10px;
                    font-family   : 'Helvetica Neue Bold';
                    font-weight   : 700; // Heading 6 - B (6)
                    font-size     : 16px;
                    line-height   : 24px;
                }

                .asset-wrap-block {
                    display: flex;
                    gap    : var(--gap);

                    .asset-block-left {
                        width: calc(50% - var(--gap)/2);

                        display       : flex;
                        flex-direction: column;
                        gap           : 10px;

                    }

                    .asset-block-right {
                        width: calc(50% - var(--gap)/2);

                        display       : flex;
                        flex-direction: column;
                        gap           : 10px;
                    }

                    .asset-header {
                        display: flex;

                        .block-title {
                            width         : 100%;
                            padding-bottom: 12px;
                            border-bottom : 2px solid themed("n_table_border");

                            font-family: 'Helvetica Neue Medium';
                            font-weight: 500; // Body Medium - Medium (4)
                            font-size  : 14px;
                            line-height: 20px;
                        }

                        .block-value {

                            display        : flex;
                            justify-content: flex-end;

                            width         : 100%;
                            padding-bottom: 12px;
                            border-bottom : 2px solid themed("n_table_border");

                            color: themed(n_layout_text_secondary_1);
                        }

                        &.only-header {

                            .block-title,
                            .block-value {
                                border-bottom : unset;
                                padding-bottom: unset;
                            }


                        }
                    }


                    .asset-body {
                        display       : flex;
                        flex-direction: column;

                        .asset-body-property {
                            display        : flex;
                            flex-direction : row;
                            justify-content: space-between;

                            padding: 10px 0;

                            .sub-block-title {
                                color      : rgba($color: themed('n_header_text_active'), $alpha: .6);
                                font-family: 'Helvetica Neue Regular';
                                font-weight: 400; // Tiny - Regular (8)
                                font-size  : 14px;
                                line-height: 20px;
                            }

                            .sub-block-value {}
                        }


                    }

                    .asset-border {
                        border       : 2px solid themed("n_table_border");
                        border-radius: 4px;
                        padding      : 5px 10px;
                    }
                }

            }

            .asset-total-rate {
                padding         : 10px;
                //padding-bottom: 50px;
                border-radius   : 4px;

                width     : calc(32% - var(--gap)/2);
                height    : 100%;
                min-height: 650px;

                .title {
                    padding-left  : 5px;
                    padding-bottom: 10px;

                    font-family: 'Helvetica Neue Bold';
                    font-weight: 700; // Heading 6 - B (6)
                    font-size  : 16px;
                    line-height: 24px;
                }

                .asset-chart {
                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    width          : 100%;
                    height         : calc(100% - 34px);

                    #asset-chart-container-pie {
                        width           : 100%;
                        height          : 80%;
                        // height       : 200px;
                        background      : themed("n_layout_bg_block");
                        border-radius   : 4px 4px 0px 0px;

                        .highcharts-container {
                            height: 100% !important;
                        }

                        .highcharts-legend-item text {
                            fill      : themed("n_header_text_active") !important;
                            transition: fill 250ms;
                        }

                        .highcharts-legend-navigation {
                            fill: themed("n_header_text_active") !important;
                        }
                    }
                }
            }
        }

        .bg-asset {
            background: themed("n_layout_bg_block");
        }


    }
}