@import "../../../styles/common";

.modal-payment {
    @include themify($themes) {
        // width    : 500px !important;
        // max-width: 500px !important;
        // z-index   : 2000;

        svg {
            width: 132px;
            height: 50px;
            background: themed('logo_background_custom');
            padding: 5px 0px;
            border-radius: 10px;
        }

        .custom-body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;
            align-items   : center;

            .item {
                padding        : 10px 45px 10px 5px;
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                border-bottom  : 1px solid themed('n_layout_border');
                width          : 100%;
                position: relative;

                .left {
                    display          : flex;
                    flex-direction   : row;
                    gap              : 15px;
                    // width         : 20%;

                    .content-item {
                        display        : flex;
                        flex-direction : column;
                        justify-content: space-between;
                    }

                    .icon-copy {
                        cursor: pointer;
                        height: 40px;
                        width : 40px;
                        position: absolute;
                        right: 0px;
                        top: 15px;
                    }
                }

                .right {
                    height : 40px;
                    width  : 40px;
                    display: flex;
                    cursor : pointer;
                }
            }
        }

        .icon-arrow {
            transform        : rotate(180deg);
            -webkit-transform: rotate(180deg);
        }

        .detail-qr {
            padding       : 10px;
            gap           : 20px;
            display       : flex;
            flex-direction: column;

            .title {
                display        : flex;
                justify-content: center;
                margin-bottom  : 10px;
            }

            .btn-qr {
                width: 100%;
            }

            .custom-qrcode {
                border-radius: 25px;
                border       : 8px solid rgba(64, 235, 213, 1);
                margin-left  : 10px;
                margin-right : 10px;
            }
        }
    }
}