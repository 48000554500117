@import "../styles/common";

.layout-modal {
  width    : 500px !important;
  max-width: 500px !important;

  .app-message-modal-title {
    font-weight: 500;
  }

  .modal-content {
    @include themify($themes) {
      background: themed('n_layout_bg_block');
    }
  }
}

.app-message-modal {
  margin: auto;

  .label-padding .col-5 {
    margin-bottom: 10px;
  }

  span.label {
    line-height   : 30px;
    padding-bottom: 15px;
  }

  .btn-container button {
    // @include themify($themes) {
    //   color: themed('modal-body-bg');
    //   background: themed('modal-body-bg');
    // }
    border       : none;
    height       : 26px;
    margin-bottom: 10px;
    margin-left  : 10px;
    width        : 100px;

    &[disabled] {
      opacity: 0.5;
    }

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 0.75;
    }
  }
}