@import "../../../styles/common";

.shortcut-modal {
    @include themify($themes) {
        // width    : 500px !important;
        // max-width: 500px !important;
        z-index: 2000;

        .body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;
        }


        .basic-table {
            margin-top: 10px;

            th,tr {
                &.shortcut-name{
                    min-width: 100px;
                }
            }

            thead{
              background-color: themed("n_table_header") !important;
              color           : themed("n_table_text_active") !important;
              th {
                padding: 5px 5px;
              }
            }
            tbody {
              tr {
                background     : themed("n_table_row") !important;
                td {
                  height       : 40px;
                  border-bottom: 1px solid themed("n_table_border");
                }
              }
            }
          }

     
    }
}