.container-radio-order {
    @include themify($themes) {
        .contaier-input {

            .radio-left,
            .radio-right {
                width  : 50%;
                display: flex;
                &.disable{
                    opacity: 0.5;
                }
            }
        }

        .container-radio {
            display               : flex;
            // align-items        : center;
            // justify-content    : center;
            position              : relative;
            margin-bottom         : unset;
            cursor                : pointer;
            -webkit-user-select   : none;
            -moz-user-select      : none;
            -ms-user-select       : none;
            user-select           : none;
            width                 : 100%;

            input {
                position: absolute;
                opacity : 0;
                cursor  : pointer;
            }

            .checkmark {
                position     : absolute;
                height       : 15px;
                width        : 15px;
                border-radius: 50%;
                border       : 1px solid themed('n_layout_text_not_active');
                top          : 50%;
                right        : 10%;
                transform    : translate(0%, -50%);
            }

            input:checked~.checkmark {
                border: 1px solid themed('n_layout_text_active');
            }

            input:focus-visible~.checkmark {
                border    : 1px solid #FFF !important;
                box-shadow: 0 0 0 0.2rem themed("n_layout_btn_bg");
            }

            .checkmark:after {
                content : "";
                position: absolute;
                display : none;
            }

            input:checked~.checkmark:after {
                display: block;
            }

            .checkmark:after {
                top          : -1px;
                width        : 15px;
                height       : 15px;
                border-radius: 50%;
                background   : themed('n_layout_text_active');
            }
        }



        .contaier-input-buy {
            .container-radio {
                display               : flex;
                // align-items        : center;
                // justify-content    : center;
                position              : relative;
                margin-bottom         : unset;
                cursor                : pointer;
                -webkit-user-select   : none;
                -moz-user-select      : none;
                -ms-user-select       : none;
                user-select           : none;
                width                 : 100%;

                input {
                    position: absolute;
                    opacity : 0;
                    cursor  : pointer;
                }

                .checkmark {
                    position     : absolute;
                    height       : 15px;
                    width        : 15px;
                    border-radius: 50%;
                    border       : 1px solid themed('n_layout_green_custom');
                    top          : 50%;
                    right        : 10%;
                    transform    : translate(0%, -50%);
                }

                input:checked~.checkmark {
                    border: 1px solid themed('n_layout_green_custom');

                }

                .checkmark:after {
                    content : "";
                    position: absolute;
                    display : none;
                }

                input:checked~.checkmark:after {
                    display: block;
                }

                .checkmark:after {
                    top          : 3px;
                    left         : 3px;
                    width        : 7px;
                    height       : 7px;
                    border-radius: 50%;
                    background   : themed('n_layout_green_custom');
                }
            }
        }

        .contaier-input-sell {
            .container-radio {
                display               : flex;
                // align-items        : center;
                // justify-content    : center;
                position              : relative;
                margin-bottom         : unset;
                cursor                : pointer;
                -webkit-user-select   : none;
                -moz-user-select      : none;
                -ms-user-select       : none;
                user-select           : none;
                width                 : 100%;

                input {
                    position: absolute;
                    opacity : 0;
                    cursor  : pointer;
                }

                .checkmark {
                    position     : absolute;
                    height       : 15px;
                    width        : 15px;
                    border-radius: 50%;
                    border       : 1px solid themed('n_layout_red_custom');
                    top          : 50%;
                    right        : 10%;
                    transform    : translate(0%, -50%);
                }

                &:hover input~.checkmark {
                    // background-color: #ccc;
                }

                input:checked~.checkmark {
                    border: 1px solid themed('n_layout_red_custom');

                }

                .checkmark:after {
                    content : "";
                    position: absolute;
                    display : none;
                }

                input:checked~.checkmark:after {
                    display: block;
                }

                .checkmark:after {
                    top          : 3px;
                    left         : 3px;
                    width        : 7px;
                    height       : 7px;
                    border-radius: 50%;
                    background   : themed('n_layout_red_custom');
                }
            }
        }
    }
}
