@import "../../../styles/common";

.net-modal {
    @include themify($themes) {
        // width    : 500px !important;
        // max-width: 500px !important;
        z-index: 2000;

        .body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;
        }

        .my-location {
            cursor         : pointer;
            margin-top     : 5px;
            text-decoration: underline;
        }

        .maps {
            width : 100%;
            height: 300px;
            border: 0;

            .gm-style {
                background: red !important;
            }
        }

        .div-list {
            padding-top: 10px;
            height     : 250px;
            background : themed('n_layout_bg_block');

            .search-custom {
                display         : flex;
                flex-direction  : row;
                width           : 100%;
                padding         : 5px;
                background-color: themed('n_form_bg');
                border          : 1px solid themed("n_layout_border");
                border-radius   : 4px;
                margin-bottom   : 5px;
            }

            .branch-list {
                overflow: scroll;
                height  : calc(100% - 40px);
            }

            .search-bar {
                width            : 100%;
                background-color : themed('n_form_bg');
                color            : themed("n_layout_text_active");
                border           : none;
                outline          : none;
                // text-transform: capitalize;

                &:focus-visible {
                    border : none;
                    outline: none;
                }
            }

            .active {
                background: themed('n_form_bg') !important;
            }

            .item-branch {
                width         : 100%;
                display       : flex;
                flex-direction: row;
                padding       : 3px 0px 3px 0px;
                border-bottom : 1px solid themed("n_layout_border");

                .left-item {
                    display       : flex;
                    flex-direction: column;
                    gap           : 3px;
                    width         : 85%;
                }

                .right-item {
                    display        : flex;
                    flex-direction : column;
                    justify-content: center;
                    gap            : 3px;
                    width          : 15%;
                    align-items    : center;

                    .circle {
                        height         : 30px;
                        width          : 30px;
                        border-radius  : 30px;
                        background     : themed('n_form_bg');
                        cursor         : pointer;
                        display        : flex;
                        flex-direction : row;
                        justify-content: center;
                        align-items    : center;
                        padding-top    : 1px;
                    }
                }
            }
        }
    }
}