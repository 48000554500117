// @import "../styles/common";
@import "../styles/common";

.toast-container {
  @include themify($themes) {
    // right: 240px;
    right: 10px;
    width: 537px;

    @include media-breakpoint-down(xs) {
      width : 100%;
      bottom: 5px;
    }

    .Toastify__toast {
      width: 100%;
    }

    .toast-item {

      white-space     : pre-wrap;
      height          : 100%;
      border-radius   : 4px;
      padding         : 12px 0px 10px 12px;
      display         : flex;
      // box-shadow   : 0 0 5px 5px themed('n_layout_box_shadow_bg');

      &.Toastify__toast--success {
        background: themed('n_toast_noti_bg');
      }

      &.Toastify__toast--error {
        background: themed('n_toast_noti_bg');
      }

      &.Toastify__toast--warn {
        background: themed('n_toast_noti_bg');
      }

      &.Toastify__toast--info {
        background: themed('n_toast_noti_bg');
      }


      .toast-close {
        position: absolute;
        right   : 10px;
        top     : 10px;
        color   : themed('n_layout_text_active');

        font-size            : $font-size-medium;
        padding              : 0;
        cursor               : pointer;
        background           : transparent;
        border               : 0;
        -webkit-appearance   : none;
        // transition        : opacity 0.2s ease-out;
      }

      .toast-item-body {
        color: themed("n_layout_text_active");

        display: block;
        flex   : none;
        width  : 100%;
        height : 100%;

        .date {
          display       : flex;
          flex-direction: row;
          color         : themed("n_layout_text_not_active");
        }

        .toast-title {
          color: themed("n_layout_text_near_active");

          .fixed-scroll-bar {
            height: 50px;
          }

          .date {
            float         : right;
            font-size     : $font-size-regular;
            vertical-align: middle;
            margin-right  : 5px;
            margin-bottom : 0;
            padding       : 2px 5px;
          }

          i {
            position    : relative;
            margin-right: 3px;
          }
        }

        .toast-content {
          font-size: $font-size-regular;
          height   : auto;
        }
      }
    }
  }
}

.custom-toast {
  display       : flex;
  flex-direction: row;
  height        : 100%;
}

.container-toast {
  display        : flex;
  flex-direction : column;
  margin-left    : 16px;
  justify-content: space-between;
  margin-right   : 30px;
}

.Toastify__toast-container {
  bottom : 0px !important;
  padding: 0px !important;
}

.Toastify__toast {
  margin-bottom: 10px !important;
}