@import "../../styles/common";
.open-account-ekyc-header {
    @include themify($themes) {
        .header-menu-ekyc {
            height: 56px;
            background: themed('n-ekyc-section-bg');
            display: flex;
            .header-logo {
                margin-left: 27px ;
                margin-top: 14px;
            }
            .navLinks {
                //display: flex;
                .content-header {
                    float: right;
                    height: 100%;
                    padding-top: 16px;
                    text-decoration: none;
                    cursor: pointer;
                    .icon-content-header {
                        margin-right: 5px;
                        margin-top: -3px;
                    }
                }
                width: 100%;
                .btn-menu {
                    //margin-top: 16px;
                    color: themed('n-ekyc-text-title');
                    text-decoration: none;
                    cursor: pointer;
                    margin-right: 25px;
                    font-weight: 600;
                    //float: right;
                }
            }
        }
    }
    .container-logo {
        height        : 100%;
        padding-left  : 18px;
        padding-right : 13px;
        padding-bottom: 7px;
        display       : flex;
        align-items   : center;
  
        img {
          width: 75px;
        }
      }
}