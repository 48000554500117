@import "../../styles/common";
.zoom-chart {
  @include themify($themes) {
    .txt-lime {
      color: themed("n_layout_increase_text") !important;
    }

    .color-orange {
      color: themed("n_price_ref_text") !important;
    }

    .txt-orange {
      color: themed("n_price_ref_text") !important;
    }

    .txt-gia-tc{
      color: themed("n_price_ref_text") !important;
    }

    .txt-red {
      color: themed("n_layout_reduce_text") !important;
    }

    .txt-blue {
      color: themed("n_price_floor_text") !important;
    }

    .txt-gray {
      color: #6e7d8b;
    }
  }
  .body {
    .chart-box {
      .chart-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}
