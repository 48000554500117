@import "../../../../../styles/common";

.condition-order-stop-limit {
    @include themify($themes) {
        .type-view {
            display: flex;
            margin : 10px 0px;

            &:first-child {
                margin-top: 0px;
            }

            &:last-child {
                margin-bottom: 0px;
            }

            .content {
                height: 32px;
            }

            .content-left {
                width      : 30%;
                display    : flex;
                align-items: center;
            }

            .content-right {
                width: 70%;
            }
        }

        .price-order {
            .content-right {
                display: flex;
                gap    : 10px;

                .row-input {
                    width : 100%;
                    height: 100%;

                }

                .row-note {
                    width        : 40%;
                    gap          : 5px;
                    display      : flex;
                    height       : 100%;
                    align-items  : center;
                    margin-bottom: 3.5px;
                }
            }
        }


        .present-order {
            .content-right {
                gap: 10px;

                .select-present {
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    white-space    : nowrap;
                    border         : 1px solid themed("n_layout_border");
                    flex           : 1 0 0;
                    height         : 100%;
                    cursor         : pointer;
                    border-radius  : $border_radius;

                    &:focus-visible {
                        box-shadow: themed("n_layout_box_shadow_bg") 1px 1px 1px;
                        border    : none;
                        outline   : none;
                    }
                }
            }

        }

        .select-date {
            .content-right {
                display        : flex;
                justify-content: space-between;
            }
        }

        .action-container {
            width  : 100%;
            display: flex;
            gap    : 10px;

            .btn-action {
                height        : 40px;
                width         : 100%;
                border        : none;
                outline       : unset;
                color         : themed("n_text_custom");
                text-transform: uppercase;
            }

            .btn-cancel-input {
                padding      : 9px 13px;
                border-radius: 5px;
            }

            .btn-reset-input {
                padding      : 9px 13px;
                border-radius: 5px;
            }
        }

        .resizesensor-wrapper {
            height: 400px;
        }

    }
}