@import "../../styles/common";

.option-custom {
  @include themify($themes) {
    position: relative;
    width   : 100%;
    height  : 100%;

    &.disable-warp {
      &:disabled {
        cursor: not-allowed;
      }
    }

    .select-input {
      display    : flex;
      align-items: center;
      cursor     : pointer;
      position   : relative;
      height     : 100%;

      .select-input-title {
        // min-width: 50px;
      }
    }

    .select-list {
      border            : none;
      border-top        : none;
      position          : absolute;
      top               : 100%;
      left              : 0px;
      background        : themed("n_layout_bg_block");
      border-radius     : $border_radius;
      box-shadow        : 0px 4px 20px themed("n_layout_box_shadow_bg");
      -webkit-box-shadow: 0px 4px 20px themed("n_layout_box_shadow_bg");
      border-radius     : 0px 0px $border_radius $border_radius;


      .select-item {
        display    : flex;
        align-items: center;
        cursor     : pointer;
        padding    : 10px;
        color      : themed("n_layout_text_near_active");

        &.item-active {
          background: themed("n_layout_active_bg");

          .select-title {
            color: themed("n_layout_text_active");
          }
        }

        .select-title {}
      }
    }
  }
}

// .selected-list {
//   display   : flex;
//   flex-wrap : wrap;
//   min-height: 42px;
//   width     : 100%;
// }

// .selected-item {
//   padding        : 5px;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   border         : 1px solid #eee;
//   border-radius  : 3px;
//   margin         : 5px;
// }

// .selected-item span {
//   margin-right: 5px;
// }