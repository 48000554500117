@import "../../../styles/common";

.order-book {
    @include themify($themes) {
        height: 100%;
        .title {
            margin-bottom: 12px;
            display: flex;
            flex-direction: row;
            gap: 16px;
            cursor: pointer;

            font-family: "Helvetica Neue Bold";
            font-weight: 700; // Heading 6 - B (6)
            font-size: 16px;
            line-height: 24px;
            padding-top: 10px;
            padding-left: 10px;
            position: relative;

            .close-btn {
                position: absolute;
                right: 10px;
            }
        }

        .block {
            background: themed("n_bg_block");
            border-radius: 4px;
            height: 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 13px;
            }

            .active {
                color: themed("n_layout_text_active");
            }

            .not-active {
                color: themed("n_layout_text_not_active");
            }

            .text-detail {
                position: absolute;
                right: 5px;
                top: -5px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .block-right {
            background: themed("n_bg_block");
            border-radius: 4px;
            padding: 10px;
            height: 100%;
            width: 100%;

            .container-block {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .block-item {
                    height: auto;
                    width: 50%;
                }

                .row-title {
                    height: 36px;
                    display: flex;
                    // align-items    : center;
                    justify-content: space-between;
                    border-bottom: 1px solid #404347;
                }

                .row-item {
                    line-height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .row-item-flex {
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .border-block {
                    border: 1px solid #404347;
                    padding: 10px;
                    border-radius: 4px;
                }
            }
        }

        .pl-10 {
            padding-left: 10px;
        }

        .block-table {
            background: themed("n_bg_block");
            border-radius: 4px;
            padding-top: 10px;
            // height       : 400px;
            margin-top: 10px;
        }

        .order-book-look-up {
            height: calc(100% - 40px);
            padding: 0px 0px 10px 0px;
        }
    }
}
