@import "../../../../styles/common";

.symbol-suggest-place-order-quick {
  max-height: 300px;
  position  : relative;
  width     : 100%;

  @include themify($themes) {

    .suggest-input-container {
      position: relative;

      .btn-icon {
        top     : 6px;
        position: absolute;
        right   : 5px;
      }

      .react-autosuggest__container {
        position: relative;

        .react-autosuggest__input {
          text-overflow   : ellipsis;
          padding   : 0 10px;
          height          : 40px;
          width           : 100%;
          font-size       : inherit;
          text-transform  : uppercase;
          border-radius   : $border_radius;
          background-color: themed("n_layout_btn_bg");
          color           : themed("n_layout_text_active");
          border          : 1px solid themed("n_layout_border");

          // &:focus {
          //   border    : 1px solid themed("n_layout_bg_block");
          //   box-shadow: themed("n_layout_bg_block") 0 0 1px 1px;
          // }

          // &:hover {
          //   border: 1px solid themed("n_layout_bg_block");
          // }
          &:focus-visible {
            border : none;
            outline: none;
          }

          &::placeholder {
            color: themed('n_layout_text_not_active');
          }
        }

        .react-autosuggest__suggestions-container {
          width           : 100%;
          z-index         : 200;
          position        : absolute;
          max-height      : 200px;
          overflow-x      : hidden;
          overflow-y      : auto;
          background-color: themed("n_layout_bg_block");


          .react-autosuggest__suggestions-list {
            padding         : 0px;
            width           : calc(100% - 7px);
            background-color: themed("n_layout_bg_block");
            margin-bottom   : 0px !important;
          }

          .react-autosuggest__suggestion {
            width        : 100%;
            height       : 26px;
            padding      : 2px 2px 2px 5px;
            text-align   : left;
            white-space  : nowrap;
            border-bottom: 1px solid themed("n_layout_border");

            &:hover,
            &.react-autosuggest__suggestion--highlighted {
              cursor          : pointer;
              color           : themed('n_layout_text_secondary_1');
              background-color: themed("n_layout_active_bg");
            }
          }
        }
      }
    }
  }
}