@import "../../../styles/common";

.detail-page {
    &.full-screen-symbol-detail-page {
        height: 100%;
    }
}

.style-symbol-detail-page {
    @include themify($themes) {
        height     : calc(100vh - 40px);
        width      : 100%;
        // overflow: auto;
        padding    : 10px;


        .symbol-detail-page {

            &.full-screen {
                height: 100%;

                .block {
                    max-height: unset !important;
                    min-height: unset !important;
                }
            }

            .full-screen {
                height: 100%;
            }

            .right-block {
                padding-left: 0px !important;
            }

            .title-market {
                margin-top   : 10px;
                margin-bottom: 10px;
                margin-left  : 10px;
            }

            .text-ceil-price {
                color: themed("n_price_ceil_text") !important;
            }

            .text-floor-price {
                color: themed("n_price_floor_text") !important;
            }

            .text-ref-price {
                color: themed("n_price_ref_text") !important;
            }

            .text-priceboard-green {
                color: themed("n_layout_increase_text") !important;
            }

            .text-priceboard-red {
                color: themed("n_layout_reduce_text") !important;
            }

            .line {
                background-color: themed("n_table_border");
                height          : 1px;
                width           : 100%;
                margin-bottom   : 10px;
            }

            .no-padding {
                padding: 0px !important;
            }

            .no-padding-right {
                padding-right: 0px !important;
            }

            .pr-10 {
                padding-right: 10px;
            }

            .market-info-news-item {
                cursor             : pointer;
                // border-bottom: 1px solid themed("n_table_border");
                // margin-bottom   : 10px;
                padding-bottom     : 10px;

                // div {
                //     height: 57px;
                //     margin-bottom: 10px;
                // }

                .image-container {
                    margin-left: 15px;
                    width      : 80px;
                    height     : 57px;
                }

                .txt-container {
                    width       : calc(100% - 100px); // 73: width của image-container
                    padding-left: 10px;
                }

                .content-event {
                    align-items: center;
                }

                img {
                    border-radius: 4px;
                    width        : 100%;
                    height       : 58px;
                }

                p {
                    margin-bottom: 0px;
                }

                .news-header {
                    min-height   : 40px;
                    color        : themed("n_layout_text_near_active");
                    display      : flex;
                    //align-items: center;
                }

                .news-date {
                    display       : flex;
                    align-items   : center;
                    color         : themed("n_layout_text_not_active");
                    // min-height : 40px;
                }
            }

            .button-container {
                button {
                    width        : 100%;
                    height       : 39px;
                    border-radius: 4px;
                    color        : themed("n_text_custom");
                }

                div {
                    text-align: center;
                }
            }

            .action-container {
                display: unset;
            }

            .back-btn-container {
                button {
                    width        : 100%;
                    height       : 20px;
                    border-radius: 15px;
                    background   : themed("n_layout_active_bg");
                }
            }



            .market-info-news-detail {
                .detail-header {
                    color: themed("n_layout_text_near_active");
                }

                .line {
                    margin: 0px 15px 10px 15px;
                }

                .detail-content {
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                    }

                    p {
                        margin: 0px;
                    }
                }

                .detail-title {
                    margin: 10px 0px 10px 0px;

                    p {
                        margin: 0px;
                    }

                    .post-date {
                        // font-weight: 400;
                        // font-size: 12px;
                        // line-height: 150%;
                        color: rgba(255, 255, 255, 0.4);
                    }
                }
            }

            .load-more {
                color : themed("n_layout_text_not_active");
                cursor: pointer;
            }

            .custom-form-group {
                text-align: right;
            }

            .text-ceil-price {
                color: $theme_01_common_ceil !important;
            }

            .text-floor-price {
                color: $theme_01_common_floor !important;
            }

            .text-ref-price {
                color: themed("n_price_ref_text") !important;
            }

            .title {
                // margin-bottom: 22px;
                padding-bottom: 10px;

                &.tech-chart {
                    display: inline-flex;
                    float  : left;
                }
            }

            .action-btn {
                display: inline-flex;
                float  : right;

                border        : none;
                font-size     : $font-size-medium;
                // line-height: 36px;
                padding       : 0;
                text-align    : center;
                user-select   : none;

                &.active {
                    color: themed("n_layout_text_active");
                }

                :hover {
                    color: themed("n_layout_text_active");
                }
            }

            .label {
                display      : inline-block;
                margin-bottom: 0px;
                font-weight  : 400; // Body Medium - Regular
                font-size    : 14px;
                line-height  : 20px;
                color        : themed("n_layout_text_not_active");
            }

            .block {
                background   : themed("n_bg_block");
                border-radius: 4px;
                padding      : 10px;
                height       : 100%;
                max-height   : 500px;
                min-height   : 440px;

                .h-row {
                    height: 44px;
                }

                .h-header-row {
                    height: 38px;
                }

                .line-row {
                    padding: 0 5px !important;

                    .line {
                        border-bottom: 1px solid themed("n_layout_border");
                    }
                }

                .btn {
                    width: 100%;
                }

                .container-btn {
                    margin-top: 13px;
                }

                .item-info {
                    display        : flex;
                    height         : 44px;
                    flex-direction : row;
                    justify-content: space-between;
                    border-bottom  : 1px solid themed("n_layout_border");
                    align-items    : center;
                }

                .left-block-info {
                    padding-right: 8px !important;
                }

                .right-block-info {
                    padding-left: 8px !important;
                }
            }

            .block-market {
                background   : themed("n_bg_block");
                border-radius: 4px;
                height       : 100%;
                max-height   : 500px;
                min-height   : 500px;

                .h-row {
                    height: 44px;
                }

                .btn {
                    width: 100%;
                }

                .container-btn {
                    margin-top: 13px;
                }
            }

            .block-header {
                background   : themed("n_bg_block");
                border-radius: 4px;
                height       : 100%;
            }

            .block-right {
                background   : themed("n_bg_block");
                border-radius: 4px;
                padding      : 10px;
                height       : 100%;
                width        : 100%;
                max-height   : 500px;

                .container-block {
                    display       : flex;
                    flex-direction: row;
                    gap           : 10px;

                    .block-item {
                        height: auto;
                        width : 50%;
                    }

                    .row-title {
                        height            : 36px;
                        display           : flex;
                        // align-items    : center;
                        justify-content   : space-between;
                        border-bottom     : 1px solid themed("n_table_border");
                    }

                    .row-item {
                        line-height    : 36px;
                        display        : flex;
                        align-items    : center;
                        justify-content: space-between;
                    }

                    .row-item-flex {
                        line-height    : 24px;
                        display        : flex;
                        align-items    : center;
                        justify-content: space-between;
                    }

                    .border-block {
                        border       : 1px solid themed("n_table_border");
                        padding      : 10px;
                        border-radius: 4px;
                    }
                }
            }

            .tab-container {
                margin-right : 0px;
                margin-left  : 0px;
                z-index      : 1;
                position     : sticky;
                top          : 0px;
                margin-bottom: 20px;
                padding-top  : 10px;
                height       : 35px;
                background   : themed("n_bg_block");

                .tab-item {
                    cursor       : pointer;
                    padding-left : 8px;
                    padding-right: 8px;

                    &.active {
                        color        : themed("n_layout_text_secondary_1");
                        border-bottom: 2px solid themed("n_layout_text_secondary_1");
                    }
                }
            }

            .news-container {
                overflow      : auto;
                padding-top   : 0px;
                padding-bottom: 0px;
            }

            .chart-container {
                padding-top     : 0px;
                // margin-bottom: 10px;

                .hidden-tab {
                    display: none;
                }
            }
        }
    }
}


@include media-breakpoint-down(md) {
    .style-symbol-detail-page {
        .symbol-detail-page {
            .pr-10 {
                padding-right: 0px !important;
            }

            .block-chart {
                padding-bottom: 10px !important;
            }
        }
    }
}