@import "../../../styles/common";

.settings-personal {
    --gap: 10px;

    display          : flex;
    width            : 100%;
    min-height       : 100%;
    gap              : var(--gap);
    // padding-bottom: 15px;

    .block-information-personal {
        display       : flex;
        flex-direction: column;
        width         : calc(60% - var(--gap)/2);
        min-height    : auto;

        @include themify($themes) {
            background-color: themed('n_layout_bg_block');
        }

        padding : 20px;
    }

    .block-addition-bank-change-password {
        display       : flex;
        flex-direction: column;
        width         : calc(40% - var(--gap)/2);
        min-height    : auto;

        @include themify($themes) {
            background-color: themed('n_layout_bg_block');
        }

        padding : 20px;

        .space {
            width : calc(100% + var(--gap) * 8);
            height: var(--gap);

            @include themify($themes) {
                background: themed('n_layout_bg');
            }

            margin-left: calc(0px - var(--gap) * 4);
        }
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;

        .block-information-personal {
            width     : 100%;
            min-height: 100%;
        }

        .block-addition-bank-change-password {
            width     : 100%;
            min-height: 100%;

        }
    }
}

@include media-breakpoint-down(sm) {
    .settings-personal {
        gap: 5px;
    }
}