@import "common";

// cấu trúc tên class txt---{Weight}-{Size}-{Line height}
// url('./fonts/helvetica-neue-ultra-light.ttf') format('truetype'),
// url('./fonts/helvetica-neue-ultra-light-italic.ttf') format('truetype'),
// url('./fonts/helvetica-neue-bold.ttf') format('truetype'),
// url('./fonts/helvetica-neue-bold-italic.ttf') format('truetype'),
// url('./fonts/helvetica-neue-condensed-black.ttf') format('truetype'),
// url('./fonts/helvetica-neue-italic.ttf') format('truetype'),
// url('./fonts/helvetica-neue-light.ttf') format('truetype'),
// url('./fonts/helvetica-neue-light-italic.ttf') format('truetype'),
// url('./fonts/helvetica-neue-medium.ttf') format('truetype'),
// url('./fonts/helvetica-neue-regular.ttf') format('truetype');
// Lưu ý: Build dựa trên bộ system design: Link Dark Mode
// 1. Sử dụng 3 loại helvetica-neue-regular, helvetica-neue-medium, ./fonts/helvetica-neue-bold
// 2. Trên link figma hiện tại các title đang k sử dụng đúng headingB và headingM 
//  => Khi code cần thay tương ứng
// 3. Trên link figma nhiều chỗ đang chỉ dùng helvetica-neue 
// => Cần thay thế tương ứng với bộ regular, medium, bold dưới đây.
// Mục đích đổi all cho hệ thống sau này
// 4. font-face: Helvetica Neue NOT USING FIGMA
// => Tạm sinh với ý nghĩa hardcode để dễ tìm ra chỗ chưa đúng font
// Về sau sửa lại thành font theo bộ regular để hạn chế lỗi về font
// 5. ý nghĩa tên class css
// headB = head bold
// headM = head medium
// txt = body

@font-face {
  font-family : 'Helvetica Neue Regular';
  font-style  : normal;
  font-display: swap;
  src         : url('./fonts/helvetica-neue-regular.ttf') format('truetype');
}

@font-face {
  font-family : 'Helvetica Neue Medium';
  font-style  : normal;
  font-display: swap;
  src         : url('./fonts/helvetica-neue-medium.ttf') format('truetype');
}

@font-face {
  font-family : 'Helvetica Neue Bold';
  font-style  : normal;
  font-display: swap;
  src         : url('./fonts/helvetica-neue-bold.ttf') format('truetype');
}

@font-face {
  font-family       : 'Helvetica Neue NOT USING FIGMA'; // hardcode để phát hiện css không theo bộ figma.scss
  font-style        : normal;
  font-weight       : 700;
  font-display      : swap;
  src               : url('./fonts/helvetica-neue-regular.ttf') format('truetype'); // Dùng mặc định để tránh phát hiện lỗi không sử dụng bộ figma.scss
  // src            : url('./fonts/comicate.ttf') format('truetype'); // Dùng hardcode để phát hiện lỗi không sử dụng bộ figma.scss


}


body.fss {
  @include themifyBody($themes) {

    // Heading - B ======== [START]
    .headB---700-36-54 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Heading 1 - B (1)
      font-size  : 36px !important;
      line-height: 54px !important;
    }

    .headB---700-32-48 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Heading 2 - B (2)
      font-size  : 32px !important;
      line-height: 48px !important;
    }

    .headB---700-28-42 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Heading 3 - B (3)
      font-size  : 28px !important;
      line-height: 42px !important;
    }

    .headB---700-24-36 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Heading 4 - B (4)
      font-size  : 24px !important;
      line-height: 36px !important;
    }




    .headB---700-20-30 {
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Heading 5 - B (5)
      font-size  : 20px !important;
      line-height: 30px !important;
    }

    .headB---700-16-24 {
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Heading 6 - B (6)
      font-size  : 16px !important;
      line-height: 24px !important;
    }

    // Heading - B ======== [END]

    .headM---600-16-24 {
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 600 !important; // Heading 6 - M (6)
      font-size  : 16px !important;
      line-height: 24px !important;
    }

    // Heading - M ======== [START]
    .headM---500-36-54 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 1 - M (1)
      font-size  : 36px !important;
      line-height: 54px !important;
    }

    .headM---500-32-48 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 2 - M (2)
      font-size  : 32px !important;
      line-height: 48px !important;
    }

    .headM---500-28-42 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 3 - M (3)
      font-size  : 28px !important;
      line-height: 42px !important;
    }

    .headM---500-24-36 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 4 - M (4)
      font-size  : 24px !important;
      line-height: 36px !important;
    }

    .headM---500-20-30 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 5 - M (5)
      font-size  : 20px !important;
      line-height: 30px !important;
    }

    .headM---500-16-24 {
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 6 - M (6)
      font-size  : 16px !important;
      line-height: 24px !important;
    }

    // R - là bộ header Bình tự thêm

    .headM---400-36-54 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 400 !important; // Heading 1 - R
      font-size  : 36px !important;
      line-height: 54px !important;
    }

    .headM---400-32-48 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 400 !important; // Heading 2 - R
      font-size  : 32px !important;
      line-height: 48px !important;
    }

    .headM---400-28-42 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 400 !important; // Heading 3 - R
      font-size  : 28px !important;
      line-height: 42px !important;
    }

    .headM---400-24-36 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium';
      font-weight: 400; // Heading 4 - R
      font-size  : 24px;
      line-height: 36px;
    }

    .headM---400-20-30 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 400 !important; // Heading 5 - R
      font-size  : 20px !important;
      line-height: 30px !important;
    }

    .headM---400-16-24 {
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Heading 6 - R
      font-size  : 16px !important;
      line-height: 24px !important;
    }

    // Heading - M ======== [END]

    // Body ======== [START]
    .txt---500-16-24 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Body Large - Medium (1)
      font-size  : 16px !important;
      line-height: 24px !important;
    }

    .txt---400-16-24 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Body Large - Regular (2)
      font-size  : 16px !important;
      line-height: 24px !important;
    }

    .txt---700-14-20 {
      font-family: 'Helvetica Neue Bold' !important;
      font-weight: 700 !important; // Body Medium - Bold (3)
      font-size  : 14px !important;
      line-height: 20px !important;
    }

    .txt---500-14-20 {
      font-family: 'Helvetica Neue Medium' !important;
      font-weight: 500 !important; // Body Medium - Medium (4)
      font-size  : 14px !important;
      line-height: 20px !important;
    }

    .txt---400-12-20 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Body Medium - Regular (5)
      font-size  : 12px !important;
      line-height: 20px !important;
    }

    .txt---500-12-18 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 500 !important; // Body Small - Medium (6)
      font-size  : 12px !important;
      line-height: 18px !important;
    }

    .txt---400-12-18 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Body Small - Regular (7)
      font-size  : 12px !important;
      line-height: 18px !important;
    }

    .txt---400-11-20 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Body Small - Regular (7)
      font-size  : 11px !important;
      line-height: 20px !important;
    }

    .txt---400-11-18 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Body Small - Regular (7)
      font-size  : 11px !important;
      line-height: 18px !important;
    }

    .txt---400-10-15 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Tiny - Regular (8)
      font-size  : 10px !important;
      line-height: 15px !important;
    }

    .txt---400-14-20 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Tiny - Regular (8)
      font-size  : 14px !important;
      line-height: 20px !important;
    }

    // Priceboard ========

    .txt-p---400-12 {
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 400 !important; // Body Small - Regular (7)
      font-size  : 13px !important;
    }

    // không có trong figma
    .txt-p---400-13 {
      //font-family: $font-family-base !important;
      //font-weight: 400 !important; // Body Small - Regular (7)
      font-size  : 13px !important;
    }

    //EKYC
    .txt---600-16-24 {
      font-size: 16px !important;
      font-weight: 600;
      line-height: 24px; /* 24px */
    }

    .txt---700-14-20 {
      font-size: 14px !important;
      font-weight: 700 !important;
      line-height: 20px !important; /* 142.857% */
    }

    .txt---700-28-42 {
      font-size: 28px !important;
      font-weight: 700 !important;
      line-height: 42px !important; /* 142.857% */
    }

    .txt---700-24-36 {
      font-size: 24px !important;
      font-weight: 700 !important;
      line-height: 36px !important; /* 142.857% */
    }

    .txt---600-20-30 {
      font-size: 20px !important;
      font-weight: 600 !important;
      line-height: 30px !important; /* 142.857% */
    }
    @-moz-document url-prefix() {

      //https://www.browserstack.com/guide/create-browser-specific-css
      // div[class^="something"]
      [class^="txt-p---400"] {
        font-weight: 600 !important; // Body Small - Regular (7)
      }

      [class^="headB---700"],
      [class^="headM---700"],
      [class^="txt---700"] {
        font-weight: 500 !important; // Body Small - Regular (7)
      }
    }

    .txt-p---500-12 {
      // Đang chưa dùng
      font-family: 'Helvetica Neue Regular' !important;
      font-weight: 500 !important; // Body Small - Medium (6)
      font-size  : 12px !important;
    }

    // Body ======== [END]

    .txt-header-not-active {
      color: themed("n_header_text_not_active") !important
    }

    .bg-layout-active {
      background-color: themed("n_layout_active_bg") !important
    }

    .txt-active {
      color: themed("n_layout_text_active") !important
    }

    .txt-right {
      text-align: right !important;
    }

    .txt-left {
      text-align: left !important;
    }

    .txt-bold {
      font-weight: bold !important;
    }

    .txt-not-active {
      color: themed("n_layout_text_not_active") !important
    }

    .txt-near-active {
      color: themed("n_layout_text_near_active") !important
    }

    .txt-reduce {
      color: themed("n_layout_reduce_bg") !important
    }

    .txt-increase {
      color: themed("n_layout_increase_bg") !important
    }

    &.theme-light {
      .btn-icon-fm {
        background: #E4EAF2;
      }
    }

    .btn-icon-fm {
      border-radius  : $border_radius;
      text-align     : center;
      width          : 24px;
      height         : 24px;
      cursor         : pointer;
      display        : flex;
      align-self     : center;
      justify-content: center;
      background     : themed("n_layout_btn_bg");
      align-items    : center;


      &:hover {
        opacity: 0.7;
      }
    }

    .btn-icon-fm-2 {
      border-radius  : $border_radius;
      text-align     : center;
      width          : 32px;
      height         : 32px;
      cursor         : pointer;
      display        : flex;
      align-self     : center;
      justify-content: center;
      background     : themed("n_layout_btn_bg");
      align-items    : center;


      &:hover {
        opacity: 0.7;
      }
    }
  }
}


html.chrome {
  .theme-light {

    /* Chrome-specific styles here */
    .txt-p---400-13 {
      font-weight: 500 !important; // Body Small - Regular (7)
    }

    .txt-p---400-12 {
      font-weight: 600 !important; // Body Small - Regular (7)
    }
  }
}


// :root {
//   //Dark
//   --theme_01_common_primary : linear-gradient(to right, #265CAB, #2681E0);
//   --theme_01_common_ref     : rgb(249, 181, 37); //#F9B525
//   --theme_01_common_ceil    : rgb(255, 0, 255); //#FF00FF
//   --theme_01_common_floor   : rgb(10, 189, 227); //#0abde3
//   --theme_01_common_increase: rgb(80, 255, 0); //#50FF00
//   --theme_01_common_reduce  : rgb(249, 38, 38); //#F92626


//   --theme_01_common_text_active     : rgb(255, 255, 255); //000000
//   --theme_01_common_text_near_active: rgba(255, 255, 255, 0.7); //000000
//   --theme_01_common_text_not_active : rgba(255, 255, 255, 0.4); //000000
//   --theme_01_common_text_hover      : rgb(255, 255, 255); //000000

//   --theme_01_common_bg          : rgb(33, 36, 41);
//   --theme_01_common_block       : rgb(40, 43, 48); //#282B30
//   --theme_01_common_header_block: rgb(40, 43, 48); //#282B30
//   --theme_01_common_stroke      : rgb(63, 66, 71); //#3F4247
//   --theme_01_common_hover       : rgb(85, 87, 90); //#55575A
//   --theme_01_common_table1      : rgb(54, 56, 61); //#36383D
//   --theme_01_common_table2      : rgb(47, 49, 54); //#2F3136
//   --theme_01_common_table       : rgb(40, 43, 48); //#282B30

//   --theme_01_common_button_primary   : linear-gradient(to right, #265CAB, #2681E0);
//   --theme_01_common_button_buy       : linear-gradient(to right, #26E07C, #00B854);
//   --theme_01_common_button_sell      : linear-gradient(to right, #FF4444, #C20606);
//   --theme_01_common_button_sacondary : rgb(64, 67, 71); //404347
//   --theme_01_common_button_sacondary2: rgb(64, 67, 71); //404347

//   // Yêu cầu riêng issue BSCWEBEXT-158
//   --theme_01_common_green_custom: #21DB77;
//   --theme_01_common_red_custom  : #F93E3E;

//   --theme_01_common_secondary1   : rgb(255, 255, 255);
//   --theme_01_common_secondary2   : rgb(255, 213, 114);
//   --theme_01_common_bg_box_shadow: #000000;

//   // Animation Priceboard Price
//   --theme_01_common_animation_price_priceboard: rgb(132, 132, 133);

//   //Line
//   --theme_01_common_line: rgba(255, 255, 255, 0.2); //000000

//   //---------------Lightmode-----------
//   --theme_02_common_primary : linear-gradient(to right, #17B19D, #2B80E4);
//   --theme_02_common_ref     : #E39725;
//   --theme_02_common_ceil    : #D934C8;
//   --theme_02_common_floor   : #26B4E1;
//   --theme_02_common_increase: #238619;
//   --theme_02_common_reduce  : #D13338;

//   --theme_02_common_text_active     : #161F2C;
//   --theme_02_common_text_near_active: #454B56;
//   --theme_02_common_text_not_active : #7E8186;
//   --theme_02_common_text_hover      : #161F2C;

//   --theme_02_common_bg          : #DBE2EB;
//   --theme_02_common_block       : #FFFFFF;
//   --theme_02_common_header_block: #FFFFFF;
//   --theme_02_common_stroke      : #DADFE6;
//   --theme_02_common_hover       : #EAECF0;
//   --theme_02_common_table1      : #FFFFFF;
//   --theme_02_common_table2      : #FFFFFF;
//   --theme_02_common_table       : #FFFFFF;

//   --theme_02_common_button_primary   : linear-gradient(to right, #17B19D, #2B80E4);
//   --theme_02_common_button_buy       : linear-gradient(to right, #17C266, #00873E);
//   --theme_02_common_button_sell      : linear-gradient(to right, #FF4444, #C20606);
//   --theme_02_common_button_sacondary : #F4F6FA;
//   --theme_02_common_button_sacondary2: #7E8186;

//   // Yêu cầu riêng issue BSCWEBEXT-158
//   --theme_02_common_green_custom: #21DB77;
//   --theme_02_common_red_custom  : #F93E3E;

//   --theme_02_common_secondary1   : #1E4D96;
//   --theme_02_common_secondary2   : #C89110;
//   --theme_02_common_bg_box_shadow: rgb(162, 165, 178); //a2a5b2

//   // Animation Priceboard Price
//   --theme_02_common_animation_price_priceboard: rgb(132, 132, 133);

//   //Line
//   --theme_02_common_line: rgba(0, 0, 0, 0.2); //000000

// }