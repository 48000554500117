@import "mixins/css3.mixins";
@import "variables";
@import "utilities";
@import "utilities-figma";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-extend";

$fa-font-path: "../../assets/fontawesome";
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/regular";
@import "fontawesome/brands";
@import "fontawesome/light";

@import "base";
@import "common-style";
@import "form";
@import "custom-form";
@import "scrollbar";
@import "section";
@import "font-awesome";
@import "effect-animation";
@import "table";