@import "../../styles/common";

.modal-config-web {

    &.modal-config-web:has(.form-show-config) {
        max-width: 900px;
    }

    @include themify($themes) {
        .form {
            .container-btn {
                display: flex;
                gap    : 10px;
                width  : 100%;

                button {
                    width: 100%;
                }
            }
        }

        .form-sign-in {
            .password-field {
                margin-bottom: 10px;

                .btn-edit {
                    background-color: transparent;
                }

                .custom-form-group {
                    display : flex;
                    position: relative;

                    &:hover {
                        border-bottom: 1px solid themed('input_hover_border_bottom')
                    }

                    &:focus-within {
                        border-bottom: 1px solid themed('input_hover_border_bottom')
                    }


                    input {
                        width: 100%;
                    }

                    button {
                        position: absolute;
                        right   : 8px;
                        height  : 30px;
                    }

                    &.disabled {
                        background: themed("n_layout_btn_bg");
                        border    : none;
                        padding   : 5px 10px;
                        cursor    : default;

                        &:hover {
                            border: none;
                        }
                    }
                }

                .custom-form-group-captcha {
                    display    : flex;
                    align-items: center;
                    padding    : 0 5px;

                    // border-bottom: 1px solid themed('n_layout_border');
                    border       : 1px solid themed("n_layout_btn_bg");
                    border-radius: 4px;
                    height       : 40px;

                    .captcha {
                        width : 100%;
                        height: 40px;

                        svg {
                            height    : 40px;
                            margin-top: -5px;
                        }
                    }

                    .reload {
                        padding: 0 5px;
                        cursor : pointer;
                    }

                    &.disabled {
                        background: themed("n_layout_btn_bg");
                    }
                }

                .note {
                    padding   : 20px 20px 20px 5px;
                    font-style: italic
                }
            }

            .custom-form-select {
                padding: 5px !important;
            }

            .custom-form-group-flex-center {
                display     : flex;
                align-items : center;
                padding-left: 5px;
            }
        }

        .form-show-config {
            .basic-table {
                thead {
                    background-color: themed("n_table_header") !important;
                    color           : themed("n_table_text_active") !important;

                    th {
                        padding: 10px 10px;
                    }
                }

                tbody {
                    tr {
                        background: themed("n_table_row") !important;

                        td {
                            height       : 40px;
                            border-bottom: 1px solid themed("n_table_border");
                        }
                    }
                }
            }
        }
    }
}