@import "common";

// html,
// body {
//   // fix lỗi ẩn url mobile web
//   height  : auto !important;
//   overflow: scroll !important;
// }

// @media only screen and (-webkit-min-device-pixel-ratio: 1) {
//   body.fss {
//     overflow: auto !important;
//     height  : calc(100vh + 1px) !important;
//   }
// }

body.fss {
  @include themifyBody($themes) {
    background: themed('n_layout_bg');
    color     : themed('n_layout_text_active');

    .label {
      display      : inline-block;
      margin-bottom: 0px;
      color        : themed('n_layout_text_near_active')
      // color        : themed('n_layout_text_not_active');

      // font-family: 'Helvetica Neue Regular';
      // font-weight: 400; // Tiny - Regular (8)
      // font-size  : 14px;
      // line-height: 20px;
    }

    .label-button {
      cursor: pointer;
    }

    .label-button {

      &:hover,
      &:focus {
        filter: brightness(90%);
      }

      &[disabled] {
        cursor : not-allowed;
        opacity: 0.5;
      }
    }

    .icon-eye {
      color: themed('n_layout_text_active');
    }

    .modal-content {
      background-color: unset;
      border          : none;
      border-radius   : $border_radius;

      .header {
        @include border-radius-separate(4px, 4px, 0, 0);
      }

      .body {
        @include border-radius-separate(0, 0, 4px, 4px);
        max-height: calc(100vh - 80px);
        overflow  : auto;
      }
    }

    //huy.quang: custominput control
    .custom-control-label:before {}

    .custom-control {
      .custom-control-input:checked~.custom-control-label::before {
        background         : themed('n_header_btn_primary');
        border             : none;
        // box-shadow      : themed('n_btn_active') 0 0 1px 1px;
        box-shadow         : none;
        outline            : none;
      }

      .custom-control-input:checked:disabled~.custom-control-label::before {
        opacity: 0.5;
        cursor : not-allowed;
      }

      .custom-control-input:disabled~.custom-control-label::after,
      .custom-control-input:disabled~.custom-control-label::before {
        cursor: not-allowed;
      }
    }

    // Không tranform place holder (VD: uppercase)
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      text-transform: none;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      text-transform: none;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      text-transform: none;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      text-transform: none;
    }

    ::placeholder {
      /* Recent browsers */
      text-transform: none;
    }

    button {
      outline      : unset;
      border       : none;
      padding      : 0px;
      color        : themed("n_layout_text_active");
      cursor       : pointer;
      border-radius: $border_radius;

      &:hover {
        filter: brightness(90%);
      }

      &:active {
        filter: brightness(100%);
      }

      &:focus {
        // outline: 1px dashed #fc0390;
      }

      &.btn-login,
      &.btn-fix,
      &.btn-action,
      &.btn-export,
      &.btn-ok,
      &.btn-back,
      &.btn-finish,
      &.btn-add,
      &.btn-order,
      &.btn-place-order,
      &.btn-success,
      &.btn-close,
      &.btn-info,
      &.btn-danger,
      &.btn-close-cash-transfer,
      &.btn-confirm-cash-transfer,
      &.btn-cancel,
      &.btn-bonds,
      &.btn-cancel-cash-transfer,
      &.btn-menu,
      &.btn-confirm,
      &.btn-save,
      &.btn-more-action {
        background: themed("n_layout_btn_bg");

        // color: #fc0390;
        &:hover {
          // background-color: #fc0390;
        }

        &[disabled] {
          opacity: 0.5 !important;

          &:hover {
            cursor: not-allowed !important;
          }
        }
      }

      &.btn-common-table {
        background: themed("n_table_btn_bg");
        //padding   : 6px 10px;
        height    : 100%;
        color     : themed("n_text_custom");

        &[disabled] {
          opacity: 0.5 !important;

          &:hover {
            cursor: not-allowed !important;
          }
        }
        &:focus{
          border: 1px solid themed("input_hover_border_bottom") !important;
          border-radius: $border-radius;
        }
      }

      &.btn-table {
        background   : themed("n_layout_primary");
        padding      : 1px 5.5px;
        height       : 100%;
        border-radius: 2px;
        color        : themed("n_text_custom");
      }

      &.btn-action {
        // height: 32px;
        // line-height: 32px;
        padding         : 5px 10px;
        background-color: themed("n_layout_btn_bg");
        &:focus{
          border: 1px solid themed("input_hover_border_bottom") !important;
          border-radius: $border-radius;
        }
      }

      &.btn-close {
        height          : 48px;
        line-height     : 48px;
        background-color: themed("n_layout_btn_bg2");
        padding         : 0px 34px 0px 34px;
      }

      &.btn-refresh {
        height          : 40px;
        background-color: themed("n_layout_btn_bg2");
        color           : themed("n_text_custom_btn_refresh");

        &:focus{
          border: 1px solid themed("input_hover_border_bottom") !important;
          border-radius: $border-radius;
        }
      }

      &.btn-confirm {
        height    : 40px;
        background: themed("n_layout_primary");
        color     : themed("n_text_custom");
        &:focus{
          border: 1px solid themed("input_hover_border_bottom") !important;
          border-radius: $border-radius;
        }
      }

      &.btn-search {
        background: themed("n_layout_primary");
        padding   : 6px 10px;
        height    : 100%;
        color     : themed("n_text_custom");
        &:focus{
          border: 1px solid themed("input_hover_border_bottom") !important;
          border-radius: $border-radius;
        }
      }
    }

    .modal {
      overflow: hidden;
      bottom  : auto;
      right   : auto;
    }

    .modal-dialog {
      -moz-transition   : none;
      -o-transition     : none;
      -webkit-transition: none;
      transition        : none;
    }

    .customtooltip {
      pointer-events: none;

      .tooltip-inner {
        background     : themed('n_tooltip_bg') !important;
        border         : 4px solid transparent !important;
        // color: #fc0390;
        max-width      : 1000px;
        height         : 40px;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
      }

      &.bs-tooltip-top .arrow::before {
        border-top-color: themed('n_layout_border');
      }

      &.bs-tooltip-bottom .arrow::before {
        border-bottom-color: themed('n_layout_border');
      }

      &.bs-tooltip-left .arrow::before {
        border-left-color: themed('n_layout_border');
      }

      &.bs-tooltip-right .arrow::before {
        border-right-color: themed('n_layout_border') !important;
      }
    }

    .tooltip {
      pointer-events: none;

      .tooltip-inner {
        background: themed('n_tooltip_bg');
        border    : 1px solid themed('n_layout_border');
        color     : themed('n_layout_text_active');
        max-width : 1000px;
        white-space: pre-wrap;
      }

      &.bs-tooltip-top .arrow::before {
        border-top-color: themed('n_layout_border');
      }

      &.bs-tooltip-bottom .arrow::before {
        border-bottom-color: themed('n_layout_border');
      }

      &.bs-tooltip-left .arrow::before {
        border-left-color: themed('n_layout_border');
      }

      &.bs-tooltip-right .arrow::before {
        border-right-color: themed('n_layout_border');
      }
    }

    .basic-table {
      border-radius: $border-radius;
      background   : themed('n_table_bg');

      border: none;

      // box-shadow: 2px 2px 4px 0px themed("n_layout_box_shadow_bg");

      // -webkit-box-shadow: 2px 2px 4px 0px themed("n_layout_box_shadow_bg");

      width: 100%;

      &.sticky-table {
        box-shadow        : 2px 2px 4px 0px themed("n_layout_box_shadow_bg");
        -webkit-box-shadow: 2px 2px 4px 0px themed("n_layout_box_shadow_bg");
        border-radius     : $border-radius;

        .table-wrap {
          background : themed("n_table_bg");
          border     : 1px solid themed("n_table_border");
          border-left: none;
          border-top : none;
        }

        table {

          tbody {
            tr {
              &:nth-child(odd) {
                td {
                  background: themed("n_table_row_odd") !important;
                }
              }

              &:last-child {
                td {
                  height       : 29px !important;
                  line-height  : 29px !important;
                  border-bottom: 1px solid themed("n_table_border");
                }
              }

              &.footer {
                td {
                  background : themed("n_table_footer") !important;
                  color      : themed("n_table_footer_text");
                  font-weight: bold;
                  bottom     : 0;
                  position   : -webkit-sticky;
                  position   : sticky
                }
              }

              &.footer2 {
                td {
                  background : themed("n_table_footer") !important;
                  color      : themed("n_table_footer_text");
                  font-weight: bold;
                  bottom     : 35px;
                  position   : -webkit-sticky;
                  position   : sticky
                }
              }
            }
          }

          tr {
            &.last-row {

              th,
              td {
                border-bottom: none;
              }
            }

            th {
              border-bottom: 1px solid themed("n_table_border");
              border-left  : none;
              border-right : 1px solid themed("n_table_border");
              border-top   : none;

              &.last-column {
                border-right: none;
              }
            }

            td {
              border-bottom: none;
              border-left  : none;
              border-right : 1px solid themed("n_table_border");
              border-top   : none;

              &:last-child {
                border-right: none;
              }

              &.last-column {
                border-right: none;
              }
            }
          }
        }
      }

      table {
        thead {
          tr {
            th {
              background   : themed("n_table_header");
              border-bottom: 1px solid themed("n_table_border");
              border-left  : none;
              border-right : 1px solid themed("n_table_border");
              border-top   : none;
              color        : themed("n_table_text_active");
            }

            &:first-child th:last-child {
              border-right: none;
            }
          }
        }

        tbody {

          tr:nth-child(odd) td {
            background: themed("n_table_row_even");
          }

          tr:nth-child(even) td {
            background: themed("n_table_row_odd");
          }

          tr {
            cursor    : default;
            background: themed("n_table_row_even");
            border    : 1px solid themed("n_table_border");

            td {
              padding      : 5px 5px;
              border-bottom: 1px solid themed("n_table_border");
              border-left  : 1px solid themed("n_table_border");
              border-right : 1px solid themed("n_table_border");
            }

            td:first-child {
              border-left: none;
            }

            td:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    .container-checkbox {
      display            : flex;
      align-items        : center;
      position           : relative;
      margin-bottom      : unset;
      cursor             : pointer;
      -webkit-user-select: none;
      -moz-user-select   : none;
      -ms-user-select    : none;
      user-select        : none;
      width              : 16px;

      &.container-checkbox-content {
        gap  : 10px;
        width: 100%;

        .title-check-box {
          margin-left: 25px;
        }
      }

      input {
        position: absolute;
        opacity : 0;
        cursor  : pointer;
        height  : 0;
        width   : 0;

        &[disabled] {
          opacity: 0.5 !important;

          &:hover {
            cursor: not-allowed !important;
          }
        }
      }

      input:disabled~.checkmark {
        border: 0px;

        // opacity: 0.5;
        &:hover {
          cursor: not-allowed !important;
        }
      }

      .checkmark {
        position     : absolute;
        left         : 0;
        height       : 16px;
        width        : 16px;
        border-radius: 2px;
        border       : 2.5px solid themed("n_table_check_box");
      }

      &:hover input~.checkmark {
        // background-color: #ccc;
      }

      input:checked~.checkmark {
        background: themed("n_layout_primary");
        border    : unset;
      }

      .checkmark:after {
        content : "";
        position: absolute;
        display : none;
      }

      input:checked~.checkmark:after {
        display: block;
      }

      .checkmark:after {
        left             : 6px;
        top              : 2px;
        width            : 5px;
        height           : 10px;
        border           : solid themed("n_text_custom");
        border-width     : 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform    : rotate(45deg);
        transform        : rotate(45deg);
      }
    }

  }

  display               : flex;
  flex-direction        : column;
  // font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // font-family: "Helvetica Neue NOT USING FIGMA","Roboto",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-family           : "Helvetica Neue NOT USING FIGMA";
  font-size             : $font-size-14;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight           : 100;
  margin                : 0;
  min-height            : 100vh;
  overflow              : hidden;
  text-rendering        : optimizeLegibility;

  .item-center {
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  .item-center-left {
    display        : flex;
    align-items    : center;
    justify-content: flex-start;
  }

  ::-webkit-scrollbar {
    height: 0;
    width : 5px;
  }

  @include themifyBody($themes) {
    ::-webkit-scrollbar-track {
      background: themed('scrollbar-track-bg');
    }

    ::-webkit-scrollbar-thumb {
      background   : themed('scrollbar-thumb-bg');
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: themed('scrollbar-thumb-hover-bg');
    }
  }

  .h-100 {
    height: 100%;
  }

  .highcharts-container {
    .highcharts-credits {
      display: none;
    }
  }

  svg {
    font-family: 'Helvetica Neue Regular' !important;
  }

  iframe {
    font-family: 'Helvetica Neue Regular' !important;
  }


  .disable-dbl-tap-zoom {
    // Disable double-tap "zoom" option in browser on touch devices
    touch-action: manipulation;
  }

  .hidden {
    display: none !important;
  }

  .visibility {
    visibility: hidden !important;
  }

  .block-custom-tab {
    padding: 0px !important;

    .header-block-custom-tab {}

    .body-block-custom-tab {
      height: calc(100% - 40px);

      &.pd-10 {
        padding: 10px;
      }

      &.pd-10-no-top {
        padding: 0px 10px 10px 10px;
      }

      &.pdt-10 {
        padding-top: 10px;
      }

      &.pdr-10 {
        padding-right: 10px;
      }

      &.pdb-10 {
        padding-bottom: 10px;
      }

      &.pdl-10 {
        padding-left: 10px;
      }
    }
  }
}


html.chrome {
  .theme-light {
    .basic-table {
      &.table-custom {
        thead {
          tr {
            th {
              font-weight: 600 !important;
            }
          }
        }

        tbody {
          tr {
            td {
              font-weight: 600 !important;

              button {
                font-weight: 600 !important;
              }

              >div {
                font-weight: 600 !important;
              }
            }
          }
        }
      }
    }
  }
}