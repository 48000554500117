@import "../../../styles/common";

.price-chart {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}