@import "../../../../../styles/common";

.company-profile-symbol {
    @include themify($themes) {
        height: 100%;
        width : 100%;

        .company-profile-symbol-wrap {
            display       : flex;
            gap           : 10px;
            flex-direction: row;
            height        : 100%;
            width         : 100%;

            >div {
                height: 100%;
                width : 50%;
            }

            .block {
                background: themed("n_layout_bg_block");
                overflow  : auto;
                min-height: 300px;

                .session-header {
                    margin-bottom: 5px;
                }

                .session-body {
                    .session-body-wrap {
                        display       : flex;
                        flex-direction: column;
                        gap           : 10px;

                        .row-content {
                            display        : flex;
                            gap            : 3px;
                            justify-content: space-between;

                            .value {
                                text-align: right;
                            }
                        }
                    }
                }
            }

            .company-profile-content {
                padding: 10px;

                .company-profile-content-warp {
                    display       : flex;
                    flex-direction: column;
                    gap           : 10px;

                    .info-company {
                        .info-company-text {
                            text-align: justify;

                            p {
                                margin-bottom: 0px !important;
                            }
                        }
                    }
                }
            }

            .company-profile-detail {
                height        : 100%;
                display       : flex;
                gap           : 10px;
                flex-direction: column;

                .session-body {
                    height: calc(100% - 28px);

                    .table-profile {
                        height: 100%;
                    }
                }
            }
        }
    }
}



// từ 991px trở xuống
@include media-breakpoint-down(md) {
    .company-profile-symbol {
        @include themify($themes) {
            .company-profile-symbol-wrap {
                flex-direction: column;

                >div {
                    width: 100%;
                }
            }
        }
    }
}