@import "../../styles/common";

.droppable-element {
    @include themify($themes) {
        // width:  100%;
        // height: 20px;
        box-shadow   : unset;
        border       : unset;
        border-radius: unset;
        align-items  : center !important;
        ;
        // padding: unset;
        // border: 1px solid themed("n_layout_border");
        // border-radius: $border_radius;
        // padding: 10px;
    }

    .checkbox {
        display: unset;
    }

    .checkmark {
        display: unset;
    }

    .title-check-box {
        padding: unset !important;
        ;
        margin-left: 25px !important;
        ;
    }
}

.droppable-element.dragging {
    @include themify($themes) {
        width           : 100%;
        // height: 150px !important;
        // box-shadow   : 0px 0px 10px 4px themed("n_layout_box_shadow_bg");
        border          : 1px solid themed("n_layout_border");
        // background: red;
        border-radius   : $border_radius;
        align-items     : unset !important;
        // padding: 10px;
    }

    .checkbox {
        display: none;
    }

    .checkmark {
        display: none;
    }

    .title-check-box {
        padding: 10px !important;
        ;
        margin-left: unset !important;
    }
}

.popupSettingLayoutCustom {
    @include themify($themes) {
        width        : 500px;
        top          : 50px;
        position     : absolute;
        // height: 400px;
        background   : themed("n_layout_bg_block");
        box-shadow   : 0px 0px 10px 4px themed("n_layout_box_shadow_bg");
        border-radius: $border_radius;

        .header-popup-setting {
            padding   : 10px 10px;
            background: themed("n_layout_bg_header_block");
            position  : relative;

            .span-name {}

            .input-edit-name {
                background   : unset;
                border       : unset;
                box-shadow   : unset;
                outline      : unset;
                width        : 100%;
                border-bottom: 1px solid themed("n_layout_border");
            }

            .container-btn {
                display : flex;
                position: absolute;
                right   : 10px;
            }
        }

        .form-tick-layout {
            height    : 300px;
            //overflow: scroll;

            .row-select-input {
                padding: 0px 10px;

                .row-select-input-wrap {
                    display      : flex;
                    gap          : 10px;
                    padding      : 10px 0px;
                    border-bottom: 1px solid themed("n_layout_border");
                    cursor       : pointer;

                    &.disabled-row {
                        cursor : not-allowed;
                        opacity: 0.5;

                        .container-checkbox {
                            cursor: not-allowed !important;

                            input {
                                cursor: not-allowed !important;
                            }
                        }
                    }

                    .custom-hr {
                        background  : $theme_01_common_stroke;
                        border-color: themed("n_layout_border");
                    }
                }
            }
        }

        .footer-btn {
            padding: 10px;
            gap    : 10px;

            .btn-footer {
                height    : 32px;
                width     : 154px;
                background: themed("n_layout_btn_bg");
            }

            .button-reset-layout {
                height    : 28px;
                width     : 100%;
                background: themed("n_layout_btn_bg");
            }
        }
    }
}