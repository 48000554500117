@import "../../../styles/common";

.symbol-new-detail {
    @include themify($themes) {
        width        : 80vw !important;
        max-width    : 50vw !important;
        // max-height: 50vh !important;
        z-index      : 2000;

        .modal-body {
            padding-left : 0px !important;
            padding-right: 0px !important;
        }

        .exten-info {
            .not-active-color {
                color: themed("n_layout_text_not_active");
            }

            .near-active-color {
                color: themed("n_layout_text_near_active");
            }

            .sep-line-ver {
                margin-left : 15px;
                margin-right: 15px;
            }

            margin-bottom: 10px;
        }

        .detail-content {
            max-height: 70vh !important;
            overflow-y: auto;
        }

        .title {
            margin-bottom: 22px;
        }

        .label {
            display      : inline-block;
            margin-bottom: 0px;
            font-weight  : 400; // Body Medium - Regular
            font-size    : 14px;
            line-height  : 20px;
            color        : themed('n_layout_text_not_active');
        }

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }

        }

        .block-right {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;
            width        : 100%;

            .container-block {
                display       : flex;
                flex-direction: row;
                gap           : 10px;

                .block-item {
                    height        : auto;
                    width         : 50%;
                    display       : flex;
                    flex-direction: column;
                    gap           : 10px;
                }

                .row-title {
                    height            : 30px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed("n_table_border");
                }

                .row-item {
                    line-height    : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed("n_table_border");
                    padding      : 3px 10px;
                    border-radius: 4px;
                }
            }
        }

        .custom-body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }


            .content {
                max-height: 40vh;
                overflow  : auto;
                margin    : 0 auto;
            }

            // .container-btn {
            //     margin-top: 16px;
            //     display   : flex;
            //     gap       : 8px;
            //     flex-wrap : nowrap;
            //     width     : 100%;
            // }
        }

        .custom-footer {
            background: themed('n_layout_bg_block');

            height         : 80px;
            color          : themed('n_layout_text_active');
            width          : 100%;
            align-items    : center;
            display        : flex;
            padding        : 0px 20px;
            justify-content: space-between;

            .btn {
                width  : 100%;
                padding: 0px 34px;
            }
        }

        .custom-icon-reload {
            cursor  : pointer;
            position: absolute;
            right   : 5px;
            top     : -5px;
        }

    }
}