@import "../../../../styles/common";

.search-symbol-quick-order {
  @include themify($themes) {
    margin: 5px 0px 0px 0px;

    .react-autosuggest__suggestions-list{
      width: 100% !important;
      li{
        overflow: hidden;
      }
    }

    .block-info-symbol {
      margin-top      : 5px;
      padding         : 6px 8px;
      background-color: themed("n_layout_btn_bg");
      width           : 100%;
      border-radius   : $border_radius;
      height          : auto;
      min-height      : 40px;
      cursor          : pointer;

      &.short {
        padding-top: 10px;
      }

      .info-symbol {
        width: 100%;

        .custom-row {
          display        : flex;
          justify-content: space-between;
        }
      }
    }

    .search-container {
      padding: 7px 0px 0px 0px;

      .custom-form-group {
        margin-bottom: 5px;
      }
    }
  }
}