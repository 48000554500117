@import "../../../styles/common";

.account-lookup-by-symbol  {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .account-lookup-by-symbol-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .bold {
            font-weight: bold;
        }

        .h-page {
            height: calc(100vh - 55px);
        }
        .symbol-select-input-suggest-new {
            min-width: 70px;
            max-width: 100px;
        }
        .react-autosuggest__suggestions-container {
            max-width: 110px !important;
        }

        #react-autowhatever-symbol-search-market-alert{
            position: fixed;
            max-width: 300px !important;
        }

        .btn-search{
            border: 1px solid transparent;
        }
    }

    .custom-form-group-n{
        select {
            padding-left: 10px !important;
            margin-top: -1;
        }

        .symbol-suggest-place-order-quick{
            width: unset !important;
            input{
                height: 14px !important;
                padding-left: 10px !important;
                border: none !important;
            }
            .btn-icon{
                display: none !important;
            }
        }
    }
}