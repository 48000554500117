@import "../../../styles/common.scss";

.symbol-chartinday-modal {
    .skylight-dialog {
        // min-height: 70vh !important;
        // max-height: 70vh !important;
    }

    .custom-padding-right {
        padding-right: 20px !important;
    }

    .style-title {
        text-overflow: ellipsis;
        white-space  : nowrap;
        overflow     : hidden;
        width        : 90%;
    }

    .symbol-match-info {
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex-direction : column;
        gap            : 5px;
        width          : 100%;
    }

    @include themify($themes) {

        .txt-orange {
            color: themed("n_price_ref_text") !important;
        }

        // color: themed("Popup-title-color");

        .modal-content {}

        .header {
            // background: themed("n_layout_bg_block");

            .title {
                // color: themed("n_layout_bg_block");
            }

            button {
                // color: themed("n_layout_bg_block");

                &:hover {
                    // background: themed("n_layout_bg_block");
                }
            }
        }

        .body {
            min-width    : 500px !important;
            // background: themed('Popup-body-color');
            // color     : themed('Popup-title-color');

            .header-info-label {
                font-weight: bold;
                padding    : 2px 5px 8px 5px;
            }

            .active {
                color      : themed("n_layout_bg_block");
                font-weight: 700;
            }
        }


        .modal-content {
            background-color: transparent;
            margin          : auto;
            max-width       : 70vw !important;
            width           : 70vw !important;

            .btn-confirm-cash-transfer,
            .btn-close-cash-transfer {
                display       : inline-block;
                line-height   : 20px;
                padding       : 0 15px;
                text-transform: uppercase;

                &:hover {
                    opacity: 0.75;
                }
            }

            .btn-confirm-cash-transfer {
                margin: 5px 0 5px 200px;
            }

            .btn-close-cash-transfer {
                margin: 5px 0 5px 5px;
            }
        }

        @include media-breakpoint-up(lg) {
            // max-width: 70vw !important;
            // width    : 70vw !important;
        }

        .header {
            height: 30px;

            .title {
                // color      : themed("Popup-title-color");
                // font-size  : $font-size-16;
                line-height: 30px;
                padding    : 0 10px;
            }

            button {
                background    : transparent;
                border        : none;
                // font-size  : $font-size-16;
                height        : 30px;
                line-height   : 30px;
                padding       : 0 10px;
                text-align    : center;
                width         : auto;

                i {
                    line-height: 30px;
                }

                .fa-times {
                    // font-size: $font-size-18 + 2px;
                }
            }
        }

        .body {
            padding: 5px 10px;

            .content-body {
                height    : 300px !important;
                position  : relative;
                min-height: 300px !important;
                width     : 100%;
            }
        }

        .btn-container {
            button {
                border: none !important;
                width : 100px;
                height: 28px;
            }
        }

        .custom-form-group {
            margin: 5px 0;

            .custom-form-control.readonly {
                height    : auto;
                min-height: 28px;
            }
        }

        .table-container {
            height           : 100%;
            max-height       : 300px;
            max-width        : 100%;
            overflow         : scroll;
            overflow         : auto;
            // padding-bottom: 5px;
            width            : 100%;

            table {
                min-width: 100%;

                thead {
                    th {
                        background   : themed("n_layout_bg_block") !important;
                        border-bottom: 1px solid themed("n_layout_border");
                        border-right : 1px solid themed("n_layout_border");
                        line-height  : 34px;
                        padding      : 0 5px;
                        white-space  : nowrap;
                    }

                    .header-info-1 {
                        position: -webkit-sticky;
                        position: sticky;
                        top     : 0;

                        td {
                            background   : themed("n_layout_bg_block");
                            // color     : themed("n_layout_bg_block");
                        }
                    }

                    .header-info-2 {
                        position: -webkit-sticky;
                        position: sticky;
                        top     : 34px;

                        td {
                            background   : themed("n_layout_bg_block");
                            // color     : themed("n_layout_bg_block");
                        }
                    }
                }

                tbody {
                    td {
                        // border-right: 1px solid themed("n_layout_bg_block");
                        line-height: 34px;
                        padding    : 0 5px;
                        white-space: nowrap;
                    }

                    tr {
                        &:nth-child(even) {
                            td {
                                // background: themed("n_layout_bg_block") !important;
                            }
                        }

                        &.total {
                            td {
                                color: themed("n_layout_text_active");
                            }
                        }

                        &.total-sum {
                            td {
                                // background : themed("n_layout_bg_block");
                                bottom     : -5px;
                                color      : themed("n_layout_text_active");
                                font-weight: bold;
                                position   : -webkit-sticky;
                                position   : sticky;
                            }
                        }

                        &:nth-child(odd) {
                            // background: themed("n_layout_bg_block")
                        }

                    }
                }
            }
        }

        .tab-container {
            height                 : 34px;
            padding                : 0px !important;
            border-top-left-radius : 10px;
            border-top-right-radius: 10px;
            overflow               : hidden;

            .tab-item {
                font-size        : 14px;
                font-weight      : 600;
                // color      : themed("Popup-tab-label-color");
                display          : inline-block;
                height           : 100%;
                width            : auto;
                padding          : 3px 15px 0px 15px;
                border-top       : 2px solid transparent;
                // font-size     : $font-size-16;

                &:hover {
                    cursor   : pointer;
                    // color : themed("Popup-title-color");
                }

                &.active {
                    font-size        : 14px;
                    font-weight      : 600;
                    // color      : themed("Popup-title-color");
                    // border-top : 2px solid themed('Popup-title-active-color');
                    // box-shadow    : 0 2px 4px 0px themed("n_layout_bg_block")
                }
            }
        }

        .symbol-detail-content-container {
            // height: calc(70vh - 80px);

            .symbol-detail-content {
                // div bao ngoài để add thêm class
                height : 100%;
                width  : 100%;
                padding: 0px;
                margin : 0px;

                &>div {
                    height: 100%;
                }
            }
        }

        .price-chart-container {
            height    : 100%;
            min-height: 150px;
            padding   : 0px;
            margin    : 0px
        }

        .price-chart-container-full {
            height    : 100%;
            min-height: 150px;
            padding   : 0px;
            margin    : 0px
        }

        // thông tin thị trường theo mã
        .market-symbol-info {
            height        : 29%;
            min-height    : 50px;
            padding       : 0px;
            margin        : 0px;
            padding-bottom: 10px;

            &.showing-detail {
                height: 99% !important; // Chiều cao của ô chi tiết = Chiều cao ô chart(50vh) + info(20vh) + 10px padding 

                .news-symbol-list {
                    height: calc(99% - 61px) !important;
                }
            }

            ::-webkit-scrollbar {
                width: 6px;
            }

            .news-symbol-list {
                height         : 100%;
                overflow       : auto;
                margin         : 10px 0 0 10px;
                // color: #dfdfdf;
                // Cho FireFox
                scrollbar-width: thin;

                .news-symbol-item {
                    padding      : 4px;
                    border-bottom: 1px solid #444;

                    body.theme-white &,
                    body.theme-classic & {
                        border-bottom: 1px solid #dddddd;
                    }

                    .inner {
                        .title {
                            padding: 0px 5px;
                        }

                        .date {
                            white-space: nowrap;
                            padding    : 3px 0 0 5px;
                            font-size  : 11px;
                            color      : #999999;
                        }
                    }

                    &:hover,
                    &.no-data-item {
                        cursor             : pointer;
                        // background-color: themed('highcharts-background');

                    }

                    &.no-data-item {
                        cursor       : default;
                        border-bottom: none;
                    }
                }
            }
        }

        .recent-trade-title {
            height          : 28px;
            // font-size : $font-size-16;
            text-align      : center;
            padding         : 0px;
            margin          : 0px;
            // color        : themed("Popup-title-color")
        }

        .recent-trades {
            height: calc(100% - 28px); // Chiều cao bảng lịch sử khớp lệnh = 100% - chiều cao tiêu đề

            .table-header {
                .table-cell {
                    &.col-time {
                        // background  : themed("Popup-body-color");
                        // color       : themed("Popup-tab-label-color");
                        padding-left: 4px;
                        min-width   : 70px;
                        font-weight : normal;
                    }

                    &.col-price {
                        // background : themed("Popup-body-color");
                        // color      : themed("Popup-tab-label-color");
                        font-weight: normal;
                        flex       : 1.5 1.5 0;
                        min-width  : 50px;
                    }

                    &.col-change {
                        // background : themed("Popup-body-color");
                        // color      : themed("Popup-tab-label-color");
                        font-weight: normal;
                        flex       : 1.5 1.5 0;
                        min-width  : 50px;
                    }

                    &.col-volume {
                        // background : themed("Popup-body-color");
                        // color      : themed("Popup-tab-label-color");
                        font-weight: normal;
                        flex       : 1.5 1.5 0;
                        min-width  : 50px;
                    }

                    &.col-total-volume {
                        // background : themed("Popup-body-color");
                        font-weight   : normal;
                        // color      : themed("Popup-tab-label-color");
                        flex          : 2 2 0;
                        min-width     : 70px;
                    }

                }
            }

            .table-cell {
                &.col-side {
                    flex         : 0 0 0;
                    min-width    : 0px;
                    padding-right: 0px;
                }
            }
        }

        .hidden {
            display: none;
        }

        .chart-title {
            text-align      : center;
            // font-size    : $font-size-16;
            height          : 28px;
            // color     : themed("Popup-title-color");
        }

        .symbol-detail-chart-container {
            height: calc(100% - 28px); // Cao = tổng chiều cao - chiều cao title

            .dual-orderbook {
                .header {
                    background: none;
                }
            }
        }
    }
}