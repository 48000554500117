@import '../styles/common';

@include themify($themes) {
  .isNew {
    position : relative;
    z-index  : 0;
    animation: 0.5s ease-out highlight;

    &:before {
      content   : '';
      z-index   : -1;
      position  : absolute;
      top       : 0;
      left      : 0;
      right     : 0;
      bottom    : 0;
      background: themed("n_layout_text_active");
      opacity   : 0;
      animation : 0.5s ease-out highlightPseudo;
    }

    &:nth-of-type(odd) {
      animation: 0.5s ease-out highlightAlt;
    }

    &.highlightDec:before {
      background: opacify(themed("n_layout_reduce_bg"), 0.8);
    }

    &.highlightInc:before {
      background: opacify(themed("n_layout_increase_bg"), 0.8);
    }
  }

  .isNewDisable:before {
    content  : none;
    animation: none;
  }

  @include keyframes('highlight') {
    0% {
      background-color: transparent;
    }

    10% {
      background-color: themed("n_highligh_row_animation")
    }

    100% {
      background-color: transparent;
    }
  }

  @include keyframes('highlightAlt') {
    0% {
      background-color: rgba(51, 51, 51, 0.25);
    }

    10% {
      background-color: themed("n_layout_text_active")
    }

    100% {
      background-color: rgba(51, 51, 51, 0.25);
    }
  }

  @include keyframes('highlightPseudo') {
    0% {
      opacity: 0;
    }

    10% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }
}