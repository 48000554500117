@import "../../styles/common.scss";

.confirm-modal {

  &.modal-dialog {
    max-width: 550px !important;
    width    : 550px !important;
  }

  @include themify($themes) {
    .modal-content {

      .btn-confirm-cash-transfer,
      .btn-close-cash-transfer {
        border: 2px solid themed("n_layout_border");
      }
    }

    .header {
      background: themed("n_layout_bg_block");

      .title {
        color: themed("n_layout_text_active");
      }

      button {
        color: themed("n_layout_text_active");

        &:hover {
          background: themed("n_layout_bg_block");
        }
      }
    }

    .body {
      background: themed("n_layout_bg_block");
      color     : themed("n_layout_text_active");

      .header-info-label {
        font-weight: bold;
        padding    : 2px 5px 8px 5px;
      }

      .active {
        color      : themed("n_layout_text_active");
        font-weight: 700;
      }
    }


    .modal-content {
      background-color: transparent;
      margin          : auto;
      width           : 100%;
      max-width       : 550px !important;
      width           : 550px !important;


      .body {
        max-height: 420px !important;

        .table-container {
          max-height: 350px !important;

          .custom-scrollbar {
            max-height: 350px !important;

            div:first-child {
              max-height: 350px !important;
            }
          }

        }
      }

      .btn-confirm-cash-transfer,
      .btn-close-cash-transfer {
        display       : inline-block;
        line-height   : 20px;
        padding       : 0 15px;
        text-transform: uppercase;

        &:hover {
          opacity: 0.75;
        }
      }

      .btn-confirm-cash-transfer {
        margin: 5px 0 5px 200px;
      }

      .btn-close-cash-transfer {
        margin: 5px 0 5px 5px;
      }
    }

    // @include media-breakpoint-up(lg) {
    //   max-width: 30vw !important;
    //   width: 30vw !important;
    // }

    .btn-container button {
      border       : none;
      height       : 26px;
      margin-bottom: 10px;
      margin-left  : 10px;
      width        : 100px;

    }

    .header {
      height: 30px;

      .title {
        font-size  : $font-size-medium;
        line-height: 30px;
        padding    : 0 10px;
      }

      button {
        background : transparent;
        border     : none;
        font-size  : $font-size-medium;
        height     : 30px;
        line-height: 30px;
        padding    : 0 10px;
        text-align : center;
        width      : auto;

        i {
          line-height: 30px;
        }

        .fa-times {
          font-size: $font-size-big + 2px;
        }
      }
    }

    .body {
      padding: 5px 10px;

      .seperate-line {
        background-color: themed("n_layout_bg_block");
        height          : 1px;
        margin-top      : 5px;
        width           : 100%;
        margin-bottom   : 15px;
      }

      .text-content {
        margin-top : 15px;
        min-height : 40px;
        font-weight: bold;
      }
    }

    .custom-form-group {
      margin: 5px 0;

      .custom-form-control.readonly {
        height    : auto;
        min-height: 28px;
      }
    }

    .table-wrap {
      div {
        &:first-child {
          margin-bottom: 0 !important;
        }
      }
    }


  }
}