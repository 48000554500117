@import "../../../../styles/common";

.stock-transfer {
    @include themify($themes) {

        .auto-scroll {
            overflow: auto;
        }

        .h-100 {
            height: 100%;
        }

        .loading {
            color: themed('n_layout_text_not_active') !important;
        }

        .title {
            margin-bottom: 16px;
            font-family  : 'Helvetica Neue Bold';
            font-weight  : 700; // Heading 6 - B (6)
            font-size    : 16px;
            line-height  : 24px;
        }

        .h-table {
            height: calc(100% - 50px) !important;
        }

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }

        }

        .block-right {
            background      : themed('n_bg_block');
            border-radius   : 4px;
            // padding      : 10px;
            height          : 100%;
            width           : 100%;
            padding-right   : 0px !important;
            padding-left    : 0px !important;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            .container-block {
                display       : flex;
                flex-direction: row;
                gap           : 10px;

                .block-item {
                    height: auto;
                    width : 50%;
                }

                .row-title {
                    height            : 36px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed("n_table_border");
                }

                .row-item {
                    line-height    : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed("n_table_border");
                    padding      : 10px;
                    border-radius: 4px;
                }
            }
        }

    }
}

// @include media-breakpoint-down(sm) {
//     .stock-transfer {
//         .auto-scroll {
//             overflow: auto;
//         }

//     }
// }