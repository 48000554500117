@import "../../../styles/common";

.trading-value-report {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .table-ex-container{
            height: calc(100% - 50px);
        }

        .trading-value-report-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }
    }
}