@import "../../styles/common.scss";

.theme-light {
  .modal-content {
    .header {
      background: $theme_02_common_secondary1 !important;

      .btn-icon-fm {
        background: rgba(255, 255, 255, 0.1) !important;
      }
    }
  }
}

.draggable-modal {
  @include themify($themes) {
    .modal-content {
      position           : relative;
      display            : flex;
      flex-direction     : column;
      width              : 100%;
      pointer-events     : auto;
      background-color   : transparent;
      background-clip    : padding-box;
      // border          : 1px solid rgba(0, 0, 0, 0.2);
      border-radius      : $border_radius;
      outline            : 0;

      .header {
        border-radius  : $border_radius $border_radius 0 0;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        background     : themed("n_layout_bg_header_block");
        padding        : 0px 10px;
        height         : 44px;
        color          : themed("n_text_custom");
      }

      .body {
        border-radius: 0 0 $border_radius $border_radius;
        background   : themed("n_layout_bg_block");
        padding      : 10px 10px;
      }
    }
  }
}