@import "../../../styles/common.scss";

.modal-dialog.symbol-detail-modal {
    @include media-breakpoint-down(xl) {
        max-width: 986px !important;
        width    : 986px !important;
    }

    .tab {
        @include themify($themes) {

            display: flex;
            gap    : 10px;
            margin : 10px 0px 0px 0px;

            .sub-tab {
                cursor       : pointer;
                padding      : 4px 8px;
                border-radius: 4px;
                background   : themed("n_btn_icon_bg");

                &.active {
                    border : 1px solid themed('n_btn_active');
                    padding: 3px 7px;
                }
            }
        }
    }

    .modal-content {
        @include themify($themes) {
            .company-name-container {
                overflow   : hidden;
                min-height : 40px;
                align-items: center;
                display    : flex;
                padding    : 0px 10px;

                .additional-information-container {
                    border-left: 1px solid themed("n_layout_border");
                    padding    : 0px 10px;

                    &:first-child {
                        border-left : unset;
                        padding-left: 0px;
                    }
                }
            }

            .body-symbol-detail-modal {
                padding   : 0px 10px 10px 10px;
                background: themed("n_layout_bg");

                .main {
                    height  : 572px;
                    position: relative;
                    overflow: auto;

                    .overview {
                        height : 100%;
                        width  : 100%;
                        display: flex;
                        gap    : 10px;

                        position: absolute;

                        z-index: 50;

                        &.show {
                            z-index: 51;
                        }

                        background : themed("n_layout_bg");

                        .tv-chart {
                            height       : inherit;
                            // width     : 660px;
                            width        : 64%;
                            background   : themed("n_layout_bg_block");

                            border-radius: 4px;

                            .price-chart {
                                .TVChartContainer {
                                    iframe {
                                        border-radius: 4px !important;
                                    }
                                }
                            }
                        }

                        .recent-trade {
                            background: themed("n_layout_bg_block");

                            border-radius: 4px;

                            // width: calc(100% - 660px - 10px);
                            width: 36%;

                            .title {
                                height       : 40px;
                                padding      : 10px;
                                border-bottom: 1px solid themed("n_table_border");
                            }

                            .recent-trades {
                                height: calc(100% - 40px);
                            }
                        }
                    }

                    .processional {
                        height : 100%;
                        width  : 100%;
                        display: flex;
                        gap    : 10px;
                        z-index: 50;

                        position: absolute;

                        &.show {
                            z-index: 51;
                        }

                        background : themed("n_layout_bg");

                        .match-in-day,
                        .order-book-trade,
                        .order-match {
                            height          : 100%;
                            width           : 100%;
                            // border       : 1px solid white;
                            border-radius   : 4px;

                            background: themed("n_layout_bg_block");

                            .title {
                                height       : 40px;
                                padding      : 10px;
                                border-bottom: 1px solid themed("n_table_border");
                            }
                        }

                        .match-in-day {
                            height: calc(100% - 1px) !important;

                            .chart-matched-inday {
                                height: calc(100% - 40px) !important;
                            }
                        }

                        .order-match {
                            div:nth-child(2) {
                                visibility: unset !important;
                                height    : calc(100% - 40px) !important;

                                .chart-matched-info {
                                    // height: calc(100% - 40px) !important;
                                }
                            }
                        }

                        .order-book-trade {

                            .block-info-container {
                                width        : 100%;
                                padding-left : 10px;
                                padding-right: 10px;

                                .block-info-symbol {
                                    margin-top      : 10px;
                                    padding         : 6px 8px;
                                    background-color: themed("n_layout_btn_bg");
                                    width           : 100%;
                                    border-radius   : $border_radius;
                                    height          : auto;
                                    min-height      : 50px;

                                    .info-symbol {
                                        width: 100%;

                                        .custom-row {
                                            display        : flex;
                                            justify-content: space-between;
                                        }
                                    }
                                }
                            }

                            .resizesensor-wrapper {
                                height  : 180px !important;
                                overflow: auto;

                                .dual-orderbook {
                                    .table-container {
                                        .container-detail {
                                            margin-top: 0;

                                            .buy,
                                            .sell {
                                                .header>div {
                                                    gap: 10px
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        max-width: 700px !important;
        width    : 700px !important;

        .modal-content {
            @include themify($themes) {
                .body-symbol-detail-modal {
                    .main {
                        .overview {
                            height        : 100%;
                            width         : 100%;
                            display       : flex;
                            flex-direction: column;
                            gap           : 10px;

                            position: absolute;

                            display: none;

                            &.show {
                                display: flex;
                            }

                            background : themed("n_layout_bg");

                            .tv-chart {
                                height: 600px;
                                width : 100%;

                                .price-chart {
                                    .TVChartContainer {
                                        iframe {
                                            border-radius: 4px !important;
                                        }
                                    }
                                }
                            }

                            .recent-trade {
                                background: themed("n_layout_bg_block");
                                width     : 100%;
                                height    : 500px;

                                .title {
                                    height       : 40px;
                                    padding      : 10px;
                                    border-bottom: 1px solid themed("n_table_border");
                                }

                                .recent-trades {
                                    height: calc(100% - 40px);
                                }
                            }
                        }

                        .processional {
                            height        : auto;
                            width         : 100%;
                            display       : flex;
                            flex-direction: column;
                            gap           : 10px;
                            z-index       : 50;

                            display: none;

                            &.show {
                                display: flex;
                            }

                            background : themed("n_layout_bg");

                            .match-in-day,
                            .order-book-trade,
                            .order-match {
                                width           : 100%;
                                height          : 500px;
                                // border       : 1px solid white;
                                border-radius   : 4px;

                                background: themed("n_layout_bg_block");

                                .title {
                                    height       : 40px;
                                    padding      : 10px;
                                    border-bottom: 1px solid themed("n_table_border");
                                }
                            }

                            .match-in-day {
                                height: calc(100% - 1px);
                            }

                            .order-match {
                                div:nth-child(2) {
                                    visibility: unset !important;
                                    height    : calc(100% - 40px) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        max-width: 95% !important;
        width    : 95% !important;

        .modal-content {
            .dragHandler.header {
                height : auto;
                padding: 5px 10px;
            }
        }
    }

}

.symbol-detail-modal-screen {
    height  : calc(100% - 136px);
    overflow: unset !important;

    &.symbol-detail-modal-screen:has(.hidden-stock-header) {
        height: calc(100% - 60px);
    }
}

.symbol-detail-static {
    height: 100%;

    .action-btn {
        @include themify($themes) {
            display: inline-flex;
            float  : right;

            border        : none;
            font-size     : $font-size-medium;
            // line-height: 36px;
            padding       : 0;
            text-align    : center;
            user-select   : none;

            &.active {
                color: themed("n_layout_text_active");
            }

            :hover {
                color: themed("n_layout_text_active");
            }
        }
    }

    .symbol-detail-static-header {
        @include themify($themes) {
            margin-top    : 2px;
            padding       : 5px 10px;
            background    : themed("n_layout_bg_header_block");
            display       : flex;
            flex-direction: column;
            gap           : 10px;
        }

        .symbol-container {
            position: static;
        }

        .stock-header-price {
            max-width     : 600px;
            display       : flex;
            flex-direction: column;
            gap           : 5px;
            margin-left   : 10px;

            .info-price-main {
                .wrap-info-price-main {
                    display        : flex;
                    justify-content: space-between;

                    .price-market-content {
                        display: flex;
                        gap    : 10px;

                        .price-market {
                            font-size      : 30px !important;
                            justify-content: center;
                            display        : flex;
                            align-items    : center;
                        }

                        .info-change-market {
                            .value-change {
                                display        : flex;
                                align-items    : flex-end;
                                justify-content: center;
                            }

                            .percent-change {
                                display        : flex;
                                align-items    : flex-end;
                                justify-content: center;
                            }
                        }
                    }

                    .market-symbol-info {
                        .info-market-col {
                            display: flex;
                            gap    : 20px;

                            .row-market {

                                .label,
                                .price-change {
                                    display        : flex;
                                    align-items    : flex-end;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }
                }
            }

            .info-price-total {
                .wrap-info-price-total {
                    display        : flex;
                    justify-content: space-between;
                    flex-wrap      : wrap;

                    .row-market {
                        display: flex;

                        .label {
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        width: 100% !important;
    }

    @include themify($themes) {
        .company-name-warp-container {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            .company-name-container {
                overflow   : hidden;
                min-height : 40px;
                align-items: center;
                display    : flex;
                padding    : 0px 10px;

                .additional-information-container {
                    border-left: 1px solid themed("n_layout_border");
                    padding    : 0px 10px;

                    &:first-child {
                        border-left : unset;
                        padding-left: 0px;
                    }
                }
            }

            .btn-container {
                .btn-filter {
                    border       : 1px solid transparent;
                    color        : themed("n_layout_text_active");
                    background   : themed("n_btn_icon_bg") !important;
                    border-radius: $border_radius;
                    height       : 30px;
                    width        : 30px;
                    cursor       : pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .body-symbol-detail-modal {
            height        : 100%;
            padding       : 0px 0px 0px 10px;
            background    : themed("n_layout_bg");
            display       : flex;
            gap           : 10px;
            flex-direction: column;
            height        : 100%;

            .tab {
                display: flex;
                gap    : 10px;
                margin : 10px 0px 0px 0px;

                .sub-tab {
                    cursor       : pointer;
                    padding      : 4px 8px;
                    border-radius: 4px;
                    background   : themed("n_btn_icon_bg");
                    text-align   : center;

                    &.active {
                        border : 1px solid themed('n_btn_active');
                        padding: 3px 7px;
                    }
                }
            }

            .tab-recenttrade {
                display: flex;
                gap    : 0px;
                margin : 5px 0px;

                .sub-tab {
                    cursor          : pointer;
                    padding         : 4px 8px;
                    // border-radius: 4px;
                    // background   : themed("n_btn_icon_bg");
                    // border-right : 1px solid themed('n_layout_text_not_active');
                    color           : themed('n_layout_text_near_active');

                    .line-title {
                        border-right   : 1px solid themed("n_layout_line");
                        height         : 60%;
                        margin-left    : 10px;
                        // margin-left : 8px;
                    }

                    &.active {
                        color     : themed('n_layout_text_active');
                        // border : 1px solid themed('n_btn_active');
                        padding   : 3px 7px;
                    }
                }
            }

            .main {
                height  : 100%;
                overflow: auto;
                width   : 100%;

                .overview {
                    .matched-chart-container {
                        height: calc(100% - 90px);
                    }

                    .tv-chart,
                    .order-book-trade,
                    .recent-trade {
                        // height          : 100%;
                        width           : 100%;
                        // border       : 1px solid white;
                        border-radius   : 4px;

                        background: themed("n_layout_bg_block");

                        .title {
                            height       : 40px;
                            padding      : 10px;
                            border-bottom: 1px solid themed("n_table_border");
                        }
                    }

                    .tv-chart {
                        &.full {
                            width: 100% !important;
                        }
                    }

                    .order-book-trade {
                        height  : 100% !important;
                        overflow: auto;

                        .block-info-container {
                            width        : 100%;
                            padding-left : 10px;
                            padding-right: 10px;

                            .block-info-symbol {
                                margin-top      : 10px;
                                padding         : 6px 8px;
                                background-color: themed("n_layout_btn_bg");
                                width           : 100%;
                                border-radius   : $border_radius;
                                height          : auto;
                                min-height      : 50px;

                                .info-symbol {
                                    width: 100%;

                                    .custom-row {
                                        display        : flex;
                                        justify-content: space-between;
                                    }
                                }
                            }
                        }

                        .resizesensor-wrapper {
                            height  : 180px !important;
                            overflow: auto;

                            .dual-orderbook {
                                .table-container {
                                    .container-detail {
                                        margin-top: 0;

                                        .buy,
                                        .sell {
                                            .header>div {
                                                gap: 10px
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                    .tv-chart {
                        // height       : inherit;
                        // width     : 60%;
                        background: themed("n_layout_bg_block");

                        border-radius: 4px;

                        .price-chart {
                            height: calc(100% - 45px);

                            .TVChartContainer {
                                iframe {
                                    border-radius: 4px !important;
                                }
                            }
                        }
                    }

                    .recent-trade {
                        background: themed("n_layout_bg_block");

                        border-radius: 4px;

                        // width: calc(100% - 60% - 10px);

                        .title {
                            height       : 40px;
                            padding      : 10px;
                            border-bottom: 1px solid themed("n_table_border");
                        }

                        .recent-trades {
                            height: calc(100% - 90px);
                        }
                    }
                }

                .processional {

                    .match-in-day,
                    .order-book-trade,
                    .order-match {
                        height          : 100% !important;
                        width           : 100%;
                        // border       : 1px solid white;
                        border-radius   : 4px;

                        background: themed("n_layout_bg_block");

                        .title {
                            height       : 40px;
                            padding      : 10px;
                            border-bottom: 1px solid themed("n_table_border");
                        }
                    }

                    .match-in-day {
                        height: calc(100% - 1px) !important;

                        .chart-matched-inday {
                            height: calc(100% - 40px) !important;
                        }
                    }

                    .order-match {
                        div:nth-child(2) {
                            visibility: unset !important;
                            height    : calc(100% - 40px) !important;

                            .chart-matched-info {
                                // height: calc(100% - 40px) !important;
                            }
                        }
                    }

                    .order-book-trade {
                        height: 100% !important;

                        .block-info-container {
                            width        : 100%;
                            padding-left : 10px;
                            padding-right: 10px;

                            .block-info-symbol {
                                margin-top      : 10px;
                                padding         : 6px 8px;
                                background-color: themed("n_layout_btn_bg");
                                width           : 100%;
                                border-radius   : $border_radius;
                                height          : auto;
                                min-height      : 50px;

                                .info-symbol {
                                    width: 100%;

                                    .custom-row {
                                        display        : flex;
                                        justify-content: space-between;
                                    }
                                }
                            }
                        }

                        .resizesensor-wrapper {
                            height  : 180px !important;
                            overflow: auto;

                            .dual-orderbook {
                                .table-container {
                                    .container-detail {
                                        margin-top: 0;

                                        .buy,
                                        .sell {
                                            .header>div {
                                                gap: 10px
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }

                .tab-info-symbol {
                    height : 100%;
                    width  : 100%;
                    display: flex;
                    gap    : 10px;
                    display: none;

                    &.show {
                        display: flex;
                    }

                    background : themed("n_layout_bg");
                }
            }
        }
    }


    @include media-breakpoint-down(md) {
        width: 100%;

        @include themify($themes) {
            .body-symbol-detail-modal {
                .main {

                    .tab-info-symbol {
                        height        : 100%;
                        width         : 100%;
                        display       : flex;
                        flex-direction: column;
                        gap           : 10px;

                        .tv-chart,
                        .order-book-trade,
                        .recent-trade {
                            min-height: 100%;
                            height    : 100%;
                        }

                        &.show {
                            display: flex;
                        }

                        background : themed("n_layout_bg");

                        .surplus {
                            width : 100%;
                            height: 600px;

                            .order-book-trade {
                                width : 100%;
                                height: 600px !important;
                            }

                            .tv-chart {
                                height: 600px;
                                width : 100%;

                                .price-chart {
                                    height: 600px;

                                    .TVChartContainer {
                                        iframe {
                                            border-radius: 4px !important;
                                        }
                                    }
                                }
                            }

                            .matched-chart-container {
                                height: 500px
                            }

                            .recent-trade {
                                background: themed("n_layout_bg_block");
                                width     : 100%;
                                height    : 500px;

                                .title {
                                    height       : 40px;
                                    padding      : 10px;
                                    border-bottom: 1px solid themed("n_table_border");
                                }

                                #recent-trades-id {
                                    height: 500px;
                                }

                                .recent-trades {
                                    height: calc(100% - 40px);
                                }
                            }
                        }

                        .processional {
                            height        : auto;
                            width         : 100%;
                            display       : flex;
                            flex-direction: column;
                            gap           : 10px;
                            z-index       : 50;

                            display: none;

                            &.show {
                                display: flex;
                            }

                            background : themed("n_layout_bg");

                            .match-in-day,
                            .order-book-trade,
                            .order-match {
                                width           : 100%;
                                height          : 500px !important;
                                // border       : 1px solid white;
                                border-radius   : 4px;

                                background: themed("n_layout_bg_block");

                                .title {
                                    height       : 40px;
                                    padding      : 10px;
                                    border-bottom: 1px solid themed("n_table_border");
                                }
                            }

                            .match-in-day {
                                height: calc(100% - 1px);
                            }

                            .order-match {
                                div:nth-child(2) {
                                    visibility: unset !important;
                                    height    : calc(100% - 40px) !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            width: 100% !important;

            .symbol-detail-static-header {
                height : auto;
                padding: 5px 10px;

                .company-name-container {}
            }
        }
    }
}