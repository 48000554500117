@import "../../../../styles/common";

.modal-place-order-tpsl {
    @include themify($themes) {
        max-width: 400px;
        margin   : auto;
        height   : auto;
        right    : 0px;

        .body {
            background: themed("n_layout_bg_block");
            padding   : 20px;

            .place-order-tpsl {
                .type-view {
                    display: flex;
                    margin : 10px 0px;

                    &:first-child {
                        margin-top: 0px;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .content {
                        padding: 6px 0px;
                    }

                    .content-left {
                        width      : 30%;
                        display    : flex;
                        align-items: center;
                    }

                    .content-right {
                        width: 70%;
                    }
                }

                .price-order {
                    .content-right {
                        display: flex;
                        gap    : 10px;

                        .row-input {
                            width: 60%;
                        }

                        .row-note {
                            width: 40%;
                        }

                        .block-input-qtty,
                        .block-input-price {
                            width        : 100%;
                            border-bottom: 1px solid themed("n_layout_btn_bg");
                        }
                    }
                }

                .container-action {
                    width  : 100%;
                    display: flex;
                    gap    : 10px;

                    .btn-action {
                        width : 100%;
                        height: 40px;
                    }
                }
            }
        }
    }
}