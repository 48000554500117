@import "../../../../../styles/common";

.company-financial-symbol {
    @include themify($themes) {
        height: 100%;
        width : 100%;

        .action-container {
            margin    : 0px 0px 10px 0px;
            display   : flex;
            gap       : 10px;
            max-width : 100%;
            overflow-x: auto;

            .list-select-exchange {
                display: flex;
                gap    : 10px;

                .select-exchange-type {
                    padding      : 5px 15px;
                    background   : themed("n_layout_btn_bg");
                    border-radius: $border_radius;
                    cursor       : pointer;
                    white-space  : nowrap;
                }
            }

            .container-select {
                gap: 10px;
            }
        }

        .legend-container {
            display      : flex;
            gap          : 10px;
            gap          : 5px;
            text-overflow: ellipsis;
            overflow     : hidden;
            white-space  : nowrap;

            .legend-item {
                display  : flex;
                flex-wrap: nowrap;
                gap      : 4px;
                padding  : 6px;

                &.single-column {
                    .block-legend-color {
                        background: themed("n_layout_primary");
                    }
                }

                &.multi-column {
                    .block-legend-color-1 {
                        background: themed("n_layout_primary");
                    }

                    .block-legend-color-2 {
                        background: themed("n_price_ceil_text");
                    }
                }

                .block-legend-color {
                    width        : 16px;
                    height       : 16px;
                    border-radius: 2px;
                    margin-top   : 1px;
                }

                .block-legend-text {
                    color: themed("n_layout_text_active");
                }
            }
        }

        .company-financial-symbol-wrap {
            height: 100%;
            width : 100%;

            .block-session {
                background: themed("n_layout_bg_block");
                width     : 100%;
                height    : 100%;
            }

            .company-financial-info {
                height: 100%;
                width : 100%;

                .company-financial-info-content {
                    height        : 100%;
                    width         : 100%;
                    display       : flex;
                    flex-direction: column;
                    gap           : 10px;
                    padding       : 10px;

                    .row-content {
                        display        : flex;
                        gap            : 5px;
                        justify-content: space-between;
                    }
                }
            }

            .company-financial-chart {
                padding: 10px;

                .filter-container {
                    display  : flex;
                    flex-wrap: nowrap;
                    gap      : 10px;

                    .left-block {
                        display   : flex;
                        flex-wrap : nowrap;
                        gap       : 10px;
                        width     : 50%;
                        text-align: left;
                    }

                    .filter-item {
                        padding         : 6px 10px;
                        border-radius   : 4px;
                        color           : themed("n_layout_text_not_active");
                        background-color: themed("n_form_bg");
                        cursor          : pointer;

                        &.active {
                            border: 1px solid themed("n_layout_text_active");
                            color : themed("n_layout_text_active");
                        }
                    }
                }

                .company-financial-symbol-chart {
                    .header-block-chart {
                        margin: 10px 0px;

                        .title {
                            padding-bottom: 3px;
                            border-bottom : 1px solid themed("n_layout_text_secondary_1");
                        }
                    }

                    .body-block-chart {
                        .category-label {
                            color: themed("n_layout_text_active");
                            fill : themed("n_layout_text_active");
                        }

                        .tooltip-category-label {
                            .category-label {
                                color: #000;
                                fill : #000;
                            }
                        }


                    }
                }
            }
        }
    }
}

// từ 1200px trở xuống
@include media-breakpoint-down(lg) {
    .company-financial-symbol {
        .company-financial-symbol-wrap {
            .company-financial-info {
                display       : flex;
                flex-direction: row;
                gap           : 10px;
            }
        }
    }
}