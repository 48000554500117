@import "../styles/common.scss";

.unverified-account-noti {

    .layout-setting-modal .modal-body {
        background: white;
        max-width: 400px;
        border-radius: 12px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
    
    .modal-title {
        font-size: 1.2rem;
    }
    
    .modal-text {
        font-size: 1rem;
        line-height: 1.5;
    }
    
    .btn-container .btn {
        font-size: 0.95rem;
        font-weight: 500;
        background-color: #f27801;
        transition: all 0.3s ease-in-out;
    }
    
    .btn-container .btn:hover {
        background-color: #ee8822;
        color: white;
    }
    
}