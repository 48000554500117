@import "../../styles/common";

.customer-selector-modal {

  @include themify($themes) {
    .header {
      background: themed("n_layout_bg_block");

      .title {
        color: themed("n_layout_text_active");
      }

      button {
        color: themed("n_layout_text_active");

        &:hover {
          background: themed("n_layout_bg_block");
        }
      }
    }

    .modal-body {
      background: themed('n_layout_bg_block');
      .TableDevExtreme {
        height: 420px;
      }
    }

    .body {
      color     : themed("n_layout_text_active");
      background: themed("n_layout_bg_block");
    }

    .search-container {
      background-color: themed('n_form_bg');
      display         : flex;
      flex-direction  : row;
      border-radius   : 4px;
      height          : 32px;
      padding         : 6px 10px 6px 10px;

      input {
        color           : themed('n_layout_text_active');
        border          : none;
        background-color: themed('n_form_bg');
        max-width       : 120px;

        &:focus-visible {
          border : none;
          outline: none;
        }
      }
    }
  }

  .modal-content {
    @include css3-prefix('box-shadow', '0px 0px 5px 3px rgba(0,0,0,0.5)');
  }

  @include media-breakpoint-up(md) {
    width    : 760px !important;
    max-width: 760px !important;
  }

  @include media-breakpoint-up(lg) {
    width    : 1100px !important;
    max-width: 1100px !important;
  }

  .header {
    height: 30px;

    .title {
      padding    : 0 10px;
      font-size  : $font-size-medium;
      line-height: 30px;
    }

    button {
      width      : auto;
      height     : 30px;
      border     : none;
      padding    : 0 10px;
      line-height: 30px;
      text-align : center;
      background : transparent;
      font-size  : $font-size-medium;

      i {
        line-height: 30px;
      }

      .fa-times {
        font-size: $font-size-big + 2px;
      }
    }
  }

  .body {
    // height: 400px;
    @include calc('max-height', '100vh - 90px');
  }
}