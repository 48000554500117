@import "../../../styles/common";

@mixin active-text-color {
  color: themed("n_layout_text_active");
}
.fss.isMobile { 
  .wizard {
    display: none !important;
  }
}
.wizard {
  @include themify($themes) {

    .step-title {
      padding-top: 7px;
    }

    .line{
      width: 55px;
      height: 1px;
      margin-top: 20px;
      border-top: 2px solid themed("n_layout_bg_block");
      margin-left: 16px;
      margin-right: 16px;
    }

    .step {
      &:before {
        background  : themed("n_layout_bg_block");
        border-color: themed("n_layout_border");
        color       : themed("n_layout_text_active");
      }

      &:after {
        background  : themed("n_layout_bg_block");
        border-color: themed("n_layout_border");
        color       : themed("n_layout_text_active");
      }

      .step-icon {
        background  : themed("n_layout_bg_block");
        border-color: themed("n_layout_border");
        color       : themed("n_layout_text_active");
      }
    }

    &.step-1-active {
      .step {
        &.step-1 {
          .step-icon {
            background: rgba(58, 79, 172, 1);;
            color     : themed("n_layout_text_active");
          }

          .step-legend {
            @include active-text-color()
          }
        }
      }
    }

    &.step-2-active {
      .step {

        &.step-1,
        &.step-2 {
          .step-icon {
            background: rgba(58, 79, 172, 1);
            color     : themed("n_layout_text_active");
          }

          // .step-legend {
          //   @include active-text-color()
          // }

          // &:before {
          //   background: rgba(58, 79, 172, 1);
          // }
        }

        // &.step-1 {
        //   &:after {
        //     background: rgba(58, 79, 172, 1);
        //   }
        // }
      }

      .line-1-2 {
        border-top-color: rgba(58, 79, 172, 1) !important;
      }
    }

    &.step-3-active {
      .step {
        .step-icon {
          background: rgba(58, 79, 172, 1);;
          color     : themed("n_layout_text_active");
        }


        // .step-legend {
        //   @include active-text-color()
        // }

        // &:before {
        //   background: rgba(58, 79, 172, 1);;
        // }

        // &:after {
        //   background: rgba(58, 79, 172, 1);
        // }
      }

      .line-1-2, .line-2-3 {
        border-top-color: rgba(58, 79, 172, 1) !important;
      }
    }
  }

  margin: 35px auto 39px auto;

  max-width: 565px;

  .step {
    z-index: 1;

    @include themify($themes) {

      &.clickable {
        cursor: pointer;

        &:hover {
          .step-icon {
            background: themed("n_layout_bg_block");
          }
        }
      }


      &.step-middle {
        &.active {
          .step-legend {
            @include active-text-color()
          }

          .step-icon {
            background: themed("n_layout_bg_block");
          }

          &:before {
            background: themed("n_layout_bg_block");
          }
        }

        &.actived {
          .step-legend {
            @include active-text-color()
          }

          .step-icon {
            background: themed("n_layout_bg_block");
          }

          &:before {
            background: themed("n_layout_bg_block");
          }

          &:after {
            background: themed("n_layout_bg_block");
          }
        }
      }

      &.step-first {
        width: 100px;

        &:before {
          display: none;
        }

        &.active {
          .step-legend {
            @include active-text-color()
          }

          .step-icon {
            background: themed("n_layout_bg_block");
          }

          .step-legend {
            @include active-text-color()
          }
        }

        &.actived {
          .step-legend {
            @include active-text-color()
          }

          .step-icon {
            background: themed("n_layout_bg_block");
          }

          &:after {
            background: themed("n_layout_bg_block");
          }
        }
      }


      &:before {
        content : "";
        height  : 8px;
        left    : 0;
        position: absolute;
        top     : 14px;
        @include calc('width', '50% - 18px');
      }

      &:after {
        content : "";
        height  : 8px;
        position: absolute;
        right   : 0;
        top     : 14px;
        @include calc('width', '50% - 18px');
      }

      &.step-1,
      &.step-3,
      &.step-2 {
        width: 120px;
        display: flex;
        gap: 8px;
      }

      &.step-1, &.step-2, &.step-3 {
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }

      &.step-last {
        width: 100px;

        &.active {
          .step-icon {
            background: themed("n_layout_bg_block");
          }

          .step-legend {
            @include active-text-color()
          }

          &:before {
            background: themed("n_layout_bg_block");
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .step-icon {
      font-size  : $font-size-medium;
      height     : 36px;
      line-height: 36px;
      // margin     : 0 auto 5px auto;
      text-align : center;
      width      : 36px;
      @include border-radius(20px);
    }

    .step-legend {
      font-size : $font-size-medium;
      text-align: center;
    }
  }

}