@import "../../../../styles/common";

.modal-action-method {
    @include themify($themes) {
        max-width: 550px;
        margin   : auto;
        height   : auto;
        right    : 0px;

        .body {
            background: themed("n_layout_bg_block");
            padding   : 20px;

            .modal-property {
                height: 48px;

                .custom-form-select-option {
                    .custom-control-select-option {
                        width    : 100%;
                        max-width: unset;
                    }
                }

                .btn-refresh,
                .btn-confirm {
                    width: 100%;
                }
            }
        }
    }
}