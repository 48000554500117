@import "../styles/common";

@font-face {
  font-family: 'password';
  font-style : normal;
  font-weight: 400;
  src        : url(../assets/images/password.ttf);
}

.layout-setting-modal {
  @include themify($themes) {
    &.change-pin-pass-modal {
      .modal-header {
        height     : 44px !important;
        padding-top: 5px !important;
        background : themed("n_layout_bg_header_block") !important;
      }

      @include media-breakpoint-down(sm) {
        .modal-body {
          height  : 550px;
          overflow: auto;

          .account-info-item {
            margin-left  : 18px;
            padding-left : 5px;
            padding-right: 5px;

            .info-title {
              padding: 0;
            }
          }
        }
      }

    }
  }
}

.change-pin-pass {

  .jt-center{
    justify-content: center;
  }

  .change-pass-toggle{
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  input.key {
    font-family: 'password';
    width      : 100px;
  }

  @include themify($themes) {
    // margin   : auto;
    // max-width: 600px;
    padding-left : 0px;
    padding-right: 0px;
    margin-left  : 0px;
    margin-right : 0px;

    .block-half {
      margin-left : 0px;
      margin-right: 0px;

      &.account-info {
        display: block !important;
      }

      .account-info-item {
        border-bottom : 1px solid themed("n_table_border");
        padding-top   : 6px;
        padding-bottom: 10px;
        margin-bottom : 20px;

        .info-title {
          color: themed("n_layout_text_not_active");
        }

        .info-value {
          color     : themed("n_layout_text_active");
          word-break: break-word;
        }
      }
    }

    .text-align-left {
      text-align: left;
    }

    .text-align-right {
      text-align: right;
    }

    .input-item {
      // margin-bottom: 15px;
      margin-top: 25px;

      &.last-child {
        margin-bottom: 25px;
      }

      .custom-form-group.editable.enable-edit {
        border       : unset;
        border-bottom: 1px solid #404347;
      }

      input {
        padding-bottom: 5px;
      }

      .note {
        font-style: italic;
        text-align: justify;
      }
    }

    .input-title {
      margin-bottom: 20px;

      &.padding-top-20 {
        margin-top: 20px;
      }
    }

    .btn-container {

      // margin-top: 10px;
      button {
        width     : 100% !important;
        height    : 40px !important;
        background: themed('n_header_btn_primary');
      }
    }

    .label-padding .col-5 {
      margin-bottom: 10px;
    }

    span.label {
      line-height   : 30px;
      padding-bottom: 15px;
    }

    .btn-container button {
      // @include themify($themes) {
      //   color:  themed("n_layout_bg_block");
      //   background:  themed("n_layout_bg_block");
      // }
      border       : none;
      height       : 26px;
      margin-bottom: 10px;
      margin-left  : 10px;
      width        : 100px;

      &[disabled] {
        opacity: 0.5;
      }

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 0.75;
      }

      &.btn-logout {
        @include themify($themes) {
          background-color: themed("n_layout_bg_block");
          // color: themed('BtnSubmit-label-color');
          border-radius   : 5px;
        }
      }
    }

    .custom-form-group {
      margin: 5px 0;

      .custom-form-control.readonly {
        background: transparent;
        min-height: 28px;
        height    : auto;
      }

      &.editable {
        position: relative;

        //padding-right: 28px;
        @include themify($themes) {
          .btn-edit {
            color: themed("n_layout_bg_block");
          }

          .btn-save {
            color: themed("n_layout_bg_block");
          }
        }

        .custom-form-control {
          &.pin-pass {
            //   border-right: none;
            //   border-top-right-radius: 0px ;
            // border-bottom-right-radius: 0px;
          }
        }

        .btn-edit,
        .btn-save {
          padding      : 0px !important;
          align-content: center;
          position     : absolute;
          top          : 0;
          right        : 0;
          width        : 28px;
          height       : 28px;
          border       : none;
          background   : transparent;
          line-height  : 28px;
          font-size    : 14px;
        }

        .btn-save {
          display: none;
        }

        &.editing {
          .btn-edit {
            display: none;
          }

          .btn-save {
            display: block;
          }
        }
      }
    }
  }
}

.layout-setting-modal {
  @include themify($themes) {
    .modal-header {
      background: themed("n_layout_bg_block");

      .modal-title {
        color: themed("n_layout_text_active");
      }

      .btn-icon-fm {
        margin-right: 10px;
      }

      button {
        color: themed("n_layout_text_active");

        &:hover {
          background: themed("n_layout_bg_block");
        }
      }
    }

    .modal-body {
      color     : themed("n_layout_text_active");
      background: themed("n_layout_bg_block");

      .widget-icon {
        .st0 {
          fill: themed("n_layout_text_active");
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    width    : 700px !important;
    max-width: 700px !important;
    &.broker-modal{
      max-width: 500px !important;
    }
  }

  @include media-breakpoint-up(lg) {
    width    : 900px !important;
    max-width: 900px !important;
    &.broker-modal{
      width: 500px !important;
    }
  }

  .modal-header {
    @include themify($themes) {
      height       : 30px;
      padding      : 0;
      border       : none;
      border-radius: 0;

      .modal-title {
        padding    : 0 10px;
        font-size  : $font-size-medium;
        line-height: 30px;
      }

      button {
        width      : auto;
        height     : 30px;
        border     : none;
        padding    : 0 10px;
        line-height: 30px;
        text-align : center;
        background : transparent;
        font-size  : $font-size-medium;

        i {
          line-height: 30px;
        }

        .fa-times {
          font-size: $font-size-big + 2px;
        }
      }
    }
  }

  .modal-body {
    @include themify($themes) {
      padding: 15px 10px 5px 10px;

      .form-check-input {
        margin-top : -8px;
        margin-left: -20px;
        cursor     : pointer;
      }

      .widget-row {
        padding-bottom: 10px;
      }

      .widget-icon {
        width       : 30px;
        height      : auto;
        margin-right: 20px;
        cursor      : pointer;
      }

      .widget-title {
        font-size: $font-size-medium;
        cursor   : pointer;
      }

      .widget-description {
        color    : themed("n_layout_text_active");
        font-size: $font-size-regular;
      }

      .widget-permission {
        color    : themed("n_layout_text_active");
        font-size: $font-size-regular;
      }
    }
  }

  .modal-footer {
    height       : 48px;
    padding      : 0;
    border       : none;
    border-radius: 0;
  }

  .flat-button {
    margin       : 10px 10px 10px 0;
    padding      : 0 15px;
    height       : 28px;
    border       : none;
    font-size    : 12px;
    line-height  : 28px;
    border-radius: 0;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }
}