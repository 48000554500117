@import "../../styles/common";



.fss.isMobile {
  .notification-dropdown {
    .notification-container {
      width  : 100%;
      top    : 50px;
      right  : 0px;
      padding: 0px;

      .container-button button {
        margin: 5px;
      }
    }
  }

}

.notification-dropdown {
  @include themify($themes) {
    .text-green {
      svg {
        fill: inherit !important;
      }
    }
  
    .text-yellow-warning {
      svg {
        fill: themed('n_layout_warning_text') !important;
      }
    }
  
    .text-red {
      svg {
        fill: themed('n_layout_reduce_text') !important;
      }
    }
    #btn-login-account-selector-id {
      cursor: pointer;
    }

    .notifications {
      .ScrollbarsCustom-Content{
        height: fit-content !important;
      }
    }

    .press-link{
      margin-top: 10px;
      a{
        font-size    : $font-size-regular;
        text-decoration: underline;
        color: themed("n_header_btn_primary") !important;
      }
    }

    .back-to {
      //padding: 0 10px;
      margin-left: 10px;
      position   : sticky;
      height     : 32px;
      padding-top: 5px;
      top        : 0px;
      z-index    : 1;


        background-color: themed("n_layout_bg_block");
        //border-bottom: 1px solid themed("n_layout_bg_block");


      button {

          color: themed("n_layout_text_active");


        //border-radius: 15px;
        border       : none !important;
        //font-weight: bold;
        background   : none;
        // width: 30px;
        // height: 30px;
        font-size    : $font-size-regular;

        svg {
          width  : 100%;
          height : 100%;
          padding: 0px;
          margin : 0px;
        }

        &:hover {
          background: none;
          border    : none;
        }

        &:active {
          background: none;
          border    : none;
        }

        &:focus {
          background: none;
          border    : none;
        }
      }
    }

    .notification-container {
      z-index           : 1000;
      background-color  : themed('n_layout_bg_block');
      box-shadow        : 0px 4px 20px themed("n_layout_box_shadow_bg");
      -webkit-box-shadow: 0px 4px 20px themed("n_layout_box_shadow_bg");
      position          : absolute;
      right             : 10px;
      top               : 50px;
      width             : 600px;
      padding           : 16px;
      height            : 600px;
      border-radius     : 0px 0px $border_radius $border_radius;


      .search-bar {
        width         : 100%;
        background    : themed('n_layout_bg_block');
        color         : white;
        border        : none;
        outline       : none;
        text-transform: capitalize;

        &:focus-visible {
          border : none;
          outline: none;
        }
      }

      .search-custom {
        display       : flex;
        flex-direction: row;
        padding-left  : 0px;
        padding-right : 0px;
      }

      .p-0 {
        padding-left : 0px;
        padding-right: 0px;
      }

      .container-button {
        display         : flex;
        gap             : 8px;
        flex-direction  : row;
        padding-top     : 16px;
        padding-bottom  : 34px;
        margin-top      : 5px;
        //border-top    : 1px solid themed('n_layout_border');

        button.btn-custom {
          overflow        : hidden;
          text-overflow   : ellipsis;
          // transition   : all 0.25s ease-in-out;
          white-space     : nowrap;
          word-break      : break-word;
          background      : themed('n_btn_icon_bg');
          padding         : 6px 10px;
          border          : 1px solid transparent;
          border-radius   : 4px;
          cursor          : pointer;
          z-index         : 99;
        }

        .active {
          border-color: themed('n_btn_active') !important;
          color       : themed('n_btn_active');
        }

        .number-display {
          position: relative;

          .transaction-number {
            background    : rgb(218, 82, 86);
            height        : 20px;
            width         : 20px;
            position      : absolute;
            right         : -6px;
            top           : -6px;
            text-align    : center;
            vertical-align: middle;
            font-size     : 13px;
            z-index       : 200;
            border-radius : 8px;
          }
        }

      }

      .title-search {
        color         : themed('n_btn_active');
        padding-bottom: 16px;
      }

      .container-list {
        display         : flex;
        flex-direction  : column;
        //height        : calc(100% - 60px); // Hiển thị nút xóa noti
        height          : calc(100% - 120px);
        overflow        : auto;
        padding-right   : 10px;

        .title-content {}

        .des-content {
          p {}
        }


        .notification-tab-content {
          flex    : 1 1;
          position: relative;

          // @include themify($themes) {
          //   border-top: 2px solid themed("n_layout_bg_block");
          // }
          .flex-scroll-content-account {
            margin-right: -10px;
            margin-top  : 0;
            height      : 92%;
          }

          .load-more {
            cursor: pointer;
          }
        }

        .notification-item {
          margin-top: 5px;
          padding   : 0;
          overflow  : auto;

          .read-detail{
            color: themed("n_table_btn_bg");
            &:hover {
              text-decoration: underline;
              cursor         : pointer;
            }
          }

          img,
          iframe {

            box-shadow        : 2px 2px 4px 0 themed("n_layout_box_shadow_bg");
            -webkit-box-shadow: 2px 2px 4px 0 themed("n_layout_box_shadow_bg");

          }

          img {
            width     : 100% !important;
            object-fit: contain;
          }

          iframe {
            width : 100%;
            height: 200px;
          }

          .inner {

            // //background: rgba(0, 0, 0, 0.3);

            background: themed("n_layout_bg_block");


            padding   : 10px;
            border-top: 1px solid themed("n_layout_border");

            a {
              overflow-wrap: break-word;
            }
          }

          .title {
            font-size: $font-size-medium;

            &.title-analytic{
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
            }
          }

          .date {
            font-size: $font-size-small;
          }

          .content {
            font-size    : $font-size-regular;
            overflow-wrap: break-word;
          }


          .title {
            color: themed("n_layout_text_active");

            a {
              color: red;
            }

            .icon-notification-confirm {
              color: red;
            }
          }

          .type-notify {
            font-size     : $font-size-small;
            color         : themed("n_layout_text_active");
            text-transform: uppercase;
          }

          .date {
            color: themed("n_layout_text_near_active");
          }

          .content {
            color: themed("n_layout_text_active");
          }

        }

        .col-12 {
          padding        : 0px !important;
          height         : 8%;
          display        : flex;
          align-items    : center;
          justify-content: center;

          .action-delete {
            width: 100%;


            .btn-delete {
              border        : none;
              color         : themed("n_layout_text_active");
              font-size     : $font-size-medium;
              padding-top   : 5px;
              padding-bottom: 5px;
              width         : 100%;
              text-transform: uppercase;
              @include border-radius(5px);
              background: themed("n_layout_reduce_bg");

              &:hover {
                background: darken(themed("n_layout_reduce_bg"), 5);
              }
            }

          }
        }

        .items {
          display           : flex;
          flex-direction    : column;
          border-top        : 1px solid themed('n_layout_border');
          padding           : 12px 0px 12px 0px;
          cursor            : pointer;
          gap               : 10px;
          // justify-content: space-between;

          .n-column {
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
          }

          .s-image {
            height       : 45px;
            width        : 45px;
            border-radius: 4px;
          }

          .p-0 {
            padding: 0px;
          }
        }

        .items img {
          max-width: 100%;
        }

        .item-stock {
          display        : flex;
          flex-direction : row;
          justify-content: space-between;
          border-top     : 1px solid themed('n_layout_border');
          padding        : 12px 0px 12px 0px;
          cursor         : pointer;

          .n-column {
            display       : flex;
            flex-direction: column;
          }
        }

        .item-func {
          display        : flex;
          flex-direction : row;
          justify-content: space-between;
          border-top     : 1px solid themed('n_layout_border');
          padding        : 12px 0px 12px 0px;
          cursor         : pointer;

          .n-column {
            display       : flex;
            flex-direction: column;
          }
        }

        .item-news {
          display           : flex;
          flex-direction    : row;
          border-top        : 1px solid themed('n_layout_border');
          padding           : 12px 0px 12px 0px;
          cursor            : pointer;
          gap               : 10px;
          align-items       : center;
          // justify-content: space-between;

          .n-column {
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
          }

          .s-image {
            height       : 45px;
            width        : 45px;
            border-radius: 4px;
          }

          .p-0 {
            padding: 0px;
          }
        }



        .empty-search {
          display        : flex;
          flex-direction : column;
          align-items    : center;
          height         : 100%;
          justify-content: center;
        }

      }
    }

    .modal-custom {
      color     : themed('n_layout_text_active');
      background: themed('n_layout_bg_block');
    }
  }
}