.preview-pdf-src {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : calc(100% - 2rem);
    margin         : 0 auto;

    .react-pdf__Document {
        // height  : 842px;
        // width   : 595px;
        // overflow: scroll;
        width: 100%;

        .react-pdf__Page {
            width    : 100%;
            // width : 595px;

            .react-pdf__Page__canvas {
                height: 100% !important;
                width : 100% !important;
            }

            .react-pdf__Page__textContent {
                height  : 100% !important;
                overflow: hidden;
            }
        }
    }
}
