@import "../../../styles/common";

.place-order-quick-order {

    #btn-place-quick-order {
        @include themify($themes) {
            &:focus-visible {
                border       : 2px solid themed('n_layout_text_active') !important;
                border-radius: $border_radius;
            }
        }
    }

    @include themify($themes) {
        overflow     : auto;
        background   : themed("n_layout_bg_block");
        // height    : calc(100vh - 20px);
        height       : fit-content;

        input[type=text] {
            &:read-only {
                opacity: 0.5;
                cursor : not-allowed;
            }
        }

        .place-order-quick-order-scroll-container {
            // height: calc(100vh - 20px) !important;
        }

        &.place-normal-order {
            height   : unset;
            overflow : hidden;
            min-width: 330px;
        }

        .list-market {
            width: 100%;

            input {
                cursor: pointer;
                width : 100%;
            }
        }

        .block-place-order-quick-order {
            // border-top      : 1px solid themed("n_layout_btn_bg");
            background: themed("n_layout_bg_block");
            padding   : 5px 10px 10px 10px;

            &.first-block {
                padding: 0px 10px 0px 10px;
            }

            &.block-mixed-order-book {
                height : 300px;
                padding: 0px;
            }

            .choose-buy-sell {
                position  : relative;
                background: themed("n_layout_bg_block");
            }


            .capacity-container {
                width : 100%;
                margin: 10px 0px 0px 0px;

                .info-capacity {
                    vertical-align: top;
                    width         : 50%;
                    display       : inline-block;

                    .row-capacity {
                        display        : flex;
                        justify-content: space-between;

                        .capacity-content {
                            display: flex;

                            .label {
                                color: themed("n_layout_text_near_active");
                            }

                            .value {
                                color: themed("n_layout_text_active");

                                .fill-qtty {
                                    cursor: pointer;
                                }
                            }

                            &.capacity-price {
                                gap   : 16px;
                                cursor: pointer;

                                .price-block {
                                    display: flex;
                                    gap    : 4px;
                                }
                            }
                        }
                    }
                }
            }

            .placeprice-container {
                width : 100%;
                margin: 10px 0px 0px 0px;

                .info-capacity {
                    .row-capacity {
                        display        : flex;
                        justify-content: space-between;

                        .capacity-content {
                            display: flex;

                            .label {
                                color: themed("n_layout_text_not_active");
                            }

                            .value {
                                color: themed("n_layout_text_active");

                                .fill-qtty {
                                    cursor: pointer;
                                }
                            }

                            &.capacity-price {
                                gap   : 16px;
                                cursor: pointer;

                                .price-block {
                                    display: flex;
                                    gap    : 4px;
                                }
                            }
                        }
                    }
                }
            }

            .edit-status-btn {
                border-radius: 30px;
                padding      : 0px 18px;
                position     : absolute;
                border       : 1px solid themed("n_layout_btn_bg");
                height       : 28px;
                top          : 50%;
                left         : 50%;
                -ms-transform: translate(-50%, -50%);
                transform    : translate(-50%, -50%);
                background   : themed("n_layout_bg_block");
                color        : themed("n_layout_text_active");

                &.editting {
                    background: themed("n_price_ref_text");
                    color     : themed("n_table_bg");
                    border    : 1px solid themed("n_price_ref_text");
                }
            }

            .tab-item {
                width      : 50%;
                height     : 40px;
                line-height: unset;
                min-width  : unset;
                background : themed("n_layout_btn_bg");
                color      : themed("n_layout_text_not_active");
                border     : 1px solid themed("n_layout_bg_hover");

                .label {}

                &.buy-tab-item {
                    border-radius: 4px 0px 0px 4px;

                    &.active {
                        background: themed("n_layout_buy_bg");
                        color     : themed("n_text_custom");
                    }
                }

                &.sell-tab-item {
                    border-radius: 0px 4px 4px 0px;

                    &.active {
                        background: themed("n_layout_sell_bg");
                        color     : themed("n_text_custom");
                    }
                }
            }

            .type-view {
                display: flex;
                margin : 5px 0px;

                &.error {
                    padding: 0px;
                    margin : 0px;

                    .content {
                        height: unset;
                    }
                }

                &:first-child {
                    margin-top: 0px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                .content {
                    height: 32px;
                }

                .content-left {
                    width      : 30%;
                    display    : flex;
                    align-items: center;
                }

                .content-broker-price {
                    width: 60%;

                    .price-input-suggest {
                        width: 100%;
                    }
                }

                .content-broker-qtty {
                    width: 60%;

                    .qtty-input-suggest {
                        width: 100%;
                    }
                }

                .content-broker-split-order {
                    width: 10%;
                    gap  : 4px;

                    .input-checkbox {
                        width : 14px;
                        height: 14px;
                    }
                }

                .content-right {
                    width: 70%;
                }

                .content-full {
                    width: 100%;
                }

                .content-present-broker {
                    width: 60%;
                }
            }

            .type-order {
                display          : flex;
                /* flex-direction: row; */
                justify-content  : center;
                align-items      : center;
                color            : themed("n_layout_text_active");

                .content-right {
                    display        : flex;
                    flex-direction : row;
                    justify-content: center;
                    align-items    : center;

                    .select-type {
                        display        : flex;
                        align-items    : center;
                        justify-content: center;
                        white-space    : nowrap;
                        background     : themed("n_layout_btn_bg");
                        flex           : 1 0 0;
                        height         : 100%;
                        cursor         : pointer;
                        color          : themed("n_layout_text_not_active");

                        &.active {
                            color: themed("n_text_custom");
                        }

                        &:first-child {
                            border-radius: $border_radius 0 0 $border_radius;
                        }

                        &:last-child {
                            border-radius: 0 $border_radius $border_radius 0;
                        }

                        &:nth-child(2) {
                            border-right: 1px solid themed("n_layout_bg_block");
                            border-left : 1px solid themed("n_layout_bg_block");
                        }

                        &:focus-visible {
                            box-shadow: themed("n_layout_box_shadow_bg") 1px 1px 1px;
                            border    : none;
                            outline   : none;
                        }

                        &:hover {
                            // opacity: 0.5;
                            filter: brightness(90%);
                        }
                    }
                }
            }

            .price-order {
                .content-right {

                    .qtty-input-suggest,
                    .price-input-suggest {
                        width: 100%;
                    }
                }
            }

            .present-order {
                margin-top: 0px;

                .content-right,
                .content-full,
                .content-present-broker {
                    gap: 5px;

                    .select-present {
                        align-items       : center;
                        justify-content   : center;
                        white-space       : nowrap;
                        border            : 1px solid themed("n_layout_border");
                        // height         : 100%;
                        height            : 24px; // Theo web cũ APG
                        cursor            : pointer;
                        border-radius     : $border_radius;
                        color             : themed("n_layout_text_not_active");
                        // flex           : 1 0 0;
                        // display        : flex;
                        // width: 50px;
                        padding           : 2px 5px 2px 5px;
                        background-color  : themed("btn_background_custom");

                        &:focus-visible {
                            box-shadow: themed("n_layout_box_shadow_bg") 1px 1px 1px;
                            border    : none;
                            outline   : none;
                        }

                        &.active {
                            border: 1px solid themed("n_layout_text_active");
                            color : themed("n_layout_text_active");
                        }
                    }
                }

            }

            .valid-until-order {
                .content-right {
                    display: flex;

                    .contaier-input {
                        display: flex;
                        width  : 100%;
                        gap    : 10px;
                    }
                }
            }

            .select-date {
                .content-right {
                    display        : flex;
                    justify-content: space-between;

                    .custom-form-group-n {
                        background-color: unset;
                        height          : 32px;

                        .custom-form-control {
                            background-color: unset;
                        }
                    }
                }
            }

            .select-option-tprl-or {
                .content-right {
                    .custom-form-group-select {
                        .custom-form-select {
                            padding: 4px 0px 4px 8px;
                        }
                    }
                }
            }

            .input-tprl-or {
                .content-left {}

                .content-right {
                    .custom-form-group {
                        width              : 100%;
                        // background-color: themed("n_layout_bg_block");

                        .custom-form-control {
                            width           : 100%;
                            outline         : none;
                            line-height     : 26px;
                            color           : themed("n_layout_text_active");
                            height          : 100%;
                            border-radius   : $border_radius;
                            padding         : 0 5px;
                            background-color: themed("n_layout_bg");
                            border          : 1px solid themed("input_border_normal");

                            &:hover {
                                // border-bottom: 1px solid themed("input_hover_border_bottom") !important;
                                border: 1px solid themed("input_hover_border_bottom") !important;
                            }

                            &-focused {
                                // border-bottom: 1px solid themed("input_hover_border_bottom");
                                border: 1px solid themed("input_hover_border_bottom");
                            }

                            &-disabled {
                                &:hover {
                                    border-color: themed("n_layout_border");
                                }
                            }
                        }
                    }
                }
            }

            .action-container {
                width         : 100%;
                display       : flex;
                gap           : 10px;
                padding-bottom: 0px;

                .btn-action {
                    height        : 40px;
                    width         : 100%;
                    border        : none;
                    outline       : unset;
                    color         : themed("n_text_custom");
                    text-transform: uppercase;
                }

                .btn-cancel-input {
                    padding      : 9px 13px;
                    border-radius: 5px;
                }

                .btn-reset-input {
                    padding      : 9px 13px;
                    border-radius: 5px;
                }

                .btn-draf-order {
                    width: 40px;
                }
            }

            .resizesensor-wrapper {
                height: 400px;
            }

        }

        .line-divide {
            height          : 8px;
            background-color: themed("n_layout_bg");
        }


    }
}

.fss.isMobile {
    // .place-order-quick-order {
    //     height: calc(100vh - 155px) !important;
    // }
}