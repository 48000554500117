@import "../../../styles/common";

.fss.isMobile {
    .setting-display {
        .setting-container {
            top: 50px;
        }
    }
}

.setting-display {
    height: 100%;

    @include themify($themes) {

        .setting-container {
            max-width         : 230px;
            max-height        : 50vh;
            overflow-x        : hidden;
            overflow-y        : auto;
            z-index           : 1000;
            background-color  : themed("n_layout_bg_block");
            box-shadow        : 0px 4px 20px themed("n_layout_box_shadow_bg");
            -webkit-box-shadow: 0px 4px 20px themed("n_layout_box_shadow_bg");
            position          : absolute;
            top               : 50px;
            width             : 363px;
            height            : fit-content;
            border-radius     : 0px 0px $border_radius $border_radius;

            .block-select-setting {
                width: 100%;

                .action-select {
                    display        : flex;
                    width          : 100%;
                    justify-content: space-between;
                    padding        : 10px;
                }
            }

            .block-change-setting {
                .back-step {
                    display: flex;
                    padding: 10px;
                    width  : 100%;

                    .icon-arrow {
                        transform        : rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    }
                }

                .switch-theme {
                    display: flex;

                    &.active {
                        background-color: themed("n_layout_active_bg");
                    }

                    .custom-row {
                        display        : flex;
                        padding        : 10px;
                        justify-content: space-between;
                        width          : 100%;
                    }
                }

                .switch-langue {
                    display: flex;
                    height : 44px;

                    &.active {
                        background-color: themed("n_layout_active_bg");
                    }

                    .custom-row {
                        display        : flex;
                        padding        : 10px;
                        justify-content: space-between;
                        width          : 100%;

                        .left {
                            display: flex
                        }

                        .right {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}