@import "../../styles/common";

.version-fs2pro {
  display: none;
}

.fss.theme-dark.isMobile {
  .header-menu {
    .navLinks {
      background: $theme_01_common_header_block !important;
    }
  }
}

.fss.theme-light.isMobile {
  .header-menu {
    .navLinks {
      background: $theme_02_common_header_block !important;
    }
  }
}

.isMobile {
  .header-menu {

    @include themify($themes) {
      .layout-top-active {
        // overflow: inherit !important;
      }

      .header-menu {
        // display: none !important;

        .navLinks {

          // height: 30px;
          div[class^="layout-top-"] {
            // height: 30px;
            // overflow: inherit !important;
            // width     : 120px !important;
            // a {
            //   .txt---400-14-20 {
            //       padding-top   : 6.5px !important;
            //       // line-height: 45px !important;
            //   }
            // }
          }
        }
      }

      .customer-container {
        height         : 50px !important;
        position       : fixed;
        width          : 100%;
        z-index        : 97;
        top            : 50px;
        flex-direction : row !important;
        max-width      : 100% !important;
        justify-content: space-around !important;
        align-items    : center !important;
        margin         : 0px;
        left           : 0px;
        display        : flex !important;
        background     : themed("n_header_bg");

        .custodycd-container {
          // padding-right: 8px !important;
          // margin-right: 8px !important;
        }

        .select-accountId {
          align-items: center;
        }

        .full-name {
          padding: 0px !important;
        }
      }

      .off-customer-container {
        display   : flex !important;
        background: red !important;
      }

      .actions {
        .btn-container {
          margin-right: 0px !important;
        }
      }

      .setting-container {
        width: 100%;
        right: 0px !important;
      }    
    }
  }
}


.header-menu {
  @include themify($themes) {
    background: themed("n_header_bg_block");

    display   : flex;
    height    : 50px;
    position  : fixed;
    width     : 100%;
    z-index   : 777;
    box-shadow: 4px 4px 5px themed("n_layout_box_shadow_bg");

    .fullname{
      display: block !important;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .container-logo {
      height        : 100%;
      padding-left  : 18px;
      padding-right : 13px;
      padding-bottom: 7px;
      display       : flex;
      align-items   : center;

      img {
        width: 75px;
      }
    }

    .timerContainer{
      height: 30px;
    }

    .header-logo {
      height: 30px;
    }

    .btn-container {
      display       : flex;
      flex-direction: row;
      gap           : 15px;
      margin-right  : 12px;
      padding-left  : 12px;
      height        : 100%;
      align-items   : center;

      &>div {
        height: 24px !important;
      }
    }

    .btn-menu {
      display     : flex;
      align-items : center;
      padding-left: 12px;
    }

    .btn-login {
      background : themed('n_header_btn_primary') !important;
      margin     : 9px 10px 9px 0px;
      padding    : 6px 10px;
      white-space: nowrap;
      min-width  : 100px;
    }

    .btn-save-layout {
      background : themed('n_header_btn_primary') !important;
      margin     : 9px 10px 9px 0px;
      padding    : 6px 20px;
      white-space: nowrap;
    }

    .mr-20 {
      margin-right: 20px;
    }

    .btn-Save-layout {
      background  : themed("n_layout_primary");
      padding     : 6px 10px;
      height      : 28px;
      margin-right: 12px;
      margin-top  : 8px;
    }

    .sidebar-toggle {
      @include themify($themes) {

        &:hover,
        &:active {
          background: themed("n_layout_bg_block");
        }
      }

      background: transparent;
      border    : 0;
      display   : none;
      height    : 40px;
      text-align: center;
      width     : 41px;

      i {
        font-size  : $font-size-big;
        line-height: 38px;

        @include themify($themes) {
          color: themed("n_layout_bg_block");
        }
      }
    }

    .content-header {
      display        : flex;
      justify-content: space-between;
      width          : 100%;
      height         : 100%;
      margin         : 0px 0px 0px 0x;
      max-width      : calc(100% - 142px);

      &.content-header-login {
        max-width: calc(100% - 260px);
      }

      .navLinks {
        height       : 100%;
        width        : 100%;
        display      : flex;
        // flex      : 25 0 auto;
        // width  : 100%;
        height       : 100%;
        overflow     : hidden;

        .btn-menu {
          background : transparent;
          border     : 0;
          display    : block;
          font-size  : $font-size-medium;
          height     : 40px;
          line-height: 40px;
          padding    : 0 10px;
          text-align : center;

          &:hover,
          &:active {
            text-decoration: none;
          }

          @include themify($themes) {
            color: themed("n_layout_text_active");

            &.active {
              color: themed("n_layout_text_active");
            }

            &:hover {
              color: themed("n_layout_text_active");
            }

            &:hover,
            &:active {
              background: themed("n_layout_bg_block");
            }
          }

          // p {
          cursor: pointer;
          // }
        }

        .dropdown-item {
          @include themify($themes) {
            color: themed("n_layout_text_active");

            &.active-item {
              color: themed("n_layout_text_active");
            }
          }

          font-size  : $font-size-medium;
          font-weight: 400
        }

        .dropdown:hover>.dropdown-menu {
          @include themify($themes) {
            background-color: themed("n_layout_bg_block");
            @include css3-prefix('box-shadow', '3px 3px 3px 0 #{themed("n_layout_bg_block")}');
            border: 1px solid themed("n_layout_bg_block");
          }

          display   : block;
          margin-top: 0;
          padding   : 5px 0;
          @include border-radius(0);
        }

        .dropdown-item:hover {
          @include themify($themes) {
            background-color: themed("n_layout_bg_block");
          }

          cursor: pointer;

          font-size: $font-size-medium;
        }

        .dropdown>.dropdown-toggle:active {
          /*Without this, clicking will make it sticky*/
          pointer-events: none;
        }
      }

      .content-setting-header {
        display: flex;
        height : 100%;
      }
    }

    .actions {
      align-self     : flex-end;
      display        : flex;
      height         : 100%;
      justify-content: flex-end;
      .btn-widget-customize,
      .btn-logout,

      .btn-icon {
        background   : transparent;
        border       : 0;
        border-radius: 0;
        display      : block;
        height       : 100%;
        padding      : 1px 0;
        text-align   : center;
        width        : 24px;
        cursor       : pointer;

        i {
          font-size: 22px;
        }

        &.save-verified {
          opacity: 0.5;
          cursor : not-allowed;
        }
      }

      .btn-logo {
        background   : transparent;
        border       : 0;
        border-radius: 0;
        display      : block;
        text-align   : center;
        height       : 100%;
        margin-right : 14px;

        i {
          font-size  : 22px;
          line-height: 38px;
        }

        // @include themify($themes) {

        //   &:hover,
        //   &:active {
        //     background: themed("n_layout_bg_block");
        //   }
        // }
      }

      @include themify($themes) {
        .btn-logout i {
          color: themed("n_layout_text_active");
        }

        .btn-notification i {
          color: themed("n_layout_text_active");
        }

        .btn-quick-setting i {
          color: themed("n_layout_text_active");
        }

        .btn-widget-customize i {
          color: themed("n_layout_text_active");
        }
      }

    }

    .customer-selector-container {
      display    : flex;
      align-items: center;

      .custom-form-group {
        margin : 0;
        padding: 0 10px 0 0;

        .customer-selector.custom-form-control {
          background-image   : url("data:image/svg+xml,%3Csvg enable-background='new 0 0 15 28' version='1.1' viewBox='0 0 15 28' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='11 13 4 13 7.5 9.5'/%3E%3Cpolygon class='st0' points='11 15 7.5 18.5 4 15'/%3E%3C/svg%3E");
          background-position: right top;
          background-repeat  : no-repeat;
          background-size    : 15px 100%;

          cursor   : pointer;
          min-width: 170px;
          overflow : hidden;

          padding-right: 15px !important;
          text-align   : left;
          text-overflow: ellipsis;
          word-break   : break-all;
        }
      }

      @include themify($themes) {
        .custom-form-group {
          // border-left: 1px solid themed("n_layout_bg_block");

          .customer-selector.custom-form-control {
            background-color: themed("n_layout_bg_block");
            color           : themed("n_layout_text_active");
          }
        }
      }
    }

    .customer-container {
      cursor         : pointer;
      display        : flex;
      flex-direction : column;
      align-items    : center;
      max-width      : 300px;
      min-width      : 100px;
      margin-right   : 12px;
      margin-left    : 12px;
      justify-content: center;
      white-space    : nowrap;
      text-overflow  : ellipsis;
      align-items    : flex-end;
      height         : 100%;

      .full-name {
        padding: 5px 0px 0px 0px;
      }

      .custodycd-container {
        // padding-right: 8px !important;
        // margin-right: 8px !important;
      }

      .custom-form-group-header {
        position     : relative;
        padding-right: 15px;

        .dropdown-icon {
          position: absolute;
          top     : 0px;
          right   : 0px;
        }

        .select-list {
          left: -10px !important;

          .select-input-title {
            max-width    : 200px;
            overflow     : hidden;
            text-overflow: ellipsis;
            white-space  : nowrap;
          }
        }

        .select-input {
          padding: 0px 0px 4px 0px !important;
        }
      }
    }

    .compact-customer-selector-container {
      display: none;

      .btn-customer-selector {
        background: transparent;
        border    : 0;
        display   : block;
        height    : 40px;
        padding   : 1px 0;
        text-align: center;
        width     : 41px;
        @include border-radius(0);

        i {
          font-size  : $font-size-big;
          line-height: 38px;
        }

        &:not(:disabled):not(.disabled):active {
          background: transparent;
        }
      }

      @include themify($themes) {
        // border-left: 1px solid themed("n_layout_bg_block");

        &:hover,
        &:active {
          background: themed("n_layout_bg_block");
        }

        i {
          color: themed("n_layout_text_active");
        }
      }
    }

    .sub-account-container {
      .custom-form-group {
        margin : 0;
        padding: 6px 10px;

        select.custom-form-control {
          background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 15 28' version='1.1' viewBox='0 0 15 28' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='11 13 4 13 7.5 9.5'/%3E%3Cpolygon class='st0' points='11 15 7.5 18.5 4 15'/%3E%3C/svg%3E");

          min-width: 130px;

          @include media-breakpoint-down(xs) {
            height     : auto;
            white-space: normal;
          }
        }

        @include themify($themes) {
          // border-left : 1px solid themed("n_layout_bg_block");
          border-right: 1px solid themed("n_layout_border");

          select.custom-form-control {
            background-color: themed("n_layout_bg_block");
            border          : 1px solid themed("n_layout_border");
            color           : themed("n_layout_text_active");
          }
        }
      }
    }

    .compact-sub-account-container {
      display: none;

      .btn {
        background: transparent;
        border    : 0;
        display   : block;
        height    : 40px;
        padding   : 0;
        text-align: center;
        width     : 41px;
        @include border-radius(0);

        i {
          font-size  : $font-size-big;
          line-height: 38px;
        }

        &:not(:disabled):not(.disabled):active {
          background: transparent;
        }
      }

      .dropdown>.dropdown-menu {
        background: transparent;
        border    : none;
        padding   : 0;
        @include border-radius(0);

        .custom-form-group {
          border-left: 0;
          min-width  : 275px;

          select.custom-form-control {
            font-size: $font-size-regular;
          }
        }
      }

      @include themify($themes) {
        .btn {
          // border-left: 1px solid themed("n_layout_bg_block");

          i {
            color: themed("n_layout_text_active");
          }

          &:hover,
          &:active {
            background: themed("n_layout_bg_block");
          }
        }

        .dropdown-menu {
          .custom-form-group {
            background-color: themed("n_layout_bg_block");
          }

          @include css3-prefix('box-shadow', '3px 3px 3px 0 #{themed("n_layout_bg_block")}');
        }
      }
    }

    .username-container {
      @include themify($themes) {
        // border-left: 1px solid themed("n_layout_bg_block");
        color: themed("n_layout_text_active");
      }

      display    : none;
      line-height: 28px;
      padding    : 6px 10px;
    }

    @media screen and (max-width: 1400px) {
      .header-logo {
        width        : auto;
        padding-left : 20px;
        padding-right: 20px;
        object-fit   : contain;
      }
    }

    @include media-breakpoint-down(sm) {
      .sidebar-toggle {
        display: block;
      }
    }

    .broker {
      @media screen and (min-width: 600px) {
        .username-container {
          display: block;
        }
      }

      @media screen and (max-width: 1199px) {
        .regular-customer-selector-container {
          .custom-form-control {
            max-width: 200px;
          }
        }

        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 100px;
          }
        }
      }

      @media screen and (min-width: 1200px) and (max-width: 1339px) {
        .regular-customer-selector-container {
          .custom-form-control {
            max-width: 230px;
          }
        }

        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 180px;
          }
        }
      }

      @media screen and (min-width: 1340px) and (max-width: 1499px) {
        .regular-customer-selector-container {
          .custom-form-control {
            max-width: 290px;
          }
        }

        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 260px;
          }
        }
      }

      @media screen and (min-width: 1500px) and (max-width: 1599px) {
        .regular-customer-selector-container {
          .custom-form-control {
            max-width: 370px;
          }
        }

        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 340px;
          }
        }
      }

      @media screen and (max-width: 1099px) {
        .regular-customer-selector-container {
          display: none;
        }

        .compact-customer-selector-container {
          display: block;
        }

        .regular-sub-account-container {
          display: none;
        }

        .compact-sub-account-container {
          display: block;
        }
      }
    }

    .customer {
      @media screen and (min-width: 900px) {
        .username-container {
          display: block;
        }
      }

      @media screen and (max-width: 600px) {
        .regular-sub-account-container {
          display: none;
        }

        .compact-sub-account-container {
          display: block;
        }
      }

      @media screen and (max-width: 1500px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 600px;
          }
        }
      }

      @media screen and (max-width: 1199px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 350px;
          }
        }
      }

      @media screen and (max-width: 999px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 200px;
          }
        }
      }

      @media screen and (max-width: 949px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 150px;
          }
        }
      }

      @media screen and (max-width: 799px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 110px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 260px;
          }
        }
      }

      @media screen and (max-width: 668px) {
        .regular-sub-account-container {
          select.custom-form-control {
            max-width: 160px;
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      .sidebar-toggle {
        width: 31px;
      }

      .navLinks {
        .btn-menu {
          padding: 0 5px;
        }
      }

      .actions {

        .btn-widget-customize,
        .btn-logout,
        .btn-notification,
        .btn-quick-setting {
          width: 31px;
        }
      }

      .compact-customer-selector-container {
        .btn-customer-selector {
          width: 31px;
        }
      }

      .compact-sub-account-container {
        .btn {
          width: 31px;
        }
      }
    }

    // từ 767px trở xuống
    @include media-breakpoint-down(sm) {
      // .container-logo {
      //   width       : 74px;
      //   padding-left: 0px;
      // }

      .content-header {
        justify-content: end;
        width          : calc(100% - 116px);


        .content-setting-header {
          width       : 100vw;
          margin-right: 10px;
        }
      }

      .btn-menu {
        padding-top   : 10px;
        padding-bottom: 10px;
        padding-left  : 16px;
      }

      .btn-container {
        margin-right: 10px;
      }

      .actions {
        padding-right: 10px;
        flex         : 1;
      }

      .navLinks {
        position     : fixed;
        height       : 50px !important;
        width        : 100%;
        top          : 50px + 50px;
        // z-index      : 100; // Lỗi che gợi ý ds tiểu khoản trên mobile
        right        : 0px;
        max-width    : none !important;
        // background: $theme_01_common_block;
      }

      .top-navLinks-moblie-logout {
        top: 50px !important;
      }
    }

    // từ 991px trở xuống
    @include media-breakpoint-down(md) {}

    // từ 1199px trở xuống
    @include media-breakpoint-down(lg) {}
  }
}