@import "../../styles/common";

.security {
    @include themify($themes) {

        .container {
            padding: 0px;

            .title {
                margin-bottom: 22px;
                font-family  : 'Helvetica Neue Bold';
                font-weight  : 700; // Heading 6 - B (6)
                font-size    : 16px;
                line-height  : 24px;
            }

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
            }

            .tab {
                margin-top: 10px;
                display   : flex;
                gap       : 10px;

                .sub-tab {
                    cursor: pointer;

                    &.active {
                        border-bottom: 2px solid #F27801;
                    }
                }
            }
        }

        .password-field {

            .btn-edit {
                background-color: transparent;
            }

            .custom-form-group {
                display : flex;
                position: relative;

                &:hover {
                    border-bottom: 1px solid themed('input_hover_border_bottom')
                }

                &:focus-within {
                    border-bottom: 1px solid themed('input_hover_border_bottom')
                }


                input {
                    width: 100%;
                }

                button {
                    position: absolute;
                    right   : 8px;
                    height  : 30px;
                }

                &.disabled {
                    background: themed("n_layout_btn_bg");
                    border    : none;
                    padding   : 5px 10px;
                    cursor    : default;

                    &:hover {
                        border: none;
                    }
                }
            }

            .custom-form-group-captcha {
                display    : flex;
                align-items: center;
                padding    : 0 5px;

                // border-bottom: 1px solid themed('n_layout_border');
                border       : 1px solid themed("n_layout_btn_bg");
                border-radius: 4px;
                height       : 40px;

                .captcha {
                    width : 100%;
                    height: 40px;

                    svg {
                        height    : 40px;
                        margin-top: -5px;
                    }
                }

                .reload {
                    padding: 0 5px;
                    cursor : pointer;
                }

                &.disabled {
                    background: themed("n_layout_btn_bg");
                }
            }

            .note {
                // padding   : 20px 20px 20px 5px;
                font-style: italic
            }
        }

        .custom-form-select {
            padding: 5px !important;
        }

        .custom-form-group-flex-center {
            display     : flex;
            align-items : center;
            padding-left: 5px;
        }

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            height       : 100%;

            .active {
                background: themed('n_form_bg');
            }

            .title {
                padding: 16px;
            }
        }

        .h-method {
            height    : 48px;
            margin-top: 10px;
        }

        .block-right {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px 10px 10px;
            height       : 100%;
            width        : 100%;
            overflow     : auto;

            .btn-custom {
                width     : 100%;
                margin-top: 20px;
            }
        }

        .server-property {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            height    : 80px;
            width     : 100%;
            background: themed('n_layout_bg_body_block');
            padding   : 16px;
            cursor    : pointer;

            .header-service {
                display    : flex;
                align-items: center;

                .icon {
                    width : 40px;
                    height: 40px;

                    background-color: themed('n_form_bg');

                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    border-radius  : 15px;
                }

                .content {
                    padding-left  : 12px;
                    display       : flex;
                    flex-direction: column;

                    // .title {
                    //     font-size  : 16px;
                    //     font-weight: 500;
                    // }

                    // .description {
                    //     font-size: 14px;
                    //     color    : rgba($color: #FFF, $alpha: .6);
                    // }
                }
            }

            .extra {
                .service-register-switch {

                    &.custom-control .custom-control-input:checked~.custom-control-label::before {
                        background: themed('n_header_btn_primary');
                        border    : none;
                        box-shadow: none;
                        outline   : none;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::before {
                        cursor: pointer;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::after {
                        cursor: pointer;
                    }

                }
            }

        }

        .sms-server-property {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            height: 80px;
            width : 100%;

            background   : themed('n_layout_bg_body_block');
            border-bottom: 1px solid themed('n_layout_border');

            .extra {
                .service-register-switch {

                    &.custom-control .custom-control-input:checked~.custom-control-label::before {
                        background: themed('n_header_btn_primary');
                        border    : none;
                        box-shadow: none;
                        outline   : none;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::before {
                        cursor: pointer;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::after {
                        cursor: pointer;
                    }

                }
            }
        }

    }
}