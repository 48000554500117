@import "../../styles/common";

.rc-input-number-new {
    @include themify($themes) {

        background-color   : themed('n_layout_bg_block');
        color              : white;
        // padding         : 8px 0px 3px 0px;
        width              : 100%;
        border             : none;

        // &:hover {
        //     border-bottom: 1px solid themed("input_hover_border_bottom");
        //     border-radius: 0px !important;
        // }

        // &-focused {
        //     border-bottom: 1px solid themed("input_hover_border_bottom");
        //     border-radius: 0px !important;
        // }

        &-disabled {
            background-color: themed('n_layout_bg_block');

            &:hover {
                border-color: themed('n_layout_border');
            }
        }

        .rc-input-number-handler-up-inner,
        .rc-input-number-handler-down-inner {
            color: themed("n_layout_text_active");

            &:hover {
                color: themed('n_layout_text_active');
            }
        }

        .rc-input-number-disabled:hover {
            border-color: themed('n_layout_border');
        }

        .rc-input-number-new-input {
            color: themed('n_layout_text_active');
        }
    }

    border-radius: 5px;

    display          : block;
    margin           : 0;
    padding          : 0;
    // transition    : all .3s;
    vertical-align   : middle;
    width            : 100%;

    &:hover {
        .rc-input-number-handler {
            i {
                display: inline-block;
            }
        }
    }

    &-disabled {
        background: transparent;
        cursor    : not-allowed;

        .rc-input-number-new-input {
            cursor: not-allowed;
        }

        .rc-input-number-handler {
            opacity: 0.4;
        }
    }

    .rc-input-number-handler {
        display     : block;
        height      : 13px;
        line-height : 13px;
        overflow    : hidden;
        text-align  : center;
        touch-action: none;

        i {
            display: none;
        }
    }

    .rc-input-number-handler-up-inner,
    .rc-input-number-handler-down-inner {
        user-select        : none;
        -webkit-user-select: none;
    }

    .rc-input-number-new-input-wrap {
        height  : 100%;
        overflow: hidden;
    }

    .rc-input-number-new-input {
        -moz-appearance : textfield;
        background-color: transparent;

        border          : 0;
        border-radius   : 0;
        height          : 100%;
        line-height     : 26px;
        outline         : none;
        // transition   : all .3s;
        width           : 100%;
    }

    .rc-input-number-handler-wrap {
        float        : right;
        height       : 100%;
        // transition: all .3s;
        width        : 20px;
    }

    .rc-input-number-handler-up {
        padding-top   : 1px;
        // transition : all .3s;
    }

    .rc-input-number-handler-down {
        // transition: all .3s;
    }

    .rc-input-number-handler-down-disabled,
    .rc-input-number-handler-up-disabled {
        opacity: 0.4;
    }
}