@import "../styles/common.scss";

.symbol-select-input-suggest-new {
    max-height: 300px;
    position  : relative;
    width     : 100%;
    max-width : 125px !important;
    min-width : 150px;

    .label-symbol{
        width: 100px;
    }

    @include themify($themes) {
        .react-autosuggest__container {
            position: relative;
            width   : 100%;


            .react-autosuggest__input {
                text-overflow                      : ellipsis;
                // padding-right             : 10px;
                // height                       : 28px;
                width                              : 100%;
                // max-width                       : 100px;
                font-size                          : inherit;
                text-transform                     : uppercase;
                // border-bottom-left-radius : 5px;
                // border-bottom-right-radius: 5px;
                // border-top-left-radius    : 5px;
                // border-top-right-radius   : 5px;
                background-color                   : themed('n_form_bg');
                color                              : themed('n_layout_text_active');
                border                             : none;
                outline                            : none;

                &:focus {
                    border : none;
                    outline: none;
                }

                &:hover {
                    border : none;
                    outline: none;
                }

            }

            .react-autosuggest__suggestions-container {
                width           : 100%;
                z-index         : 500;
                max-width       : 300px;
                position        : fixed;
                max-height      : 200px;
                overflow-y      : auto;
                overflow-x      : hidden;
                background-color: themed("n_layout_bg_block");


                .react-autosuggest__suggestions-list {
                    padding         : 0px;
                    width           : calc(100% - 7px);
                    background-color: themed("n_layout_bg_block");
                    margin-bottom   : 0px !important;
                }

                .react-autosuggest__suggestion {
                    width        : 100%;
                    height       : 26px;
                    padding      : 2px 2px 2px 5px;
                    text-align   : left;
                    border-bottom: 1px solid #383c45;
                    white-space  : nowrap;
                    border-bottom: 1px solid themed('n_layout_border');

                    &:hover,
                    &.react-autosuggest__suggestion--highlighted {
                        cursor          : pointer;
                        color           : themed('n_layout_text_secondary_1');
                        background-color: themed("n_layout_active_bg");
                    }
                }
            }
        }
    }

    input {
        background-position: right top;
        background-repeat  : no-repeat;
        background-size    : 15px 100%;


    }

    .suggest-input-container {
        margin  : 0;
        padding : 0;
        //height: 38px;
    }

    .suggest-input-container-label {
        margin : 0px;
        padding: 0px;

        .custom-form-group {
            margin : 0px !important;
            padding: 0px;
        }
    }

    .custom-form-group-symbol {
        @include themify($themes) {
            background-color: themed('n_form_bg');
            display         : flex;
            flex-direction  : row;
            padding         : 6px 10px 6px 10px;
            border-radius   : 4px;
            height          : 32px;
        }
    }


    .dropdown-btn-container {
        float        : right;
        //padding-top: 19px;
        padding-left : 0;
        padding-right: 0;

        //position: relative;
        // background: #fc0390;
        // border: none;
        // color: white;
        button {
            border-bottom-left-radius : 0;
            border-bottom-right-radius: 5px;
            border-left               : none;
            border-top-left-radius    : 0;
            border-top-right-radius   : 5px;
            color                     : white;
            height                    : 28px;
            position                  : relative;
            width                     : 100%;

            @include themify($themes) {
                background: themed("n_layout_bg_block");
                border    : 1px solidthemed("n_layout_border");
            }

            .seperator {
                background: white;
                height    : 50%;
                left      : 0;
                position  : absolute;
                top       : 6px;
                width     : 1px;
            }

            &.suggest-dropdown-btn-focusing {
                @include themify($themes) {
                    border-color: themed("n_layout_border");
                    box-shadow  : themed("n_layout_box_shadow_bg") 0 0 1px 1px;
                    color       : themed("n_layout_text_active");
                }
            }

            &.suggest-dropdown-btn-hovering {
                @include themify($themes) {
                    border: 1px solid themed("n_layout_border");
                    color : themed("n_layout_text_active");
                }
            }
        }
    }

    // .dropdown-btn-container-no-label{
    //   padding-left: 0px;
    //   float: left;
    // }

    .no-label-btn {
        float       : left;
        padding-left: 0;
        padding-top : 0;
    }

    .custom-form-group {
        @include themify($themes) {
            margin-top: 0 !important;
            padding   : 0;

            .btn-tooltip {
                background   : themed("n_layout_bg_block");
                color        : themed("n_layout_text_active");
                border       : none;
                height       : 20px;
                line-height  : 20px;
                max-width    : 100%;
                padding      : 0 5px;
                white-space  : nowrap;
                width        : 23px;
                font-size    : 13px;
                border-radius: 4px;
                margin-top   : 8px;
            }

            .quote-fullname {
                white-space     : nowrap;
                padding-left    : 6px;
                // margin-bottom: 5px !important;
                text-overflow   : ellipsis;
                overflow        : hidden;
                margin-top      : 8px;
            }

            .custom-form-control {
                border-bottom-left-radius : 5px;
                border-bottom-right-radius: 5px;
                //border-right: none;
                border-top-left-radius    : 5px;
                border-top-right-radius   : 5px;
                height                    : 28px;

                @include themify($themes) {
                    &:focus {
                        border    : 1px solid themed("n_layout_border");
                        box-shadow: themed("n_layout_box_shadow_bg")0 0 1px 1px;
                    }

                    &:hover {
                        border: 1px solid themed("n_layout_border");
                    }
                }
            }
        }
    }

    .warning-block-suggest {
        width: 100%;

    }

    #suggestion-box {
        @include themify($themes) {
            //background: #fc0390;
            background-color: themed("n_layout_bg_block");
            border          : 1px solid themed("n_layout_border");

            .suggest-item {
                border-bottom: 1px solid themed("n_layout_border");

                &.suggest-active {
                    //background-color: themed("n_layout_border");
                    background-color: themed("n_layout_bg_block");
                }

                &:hover {
                    //background-color: themed("n_layout_border");
                    background-color: themed("n_layout_bg_block");
                }
            }
        }

        max-height: 100px;
        overflow-x: hidden;
        overflow-y: scroll;
        position  : absolute;
        top       : 48px;

        width  : 100%;
        z-index: 22;

        &.no-label-suggest-box {
            //top: 26px
        }

        .suggest-item {
            height    : 26px;
            padding   : 2px 2px 2px 5px;
            text-align: left;

            &:hover {
                cursor: pointer;
            }
        }
    }
}