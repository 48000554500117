@import "../styles/common";



.ScrollbarsCustom {
  @include themify($themes) {
    .ScrollbarsCustom-Wrapper {
      width : 100% !important;
      height: 100% !important;

      .ScrollbarsCustom-Content {
        height: 100%;
      }

      // .ScrollbarsCustom-Content>div {
      //   height: 100% !important;
      // }
    }

    .ScrollbarsCustom-TrackY {
      // display: none !important;
      visibility: hidden !important; // fix lỗi tính sai height
      z-index   : 2 !important;
      top       : 0px !important;

      .ScrollbarsCustom-ThumbY {
        // background-color  : red !important;
        background-color: rgba(themed('scrollbar-thumb-hover-bg'), 0.4) !important;
        box-shadow      : inset 1px 1px 1px rgba(themed('scrollbar-thumb-hover-bg'), 0.50);
        width           : 6px !important;
        margin-left     : 4px !important;
      }
    }

    .ScrollbarsCustom-TrackX {
      // display: none !important;
      visibility: hidden !important; // fix lỗi tính sai width
      left      : 0px !important;

      .ScrollbarsCustom-ThumbX {
        //background-color: hsla(0,0%,100%,.4) !important;
        background-color: rgba(themed('scrollbar-thumb-hover-bg'), 0.4) !important;
        box-shadow      : inset 1px 1px 1px rgba(themed('scrollbar-thumb-hover-bg'), 0.50);
        height          : 6px !important;
        margin-top      : 4px !important;
      }
    }
  }
}

.ScrollbarsCustom:hover {
  @include themify($themes) {

    // display: none !important;
    .ScrollbarsCustom-TrackY {
      //display         : inherit !important;
      visibility      : inherit !important;
      z-index         : 2 !important;
      top             : 0px !important;
      width           : 10px !important;
      height          : 100% !important;
      right           : 0px !important;
      opacity         : 0.5 !important;
      background-color: inherit !important;
    }
  }
}

.ScrollbarsCustom:hover,
:active,
:focus {
  @include themify($themes) {
    .ScrollbarsCustom-TrackY:has(.dragging) {
      //display         : inherit !important;
      visibility      : inherit !important;
      z-index         : 2 !important;
      top             : 0px !important;
      width           : 10px !important;
      height          : 100% !important;
      right           : 0px !important;
      opacity         : 0.5 !important;
      background-color: inherit !important;
    }

    .ScrollbarsCustom-TrackY {
      // //display         : inherit !important;
      // visibility      : inherit !important;
      // z-index         : 2 !important;
      // top             : 0px !important;
      // width           : 10px !important;
      // height          : 100% !important;
      // right           : 0px !important;
      // opacity         : 0.5 !important;
      // background-color: inherit !important;

      .ScrollbarsCustom-ThumbY {
        //background-color : hsla(0, 0%, 100%, .4) !important;
        background-color: rgba(themed('scrollbar-thumb-hover-bg'), 0.4) !important;
        width           : 6px !important;
        margin-left     : 4px !important;
        box-shadow      : inset 1px 1px 1px rgba(themed('scrollbar-thumb-hover-bg'), 0.50);
      }

      .ScrollbarsCustom-ThumbY:hover,
      :active,
      :focus {
        //background-color: hsla(0, 0%, 100%, .8) !important;
        background-color: rgba(themed('scrollbar-thumb-hover-bg'), 0.9) !important;
        width           : 10px !important;
        margin-left     : 0px !important;
        box-shadow      : inset 1px 1px 1px rgba(themed('scrollbar-thumb-hover-bg'), 0.50);

      }
    }

    .ScrollbarsCustom-TrackX {
      //display         : inherit !important;
      visibility      : inherit !important;
      z-index         : 2 !important;
      left            : 0px !important;
      width           : 100% !important;
      opacity         : 0.5 !important;
      background-color: inherit !important;
      display         : block !important;

      .ScrollbarsCustom-ThumbX {
        //background-color: hsla(0, 0%, 100%, .4) !important;
        background-color: rgba(themed('scrollbar-thumb-hover-bg'), 0.4) !important;
        box-shadow      : inset 1px 1px 1px rgba(themed('scrollbar-thumb-hover-bg'), 0.50);
        height          : 6px !important;
        margin-top      : 4px !important;
      }

      .ScrollbarsCustom-ThumbX:hover,
      :active,
      :focus {
        //background-color: hsla(0, 0%, 100%, .8) !important;
        background-color: rgba(themed('scrollbar-thumb-hover-bg'), 0.9) !important;
        box-shadow      : inset 1px 1px 1px rgba(themed('scrollbar-thumb-hover-bg'), 0.50);
        height          : 10px !important;
        margin-top      : 0px !important;
      }

    }
  }
}

.priceboard-layout {
  .ScrollbarsCustom {
    .ScrollbarsCustom-Scroller {
      margin-bottom: 0px !important;

      .ScrollbarsCustom-Content {
        // height: inherit !important;
        // display: contents !important;
        padding: 0px !important;
        display: unset;
      }

      .ScrollbarsCustom-Content>div {
        // height: 100% !important;
      }
    }
  }
}