 .header-container {
     .header-and-btn {
         display        : flex;
         justify-content: center;
         align-items    : center;
         gap            : 15px;

         .fa.fa-arrow-left {
             font-size: 16px;
             cursor   : pointer;
         }
     }
 }