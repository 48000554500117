@import "../../../../styles/common";

.right-off-detail-modal {
    @include themify($themes) {
        max-width: 900px !important;

        .body {
            .info-detail-symol {
                padding: 20px 0px;

                .col-style {
                    padding: 0px 20px;
                }
            }
        }
    }
}