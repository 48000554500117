@import "../../../../../styles/common.scss";

.company-news-symbol {
    @include themify($themes) {
        height        : 100%;
        width         : 100%;
        display       : flex;
        flex-direction: column;
        gap           : 10px;
        background    : themed("n_layout_bg_block");

        .company-news-symbol-content {
            height        : 100%;
            width         : 100%;
            display       : flex;
            flex-direction: column;
            gap           : 10px;
            padding       : 10px;

            .action-container {
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                flex-wrap      : nowrap;

                .btn-search {
                    background: themed("n_form_bg") !important;
                    padding   : 2px 10px !important;
                    border    : 1px solid transparent;

                    &:active {
                        border: none;
                    }
                }

                .container-select {
                    gap     : 4px;
                    width   : 100vw;
                    overflow: auto
                }

                .custom-form-group-n {
                    .dropdown-item {
                        background: transparent;
                        // color: ;
                    }
                }
            }

            .block-news-list {
                width : 100%;
                height: 100%;

                .block-news {
                    height: 100%;

                    .list-item-window {
                        .list-item-news {
                            cursor: pointer;

                            &.column1 {
                                display: block;
                            }

                            &.column2 {
                                display              : grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap                  : 10px;
                            }

                            .item-new {
                                // flex         : 1;
                                border-bottom: 1px solid themed("n_layout_border");
                            }

                            margin: 0px 0px 10px 0px;

                            .wrap-item-new {
                                padding        : 10px;
                                display        : flex;
                                justify-content: space-between;
                                gap            : 10px;

                                .info-new {
                                    display        : flex;
                                    flex-direction : column;
                                    gap            : 10px;
                                    justify-content: space-between;

                                    .title {}

                                    .change {
                                        display: flex;
                                    }
                                }

                                .image-new {
                                    height   : 90px;
                                    min-width: 160px;
                                    width    : 160px;

                                    img {
                                        object-fit   : cover;
                                        border-radius: 8px;
                                        width        : 100%;
                                        height       : 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}