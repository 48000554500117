@import "../../styles/common";

.layout-top-item {
    width: 210px;

    .container-block {

        .container-title {
            text-overflow: ellipsis;
            overflow     : hidden;
            white-space  : nowrap;

            padding-left : 10px;
            padding-right: 10px;
            display      : flex;
            min-width    : 10px;
            height       : 100%;
            width        : 100%;
            a {
                width: 100% !important;
            }
        }

        .container-icon {

            display        : flex;
            flex-direction : row;
            justify-content: end;
            padding-left   : 0px;
            padding-right  : 10px;
            gap            : 5px;
            margin-left    : 5px;

            .icon-close {
                font-size: 20px !important;

                i {
                    margin-top: 6px;
                }
            }
        }
    }

    @include themify($themes) {
        width: 180px; //width tab menu top

        .container-block {
            .container-icon {
                color: themed('n_text_custom') !important;
            }
        }
    }

    &.layout-top-item-custom {
        .container-block {
            .container-title {
                padding-left : 5px;
                padding-right: 5px;
                min-width    : unset;
            }

            .container-icon {
                gap: 2px !important;
            }
        }
    }
}

.theme-light {

    .layout-top-active {
        background: #1b4587 !important;
    }

    .layout-top-unactive {
        .title {
            color: rgba(255, 255, 255, 0.4) !important;
        }
    }
}

.layout-top-unactive {
    @include themify($themes) {
        display              : flex;
        flex-direction       : row;
        height               : 100%;
        text-overflow        : ellipsis;
        overflow             : hidden;
        white-space          : "nowrap";
        // justify-content: space-between;
        // max-width         : 200px;

        .container-block {
            position          : initial;
            // gap            : 10px;
            justify-content   : space-between;
            width             : 100%;

            // height            : 100%;
            .container-title {

                flex-direction: column;


            }

            .container-icon {

                .icon {
                    cursor   : pointer;
                    font-size: 16px;

                    &.active {
                        display: block;
                    }

                    &.not-active {
                        display: none;
                    }
                }
            }
        }

        &:hover {
            // background: linear-gradient(themed("n_layout_bg_hover"), themed("n_header_bg"));
            background: themed("n_layout_bg_header");

            .title {
                color: themed('n_text_custom');
            }
        }

        .title {
            text-overflow: ellipsis;
            overflow     : hidden;
            white-space  : nowrap;
            color        : themed("n_layout_text_not_active");
            height       : 20px;
        }

        .icon {
            display          : flex;
            // flex-direction: column-reverse;
        }

        a {
            height         : 100%;
            text-decoration: none !important;
            outline        : none !important;

            .txt---400-14-20 {
                height           : 100%;
                display          : flex;
                align-items      : center;
                // padding-top   : 12.5px !important;
                // line-height: 45px !important;
            }
        }

        @include themify($themes) {
            .setting-container {}
        }
    }
}

.layout-top-active {
    @include themify($themes) {
        display              : flex;
        flex-direction       : row;
        height               : 100%;
        text-overflow        : ellipsis;
        overflow             : hidden;
        white-space          : "nowrap";
        // background           : linear-gradient(themed("n_layout_bg_hover"), themed("n_header_bg"));
        background           : themed("n_layout_bg_header");
        // justify-content: space-between;
        // max-width         : 200px;

        .container-block {
            position          : initial;
            // gap            : 10px;
            justify-content   : space-between;
            width             : 100%;

            // height            : 100%;
            .container-title {}

            .container-icon {
                .icon {
                    cursor   : pointer;
                    font-size: 16px;

                    &.active {
                        display: block;
                    }

                    &.not-active {
                        display: none;
                    }
                }

            }
        }

        &:hover {
            background: themed("n_layout_bg_header");

            .title {
                color: themed('n_text_custom');
            }
        }

        a {
            height         : 100%;
            text-decoration: none !important;
            outline        : none !important;

            .txt---400-14-20 {
                height          : 100%;
                display         : flex;
                align-items     : center;
                //padding-top   : 12.5px !important;
                // line-height: 45px !important;
            }
        }

        .title {
            text-overflow: ellipsis;
            overflow     : hidden;
            white-space  : nowrap;
            color        : themed("n_text_custom");
            height       : 20px;
        }

        .icon {
            display          : flex;
            // flex-direction: column-reverse;
        }

        .setting-container {}


        .popupSettingLayoutCustom {
            background: themed("n_layout_bg_block");
            width     : 300px;
            top       : 44px;
            position  : absolute;
        }
    }
}