@import "common";

.section {
  @include themify($themes) {
    display: block;

    .section-header {
      background: themed("n_layout_bg_block") !important;
      color     : themed("n_layout_text_active");

      .title {
        color: themed("n_layout_text_active");
      }

      button {
        color: themed("n_layout_text_active");

        &:hover {
          background: themed("n_layout_bg_hover");
        }
      }
    }

    .section-body {
      color: themed("n_layout_text_active");
    }
  }

  margin-bottom: 10px;
  display      : block;

  .section-header {
    display    : flex;
    width      : 100%;
    height     : 30px;
    font-weight: 500;

    >.row {
      flex: 1 1 auto;
    }

    .title {
      padding    : 0 10px;
      font-size  : $font-size-medium;
      line-height: 30px;
    }

    button {
      width      : 24px;
      height     : 30px;
      border     : none;
      padding    : 0;
      line-height: 30px;
      text-align : center;
      background : transparent;
      font-size  : $font-size-medium;

      i {
        line-height: 30px;
      }

      .fa-times {
        font-size: $font-size-big + 2px;
      }
    }
  }

  .section-body {
    display : block;
    overflow: hidden;
  }
}