@import "../../../styles/common";

.dual-orderbook {
  @include themify($themes) {
    height: 100%;

    .bg-green-price{
      background: themed('n_layout_increase_text') !important;
    }

    .bg-red-price {
      background: themed('n_layout_reduce_text') !important;
    }

    .container-total {
      padding        : 10px 0px;
      display        : flex;
      justify-content: space-between;
      gap            : 10px;

      p {
        margin: 0px;
      }

      .style-bar {
        padding: 0px;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }

      .label-bar {
        white-space: nowrap;
      }

      .total-bar {
        display        : flex;
        align-items    : center;
        justify-content: center;
        width          : 100%;

        div {
          height: 10px;
        }
      }
    }


    .container-detail {
      margin: 10px 0px 0px 0px;
    }

    .table-row,
    .header {
      margin-bottom: 10px;
    }

    .table-container {
      width         : 100%;
      overflow-x    : hidden;
      padding-bottom: 5px;
    }

    .matched-price {
      border-bottom: 1px solid themed('n_layout_border');
    }

    .buy,
    .sell {
      .body>.row {
        position: relative;
        height  : 20px;
        cursor  : pointer;

        .slider {
          position: absolute;
          top     : 1px;
          height  : 18px;
          width   : 50%;
        }
      }
    }

    .buy .row {

      .slider {
        background: themed("n_layout_increase_text");
        right     : 1px;
        opacity   : 0.1;
      }

      // &:hover {
      //   background: rgba(themed("n_layout_increase_bg"), 0.03);

      //   .slider {
      //     background: rgba(themed("n_layout_increase_bg"), 0.4);
      //   }
      // }

      // &:active {
      //   background: rgba(themed("n_layout_increase_bg"), 0.3);

      //   .slider {
      //     background: rgba(themed("n_layout_increase_bg"), 0.7);
      //   }
      // }
    }

    .sell .row {
      .slider {
        background: themed("n_layout_reduce_text");
        opacity   : 0.1;
        left      : 1px;
      }

      // &:hover {
      //   background: rgba(themed("n_layout_reduce_bg"), 0.03);

      //   .slider {
      //     background: rgba(themed("n_layout_reduce_bg"), 0.4);
      //   }
      // }

      // &:active {
      //   background: rgba(themed("n_layout_reduce_bg"), 0.3);

      //   .slider {
      //     background: rgba(themed("n_layout_reduce_bg"), 0.7);
      //   }
      // }
    }
  }
}