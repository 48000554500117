@import "../../../../styles/common";

.cash-in-advance {
    @include themify($themes) {

        .input-checkbox{
            margin-right: 8px;
        }

        .text-center {
            text-align: center !important;
        }

        .line {
            border-top: 1px solid themed('n_layout_border');
            margin    : 16px 0px;
        }

        .h-table {
            height    : calc(100% - 50px) !important;
            // padding: 10px;
        }

        .title {
            margin-bottom: 16px;
            font-family  : 'Helvetica Neue Bold';
            font-weight  : 700; // Heading 6 - B (6)
            font-size    : 16px;
            line-height  : 24px;
        }

        .label {
            display      : inline-block;
            margin-bottom: 0px;
            font-weight  : 400; // Body Medium - Regular
            font-size    : 14px;
            line-height  : 20px;
            color        : themed('n_layout_text_not_active');
        }

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                &:focus{
                    border: 1px solid themed("input_hover_border_bottom") !important;
                }
            }

            .container-btn {
                margin-top: 13px;
            }

        }

        .block-right {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding-right: 0px !important;
            padding-left : 0px !important;
            height       : 100%;
            width        : 100%;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            .container-block {
                display       : flex;
                flex-direction: row;
                gap           : 10px;
                padding-left  : 10px;
                padding-right : 10px;

                .block-item {
                    height: auto;
                    width : 50%;
                }

                .row-title {
                    height            : 36px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed('n_layout_border');
                }

                .row-item {
                    line-height    : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed('n_layout_border');
                    padding      : 10px;
                    border-radius: 4px;
                }

                .basic-table {
                    // transition           : all 2s;
                    // -webkit-transition   : all 2s;
                    border               : none;
                    // background        : themed("n_table_bg");

                    thead {
                        tr {
                            // border-bottom: 1px solid themed("n_table_border");
                            background: themed("n_table_header");

                            th {
                                width       : 25%;
                                padding     : 15px;
                                border-left : 1px solid themed("n_table_border");
                                border-right: 1px solid themed("n_table_border");
                            }

                            th:first-child {
                                border-left: none;
                            }

                            th:last-child {
                                border-right: none;
                            }

                        }
                    }

                    tbody {
                        tr {
                            cursor    : pointer;
                            background: themed("n_table_row_even");
                            border    : 1px solid themed("n_table_border");


                            td {
                                padding      : 10px 10px;
                                border-bottom: 1px solid themed("n_table_border");
                                border-left  : 1px solid themed("n_table_border");
                                border-right : 1px solid themed("n_table_border");

                                .status-order {
                                    position: relative;

                                    .status-icon {
                                        position     : absolute;
                                        background   : #FC944D;
                                        border-radius: 50%;
                                        height       : 8px;
                                        width        : 8px;
                                        top          : 7px;
                                        left         : -15px;
                                    }
                                }
                            }

                            td:first-child {
                                border-left: none;
                            }

                            td:last-child {
                                border-right: none;
                            }
                        }

                        tr:nth-child(odd) td {
                            //background: themed("n_table_row_even");
                            background: themed("n_table_row");
                        }

                        tr:nth-child(even) td {
                            background  : themed("n_table_row");
                            //background: themed("n_table_row_odd");
                        }
                    }
                }

            }
        }

    }
}