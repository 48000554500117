@import "../../styles/common";

.fss.isDeskTop {
    .fakeMenuTool {
        width: 75px !important;
        display: inline-block !important;

        &.close-menu-tool {
            width: 10px !important;
        }
    }

    @media (max-height: 667px) {
        // overflow: scroll !important;
    }
}

.fss.isMobile {
    .menu-container {
        display: inline-flex !important;
        height: 100% !important;
        padding: 0px !important;
        overflow: hidden !important;
        overflow-x: auto !important;
        grid-gap: 0px !important;
        gap: 0px !important;
        width: 100% !important;
        margin-top: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        .menu-icon {
            padding: 0px 15px 0px 15px !important;
            p {
                margin-bottom: 4px;
            }
        }
    }

    .menu-tool {
        height: 80px !important;
        .btn-extend {
            display: none !important;
        }
    }
}

.btn-extend-close {
    @include themify($themes) {
        display: block;
        position: fixed;
        left: 0px;
        top: calc(45% + 35px);
        cursor: pointer;
        z-index: 3;
        background-color: themed("n_layout_bg");
        font-size: inherit !important;

        width: 10px;
        height: 40px;
        border-radius: 0px 20px 20px 0px !important;
    }
}

.menu-tool {
    &.close-menu-tool {
        margin-left: -75px !important;
    }

    @keyframes slideIn {
        0% {
            transform: translate(0, 20px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    &:hover {
        .btn-extend {
            display: block !important;  
        }
    }
    @include themify($themes) {
        .btn-extend {
            display: none;
            position: absolute;
            right: 0px;
            top: 45%;
            cursor: pointer;
            z-index: 2;
            background-color: themed("n_layout_bg");
            font-size: inherit !important;

            width: 20px;
            height: 40px;
            border-radius: 30px 0 0 30px;
        }

        position: absolute !important;
        height: calc(100vh - 50px);
        border-radius: 0px $border_radius $border_radius 0px;
        box-shadow: 0px 4px 10px themed("n_layout_box_shadow_bg");

        &.full {
            height: calc(100vh - 50px) !important;
            margin-top: 50px !important;
        }

        // height  : calc(100vh - 45px);

        // height    : calc(100vh - 90px);
        margin: 55px 0 0 0;
        width: 75px;
        background: themed("n_layout_bg_block");
        overflow: auto;

        .menu-container {
            height: 100%;
            padding: 12px 5px 0px 5px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .text-active {
                color: themed("n_menu_sidebar_text_active") !important;
            }
        }

        .menu-icon {
            // margin-bottom: 28px;
            p {
                margin-bottom: 4px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .menu-tool {
        position: fixed;
        top: 108px + 50px;
        z-index: 500;
        height: 44px;
        width: 100% !important;
        margin: 0px !important;
        overflow: unset !important;
        background: #181818;
        @include themify($themes) {
            .menu-container {
                align-items: center;
                padding-left: 10px;
                padding: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding-left: 30px;
                overflow-x: scroll;
                display: flex;
                flex-direction: row !important;
                gap: 30px;
                padding-top: 0px !important;
                .text-active {
                    color: themed("n_menu_sidebar_text_active") !important;
                }
            }
        }
    }

    .menu-tool-top-moblie-logout {
        top: 108px !important;
    }
}



.icon-tooltip {
    @include themify($themes) {
        border    : none;
        padding   : 0px;
        background: transparent;
        color     : themed("n_layout_text_active");
        border    : none !important;

        &:hover {
            cursor: pointer;
        }

        &:not([disabled]) {
            cursor: pointer;
        }

        i {
            font-size: 14px;
            border   : 4px solid red;
            display  : inline-block;

            &:hover {
                cursor: pointer !important;
            }
        }

        button:focus {
            border    : 1px solid yellow;
            box-shadow: black 0 0 1px 1px;
            outline   : none;
        }
    }
}
.customtooltip-menu-tool{
    position: relative;
    left: 35px;
}

.arrow-menu-tool{
    position: relative;
    left: 35px;
    transform: rotate(180deg);
}
