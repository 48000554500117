@import "../../styles/common";

.modal-create-layout-header {
    @include themify($themes) {
        // width    : 400px !important;
        max-width: 400px !important;
        z-index  : 2000;

        .custom-header {
            background: themed('n_layout_bg_block');
        }

        .custom-body {
            background    : themed('n_layout_bg_block');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;
            overflow      : auto;
            max-height    : calc(100vh - 80px);

            .body {
                background    : themed('n_layout_bg_block');
                flex-direction: row;
                height        : 100%;

                .wrap-body {
                    .create-new-custom {
                        display: flex;
                        gap    : 10px;

                        .div-input-layout {
                            display       : flex;
                            flex-direction: row;
                            border-bottom : solid 1px themed('n_layout_border');
                            height        : 32px;

                            .input-edit-name {
                                background: unset;
                                border    : unset;
                                box-shadow: unset;
                                outline   : unset;
                                width     : 250px;
                            }

                            .btn-not-outline {
                                background: none;
                                outline   : none;
                            }
                        }

                        .container-btn {
                            .btn-confirm {
                                height: 32px;
                            }
                        }
                    }

                    .create-override-data {
                        .create-override-data-dc-o-dc {
                            background    : themed("n_layout_bg_block");
                            border-radius : 4px !important;
                            display       : flex;
                            flex-direction: column;
                            align-items   : center;

                            .title {
                                padding: 16px 0px 4px 0px;
                            }

                            .content {
                                color     : themed('n_layout_text_near_active');
                                margin    : 10px 20px 20px 20px;
                                text-align: center;
                            }
                        }
                    }


                    .container-btn {
                        display: flex;
                        gap    : 10px;
                        width  : 100%;

                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}