@import "../../../styles/common";

.broker-order-book {
  width : 100%;
  height: 100%;

  .table-broker-order-book {
    height: 100%;
  }

  .container-action {
    padding: 10px 0px 0px 0px;
  }
}