@import "common";

.custom-form-group-select {
  @include themify($themes) {
    width     : 100%;
    background: none;

    .custom-form-select {
      width           : 100%;
      border          : none;
      padding         : 8px 0px 4px 8px;
      color           : themed('n_layout_text_active');
      background-color: themed('n_layout_bg_block');
      // border-bottom   : 1px solid themed("n_layout_btn_bg");
      border: 1px solid themed("n_layout_btn_bg");
      border-radius: 4px;
      background-position: 98%;

      // &:focus {
      //   border       : 1px solid yellow;
      //   border-radius: $border_radius;
      // }
    }

    select.custom-form-select {
      background-image: themed('common_icon_dropdown_img');
    }
  }

  select.custom-form-select {
    -webkit-appearance: none;
    -moz-appearance   : none;

    padding-right: 15px;

    background-position: right top;
    background-repeat  : no-repeat;
    background-size    : 15px 100%;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.focus-item {
  @include themify($themes) {
    &:focus-visible {
      border       : 1px solid themed('n_layout_text_active') !important;
      border-radius: $border_radius;
    }
  }
}

.custom-form-group-header {
  @include themify($themes) {
    width     : 100%;
    background: none;

    .custom-form-select {
      width                    : 100%;
      border                   : none;
      // padding         : 8px 0px 4px 0px;
      color                    : themed('n_layout_text_near_active');
      background-color         : themed('n_layout_bg_block');
      display                  : flex;
      text-align-last          : justify;
      // flex-direction        : row;
      // justify-content       : end;
      // border-bottom      : 1px solid themed("n_layout_btn_bg");
    }

    select.custom-form-select {
      // background-image   : themed('common_icon_dropdown_img');
      // position        : fixed;
    }
  }

  select.custom-form-select {
    -webkit-appearance: none;
    -moz-appearance   : none;


    background-position: left top;
    background-repeat  : no-repeat;
    background-size    : 15px 100%;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.custom-form-select {
  @include themify($themes) {
    &[type="radio"] {
      &:focus {
        border    : none !important;
        box-shadow: none !important;
      }
    }

    &[disabled] {
      opacity: 0.5 !important;
    }

    &.readonly {
      &:hover {
        border: 1px solid themed("n_layout_border");
      }
    }

    &:focus {
      // border    : 1px solid themed("n_layout_bg_block");
      // box-shadow: themed("n_layout_bg_block") 0 0 1px 1px;
      outline: none;
    }

    &:hover {
      // border: 1px solid themed("n_layout_bg_block");
    }

    &:focus-visible {
      // box-shadow: themed("n_layout_bg_block") 0 0 1px 1px;
    }
  }
}

.custom-form-group {
  @include themify($themes) {
    // border-bottom: 1px solid themed('n_layout_border');
    border: 1px solid themed("n_layout_btn_bg");
    border-radius: 4px;
    height: 32px;

    &>div{
      height: 100%;
    }

    &>span{
      line-height: 32px;
      padding-left: 8px;
    }

    &.read-only {
      background: themed("n_layout_btn_bg");
    }

    &.reload-icon{
      position: relative;
      svg{
        position: absolute;
        right: 8px;
        top: 5px;
        cursor: pointer;
        input{
          padding-right: 32px;
        }
      }
    }
    
    .custom-form-control {
      color           : themed('n_layout_text_active');
      width           : 100%;
      background-color: themed('n_layout_bg_block');
      height: 100%;
      border: none;
      padding-left: 8px;


      // &.readonly {
      //   color           : themed("n_layout_text_active");
      //   background-color: themed("n_layout_bg_block");
      // }

      &:read-only {
        background: themed("n_layout_btn_bg");
      }

      &[type="radio"] {
        &:focus {
          border    : none !important;
          box-shadow: none !important;
        }
      }

      &[disabled] {
        opacity: 0.5 !important;
      }

      &.readonly {
        &:hover {
          border: 1px solid themed("n_layout_border");
        }
      }

      // &:focus {
      //   outline: none;
      //   border: 1px solid themed('n_layout_text_active') !important;
      //   border-radius: $border_radius;
      // }

      &:focus {
        outline: none;
      }

      &:hover {
        outline: none;
      }
    }

    .custom-form-control-custodycd {
      max-width: 100px;
      min-width: 100px;
      &:focus {
        //outline: none;
        border: 1px solid themed('n_layout_text_active') !important;
        border-radius: $border_radius;
      }
    }

    select.custom-form-control {
      background-image: themed('form-control-select-bg-img');
    }

    input[type="text"].custom-form-control {
      &:focus {
        border: 1px solid themed('n_layout_text_active');
        border-radius: $border_radius;
      }
    }
  }

  textarea.custom-form-control {
    min-height: 28px;
    height    : auto;
    border-radius: $border_radius;
  }

  select.custom-form-control {
    -webkit-appearance: none;
    -moz-appearance   : none;

    padding-right: 15px;

    background-position: right top;
    background-repeat  : no-repeat;
    background-size    : 15px 100%;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.custom-form-group-effect input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  @include themify($themes) {
    -webkit-box-shadow     : 0 0 0 60px themed('n_layout_bg_block') inset !important;
    -webkit-text-fill-color: themed('n_layout_text_active') !important;
    background-color       : themed('n_layout_bg_block') !important;
    background-clip        : content-box !important;
    color                  : themed('n_layout_text_active') !important;
    font-weight            : 400 !important;
    font-size              : 14px !important;
    line-height            : 20px !important;
    -webkit-text-fill-color: themed('n_layout_text_active') !important;
  }
}

.custom-form-group-effect {
  @include themify($themes) {
    border: 1px solid themed('n_layout_border');
    border-radius: 4px;
    height: 32px;
    // border-bottom: 1px solid themed('n_layout_border');
    &>div{
      height: 100%;
    }


    &:hover {
      // border-bottom: 1px solid themed('input_hover_border_bottom')
      border: 1px solid themed('input_hover_border_bottom') //hover input
    }

    &:focus-within {
      border: 1px solid themed('input_hover_border_bottom') //focus input
      // border-bottom: 1px solid themed('input_hover_border_bottom')
    }

    input, .custom-form-control {
      color           : themed('n_layout_text_active');
      width           : 100%;
      border          : none;
      background-color: themed('n_layout_bg_block');
      height: 100%;
      padding-left: 8px;
      padding-right: 8px;


      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 60px themed('n_layout_bg_block') inset !important;
        background-color  : themed('n_layout_bg_block') !important;
        background-clip   : content-box !important;
      }

      &.readonly {
        color           : themed("n_layout_text_active");
        background-color: themed("n_layout_bg_block");
      }

      &[type="radio"] {
        &:focus {
          border    : none !important;
          box-shadow: none !important;
        }
      }

      &[disabled] {
        opacity: 0.5 !important;
      }

      &.readonly {
        &:hover {
          border: 1px solid themed("n_layout_border");
        }
      }

      &:focus {
        outline: none;
      }

      &:hover {
        outline: none;
      }
    }

    select.custom-form-control {
      background-image: themed('form-control-select-bg-img');
    }
  }

  textarea.custom-form-control {
    min-height: 28px;
    height    : auto;
    border-radius: $border_radius;
  }

  select.custom-form-control {
    -webkit-appearance: none;
    -moz-appearance   : none;

    padding-right: 15px;

    background-position: right top;
    background-repeat  : no-repeat;
    background-size    : 15px 100%;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.custom-form-select-option {
  @include themify($themes) {
    background-color: themed('n_form_bg');
    display         : block;
    height          : 32px;
    border-radius   : $border_radius;

    .label {
      overflow     : hidden;
      text-overflow: ellipsis;
      white-space  : nowrap;
    }

    .custom-control-select-option {
      color           : themed('n_layout_text_active');
      border          : none;
      background-color: themed('n_form_bg');
      max-width       : 100px;
      width           : 100%;

      &:focus-visible {
        border : none;
        outline: none;
      }
    }

    select.custom-control-select-option {
      color           : themed('n_layout_text_active');
      border          : none;
      background-color: themed('n_form_bg');
      max-width       : 175px;
      padding         : 0px 5px;
      border-radius   : $border_radius;
      height          : 100%;
      cursor          : pointer;
      width           : 100%;

      // background-image  : themed('form-control-select-bg-img');
      // -webkit-appearance   : none;
      // -moz-appearance      : none;

      // padding-right: 15px;

      // background-position: right top;
      // background-repeat  : no-repeat;
      // background-size    : 15px 100%;
    }
  }
}

.custom-form-group-n {
  @include themify($themes) {
    background-color: themed('n_form_bg');
    display         : flex;
    flex-direction  : row;
    border-radius   : 4px;
    height          : 32px;
    padding         : 6px 10px 6px 10px;

    &.padding-top-0 {
      padding-top: 0px;
    }

    .label {
      overflow     : hidden;
      text-overflow: ellipsis;
      white-space  : nowrap;
    }

    .custom-form-control {
      color           : themed('n_layout_text_active');
      border          : none;
      background-color: themed('n_form_bg');
      max-width       : 100px;

      &:focus-visible {
        border : none;
        outline: none;
      }
    }
    input.custom-form-control {
      min-width: 100px;
    }
    select.custom-form-control {
      color    : themed('n_layout_text_active');
      border   : none;
      max-width: 190px;
    }

    select.custom-form-control {
      background-image: themed('form-control-select-bg-img');
      &:focus {
        //outline: none;
        border: 1px solid themed('n_layout_text_active') !important;
        border-radius: $border_radius;
      }
    }

    select.custom-form-control {
      -webkit-appearance: none;
      -moz-appearance   : none;

      padding-right: 15px;
      padding-left: 2px;
      background-position: right top;
      background-repeat  : no-repeat;
      background-size    : 15px 100%;

      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.dropdown {
  display: inline-flex;
}

.dropdown-item {
  @include themify($themes) {
    color: themed("n_header_text_active");

    &.active-item {
      color: themed("n_header_text_active");
    }
  }

  font-size  : $font-size-medium;
  font-weight: 400
}

.dropdown:hover>.dropdown-menu {
  @include themify($themes) {
    background-color: themed("n_layout_bg_block");
    @include css3-prefix('box-shadow', '3px 3px 3px 0 #{themed("n_layout_bg_block")}');
    border: 1px solid themed("n_layout_bg_block");

  }

  display   : block;
  margin-top: 0;
  padding   : 5px 0;
  @include border-radius(5px);
}

.dropdown-item:hover {
  @include themify($themes) {
    background-color: themed("n_layout_active_bg");
  }

  cursor: pointer;

  font-size: $font-size-medium;
}

.dropdown>.dropdown-toggle:active {
  pointer-events: none;
}

.bold {
  font-weight: bold;
}