@import "../../../styles/common";

.market-alert-content {
    @include themify($themes) {

        .pd-0 {
            padding: 0px !important;
        }

        .p-style {
            //padding-bottom: 10px;
            padding-right: 10px !important;
        }

        .add-alert {
            width       : 100%;
            padding-left: 0px !important;

            .rc-input-number-handler-wrap {
                display: none !important;
            }

            .rc-input-number {
                border: none;
            }

            .row {
                margin-left : 0px;
                margin-right: 0px;
            }

            .button-container {
                border-bottom: none;

                button {
                    color        : themed("n_text_custom") !important;
                    height       : 40px;
                    background   : themed('n_header_btn_primary');
                    border-radius: 4px;
                }
            }

            .custom-form-group {
                &.radio-check {
                    border: none;

                    .custom-form-control {
                        height    : 16px;
                        background: themed('n_table_filter_bg');
                    }
                }

                &.date-picker {
                    margin-bottom : 15px;
                }

                .custom-form-control {
                    // background   : themed('n_table_filter_bg');
                }
            }

            .input-value {
                input {
                    text-align: center;
                }

                margin-top: 10px;
            }

            .search-symbol-quick-order-market-alert {
                padding-left : 0px;
                padding-right: 0px;
            }

            .input-row {
                margin-top   : 10px;
                padding-right: 0px;
                margin-bottom: 15px;

                .input-value {
                    // border-bottom: none;
                    margin-bottom: 10px;

                    // margin-left: 10px;
                    input {
                        margin-bottom: 10px;
                    }
                }

                .custom-form-control {
                    //border-bottom: 1px solid #3F4247;
                }

                .present-order {
                    margin-top   : 10px;
                    margin-bottom: 10px;
                }

                .content-right {
                    gap   : 10px;
                    height: 30px;

                    .select-present {
                        display        : flex;
                        align-items    : center;
                        justify-content: center;
                        white-space    : nowrap;
                        border         : 1px solid themed("n_layout_border");
                        flex           : 1 0 0;
                        height         : 100%;
                        cursor         : pointer;
                        border-radius  : $border_radius;

                        &:focus-visible {
                            // box-shadow: themed('form-control-border-focus') 1px 1px 1px 1px;
                            border : 1px solid themed('form-control-border-focus');
                            outline: none;
                        }
                    }
                }
            }
        }

        .block-info-symbol-market-alert {
            margin-top      : 10px;
            padding         : 6px 8px;
            background-color: themed("n_layout_btn_bg");
            width           : 100%;
            border-radius   : $border-radius;
            height          : auto;
            min-height      : 40px;
            cursor          : pointer;

            .info-symbol {
                width: 100%;

                .custom-row {
                    display        : flex;
                    justify-content: space-between;
                }
            }
        }

        .sep-line {
            height       : 0px;
            width        : 100%;
            border-bottom: 1px solid themed("n_layout_border");
            ;
            margin-top   : 10px;
            margin-bottom: 10px;
        }

        .capacity-container {
            width : 100%;
            margin: 10px 0px 0px 0px;

            .info-capacity {
                .row-capacity {
                    display        : flex;
                    justify-content: space-between;

                    &.index {
                        display   : block;
                        text-align: center;

                        .capacity-content {
                            display: block;
                        }
                    }

                    .capacity-content {
                        display: flex;

                        .label {
                            color: themed("n_layout_text_not_active");
                        }

                        .value {
                            color: themed("n_layout_text_active");

                            .fill-qtty {
                                cursor: pointer;
                            }
                        }

                        &.capacity-price {
                            gap   : 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .text-center {
            text-align: center !important;
        }

        .line {
            border-top: 1px solid themed("n_table_border");
            margin    : 16px 0px;
        }

        .title {
            margin-bottom: 22px;
        }

        .label {
            display      : inline-block;
            margin-bottom: 0px;
            font-weight  : 400; // Body Medium - Regular
            font-size    : 14px;
            line-height  : 20px;
            color        : themed("n_layout_text_not_active");
        }

        .block {
            background   : themed("n_bg_block");
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 13px;
            }
        }

        .block-right {
            background    : themed("n_bg_block");
            border-radius : 4px;
            padding       : 10px 10px 0px 10px;
            padding-right : 0px !important;
            padding-left  : 0px !important;
            height        : 100%;
            width         : 100%;
            display       : flex;
            flex-direction: column;

            .container-block {
                display       : flex;
                flex-direction: row;
                gap           : 10px;
                height        : 100%;
                min-height    : 300px;

                .block-item {
                    height: auto;
                    width : 50%;
                }

                .row-title {
                    height            : 36px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed("n_table_border");
                }

                .row-item {
                    line-height    : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed("n_table_border");
                    padding      : 10px;
                    border-radius: 4px;
                }

                .basic-table {
                    border-radius: $border_radius;
                    border       : 1px solid themed("n_table_border");

                    .action-col {
                        width     : 62px;
                        // padding: 4px;

                        button {
                            color        : themed("n_text_custom");
                            border-radius: 2px;
                            width        : 100px;
                            height       : 25px;
                        }
                    }

                    thead {
                        tr {
                            // border-bottom: 1px solid themed("n_table_border");
                            background: themed("n_table_header");

                            th {
                                padding     : 15px;
                                border-left : 1px solid themed("n_table_border");
                                border-right: 1px solid themed("n_table_border");
                            }
                        }
                    }

                    tbody {
                        tr {
                            cursor    : default;
                            background: themed("n_table_row_even");
                            border    : 1px solid themed("n_table_border");

                            td {
                                padding      : 10px 10px;
                                border-bottom: 1px solid themed("n_table_border");
                                border-left  : 1px solid themed("n_table_border");
                                border-right : 1px solid themed("n_table_border");

                                &.action-container {
                                    border-bottom: none;

                                    button {
                                        outline   : unset;
                                        box-shadow: unset;
                                        border    : unset;
                                        background: none;
                                        color     : themed("n_layout_reduce_text");
                                        background: rgba(themed("n_layout_reduce_bg"), 0.15) !important;
                                    }
                                }
                            }

                            td:first-child {
                                border-left: none;
                            }

                            td:last-child {
                                border-right: none;
                            }
                        }

                        tr:nth-child(odd) td {
                            background  : themed("n_table_row");
                            //background: themed("n_table_row_even");
                        }

                        tr:nth-child(even) td {
                            // background: themed("n_table_row_odd");
                            background: themed("n_table_row");
                        }
                    }
                }
            }
        }
    }
}


@include media-breakpoint-down(sm) {
    .market-alert-content {
        @include themify($themes) {
            >.row {
                gap: 5px;
            }

            .p-style {
                padding-right: 5px !important;
                padding-left : 5px !important;
            }

            .add-alert {
                padding-right: 5px !important;
                padding-left : 5px !important;
            }
        }
    }
}