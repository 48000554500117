@import "../../../../styles/common";

.trade-auth-quick-order {
  @include themify($themes) {

    .custom-row-group {

      border-bottom: 1px solid themed("n_layout_border");

      .custom-row-form {
        padding: 14px 0px;

        .label {
          color      : themed('n_layout_text_not_active');
          font-weight: 400;
          font-size  : 14px;
          line-height: 20px;
          text-align : left !important;
          height     : 100%;
          align-items: center;
          display    : flex;
          word-break : break-word;

        }

        .value {
          color      : themed('n_layout_text_active');
          font-weight: 400;
          font-size  : 14px;
          line-height: 20px;
          text-align : right !important;
          word-break : break-word;

        }

      }
    }

    .normal-order-cancel {
      .basic-table {
        margin-top: 10px;
        thead{
          background-color: themed("n_table_header") !important;
          color           : themed("n_table_text_active") !important;
          th {
            padding: 19px 10px;
          }
        }
        tbody {
          tr {
            background     : themed("n_table_row") !important;
            td {
              height       : 40px;
              // border-bottom: 1px solid themed("n_table_border");
            }
          }
        }
        table, th, td {
          border: 1px solid #2D2C35;
        }
      }
    }

    .custom-row-oco {
      border-bottom: 1px solid themed("n_layout_border");

      .custom-row-group {}
    }
  }
}