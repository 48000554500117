@import "../../../../styles/common";

.detail-modal {
    @include themify($themes) {
        // width    : 320px !important;
        // max-width: 320px !important;

        .title {
            margin-bottom: 22px;
        }

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }

        }

        .block-right {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 10px;
            height       : 100%;
            width        : 100%;

            .container-block {
                display       : flex;
                flex-direction: row;
                gap           : 10px;

                .block-item {
                    height        : auto;
                    width         : 50%;
                    display       : flex;
                    flex-direction: column;
                    gap           : 10px;
                }

                .row-title {
                    height            : 30px;
                    display           : flex;
                    // align-items    : center;
                    justify-content   : space-between;
                    border-bottom     : 1px solid themed("n_table_border");
                }

                .row-item {
                    line-height    : 36px;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                }

                .border-block {
                    border       : 1px solid themed("n_table_border");
                    padding      : 3px 10px;
                    border-radius: 4px;
                }
            }
        }

        .custom-body {
            background    : themed('n_layout_bg_block');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;

            .h-row {
                padding-top    : 16px;
                padding-bottom : 16px;
                display        : flex;
                justify-content: space-between;
                border-top     : 1px solid themed('n_layout_border');
                margin-right   : 0px;
                margin-left    : 0px;
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top   : 13px;
                padding-right: 0px;
                padding-left : 0px;
            }


            .content {
                max-height: 40vh;
                overflow  : auto;
                margin    : 0 auto;
            }

            .color-body {
                color: themed('n_layout_text_active') !important;
            }

            // .container-btn {
            //     margin-top: 16px;
            //     display   : flex;
            //     gap       : 8px;
            //     flex-wrap : nowrap;
            //     width     : 100%;
            // }

        }

        .custom-footer {
            background: themed('n_layout_bg_block');

            height         : 80px;
            color          : themed('n_layout_text_active');
            width          : 100%;
            align-items    : center;
            display        : flex;
            padding        : 0px 20px;
            justify-content: space-between;

            .btn {
                width  : 100%;
                padding: 0px 34px;
            }
        }

        .custom-icon-reload {
            cursor  : pointer;
            position: absolute;
            right   : 5px;
            top     : -5px;
        }

    }
}