@import "../../../../styles/common";

.addition-bank {
    @include themify($themes) {

        padding-bottom: 10px;

        .addition-bank_header {
            display        : flex;
            justify-content: space-between;

            font-size     : 16px;
            font-weight   : 700;
            padding-bottom: 10px;

            .btn-add {
                cursor: pointer;
            }
            .disabled-btn-add {
                cursor: not-allowed !important;
                opacity: 0.4;
            }
        }

        .addition-bank_body {
            display       : flex;
            flex-direction: column;
            gap           : 5px;
            padding-right : 5px;

            // height    : 50%;
            max-height: 400px;
            min-height: 400px;
            overflow  : auto;


            .bank-item {
                display: flex;
                gap    : 10px;

                border-radius: 4px;
                cursor       : pointer;

                // background         : themed('n_form_bg');
                // color           : themed('n_layout_text_active');

                padding: 15px 0 15px 0px;

                &.active {
                    background: themed('n_header_btn_primary');
                }

                .bank-item-body {
                    display        : flex;
                    justify-content: space-between;
                    width          : 100%;

                    display: flex;

                    .info {
                        display       : flex;
                        flex-direction: column;

                        .bank-name {
                            font-size: 14px !important;
                        }

                        .bank-number {
                            font-size: 12px !important;
                        }
                    }

                    .icon {
                        display    : flex;
                        align-items: center;
                    }

                    .icon-bank {
                        height         : 48px;
                        width          : 48px;
                        border-radius  : 30px;
                        background     : themed('n_form_bg');
                        cursor         : pointer;
                        display        : flex;
                        flex-direction : row;
                        justify-content: center;
                        align-items    : center;
                        padding-top    : 1px;
                        margin-right    : 4px
                    }
                }

                .image {
                    display    : flex;
                    align-items: center;
                }

                &:hover {
                    background: themed('n_form_bg');
                }
            }
        }
    }
}