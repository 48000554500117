@import "../../../styles/common";

.TableDevExtreme {
  @include themify($themes) {
    .action-order {
      .container-btn {
        display: flex;

        .action-edit {
          cursor: pointer;
        }

        .action-cancel {
          cursor: pointer;
        }
      }
    }
  }
}