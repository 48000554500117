// @import "../../styles/common";
@import "../../styles//common";

.fss.isMobile {
    .quick-order {
        width  : 100% !important;
        top    : 0px !important;
        left  : 0px !important;
        z-index: 999;
    }
}

.quick-order-skylight{
    position: absolute;
    top: 0px;
    left: 0px;
    h2.dragHandler{
        display: none;
    }
    .skylight-close-button{
        display: none;
    }

    &.place-quick-order{
        .skylight-dialog{
            background: transparent;
            background-color: unset !important;
        }
    }
}

.quick-order {
    @include themify($themes) {
        background-color       : themed("n_layout_bg_block");
        position               : absolute;
        z-index                : 100;
        text-align             : center;
        // height                 : calc(100vh - 40px);
        height                 : fit-content;
        width                  : 350px;
        // top                    : 60px;
        // transition        : all 0.5s;
        // -webkit-transition: all 0.5s;
        //box-shadow           : 2px 2px 4px 3px themed("n_layout_box_shadow_bg");
        // box-shadow             : -1px 2px 10px themed("n_layout_box_shadow_bg");
        // -webkit-box-shadow     : -1px 2px 10px themed("n_layout_box_shadow_bg");
        // left                   : 85px;


        .selector-account-customer{

            &.account-select-block{
                max-width: 100px;
            }

            &.account-name{
                max-width: 175px;
            }

            &.account-name > div{
                padding-right: 5px;
                padding-top: 5px;
                max-width: 175px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.edit-only{
            position            : unset;
        }
        
        .disable{
            opacity: 0.5;
        }

        .line-divide {
            height          : 8px;
            background-color: themed("n_layout_bg");
        }

        &.quick-order-open {
            transform: translate(0px, 0px)
        }

        &.quick-order-close {
            transform: translate(100%, 0px);
            position : unset;
            width    : 0px;
        }

        .block-quick-order {

            .header-quick-order {
                display        : flex;
                align-items    : center;
                justify-content: space-between;
                background     : themed("n_layout_bg_header_block");
                padding        : 8px 10px;
            }

            .block-selector-account {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 5px;


                padding: 0px 10px 10px 10px;

                .custom-form-group {
                    flex: 3;
                    height: 32px;
                    border-bottom: none;
                    .custom-form-control{
                        padding-left: 8px;
                        background: themed("n_layout_btn_bg");
                        border-radius: 4px;
                        // background: red !important;
                    }
                }

                .custom-form-group-select {
                    flex: 1;
                    height: 32px;

                    &.accountId-select{
                        max-width: 50px !important;
                    }

                    .custom-form-select{
                        border: none;
                        padding: 6px;
                        border: 1px solid themed("n_layout_btn_bg");
                        border-radius: 4px;
                        
                    }
                    // padding: 10px 0px 0px 0px;
                }
            }

            .list-item-quick-order-select {
                display        : flex;
                justify-content: space-between;
                padding        : 0px 10px;

                .item-select {
                    padding: 10px 10px 0px 10px;
                    cursor : pointer;

                    &:first-child {
                        padding-left: 0px;
                    }

                    &:last-child {
                        padding-right: 0px;
                    }

                    .item-text {
                        color         : themed("n_layout_text_not_active");

                        &.active {
                            color: themed("n_layout_text_active");
                        }
                    }
                }
            }
        }
    }
}