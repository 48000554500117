@import "../../../styles/common";

.match-order-detail-by-cus-modal-frpb {
    background-color: #222;
    color           : rgb(216, 216, 216);

    body.theme-white &,
    body.theme-classic & {
        background-color: #ffffff;
        color           : #13161b;
    }

    .row {
        margin-bottom: 0;
    }

    .header-info {
        padding: 5px 20px;

        .custom-row {
            display: flex;

            .header-info-label {
                width  : 50%;
                padding: 0;
            }

            .active {
                // color: $theme01_pro_01;

                body.theme-white &,
                body.theme-classic & {
                    // color: $theme02_pro_01;
                }
            }
        }
    }

    .header-info-label {
        font-weight: bold;
        padding    : 5px 5px 5px 25px;
        line-height: 30px;
    }


    .scrollbars-content {
        max-height: 310px !important;

        div:first-child {
            max-height: 310px !important;
        }
    }

    table {
        //margin: 0 15px 15px 0px;
        width: 100%;

        thead {
            tr {
                &.info-row {
                    td {}
                }
            }

            th {
                background: #1e1e1e;

                // border-bottom: 1px solid #fc0390;
                // border-right: 1px solid #fc0390;
                body.theme-white &,
                body.theme-classic & {
                    background      : #e2e2e2;
                    // border-bottom: 1px solid #fc0390;
                    // border-right: 1px solid #fc0390;
                }

                line-height: 32px;
                padding    : 0 5px;
                white-space: nowrap;
            }

            .header-info,
            .header-table {}

            .header-info {
                top: 0;
            }

            .header-table {
                position: sticky;
                top     : 0px;
            }
        }

        tbody {
            td {
                line-height: 32px;
                padding    : 0 5px;
                white-space: nowrap;

                body.theme-white &,
                body.theme-classic & {
                    border-right: 1px solid #dddddd;
                }
            }

            tr {
                &:nth-child(even) {
                    background: #181818 !important;

                    body.theme-white &,
                    body.theme-classic & {
                        background: lighten(#f1f1f1, 2) !important;
                    }
                }

                // &:nth-child(odd) {
                //     background: $theme01_background_01 !important;
                //     body.theme-white &, body.theme-classic & {
                //         background: #f5f5f5 !important;
                //     }
                // }

                &.total {
                    &:hover {
                        cursor: pointer;
                    }

                    td {
                        // background: #fc0390;
                    }

                    .active {
                        font-weight   : bold;
                        // color      : $theme01_pro_01;

                        body.theme-white &,
                        body.theme-classic & {
                            color: #236ABB;
                        }
                    }
                }

                &.total-sum {
                    td {
                        border        : none;
                        position      : sticky;
                        bottom        : -1px;
                        font-weight   : bold;
                        padding-bottom: 5px;
                        background    : #1e1e1e;

                        body.theme-white &,
                        body.theme-classic & {
                            background: #e2e2e2;
                        }

                        // color: $theme01_pro_01;

                        body.theme-white &,
                        body.theme-classic & {
                            color: #236ABB;
                        }
                    }
                }

                // &:nth-child(odd) {
                //   background: #fc0390;
                // }
            }
        }
    }
}

.Dragable-wrapper {
    position  : fixed;
    background: none;
    z-index   : -99;
    display   : none !important;
    top       : 66px;

    &.opening {
        z-index: 100 !important; // z-index cao nhất của header là 99 (cần để cao hơn để lúc di lên trên không bị vướng header)
        display: block !important;
    }

    .skylight-overlay {
        background      : none;
        background-color: unset !important;
    }

    .dragHandler {
        cursor: move;
    }
}

.starLight-overlay-replacement {
    position        : fixed;
    top             : 0px;
    left            : 0px;
    height          : 100vh;
    width           : 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    z-index         : 99;
}

.skylight-dialog {
    // max-height: 300px !important;
    // min-height: 300px !important;
    min-height: 70vh;
    max-height: 70vh;

    @include themify($themes) {
        .scrollbars-content {
            height    : 100%;
            max-height: 300px !important;
            min-height: 300px !important;
        }

        .skylight-close-button {
            top: -12px !important;

            // position: sticky !important;
            // top: 0px;
            // left: 100% !important;
            body.theme-white &,
            body.theme-classic & {
                color: #f5f5f5 !important;
            }
        }

        h2 {
            line-height        : 14px;
            z-index            : 98;
            position           : sticky !important;
            top                : 0px;
            font-size          : 16px;
            margin-top         : 0px;
            margin-bottom      : 0px;
            padding-left       : 5px;
            padding-top        : 5px;
            //position: sticky;
            top                : 0;
            height             : 25px;
            background-color   : themed("n_layout_bg_block");
            // color           : $theme01_background_05 !important;
            font-weight        : unset !important;

            body.theme-white &,
            body.theme-classic & {
                // background-color: $theme02_background_05 !important;
                // color           : $theme02_background_01 !important;
            }
        }

        background-color: themed("n_layout_bg_block") !important;

        body.theme-white &,
        body.theme-classic & {
            background-color: #f5f5f5 !important;

            h2 {
                color: #6e7d8b;
            }
        }

        .align-center,
        .text-center {
            text-align: center !important;
        }
    }
}

.center {
    margin   : 0;
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}