@import "../../../../styles/common";

.realized-profit-loss {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .custom-form-group-n{
            &.fullname{
                background: transparent !important;
                padding: 10px 2px 10px 2px !important;
    
                // & > div{
                //     display: block !important;
                //     max-width: 140px;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                // }
            }
        }

        .realized-profit-loss-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .bold {
            font-weight: bold;
        }
    }
}