@import "../styles/common.scss";

.error-container-end {
  @include themify($themes) {
    width: 310px;

    header {
      font-size  : $font-size-big;
      font-weight: 700;
      padding    : 0 10px;
      height     : 50px;
      line-height: 50px;
      width: 100%;
  
      button {
        float       : right;
        display     : block;
        margin-right: -10px;
        width       : 20px;
        height      : 20px;
        border      : none;
        padding     : 0;
        text-align  : center;
        font-size   : $font-size-big + 2px;
        background  : transparent;
      }
    }

    .bg-btn-login {
      background: themed('n_header_btn_primary');
    }

    .confirm-body {
      background    : themed("n_layout_bg_block");
      border-radius : 4px !important;
      display       : flex;
      flex-direction: column;
      align-items   : center;
      padding       : 5px 20px 30px 20px;

      .title {
        margin: 16px 0px 4px 0px;
      }

      .content {
        color        : themed('n_layout_text_near_active');
        margin-bottom: 16px;
        text-align   : center;
      }

      .btn {
        width: 100%;
      }

      .container-btn {
        margin-top: 16px;
        display   : flex;
        gap       : 8px;
        flex-wrap : nowrap;
        width     : 100%;
      }
    }
  }
}


// .error-container {
//   @include themify($themes) {
//     width          : 100vw;
//     height         : 100vh;
//     display        : flex;
//     flex-direction : column;
//     justify-content: center;


//     .image {
//       text-align: center;

//       img {
//         height: 200px;
//         width : auto;
//       }

//       svg {
//         height: 200px;
//         width : auto;
//         fill  : themed("n_layout_text_active");
//       }
//     }

//     .message {
//       margin-top: 10px;
//       font-size : $font-size-big;
//       text-align: center;
//       color     : themed("n_layout_text_active");
//     }

//     .action {
//       margin-top: 20px;
//       text-align: center;

//       .btn {
//         text-transform: uppercase;
//         margin-right  : 10px;
//       }
//     }

//   }
// }