@import "../../styles/common";

.modal-login {
    position : relative;
    margin   : 0 auto !important;
    max-width: 370px;
    height   : 100%;

    .btn-close {
        position: absolute;
        top     : 5px;
        right   : 10px;
        cursor  : pointer;
    }

    .modal-content {
        height          : 100%;
        width           : 100%;
        min-width       : 100%;
        overflow        : hidden;
        border-radius   : 5px;
        background-color: transparent;
    }

    .modal-login-content {
        // width : 366px;
        // height: 633px;
        height   : 100%;
        width    : 100%;
        min-width: 100%;

        iframe {
            border: none;
        }
    }

}

.modal-dialog.modal-login {
    min-width: 100%;
}


// .modal-login {
//     .skylight-dialog {
//         width: unset !important;
//         padding: unset !important;
//         top: 37% !important;
//         .modal-login-content {
//             width : 300px;
//             height: 570px;
//         }
//     }

// }