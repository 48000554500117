@import "../styles/common.scss";

.not-found {
  @include themify($themes) {
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : 100%;
    height         : 100%;

    .image {
      text-align: center;

      img {
        height: 200px;
        width : auto;
      }
    }

    .message {
      margin-top: 10px;
      font-size : $font-size-big;
      color     : themed("n_layout_text_active");
      text-align: center;
    }

    .action {
      margin-top: 20px;
      text-align: center;

      .btn {
        text-transform: uppercase;
      }
    }
  }
}