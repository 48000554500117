@import "../../../../styles/common";

.list-right-off {
    @include themify($themes) {
        .block-list-right-off {

            height           : 100%;
            display          : flex;
            flex-direction   : column;
            background       : #282b30;
            border-radius    : 4px;
            // padding       : 10px;
            height           : 100%;


            .container-table {
                height       : 100%;
                overflow     : auto;
                margin       : 0px 10px 10px 10px;
                border-radius: $border_radius;
                min-height   : 300px;

                .basic-table {
                    // min-width: 1025px;
                    border-radius: $border_radius;
                    border       : 1px solid themed("n_table_border");

                    th {
                        padding: 5px !important;
                    }

                    .sum-row{
                        color: themed("n_sum_group_color");

                    }

                    .btn-confirm {
                        min-width: 70px !important;
                    }

                    .btn-view {
                        cursor: pointer;
                    }
                }
            }

            .btn {
                background: themed("n_layout_primary");
                padding   : 6px 10px;
                height    : 100%;
            }

            .action-container {
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                padding-bottom : 10px;
            }

            .ml-10 {
                margin-left: 10px;
            }

            .search-container {
                margin   : 0 auto 8px auto;
                max-width: 590px;

                .custom-form-group {
                    width: 100%;
                }

            }
        }

        .right-off-info-trade {

            .custom-form-group-block {
                padding: 0px 0px 20px 0px;

                .block-info-symbol {
                    margin-top      : 10px;
                    padding         : 6px 8px;
                    background-color: themed("n_layout_btn_bg");
                    width           : 100%;
                    border-radius   : $border_radius;
                    height          : auto;

                    .info-symbol {
                        width: 100%;

                        .custom-row {
                            display        : flex;
                            justify-content: space-between;
                        }
                    }

                }
            }

            .accept-rule {
                display: flex;
                gap    : 10px;
            }
        }
    }
}