@import "../../styles/common";

.ProSidebar {
    position: absolute !important;
    height  : 100vh !important;
}

.fake-overlay {
    position        : absolute;
    display         : block;
    width           : 100vw;
    height          : 100vh;
    top             : 50px;
    left            : 0px;
    right           : 0;
    bottom          : 0;
    background-color: rgba(10, 10, 10, 0.5);
    z-index         : 999;
    cursor          : pointer;
}

.fss.isMobile {
    .ProSidebar {
        .pro-sidebar {
            margin-top: 50px;
            width     : 100vw !important;

            .pro-sidebar-content {
                width : 100vw !important;
                border: 1px solid rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.fss.isDeskTop {
    .ProSidebar {
        margin-top: 50px;

        .pro-sidebar {
            // width: inherit !important;

            .pro-sidebar-content {
                // width: inherit !important;
            }
        }
    }
}

.pro-sidebar {
    // position  : absolute;
    height           : 100%;
    z-index          : 1000;
    // margin-top    : 50px;
    transition       : width, left, right, 0s;
    padding-bottom   : 10px;
    // position  : absolute;


    .pro-menu {
        padding-top   : 0px !important;
        padding-bottom: 0px !important;

    }

    .pro-sidebar-inner {
        @include themify($themes) {
            background     : themed("n_layout_bg_block") !important;
            color          : themed("n_layout_text_near_active") !important;
            // border-right: 1px solid themed('n_menu_border_right');
        }

        .pro-sidebar-layout {
            height  : calc(100% - 40px) !important;
            position: sticky;
            top     : 0;
        }

        .pro-icon-wrapper {
            @include themify($themes) {
                // background: red !important;
            }

            line-height: 0px !important;

        }
    }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    // @include themify($themes) {
    //     color: themed('NEW-SideBar-text-color-item')!important;
    // }

}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 17px 8px 0px;
}

.pro-sidebar .pro-menu .pro-menu-item .pro-inner-item {
    padding: 6px 17px 6px 0px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: -3px !important;
    width       : 0px !important;
    min-width   : 30px !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
    padding-top   : 0px;
    padding-bottom: 0px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    padding-left: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    right: 18px !important;
}

.pro-sidebar {
    min-width: inherit !important;
}

.pro-sidebar .pro-menu ul .pro-sub-menu .pro-inner-list-item {
    position: relative;

    // @include themify($themes) {
    //     background: themed('NEW-SideBar-background') !important;
    // }
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    background: transparent;
}

.pro-inner-item {
    // @include themify($themes) {
    //     color: themed('NEW-SideBar-text-color') !important;
    // }
}

.react-slidedown .pro-inner-item {
    // @include themify($themes) {
    //     color: themed('NEW-SideBar-text-color-item') !important;
    // }
}

.pro-inner-item:hover {
    @include themify($themes) {
        color: themed("n_menu_sidebar_text_active") !important;
    }

    // @include themify($themes) {
    //     color: themed('NEW-SideBar-text-color-hover') !important;

    //     .pro-arrow {
    //         border-color: themed('NEW-SideBar-text-color-hover') !important;
    //         color       : themed('NEW-SideBar-text-color-hover') !important;
    //     }

    //     .pro-icon-wrapper {
    //         .custom-icon-asc {
    //             svg {
    //                 path {
    //                     fill   : themed('Icon-hover') !important;
    //                     opacity: 1 !important;
    //                 }
    //             }

    //             &.sidebar-icon.unavailable-active {
    //                 svg {
    //                     path {
    //                         fill   : themed('Icon-hover') !important;
    //                         opacity: 1 !important;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

.activeMenuSideBar>.pro-inner-item {
    @include themify($themes) {
        color: themed('n_menu_active') !important;
    }
}


// .activeMenuSideBar>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
//     @include themify($themes) {
//         border-color: themed('NEW-SideBar-text-color-hover') !important;
//     }
// }

// // do custom icon 
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 27px !important;

    @include themify($themes) {
        background   : themed("n_layout_bg_block") !important;
        // color     : themed("n_layout_bg_block") !important;
    }
}

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
    position: absolute;
    right   : 13px;
}



// .popper-element {
//     top: 50px !important;

// }

// .pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
//     @include themify($themes) {
//         background: themed('NEW-SideBar-background') !important;
//         color     : themed('NEW-SideBar-text-color') !important;
//     }
// }

// .pro-sidebar-footer {
//     @include themify($themes) {
//         background: themed('NEW-SideBar-background-footer') !important;
//     }

//     height         : 40px !important;
//     z-index        : 1 !important;
//     bottom         : 0;
//     position       : fixed !important;
//     transition     : width 0.3s;
//     display        : flex;
//     justify-content: center;
//     align-items    : center;

//     .sidebar-btn-wrapper {
//         display: flex;
//     }
// }

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    @include themify($themes) {
        flex-grow: 0;

        :hover {
            color: themed("n_menu_sidebar_text_active");
        }

        a {
            color: themed("n_layout_text_near_active");
        }

        a:hover {
            color: themed("n_menu_sidebar_text_active");
        }
    }
}

.pro-sidebar .pro-menu .pro-menu-item .pro-inner-item .pro-item-content {
    margin-left  : 10px;
    padding-right: 10px;
}


.theme-light {
    .pro-sub-menu {
        &.open>.pro-inner-item>.pro-item-content {
            color: $theme_02_common_text_active !important;

        }
    }
}


























.shake-text {
    animation                      : shake 1s;
    animation-iteration-count      : infinite;
    // background                  : red;

    @include themify($themes) {
        color: red !important;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    // 10% {
    //     transform: translate(-1px, -2px) rotate(-1deg);
    // }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    // 30% {
    //     transform: translate(3px, 2px) rotate(0deg);
    // }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    // 50% {
    //     transform: translate(-1px, 2px) rotate(-1deg);
    // }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    // 70% {
    //     transform: translate(3px, 1px) rotate(-1deg);
    // }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    // 90% {
    //     transform: translate(1px, 2px) rotate(0deg);
    // }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}