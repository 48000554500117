@import "../../../../styles/common";

.right-off-history {
  @include themify($themes) {
    height: 100%;

    .block {
      background: themed("n_bg_block");
      border-radius: 4px;
      height: 100%;

      .h-row {
        height: 48px;
      }

      .btn {
        width: 100%;
      }

      .container-btn {
        margin-top: 13px;
      }

      .active {
        color: themed("n_layout_text_active");
      }

      .not-active {
        color: themed("n_layout_text_not_active");
      }

      .text-detail {
        position: absolute;
        right: 5px;
        top: -5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .right-off-history-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .container-table {
      height: 100%;
      overflow: scroll;
    }

    .btn {
      background: themed("n_layout_primary");
      padding: 6px 10px;
      height: 100%;
    }

    .bold {
      font-weight: bold;
    }

    .action-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .securities-transaction-report-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .search-container {
      margin: 0 auto 8px auto;
      max-width: 590px;

      .custom-form-group {
        width: 100%;
      }
    }

    .container-table {
      height: 100%;
      overflow: hidden !important;
    }
  }
}
