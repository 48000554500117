@import "../../../../styles/common";

.modal-place-multi-order {
    max-width: 900px !important;
    @include themify($themes) {

        .body {
            background: themed("n_layout_bg_block");
            padding   : 20px;

            .ScrollbarsCustom-TrackY {
              visibility: visible !important;
            }


            .wrap-body {
                .info-detail {
                    display: flex;
                    gap    : 15px;
                    margin : 20px 0px;

                    .row-content {
                        display: flex;
                        gap    : 5px;
                    }
                }

                .basic-table {
                    margin-top: 10px;
                    thead{
                      background-color: themed("n_table_header") !important;
                      color           : themed("n_table_text_active") !important;
                      th {
                        padding: 10px 10px;
                      }
                    }
                    tbody {
                      tr {
                        background     : themed("n_table_row") !important;
                        td {
                          height       : 40px;
                          border-bottom: 1px solid themed("n_table_border");
                        }
                      }
                    }

                    .acc-col{
                      width: 10px;
                    }
                  }

                .btn-container {
                    width  : 100%;
                    height : 40px;
                    gap    : 10px;
                    display: flex;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}