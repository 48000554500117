@import "../../../styles/common.scss";

@-webkit-keyframes blinkLoad {
  from {
    border-bottom: 2px solid #1CBBCD;
  }

  to {
    border-bottom: 2px solid #3393EA;
  }
}
.dx-datagrid-borders>.dx-datagrid-headers, .dx-datagrid-borders>.dx-datagrid-rowsview, .dx-datagrid-borders>.dx-datagrid-total-footer {
  background-color: inherit !important;
}
.dx-menu-base {
  // Ẩn menu sorting khi dùng chuột phải trên header
  display: none !important;
}

.dx-datagrid-focus-overlay {
  // Ẩn css khi sử dụng filter
  display: none !important;
}

.dx-error-message {
  display: none !important;
}

.TableDevExtreme {
  display: grid !important;
  .dx-scrollable-content{
    height: 100% !important;
    .dx-scrollview-content{
        display: unset !important;
    }
  }
  .dx-scrollable-container::-webkit-scrollbar {
    height: 8px !important;
    width : 8px !important;
  }

  @include themify($themes) {
    .dx-scrollable-container::-webkit-scrollbar-thumb{ 
      display: none;
      // background: themed("n_table_row") !important;
    }
    .dx-scrollable-container::-webkit-scrollbar-track{
      display: none;
      // background: themed("n_table_row") !important;
    }
    .dx-scrollable-container::-webkit-scrollbar-corner{
      display: none;
      // background: themed("n_table_row") !important;
    }
   
    .dx-scrollable-container:hover::-webkit-scrollbar-thumb { 
      display: inherit;
      background: themed('scrollbar-thumb-bg') !important;
      // background: themed('scrollbar-thumb-hover-bg') !important;
    }
    .dx-scrollable-container:hover::-webkit-scrollbar-corner{
      // display: inherit;
      // background: themed('scrollbar-thumb-bg') !important;
      // background: themed('scrollbar-thumb-hover-bg') !important;
    }
    .dx-freespace-row{
      display: none;
    }
  }

  @include themify($themes) {
    color     : themed("n_header_text_active");
    height    : 100%;
    width     : 100%;
    min-height: 300px;
    max-height: 835px;

    >.dx-visibility-change-handler {
      min-height: inherit;
    }

    .dx-datagrid-borders>.dx-datagrid-rowsview.dx-last-row-border tbody:last-child>.dx-data-row:nth-last-child(2)>td {
      border-bottom-width: inherit !important;
    }

    .dx-datagrid-borders>.dx-datagrid-headers {
      border-top-left-radius : $border_radius;
      border-top-right-radius: $border_radius;
    }

    .dx-datagrid .dx-datagrid-table tbody .dx-row td {
      // display: inline-flexbox !important;
    }

    .dx-datagrid-borders>.dx-datagrid-total-footer,
    .dx-datagrid-borders>.dx-datagrid-rowsview {
      // border-bottom-left-radius : 10px;
      // border-bottom-right-radius: 10px;
    }

    // Chỉ dùng với màn hình giống Sao kê tiền: cần cố định row top và bottom, đưa ra css của màn hình cần sử dụng
    // .dx-datagrid .dx-datagrid-table tbody .dx-row td {
    //   border: inherit !important;
    // }
    // .dx-datagrid-content .dx-row-lines{
    //   border-right: 1px solid themed("n_table_border");
    //   border-left: 1px solid themed("n_table_border");
    // }

    >.dx-visibility-change-handler {
      // height: 100% !important;
    }

    div.dx-datagrid-headers.dx-datagrid-nowrap.dx-header-multi-row>div.dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container.dx-datagrid-content-fixed>table {
      height: 100% !important;
    }

    tr.dx-row.dx-header-row {
      height: 100% !important;
    }

    .dx-datagrid-headers {
      td {
        text-align: center !important;
      }
    }

    .dx-footer-row {
      td.green-TotalItem {
        .dx-datagrid-summary-item {
          color: themed('n_layout_increase_text') !important;
        }
      }

      td.yellow-TotalItem {
        .dx-datagrid-summary-item {
          color: themed('n_price_ref_text') !important;
        }
      }

      td.red-TotalItem {
        .dx-datagrid-summary-item {
          color: themed('n_layout_reduce_text') !important;
        }
      }
    }

    .dx-command-select .dx-checkbox-icon {
      border-color: themed("n_table_check_box");
    }

    .disableCheckbox .dx-command-select input {
      display: none !important;
    }

    .disableCheckbox .dx-command-select .dx-checkbox-icon {
      display: none !important;
    }

    .dx-state-disabled .dx-checkbox-container .dx-checkbox-icon {
      display: none !important;
    }

    .dx-checkbox-container{
      &.hidden-checkbox{
        display: none !important;
      }
    }


    .dx-datagrid-headers .dx-header-row tr td div {
      line-height: 17px !important;
      height     : 17px !important;
    }


    // .dx-datagrid-rowsview .dx-row>td {
    //   // line-height: 13px !important;
    //   // height           : 18px !important;
    //   // line-height   : 7px !important;
    //   // height     : 7px !important;
    //   // div
    // }

    .dx-datagrid-total-footer {
      border-bottom: 1px solid themed("n_table_border");
      border-left  : 1px solid themed("n_table_border");
      border-right : 1px solid themed("n_table_border");

      tr {

        // line-height: 20px !important;
        // height     : 26px !important;
        td {
          line-height: 20px !important;
          height     : 20px !important;
        }
      }
    }

    .dx-datagrid .dx-editor-cell .dx-texteditor .dx-texteditor-input {
      background: themed("n_table_input_filter_bg") !important;

      line-height: 18px !important;
      height     : 22px !important;
    }

    ::selection {
      color     : unset !important;
      background: unset !important;
    }



    &.disable {
      .dx-widget {
        display: none !important;
        height : 100% !important;
      }
    }

    .dx-scrollable-container {
      // .dx-scrollbar-vertical {
      //   .dx-scrollable-scroll {
      //     width: 6px;
      //   }
      // }

      .dx-scrollbar-vertical.dx-state-hover {
        .dx-scrollable-scroll {
          width: 10px !important;
        }
      }

      .dx-scrollbar-vertical .dx-scrollable-scrollbar-active {
        .dx-scrollable-scroll {
          width: 10px !important;
        }
      }


      // .dx-scrollbar-horizontal {
      //   .dx-scrollable-scroll {
      //     height: 6px !important;
      //   }
      // }

      .dx-scrollbar-horizontal.dx-state-hover {
        .dx-scrollable-scroll {
          height: 10px !important;
        }
      }

      .dx-scrollbar-horizontal .dx-scrollable-scrollbar-active {
        .dx-scrollable-scroll {
          height: 10px !important;
        }
      }
    }

    .dx-datagrid-rowsview {
      border-top                : none !important;
      border-bottom             : 1px solid themed("n_table_border") !important;
      border-bottom-left-radius : $border_radius !important;
      border-bottom-right-radius: $border_radius !important;
      background                : themed("n_table_row") !important;

      .dx-datagrid-checkbox-size.dx-state-focused {
        display: none !important;
      }

      .dx-datagrid-checkbox-size {
        .dx-checkbox-icon::before {
          color: themed("n_table_tick_box") !important;
        }
      }
    }

    // .dx-datagrid-headers  .dx-datagrid-scrollable-simulated
    .dx-datagrid-headers .loading:first-child {
      animation   : blinkLoad 1s infinite;
      // animation: inherit !important;
    }

    .dx-placeholder {
      // Ẩn text ...Selector css khi sử dụng pager
      display: none !important;
    }

    .dx-dropdowneditor-overlay {
      // Ẩn css khi sử dụng pager
      display: none !important;
    }

    .dx-datagrid-rowsview .dx-datagrid-table tbody .dx-freespace-row {
      height: 0px !important;
    }

    .dx-datagrid-rowsview .dx-datagrid-table tbody .dx-freespace-row>td {
      display   : none !important;
      visibility: hidden !important;
    }

    .dx-datagrid-rowsview .dx-datagrid-table tbody tr:first-child.dx-freespace-row>td {
      display      : block !important;
      // visibility: hidden !important;
    }

    // .dx-loadpanel-wrapper {
    //   display: none !important;
    // }

    .dx-datagrid-pager {
      margin-top: 0px !important;
      height    : 32px !important;
      color     : themed("n_table_text_hover") !important;
      // display: flex;

      .dx-page-sizes {
        margin-top: -12px !important;

        .dx-selectbox {
          width: 65px !important;
        }
      }

      .dx-pages {
        height    : 30px !important;
        margin-top: -12px !important;

        .dx-navigate-button {
          padding: 9px 8px !important;
          color  : themed("n_table_text_active") !important;
        }
      }

      .dx-page-size {
        padding: 9px 5px !important;
      }

      .dx-selection {
        background-color: inherit !important;
        font-weight     : 700px !important;
        color           : themed("n_table_text_active") !important;
      }
    }

    .dx-pages-count {
      display: none;
    }

    .dx-light-pages {
      pointer-events: none;
    }

    .PagerAll {
      .dx-light-pages {
        pointer-events: inherit !important;
      }
    }

    .dx-pages-count:before {
      content: "/";
    }

    .dx-texteditor-container {
      height: 22px;
    }

    .dx-info-text {
      display: none;
    }

    .dx-widget {
      // width    : 100% !important;
      max-width    : unset !important;
      border-radius: $border_radius;
      max-height   : inherit;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
      // background-color: rgba(3,169,244,.07); // Mặc định
      background-color: inherit !important;
    }

    .dx-widget.dx-select-checkbox.dx-state-focused {
      // background-color: red !important;
    }

    .dx-checkbox-checked .dx-checkbox-icon {
      // background-color: #03a9f4;  // Mặc định
      background: themed("n_layout_primary") !important;
      color     : themed("n_text_custom") !important;
    }

    .dx-datagrid {
      background-color: themed("n_table_header") !important;
      color           : themed("n_table_text_active") !important;
      border-radius   : $border_radius;

      .dx-datagrid-filter-row {
        background-color: themed("n_table_bg");

        .dx-texteditor-input-container {
          input {
            border: inherit !important;
          }
        }

        td {
          background: themed("n_table_row_even") !important;
        }
      }

      .dx-editor-cell .dx-texteditor .dx-texteditor-input {
        background      : themed("n_table_input_filter_bg");
        border          : 1px solid themed("n_table_border");
        line-height     : 24px;
        color           : themed("n_table_text_active");
        display         : block;
        // height       : 24px;
        border-radius   : $border_radius;
        font-size       : 13px;

        ::selection {
          color     : unset;
          background: unset;
        }
      }

      // Sửa lỗi border khi group column
      .dx-datagrid-headers tr {
        td[rowspan='2'] {
          border-top: inherit !important;
        }

        td[colspan] {
          border: inherit !important;
          border-right: 1px solid themed("n_table_border") !important;
        }

        td[colspan] + td {
          &[colspan]{
            border-right: 1px solid themed("n_table_border") !important;
          }
        }
      }


      .dx-datagrid-table {
        &::selection {
          color     : unset !important;
          background: unset !important;
        }

        tbody {
          &::selection {
            color     : unset !important;
            background: unset !important;
          }

          .dx-header-row {
            .dx-datagrid-action {
              border: 1px solid themed("n_table_border");
            }
          }

          .dx-row {
            td {
              border          : 1px solid themed("n_table_border");
              padding         : 5px !important;
              border-left     : 0;
              // border-bottom: 0;
              font-family     : 'Helvetica Neue Regular';
              font-weight     : 400; // Body Small - Regular (7)
              font-size       : 12px;
              line-height     : 18px;
              height          : 100%;
              overflow        : visible !important;

              &::selection {
                color     : unset !important;
                background: unset !important;
              }

              .action-container {
                line-height: 23px !important;
                height     : 23px !important;
                .block-btn{
                  cursor: pointer;
                }
                button{
                  &:focus{
                    outline: none !important;
                    border: none !important;
                  }
                }
              }

              button.btn-action {
                padding    : 5px;
                font-family: 'Helvetica Neue Regular';
                font-weight: 400; // Body Small - Regular (7)
                font-size  : 12px;
                line-height: 6px;
              }

              div {
                line-height: 23px !important;
                height     : 23px;
              }
            }
          }
        }
      }

      .dx-datagrid-content-fixed .dx-datagrid-table .dx-col-fixed {
        background-color: themed("n_table_row_odd") !important;
      }

      .dx-datagrid-total-footer {
        position     : relative;
        // border  : 1px solid themed("n_table_border");
        border-radius: 0px 0px $border_radius $border_radius;

        .dx-datagrid-scrollable-simulated {
          border-radius: 0px 0px $border_radius $border_radius;
        }

        .dx-datagrid-summary-item {
          color      : themed('n_layout_text_active') !important;
          font-weight: bold;
          //text-align : right !important;
        }
      }

      .dx-datagrid-rowsview {
        border-top: 1px solid transparent;
        border    : 1px solid themed("n_table_border");
      }

      .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none {
        border-left: 1px solid transparent;
      }

      .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort,
      .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator,
      .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
        color: themed("n_table_text_active") !important;
      }

      .dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row>td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit) {
        background-color: inherit !important;
      }

      .dx-datagrid-headers {
        color : themed("n_table_text_active") !important;
        border: 1px solid themed("n_table_border");

        .dx-datagrid-scrollable-simulated {
          border-radius: $border_radius;
        }

        .dx-row td.dx-pointer-events-none {
          border-left: 1px solid transparent;
        }

        .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
          color: themed("n_table_text_active") !important;
        }


        &.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row>td {
          border        : 1px solid themed("n_table_border");
          border-left   : 0;
          vertical-align: middle;
        }

        .dx-datagrid-table {
          .dx-error-row {
            display: none !important;
          }
        }

        .dx-datagrid-checkbox-size.dx-state-focused {
          display: none !important;
        }

        .dx-datagrid-checkbox-size {
          .dx-checkbox-icon::before {
            color: themed("n_table_tick_box") !important;
          }

          .dx-checkbox-icon::after {
            color: themed("n_table_tick_box") !important;
          }
        }

        .dx-datagrid-checkbox-size[aria-checked="mixed"] {
          .dx-checkbox-icon {
            // css để ẩn giao diện [-] nếu return false
            background-color: themed("n_table_header") !important;
            border          : 2px solid themed("n_table_check_box") !important;
          }

          .dx-checkbox-icon::before {
            // css để ẩn giao diện [-] nếu return false
            content: none;
          }


          .dx-checkbox-icon::after {
            // css để ẩn giao diện [-] nếu return false
            content: none;
          }
        }

      }

      .dx-datagrid-rowsview .dx-row>td {
        // line-height: 18px !important;
      }

      .dx-datagrid-rowsview .dx-datagrid-table tbody tr:nth-child(2n+1) {
        //background: themed("n_table_row_even") !important;
        background: themed("n_table_row") !important;

        &.dx-selection {
          background: themed("n_table_bg_active") !important;
        }
      }

      .dx-datagrid-rowsview .dx-datagrid-table tbody tr:nth-child(2n) {
        //background: themed("n_table_row_odd") !important;
        background: themed("n_table_row") !important;

        &.dx-selection {
          background: themed("n_table_bg_active") !important;
        }
      }
    }

    .dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
    .dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
    .dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
    .dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
    .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
      border-left: 0px solid transparent !important;
    }

    //fix lỗi BSCWEB-426
    .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content-fixed {
      z-index: 0;
    }


    //fix action hủy
    .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-command-select,
    .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-select,
    .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-select {
      width    : 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;

      button {
        color: themed('n_text_custom') !important;
      }

      &.dx-command-select-cancel {
        width    : 100px !important;
        min-width: 100px !important;
        max-width: 100px !important;

        .btn-action {
          height       : 24px !important;
          padding      : unset !important;
          margin-bottom: 0px !important;
          width        : 100%;
        }
      }

      &.dx-command-select-confirm {
        width    : 120px !important;
        min-width: 120px !important;
        max-width: 120px !important;

        .btn-confirm {
          height       : 24px !important;
          padding      : unset !important;
          margin-bottom: 0px !important;
          width        : 100%;
        }

        .btn-common-table{
          height       : 24px !important;
          padding      : unset !important;
          margin-bottom: 0px !important;
          width        : 100%;
        }
      }
    }

    // Scroll
    .dx-scrollbar-vertical.dx-scrollbar-hoverable {
      cursor       : pointer;
      width        : 10px;
      border-radius: 10px;
    }

    .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll {
      width     : 10px !important;
      transition: background-color .05ss linear 0.1s, width 0.1s linear 0.05s;
    }

    .dx-scrollbar-horizontal.dx-scrollbar-hoverable {
      cursor: pointer;
      height: 10px;
    }

    .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
      border-radius: 10px !important;
    }

    .dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll {
      height    : 10px;
      transition: box-shadow 0.05s linear 0.1s, background-color 0.05s linear 0.1s, -webkit-box-shadow 0.05s linear 0.1s !important;
    }

    .dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
      border-radius: 10px !important;
      transition   : box-shadow 0.05s linear 0.1s, background-color 0.05s linear 0.1s, -webkit-box-shadow 0.05s linear 0.1s !important;
    }
  }

  .dx-datagrid-pager {
    .dx-texteditor.dx-editor-filled {
      &::after {
        border-bottom: none !important;
      }
    }

    .dx-pages {
      .dx-page-indexes {
        .dx-page {
          padding: 9px 5px !important;
        }

        .dx-light-pages {
          .dx-page-index {
            .dx-texteditor-container {
              .dx-texteditor-input-container {
                input {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}


.TableDevExtreme.Filter {
  @include themify($themes) {
    .dx-datagrid {

      .dx-datagrid-rowsview .dx-datagrid-table tbody tr:nth-child(2n+1) {
        //background: themed("n_table_row_odd") !important;
        background: themed("n_table_row") !important;

        &.dx-selection {
          background: themed("n_table_bg_active") !important;
        }
      }

      .dx-datagrid-rowsview .dx-datagrid-table tbody tr:nth-child(2n) {
        //background: themed("n_table_row_even") !important;
        background: themed("n_table_row") !important;

        &.dx-selection {
          background: themed("n_table_bg_active") !important;
        }
      }
    }
  }
}

.TableDevExtreme.StickyRow {
  @include themify($themes) {
    .dx-scrollable-wrapper tr:nth-child(n).sticky-0 {
      position: sticky !important;
      top     : -1px !important;
    }

    .dx-datagrid-content tr:nth-child(n).sticky-0 {
      position: sticky !important;
      top     : -1px !important;
    }

    .dx-scrollable-wrapper tr:nth-child(n).sticky-1 {
      position: sticky !important;
      bottom  : -1px !important;
    }

    .dx-datagrid-content tr:nth-child(n).sticky-1 {
      position: sticky !important;
      bottom  : -1px !important;
    }

    .dx-datagrid-rowsview .dx-datagrid-table tbody tr:nth-child(n).sticky-0 {
      //background   : themed("n_table_row_even") !important;
      // box-shadow: 0px 0px 5px 5px themed("n_table_row_even") !important;
      background: themed("n_table_row") !important;
    }

    .dx-datagrid-rowsview .dx-datagrid-table tbody tr:nth-child(n).sticky-1 {
      //background   : themed("n_table_row_odd") !important;
      // box-shadow: 0px 0px 5px 5px themed("n_table_row_even") !important;
      background: themed("n_table_row") !important;
    }
  }

}



html.chrome {
  .theme-light {
    .TableDevExtreme {
      .dx-datagrid {
        .dx-datagrid-table {
          tbody {
            .dx-row {
              td {
                font-weight: 600;
                color: #454B56;

                button.btn-action {
                  font-weight: 600; // Body Small - Regular (7)
                }
              }
            }
          }
        }
      }
    }
  }
}