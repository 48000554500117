body, .modal-open .modal {

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:vertical {
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track:horizontal {
    background-clip: padding-box
  }

  &::-webkit-scrollbar-track:vertical {
    background-color: rgba(255, 255, 255, 0.07);
    -webkit-box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.28), inset -1px -1px 0 rgba(255, 255, 255, 0.14)
  }

  &::-webkit-scrollbar-track:horizontal {
    background-color: rgba(255, 255, 255, 0.07);
    -webkit-box-shadow: inset -1px 1px 0 rgba(255, 255, 255, 0.28), inset 1px -1px 0 rgba(255, 255, 255, 0.14)
  }

  &::-webkit-scrollbar-track:vertical:active {
    background-color: rgba(255, 255, 255, 0.10);
    -webkit-box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.28), inset -1px -1px 0 rgba(255, 255, 255, 0.14)
  }

  &::-webkit-scrollbar-track:horizontal:active {
    background-color: rgba(255, 255, 255, 0.10);
    -webkit-box-shadow: inset -1px 1px 0 rgba(255, 255, 255, 0.28), inset 1px -1px 0 rgba(255, 255, 255, 0.14)
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-clip: padding-box;
    min-height: 28px
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-clip: padding-box;
    min-width: 28px
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.10), inset 0 -1px 0 rgba(255, 255, 255, 0.07)
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.50)
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.6)
  }
}
