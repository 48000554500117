@import "../../../styles/common";

:root {
  --openPosition-mobile-height: calc(100vh - 105px);
}


.open-positions-new {
  width  : 100%;
  height : 100%;
  padding: 0px 10px 10px 10px;

  .sell-all-btn{
    // position: absolute;
    // top: 74px;
    // left: 17px;
    // z-index: 10;
    padding: 2px 10px 2px 10px !important;
    border: 1px solid transparent !important;
  }

  .TableDevExtreme{

    .dx-datagrid-table-fixed{
      transform: translate(0px, 0px) !important;
    }

    .dx-hidden-cell{
      visibility: visible !important;
    }

    .dx-datagrid-table-fixed{
      z-index: 2 !important;
    }

    .dx-datagrid-content-fixed{
      z-index: 2 !important;
    }

    tr.dx-row.dx-header-row {
      height: 33.5px !important;
    }
    .dx-data-row {
      td{
      color: hsla(0,0%,100%,.7);
      }
    }
  }

  .table-open-positions-new {
    padding: 0px;
    width  : 100%;
    height : 100%;

    .open-positions-table-new-tableEx {
      @include themify($themes) {

        .action-container {
          display           : flex;
          gap               : 5px;
          padding           : 0px;
          // justify-content: space-between;

          >div {
            width: 50%;
          }

          &.action-container-position {
            >div {
              // width: calc(100% / 3);
            }
          }

          .block-btn {
            padding      : 0px 5px;
            border-radius: $border_radius;

            &.block-btn-buy {
              background: rgba(themed("n_layout_green_custom"), 0.15);
            }

            &.block-btn-sell {
              background: rgba(themed("n_layout_red_custom"), 0.15);
            }

            &.block-btn-tpsl {
              background: themed("n_layout_border");

              &.active {
                .tpsl-button {
                  color: themed("n_price_ref_text");
                }
              }
            }

            .sell-button {
              outline   : unset;
              box-shadow: unset;
              border    : unset;
              background: none;
              color     : themed("n_layout_red_custom");
            }

            .buy-button {
              outline   : unset;
              box-shadow: unset;
              border    : unset;
              background: none;
              color     : themed("n_layout_green_custom");
            }

            .tpsl-button {
              outline   : unset;
              box-shadow: unset;
              border    : unset;
              background: none;
              color     : themed("n_layout_text_active");
            }
          }
        }
      }
    }
  }
}