@import "../../../../styles/common";

.condition-order-guide {
    max-width: 70vw !important;
    height: 70vh !important;

    .txt---500-14-22{
        font-family: 'Helvetica Neue Medium';
        font-weight: 500 !important; // Body Medium - Medium (4)
        font-size  : 14px !important;
        line-height: 22px !important;

        b,strong {
            font-family: 'Helvetica Neue Bold' !important;
        }
    }

    .content {
        padding-right: 0px;
    }

    .modal-content {
        .body {
            padding: 0px !important;
        }
    }
    @include themify($themes) {
        .signal-name {
            display: inline-block;
            width: 200px !important;
            padding-right: 0px;
            margin-left: 10px;
            .item-signal-type {
                position: relative;
                padding: 10px 10px 10px 5px;
                color: themed("n_layout_text_near_active") !important;
                cursor: pointer;
                &.active {
                    // background: themed("n_form_bg");
                    background: themed('tooltip-bg-custom') !important;
                }

                .base-type-dot {
                    position: absolute;
                    right: 15px;
                    height: 6px;
                    width: 6px;
                    border-radius: 9999px;
                    background-color: themed('n_layout_reduce_text');
                    top: 15px;
                }
            }

            border-right: 1px solid themed("n_layout_primary");
            height: 70vh;
        }

        .description {
            padding: 10px;
            height: 70vh;
            overflow-y: auto;
            white-space: pre-line;
            margin-bottom: 10px;
            text-align: justify;

            display: inline-block;
            width: calc(100% - 210px);

            p{
                margin: 0px !important;
            }
        }

        .footer{
            text-align: right;
            padding: 0px;
            margin-top: 15px;
            margin-bottom: 15px;

            button{
                padding: 2px 10px;
                width: 140px;
            }
        }
    }
}
