@import "../../../../styles/common";

.modal-quick-order-condition-detail {
    @include themify($themes) {
        max-width       : 1200px;
        margin          : auto;
        height          : auto;
        right           : 0px;
        box-shadow      : unset;
        background-color: unset;

        &.quick-order {
            box-shadow      : unset;
            background-color: unset;
        }

        .body {
            background: themed("n_layout_bg_block");
            padding   : 20px;
            height    : 60vh;
            overflow  : auto;

            .wrap-body {

                .table-order-book {
                    .basic-table {
                        // transition        : all 2s;
                        // -webkit-transition: all 2s;
                        border    : none;
                        background: themed("n_table_bg");

                        thead {
                            tr {
                                padding      : 10px 0px;
                                border-bottom: 1px solid themed("n_table_border");
                                position     : sticky;
                                top          : 0;
                                z-index      : 10;
                                background   : themed("n_table_bg");
                            }
                        }

                        tbody {
                            tr {
                                cursor: pointer;

                                td {
                                    padding      : 10px 0px;
                                    border-bottom: 1px solid themed("n_table_border");

                                    .status-order {
                                        position: relative;

                                        .status-icon {
                                            position     : absolute;
                                            background   : #FC944D;
                                            border-radius: 50%;
                                            height       : 8px;
                                            width        : 8px;
                                            top          : 7px;
                                            left         : -15px;
                                        }
                                    }
                                }
                            }

                            .block-info-detail-order {
                                border-radius: 10px;

                                &.active {}

                                &.not-active {
                                    display: none;
                                }

                                td {

                                    .info-detail-order {

                                        .time-order {
                                            border-bottom  : 1px solid themed("n_table_border");
                                            background     : themed("n_layout_active_bg");
                                            border-radius  : $border_radius $border_radius 0px 0px;
                                            padding        : 5px 8px;
                                            display        : flex;
                                            justify-content: space-between;

                                            .value-time-order {
                                                display: flex;
                                                gap    : 5px;
                                            }
                                        }

                                        .wrap-content {
                                            width        : 100%;
                                            display      : flex;
                                            background   : themed("n_layout_active_bg");
                                            border-radius: 0px 0px $border_radius;

                                            .content-order {
                                                width  : 50%;
                                                padding: 0px 8px;

                                                .row-content {
                                                    display        : flex;
                                                    padding        : 2px 0px;
                                                    justify-content: space-between;
                                                    border-bottom  : 1px solid themed("n_table_border");
                                                }
                                            }
                                        }

                                        .action-order {
                                            margin-top: 10px;

                                            .container-btn {
                                                display: flex;
                                                gap    : 10px;

                                                .btn-action {
                                                    width: 50%;
                                                    color: themed("n_layout_text_active");

                                                    &.btn-fix {
                                                        background: themed("n_layout_primary");
                                                    }

                                                    &.btn-cancel {
                                                        background: themed("n_layout_reduce_bg");
                                                    }

                                                    &:disabled {
                                                        background: themed("n_layout_btn_bg");
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}