@import "../../../styles/common.scss";

.timerContainer {
    display       : flex;
    flex-direction: row;
    align-items   : center;

    .icon-clock {
        font-size   : 16px;
        margin-right: 5px;
    }

    // .time {
    //     color: #0f0;
    // }
}