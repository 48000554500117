@import "../../../styles/common";

.TableDevExtreme {
  @include themify($themes) {

    .style-text-ellipsis {
      text-overflow: ellipsis;
      white-space  : nowrap;
      overflow     : hidden;
    }

    .dx-datagrid .dx-datagrid-table tbody .dx-row td .style-text-not-ellipsis {
      white-space: normal;
      height     : unset !important;
    }

    .action-order-putthrough {
      .btn-confirm {
        background: rgba(themed("n_layout_green_custom"), 0.15) !important;
        color     : themed("n_layout_green_custom") !important;
      }

      .btn-cancel {
        background: rgba(themed("n_layout_red_custom"), 0.15) !important;
        color     : themed("n_layout_red_custom") !important;
      }
    }

    .action-order {
      display           : flex;
      gap               : 5px;
      padding           : 0px;
      // justify-content: space-between;

      >div {
        width: 50%;
      }

      .block-btn {
        padding      : 0px 5px;
        border-radius: $border_radius;

        &.block-btn-cancel {
          background: rgba(themed("n_layout_red_custom"), 0.15);
        }

        &.block-btn-edit {
          background: themed("n_layout_border");
        }

        &.block-btn-copy {
          background: themed("n_layout_border");
        }

        .action-edit {
          outline   : unset;
          box-shadow: unset;
          border    : unset;
          background: none;
          color     : themed("n_layout_text_active");
        }

        .action-cancel {
          outline   : unset;
          box-shadow: unset;
          border    : unset;
          background: none;
          color     : themed("n_layout_red_custom");
        }

        .action-copy {
          outline   : unset;
          box-shadow: unset;
          border    : unset;
          background: none;
          color     : themed("n_layout_text_active");
        }
      }
    }
  }
}