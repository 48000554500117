@import "../../../styles/common";



.ticket-item {
  @include themify($themes) {

    background-color: themed(n_form_bg);

    border-radius: 4px;

    @include media-breakpoint-only(xl) {
      flex     : 1 0 360px;
      min-width: calc(25% - 8px);
      max-width: calc(25% - 8px);

    }

    @include media-breakpoint-only(lg) {
      flex     : 1 0 360px;
      min-width: calc(100% / 3 - 8px);
      max-width: calc(100% / 3 - 8px);
    }

    @include media-breakpoint-only(md) {
      flex     : 1 0 360px;
      min-width: calc(100% / 2 - 8px);
      max-width: calc(100% / 2 - 8px);
    }

    @include media-breakpoint-only(sm) {
      flex     : 1 0 360px;
      min-width: calc(100% / 2 - 8px);
      max-width: calc(100% / 2 - 8px);
    }

    @include media-breakpoint-only(xs) {
      flex     : 1 0 360px;
      min-width: calc(100% - 8px);
      max-width: calc(100% - 8px);
    }

    &.isOpenSidebar {
      @include media-breakpoint-only(xl) {
        flex     : 1 0 360px;
        min-width: calc(100% / 3 - 8px);
        max-width: calc(100% / 3 - 8px);
      }
    }

    .ticket-header {
      padding: 5px 10px 25px 10px;
      display: flex;

      position: relative;

      .ticket-combo-title {
        display       : flex;
        flex-direction: column;

        .ticket-title {
          .icon-info {}
        }

        .description {
          .price {}
        }

      }

      .ticket-image {
        display        : flex;
        justify-content: center;
        align-items    : center;

        border-radius   : 10px;
        width           : 40px;
        height          : 40px;
        // background   : themed(n_voucher_img_bg);

        &.in-effect {
          padding-top: 3px;
        }

        img {
          width : 100%;
          height: 100%;
        }
      }

      .dot-ticket-left {
        width        : 12px;
        height       : 12px;
        border-radius: 50%;
        background   : themed(n_layout_bg_block);

        position: absolute;
        bottom  : -6px;

        left: -6px;

        &.in-effect {
          bottom: -6px;
        }
      }

      .dot-ticket-right {
        width        : 12px;
        height       : 12px;
        border-radius: 50%;
        background   : themed(n_layout_bg_block);

        position: absolute;
        bottom  : -6px;
        right   : -6px;

        &.in-effect {
          bottom: -6px;
        }
      }
    }

    .ticket-body {
      padding   : 4px 10px 5px 10px;
      border-top: 1px dashed rgba($color: themed(n_header_text_active), $alpha: .5);

      .ticket-property {
        display        : flex;
        justify-content: space-between;
        margin-bottom  : 12px;

        .value-ticket-property {
          &.price {}
        }
      }

      .btn-register {
        width        : 100%;
        height       : auto;
        font-size    : 16px;
        padding      : 8px 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        color        : themed("n_text_custom");

        background: themed('n_header_btn_primary');

        &:disabled {
          background: themed(n_layout_bg_block);
          cursor    : not-allowed;
        }
      }

    }
  }

}