@import "../../../styles/common";

.customer-list-debt-margin {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .customer-list-debt-margin-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }
    }
}