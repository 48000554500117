@import "../../styles/common.scss";

.select-search-by-suggest {
  @include themify($themes) {
    max-height: 300px;
    position  : relative;
    width     : 100%;

    .suggest-input-container {
      margin  : 0;
      padding : 0;
      //height: 38px;
    }

    .suggest-input-container-label {
      margin : 0px;
      padding: 0px;

      .custom-form-group {
        margin : 0px !important;
        padding: 0px;
      }
    }

    .icon {
      background         : themed('form-control-select-bg-img1') no-repeat;
      background-position: right;
    }

    #suggestion-box {
      max-height      : 200px;
      overflow-x      : hidden;
      overflow-y      : scroll;
      position        : absolute;
      top             : 33px;
      width           : 100%;
      z-index         : 22;
      background-color: themed("n_layout_bg_block");

      .suggest-item {
        line-height  : 26px;
        height       : auto;
        padding      : 2px 2px 2px 5px;
        text-align   : left;
        border-bottom: 1px solid themed("n_layout_btn_bg");

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}