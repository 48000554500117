@import "../../../../styles/common";

.cancel-right-list {
  @include themify($themes) {
    height: 400px;

    .cancel-right-list-content {
      height        : 100%;
      display       : flex;
      flex-direction: column;
    }

    .container-table {
      height  : 100%;
      overflow: scroll;
    }

    .btn {
      background: themed("n_layout_primary");
      padding   : 6px 10px;
      height    : 100%;
    }

    .bold {
      font-weight: bold;
    }

    .action-container {
      display        : flex;
      flex-direction : row;
      justify-content: space-between;
      padding-bottom : 10px;
    }

    .btn-cancel {
      outline   : unset;
      box-shadow: unset;
      border    : unset;
      background: none;
      color     : themed("n_layout_reduce_text");
      background: rgba(themed("n_layout_reduce_bg"), 0.15) !important;
      padding: 4px;
      width: 100%;
      height: 100%;
    }

    .securities-transaction-report-content {
      display       : flex;
      flex-direction: column;
      height        : 100%;
    }

    .search-container {
      margin   : 0 auto 8px auto;
      max-width: 590px;

      .custom-form-group {
        width: 100%;
      }

    }

    .container-table {
      height  : 100%;
      overflow: hidden !important;
    }
  }
}