@import "../../../styles/common";

.style-symbol-detail-analysis {
    @include themify($themes) {
        .style-load {
            padding-bottom: 10px;
            cursor        : pointer;
        }

        .market-info-news-item {
            cursor        : pointer;
            padding-bottom: 10px;

            .image-container {
                margin-left: 15px;
                width      : 80px;
                height     : 57px;
            }

            .txt-container {
                width       : calc(100% - 100px); // 73: width của image-container
                padding-left: 10px;
            }


            img {
                border-radius: 4px;
                width        : 100%;
                height       : 58px;
            }

            p {
                margin-bottom: 0px;
            }

            .news-header {
                min-height : 40px;
                color      : themed("n_layout_text_near_active");
                display    : flex;
                align-items: center;
            }

            .news-date {
                display    : flex;
                align-items: center;
                color      : themed("n_layout_text_not_active");
            }
        }
    }
}