@import "../styles/common";

.lds-gear {
  @include themify($themes) {
    .machine {
      fill: themed("n_layout_text_active");
    }
  }

  width          : 100%;
  height         : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;

  .machine {
    width : 100%;
    height: 100%;
  }

  .small-shadow,
  .medium-shadow,
  .large-shadow {
    fill: rgba(0, 0, 0, 0.05);
  }

  .small {
    animation       : counter-rotation 2.5s infinite linear;
    transform-origin: 100.136px 225.345px;
  }

  .small-shadow {
    animation       : counter-rotation 2.5s infinite linear;
    transform-origin: 110.136px 235.345px;
  }

  .medium {
    animation       : rotation 3.75s infinite linear;
    transform-origin: 254.675px 379.447px;
  }

  .medium-shadow {
    animation       : rotation 3.75s infinite linear;
    transform-origin: 264.675px 389.447px;
  }

  .large {
    animation       : counter-rotation 5s infinite linear;
    transform-origin: 461.37px 173.694px;
  }

  .large-shadow {
    animation       : counter-rotation 5s infinite linear;
    transform-origin: 471.37px 183.694px;
  }

  @include keyframes('rotation') {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  @include keyframes('counter-rotation') {
    from {
      transform: rotate(359deg);
    }

    to {
      transform: rotate(0deg);
    }
  }
}