@import "../styles/common.scss";

.transauth-auth2 {

  padding: 8px 0;

  @include themify($themes) {
    .trans-auth {
      margin-top: 12px;
      margin-bottom: 5px;

      .custom-form-group, .custom-form-group-effect{
        margin-bottom: 12px;

        &.save-auth{
          border: none;
          text-align: right;
          margin-bottom: 0px !important;
        }
      }

      .btn-action {
        margin     : 5px 0;
        padding    : 0 15px;
        border     : none;
        font-size  : $font-size-regular;
        line-height: 28px;
        width      : 100px;
        @include border-radius(0);
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;



        &[disabled] {
          opacity: 0.5;
        }
      }

      .off-effect {

        &:hover {
          border-bottom: none !important;
        }

      }

      .off-effect:focus {
        border-bottom: none !important;
      }

      .btn-re-send-otp {
        width      : 100%;
        margin     : 5px 0;
        padding    : 0 5px;
        height     : 28px;
        border     : none;
        font-size  : $font-size-regular;
        line-height: 28px;
        @include border-radius(0);
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;

        &[disabled] {
          opacity: 0.5;
        }
      }

      .btn-re-send-otp-new {

        text-decoration   : underline;
        justify-content   : flex-end;
        width             : 100%;
        // margin         : 5px 0;
        // padding        : 0 5px;
        height            : 32px;
        border            : none;
        font-size         : $font-size-regular;
        line-height       : 28px;
        @include border-radius(0);
        white-space     : nowrap;
        // overflow     : hidden;
        text-overflow   : ellipsis;
        cursor          : pointer;
        display         : flex;
        color           : themed("n_btn_active");

        &[disabled] {
          opacity: 0.5;
        }

      }

      .custom-form-control {
        height: 100% !important;

        &[disabled] {

          background-color      : themed("n_layout_bg_block");
          // padding-top     : 5px;
          // padding-bottom  : 5px;
          // height             : 43px;
        }

      }

      .h-auth {
        height: 32px !important;
      }

      .ca-nocaloaded {}

      .pin-input-container {
        // padding-top   : 5px;
        // padding-bottom: 5px;

        &.editable {

          &.enable-edit {
            .custom-form-control {
              // background-color: red;
            }
          }

          .btn-edit,
          .btn-save {
            border    : none;
            background: transparent;
          }

          .btn-edit {
            color: themed("n_price_ref_text");
          }

          .btn-save {
            color: themed("n_layout_primary");
          }

          &.editing {
            .custom-form-control {
              // background-color: #fc0390;
            }
          }
        }

        &.editable {
          position: relative;

          .btn-edit,
          .btn-save {
            position   : absolute;
            top        : 0;
            right      : 0;
            width      : 28px;
            height     : 28px;
            border     : none;
            background : transparent;
            line-height: 28px;
            font-size  : 14px;
            margin-top : 0px;
            //position: relative;

            i {
              position: absolute;
              top     : 7px;
              left    : 3px;
              width   : 0px;
            }
          }

          .btn-save {
            display: none;
          }

          &.editing {
            .btn-edit {
              display: none;
            }

            .btn-save {
              display: block;
            }
          }
        }

      }

      .serial-container {
        padding     : 5px;
        margin-left : 0px;
        margin-right: 0px;
        margin-top  : 10px;

        div {
          padding: 0px;
        }
      }
    }

    .container-confirm-auth {
      display: flex;
      gap    : 5px;
      padding: 10px 0px 0px 0px;

      .icon-confirm {
        height       : 20px;
        width        : 20px;
        border-radius: 50%;
        border       : 2px solid themed('n_layout_increase_text');

        i {
          font-weight: 600;
          font-size  : 10px;
        }
      }
    }

    .trade-auth-btn-container {
      margin : 10px 0px;
      display: flex;
      gap    : 10px;

      .btn-action {
        width: 50%;
      }
    }

    .mb-10px {
      margin-bottom: 10px;
    }
  }
}