@import "../styles/common";

.modal-check-version {

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    margin: auto !important;
  }

  @include themify($themes) {
    width: 677px;
    max-width: 700px !important;

    header {
      font-size  : $font-size-big;
      font-weight: 700;
      padding    : 0 10px;
      height     : 50px;
      line-height: 50px;
      width: 100%;
  
      button {
        float       : right;
        display     : block;
        margin-right: -10px;
        width       : 20px;
        height      : 20px;
        border      : none;
        padding     : 0;
        text-align  : center;
        font-size   : $font-size-big + 2px;
        background  : transparent;
      }
    }

    .bg-btn-submit {
      background: themed('n_header_btn_primary');
    }

    .confirm-body {
      background    : themed("n_layout_bg_block");
      border-radius : 4px !important;
      display       : flex;
      flex-direction: column;
      align-items   : center;
      padding       : 5px 20px 30px 20px;

      &.waiting{
        overflow: hidden;
        height: 321px !important;
        padding: 0px !important;
        width: 677px;

        @include media-breakpoint-down(sm) {
          width: 100% !important;
          height: 400px !important;
        }

        iframe{
          @include media-breakpoint-down(sm) {
            width: 110% !important;
            margin-left: -10px;
            height: 450px;
          }
          width: 700px;
          height: 500px;
          margin-top: -115px;
        }

      }


      .title {
        margin: 16px 0px 10px 0px;
      }

      .content {
        color        : themed('n_layout_text_near_active');
        margin-bottom: 16px;
        text-align   : center;
      }

      .btn {
        width: 100%;
        cursor: pointer;
      }

      .container-btn {
        margin-top: 16px;
        display   : flex;
        gap       : 8px;
        flex-wrap : nowrap;
        width     : 100%;
      }
    }
  }
}