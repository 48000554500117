@import "../../../../styles/common";

.group-confirm-order {
    @include themify($themes) {
        background: themed('n_layout_bg_header_block');
        height    : 100%;

        .block-custom-tab {
            height: 100%;
        }

        .tab {
            display: flex;
            gap    : 15px;
            height : 40px;
            padding: 10px 10px 0px 10px;

            background: themed('n_layout_bg_header_block');

            .sub-tab {
                padding            : 5px 0px;
                // background-color: themed('n_form_bg');
                cursor             : pointer;
                // border-radius   : 4px;

                opacity: 0.7;

                &.active {
                    opacity: 1;
                }
            }
        }

        .main-content {
            &.broker {
                // height: calc(100% - 40px);

                .order-history {
                    border-top-left-radius : unset !important;
                    border-top-right-radius: unset !important;
                }

                .confirm-order-broker-content {
                    border-top-left-radius : unset !important;
                    border-top-right-radius: unset !important;
                }
            }
        }
    }
}