// @import "../../styles/common";
@import "../../../styles/common";

.assets-container {

    @include themify($themes) {
        .block-1 {
            // background-image     : themed("common_bg_img_quickorder");
            // background-image   : url("../../../assets/images_new/bg_quickoder.png");
            margin-top: 10px;
            // background   : linear-gradient(180deg, #3A4FAC 0%, #9E44E4 100%);
            background   : #F27801;
            background-position: right top;
            background-repeat  : no-repeat;
            display            : block;
            flex-direction     : column;
            justify-content    : space-between;
            position           : relative;
            background-size    : cover;
            height: 80px;
            border-radius: 4px;

            .row-block-1 {
                display        : flex;
                justify-content: space-between;
                padding        : 8px 10px;
                color          : themed("n_text_custom");
            }

            .block-wrap {
                background-color: themed("n_layout_bg_block");
                border-radius   : 15px 15px 0px 0px;

                .block-content {
                    // padding: 10px 10px 0px 10px;

                    .block-2-scroll-container {
                        height: calc(100vh - 310px) !important;
                        margin: 10px 0px 0px 0px;
                    }

                    .block-2 {
                        padding    : 10px;
                        // height  : calc(100vh - 310px);
                        // overflow: auto;

                        .row-block-2 {
                            border-top     : 1px solid themed("n_layout_border");
                            padding        : 12px 0px;
                            display        : flex;
                            justify-content: space-between;

                            &.row-block-first {
                                border-top : none;
                                padding-top: 0px;
                            }
                        }

                        .container-icon {
                            width          : 20px;
                            height         : 20px;
                            justify-content: center;
                            display        : flex;
                            align-items    : center;
                        }

                        .ml-20 {
                            margin-left: 20px;
                        }

                        .cursor-pointer {
                            cursor: pointer;
                        }
                    }

                    .label {
                        color: themed("n_layout_text_not_active");
                        &.title{
                            color  : themed("n_layout_text_active");
                        }
                    }

                    .value {
                        color  : themed("n_layout_text_active");
                        display: flex;
                    }
                }
            }
        }
    }
}