@import "../../styles/common";

.fss.isMobile {
    .search-dropdown {
        .search-container {
            top    : 50px;
            width  : 100%;
            height : calc(100vh - 50px);
            right  : 0;
            padding: 10px;

            .container-button {
                display: inherit;

                button.btn-custom {
                    margin-right: 10px;
                }
            }
        }
    }
}

.search-dropdown {
    @include themify($themes) {
        #btn-login-account-selector-id {
            cursor: pointer;
        }

        .search-container {
            z-index           : 1000;
            background-color  : themed('n_layout_bg_block');
            box-shadow        : 0px 4px 20px themed("n_layout_box_shadow_bg");
            -webkit-box-shadow: 0px 4px 20px themed("n_layout_box_shadow_bg");
            position          : absolute;
            right             : 10px;
            top               : 50px;
            width             : 600px;
            padding           : 16px;
            height            : 600px;
            border-radius     : 0px 0px $border_radius $border_radius;


            .search-bar {
                width            : 100%;
                background       : themed('n_layout_bg_block');
                color            : themed("n_layout_text_active");
                border           : none;
                outline          : none;
                // text-transform: capitalize;

                &:focus-visible {
                    border : none;
                    outline: none;
                }
            }

            .search-custom {
                display       : flex;
                flex-direction: row;
                padding-left  : 0px;
                padding-right : 0px;
            }

            .p-0 {
                padding-left : 0px;
                padding-right: 0px;
            }

            .container-button {
                display       : flex;
                gap           : 8px;
                flex-direction: row;
                padding       : 10px;
                padding-top   : 16px;
                padding-bottom: 16px;
                margin-top    : 5px;
                border-top    : 1px solid themed('n_layout_border');

                button.btn-custom {
                    overflow        : hidden;
                    text-overflow   : ellipsis;
                    // transition   : all 0.25s ease-in-out;
                    white-space     : nowrap;
                    word-break      : break-word;
                    background      : themed('n_btn_icon_bg');
                    padding         : 6px 10px;
                    border          : 1px solid transparent;
                    border-radius   : 4px;
                    cursor          : pointer;
                }

                .active {
                    border-color: themed('n_btn_active') !important;
                    color       : themed('n_btn_active');
                }
            }

            .title-search {
                // color         : themed('n_btn_active');
                padding-bottom: 16px;
            }

            .container-list {
                display         : flex;
                flex-direction  : column;
                height          : calc(100% - 100px) !important;
                //overflow      : auto;

                .active {
                    // background: themed('n_form_bg');
                }

                .item-stock {
                    display        : flex;
                    flex-direction : row;
                    justify-content: space-between;
                    border-top     : 1px solid themed('n_layout_border');
                    padding        : 12px 0px 12px 0px;
                    cursor         : pointer;

                    .n-column {
                        display       : flex;
                        flex-direction: column;
                    }

                }

                .item-func {
                    display        : flex;
                    flex-direction : row;
                    justify-content: space-between;
                    border-top     : 1px solid themed('n_layout_border');
                    padding        : 12px 0px 12px 0px;
                    cursor         : pointer;

                    .n-column {
                        display       : flex;
                        flex-direction: column;
                    }
                }

                .item-news {
                    display           : flex;
                    flex-direction    : row;
                    border-top        : 1px solid themed('n_layout_border');
                    padding           : 12px 0px 12px 0px;
                    cursor            : pointer;
                    gap               : 10px;
                    align-items       : center;
                    // justify-content: space-between;

                    .n-column {
                        display        : flex;
                        flex-direction : column;
                        justify-content: space-between;
                    }

                    .s-image {
                        height       : 45px;
                        width        : 45px;
                        border-radius: 4px;
                    }

                    .p-0 {
                        padding: 0px;
                    }
                }



                .empty-search {
                    display        : flex;
                    flex-direction : column;
                    align-items    : center;
                    height         : 100%;
                    justify-content: center;
                }

            }
        }

        .modal-custom {
            color     : themed('n_layout_text_active');
            background: themed('n_layout_bg_block');
        }
    }
}