@import "../../styles/common";

.container-modal-screen {
    @include themify($themes) {
        overflow : auto;
        display  : flex;
        flex-grow: 1;

        // left         : 75px;
        left         : 10px;
        // width        : calc(100% - 50px);
        width        : 100%;
        height       : calc(100% - 50px);
        margin       : 0;
        z-index      : 500;
        border       : 0px;
        position     : absolute !important;
        border-radius: 0px;
        border       : none !important; //fix khoảng cách 1px  

        // &.open {
        //     width: calc(100% - 226px);
        //     left : 226px;
        // }

        &.menu-tool-open{
            width: calc(100% - 75px);
            left : 75px;
        }

        &.open {
            width: calc(100% - 300px) !important;
            left : 300px !important;
        }

        .p-table-10 {
            padding-left  : 10px;
            padding-right : 10px;
            padding-bottom: 10px;
        }

        .p-0 {
            padding: 0px;
        }

        .p-title {
            padding-top : 10px !important;
            padding-left: 10px !important;
        }


        background: themed('n_layout_bg') !important;

        .header-container {
            min-height        : 62px;
            height            : 62px;
            display           : flex;
            flex-direction    : row;
            justify-content   : space-between;
            align-items       : center;
            padding           : 0px 16px 0px 16px;
            background        : themed('n_layout_bg_body_block') !important;
            box-shadow        : inset 0px 4px 4px themed("n_layout_box_shadow_bg");
            -webkit-box-shadow: inset 0px 4px 4px themed("n_layout_box_shadow_bg");
            margin            : 0px 10px;

            .title {
                color: themed('n_layout_text_active');
            }
        }

        .padding-scroll {
            padding-right: 5px !important;
        }

        .body-container {
            height    : calc(100% - 67px);
            padding   : 10px;
            background: themed('n_layout_bg');
        }

        .pl-0 {
            padding-left: 5px;
        }

        .h-block-table {
            height: calc(100% - 52px);
        }

        .card-body {
            .custom-hr {
                border-top: 1px solid themed("n_table_border");
            }

            .basic-table {
                // transition        : all 2s;
                // -webkit-transition: all 2s;
                border: none;
                width : 100%;

                thead {
                    tr {
                        background: themed("n_table_header");

                        th {
                            padding     : 5px 0px;
                            border-top  : 1px solid themed("n_table_border");
                            border-left : 1px solid themed("n_table_border");
                            border-right: 1px solid themed("n_table_border");
                        }

                        // th:first-child {
                        //     border-left: none;
                        // }

                        // th:last-child {
                        //     border-right: none;
                        // }

                    }
                }

                tbody {
                    tr {
                        background: themed("n_table_row_even");
                        border    : 1px solid themed("n_table_border");

                        td {
                            padding      : 5px 5px;
                            border-bottom: 1px solid themed("n_table_border");
                            border-left  : 1px solid themed("n_table_border");
                            border-right : 1px solid themed("n_table_border");
                        }
                    }

                    tr:nth-child(odd) td {
                        background: themed("n_table_row");
                    }

                    tr:nth-child(even) td {
                        background: themed("n_table_row");
                    }

                    .btn-refresh {
                        color           : themed("n_layout_text_active");
                        background-color: themed("n_layout_btn_bg");
                        border-radius   : 2px;
                        padding         : 4px 10px;
                    }

                    .btn-confirm {
                        color        : themed("n_layout_text_active");
                        background   : themed("n_layout_primary");
                        border-radius: 2px;
                        padding      : 4px 10px;
                    }

                }
            }

            .headB---700-16-24 {
                &.title {
                    color: themed('n_layout_text_secondary_1');
                }
            }
        }
    }

    .container-end-header {
        display       : flex;
        flex-direction: row;
        align-items   : center;
        gap           : 20px;
    }
}



@include media-breakpoint-down(md) {

    .container-modal-screen {
        // height: calc(100vh - 50px) !important;
        // height: calc(100dvh - 50px) !important;

        .scrollbar-screen-modal {
            >div:first-child {
                display       : flex;
                flex-direction: column;
            }
        }

        .body-container {
            height    : unset !important;
            min-height: unset !important;
        }
    }
}

@include media-breakpoint-down(sm) {
    .container-modal-screen {
        left                     : 0px !important;
        width                    : 100% !important;
        // height             : calc(100% - 135px);
        // height          : 100%;
        // padding-top     : 135px;
        // height                : calc(100vh - 210px) !important;
        // height                : calc(100dvh - 210px) !important;

        &.open {
            width: calc(100% - 226px);
            left : 226px;
        }

        .header-container {
            margin: 0px 5px !important;
        }

        .body-container {
            // height : unset !important;
            padding: 5px !important;
        }

    }
}

.container-modal-screen {
    @include themify($themes) {
        .action-container {
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            padding        : 10px;

            .container-select {
                gap     : 4px;
                width   : 100vw;
                overflow: auto
            }
        }

        .action-end {
            display        : flex;
            flex-direction : row;
            justify-content: flex-end;
            padding        : 10px;
        }

        .action-start {
            display        : flex;
            flex-direction : row;
            justify-content: flex-start;
            padding        : 10px;
        }

        .container-table {
            height          : 100%;
            overflow        : scroll;
            // padding-left : 10px;
            // padding-right: 5px;
            background      : themed("n_table_header");
        }

        .btn-search {
            background   : themed("n_layout_primary");
            padding      : 6px 10px;
            height       : 100%;
            overflow     : hidden;
            text-overflow: ellipsis;
            white-space  : nowrap;
        }

        .container-table-hidden {
            height       : 100%;
            overflow     : hidden !important;
            padding-left : 10px;
            padding-right: 10px;
        }

        .basic-table {
            // transition        : all 2s;
            // -webkit-transition: all 2s;
            border: none;
            width : 100%;

            thead {
                tr {
                    background: themed("n_table_header");

                    th {
                        padding     : 5px 0px;
                        border-left : 1px solid themed("n_table_border");
                        border-right: 1px solid themed("n_table_border");
                        font-weight : unset;
                    }
                }
            }

            tbody {
                tr {
                    cursor    : pointer;
                    background: themed("n_table_row_even");
                    border    : 1px solid themed("n_table_border");


                    td {
                        padding      : 10px 10px;
                        border-bottom: 1px solid themed("n_table_border");
                        border-left  : 1px solid themed("n_table_border");
                        border-right : 1px solid themed("n_table_border");

                        .status-order {
                            position: relative;

                            .status-icon {
                                position     : absolute;
                                background   : #FC944D;
                                border-radius: 50%;
                                height       : 8px;
                                width        : 8px;
                                top          : 7px;
                                left         : -15px;
                            }
                        }

                        .btn-extend {
                            background   : themed("n_layout_primary");
                            padding      : 5px 10px;
                            border-radius: 2px;
                        }

                        .btn-pay {
                            background-color: themed("n_layout_btn_bg");
                            padding         : 5px 10px;
                            border-radius   : 2px;
                        }
                    }
                }

                tr:nth-child(odd) td {
                    //background: themed("n_table_row_even");
                    background: themed("n_table_row");
                }

                tr:nth-child(even) td {
                    //background: themed("n_table_row_odd");
                    background: themed("n_table_row");
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .action-container {
                justify-content: none;
                gap            : 10px;
            }
        }
    }
}

.custom-header {
    @include themify($themes) {
        background             : themed('n_header_bg');
        color                  : themed('n_layout_text_active');
        width                  : 100%;
        border-top-left-radius : 4px;
        border-top-right-radius: 4px;
        align-items            : center;
        display                : flex;
        padding                : 10px 16px;
        justify-content        : space-between;
    }
}