@import "../../../../styles/common";

.cond-order-history {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .order-history-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .bold {
            font-weight: bold;
        }

        .h-page {
            height: calc(100vh - 55px);
        }

    }

    .TableDevExtreme {
        .dx-header-row{
            td{
                height: 45px !important;
                white-space: normal;
                .dx-datagrid-text-content{
                    height: 45px !important;
                    white-space: normal;
                    align-content: center !important;

                    svg{
                        width: 13px;
                    }
                }
            }
        }
    }
}