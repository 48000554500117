@import "../../../../styles/common";

.custom-form-group-select {
    width: 100%;
}

.custom-form-group-select__control {
    background-color: transparent !important;
    min-height: 30px;
    border-radius: 4px;
    color: white !important;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.custom-form-group-select__menu {
    background-color: #0e091d !important;
    border-radius: 4px;
}

.custom-form-group-select__option {
    padding: 10px;
    color: #ffffff !important;
}

.custom-form-group-select__option--is-focused {
    background-color: #007bff !important;
}

.custom-form-group-select__option--is-selected {
    background-color: #007bff !important;
}

.custom-form-group-select__control--is-focused {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 0px #ffffff !important;
}

.custom-form-group-select__indicator-separator {
    display: none;
}

.custom-form-group-select__dropdown-indicator {
    color: white !important;
}

.custom-form-group-select__indicator.custom-form-group-select__dropdown-indicator {
    padding-right: 0px !important;
}

.custom-form-group-select__dropdown-indicator {
    color: white !important;
    font-size: 0px !important;
    width: 0px !important;
}

.custom-form-group-select__input {
    color: white !important;
}

.custom-form-group-select__single-value {
    color: white !important;
}