@import "../../../../../styles/common";

.company-price-history-symbol-container {
    @include themify($themes) {
        height: 100%;
        width : 100%;

        .company-price-history {
            height        : 100%;
            width         : 100%;
            display       : flex;
            flex-direction: column;
            gap           : 10px;

            .action-container {
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                flex-wrap      : nowrap;

                .btn-search {
                    background: themed("n_form_bg") !important;
                    padding   : 2px 10px !important;
                    border    : 1px solid transparent;

                    &:active {
                        border: none;
                    }
                }

                .container-select {
                    gap     : 4px;
                    width   : 100vw;
                    overflow: auto
                }

                .custom-form-group-n {
                    .dropdown-item {
                        background: transparent;
                        // color: ;
                    }
                }
            }


            .info-history-price {
                .info-history-price-warp {
                    display: flex;
                    gap    : 10px;

                    .info {
                        display: flex;
                        gap    : 5px;
                    }
                }
            }

            .price-history {
                width     : 100%;
                height    : 100%;
                max-height: calc(100% - 72px);

                .table-price-history {
                    width : 100%;
                    height: 100%;
                }
            }
        }
    }
}