@import "../../../styles/common.scss";

.chart-matched-info {

    @include themify($themes) {
        .highcharts-background {
            fill: themed('n_layout_bg_body_block');
        }

        .highcharts-plot-border {
            stroke: themed('highcharts-plot-border');
        }

        .highcharts-yaxis-grid .highcharts-grid-line {
            stroke: themed('highcharts-grid-line');
            ;
        }

        .highcharts-plot-lines-0 {
            stroke: themed('highcharts-plot-lines');
            ;
        }
    }

}