@import "../../../styles/common";

.order-book-trade {
  @include themify($themes) {
    width: 100%;
    height: calc(100% - 45px);

    .block-info-container {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .block-info-symbol {
      margin-top: 10px;
      padding: 6px 8px;
      background-color: themed("n_layout_btn_bg");
      width: 100%;
      border-radius: $border_radius;
      height: auto;
      min-height: 40px;

      .info-symbol {
        width: 100%;

        .custom-row {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .resizesensor-wrapper{
      height: 135px;
    }


  }
}
