@import "../../../styles/common";

.order-history {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .order-history-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .bold {
            font-weight: bold;
        }

        .h-page {
            height: calc(100vh - 55px);
        }

    }
}