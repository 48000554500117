@import "../../../../styles/common";

.match-history {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .match-history-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
        }

        .bold {
            font-weight: bold;
        }

    }
}