@import "../../../styles//common";

.pdf-retry-table {
    width    : 100%;
    max-width: 900px;
    margin   : 0 auto;

    .pdf-viewer {
        max-height: 450px;

        @include media-breakpoint-up(xl) {
            max-height: 600px;
        }

        // overflow: auto;
        height : 100vh;
        width  : 100%;
        padding: 10px;
    }
}
