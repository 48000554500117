@import "../../../../../styles/common";

.company-statistic-symbol {
    @include themify($themes) {
        width     : 100%;
        height    : 100%;
        background: themed("n_layout_bg_block");

        .company-statistic-symbol-content {
            width  : 100%;
            height : 100%;
            padding: 10px;

            .block-custom-tab {
                display       : flex;
                flex-direction: column;
                gap           : 10px;
                width         : 100%;
                height        : 100%;

                .header-block-custom-tab {
                    .tab-menu {
                        .tab-item {
                            .wrap-tab-item {
                                .title-name {
                                    padding: 5px 15px;
                                }
                            }
                        }
                    }
                }

                .body-block-custom-tab {
                    // height: calc(100% - 50px);
                }
            }
        }
    }
}