@import "../../../../styles/common";

.securities-transaction-report {
    @include themify($themes) {
        height       : 100%;
        background   : themed('n_bg_block');
        border-radius: 4px;

        .custom-form-group-n{
            &.fullname{
                background: transparent !important;
                padding: 10px 2px 10px 2px !important;
    
                // & > div{
                //     display: block !important;
                //     max-width: 140px;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                // }
            }
        }

        .securities-transaction-report-content {
            height        : 100%;
            display       : flex;
            flex-direction: column;
            .sum-row {
                color: themed("n_sum_group_color");
            }
        }
        .text-right {
            padding-right: 5px;
        }

    }

    .TableDevExtreme {
        & > .dx-scrollable-native.dx-scrollable-vertical > .dx-scrollable-wrapper > .dx-scrollable-container {
          overflow-y: hidden !important;
        }
    }
}