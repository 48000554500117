@import "../styles/common";

$lds-grid-color: #ffffff;
$lds-dot-size: 15px;
$lds-grid-spacing: 8px;

$lds-point-1: $lds-grid-spacing;
$lds-point-2: $lds-grid-spacing + (($lds-grid-spacing + $lds-dot-size)) + 1px;
$lds-point-3: $lds-grid-spacing + (2 * ($lds-grid-spacing + $lds-dot-size)) + 1px;
$lds-grid-size: $lds-point-3 + $lds-grid-spacing + $lds-dot-size;

.lds-grid-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -($lds-grid-size / 2);
  margin-left: -($lds-grid-size / 2);
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: $lds-grid-size;
  height: $lds-grid-size;

  div {
    position: absolute;
    width: $lds-dot-size;
    height: $lds-dot-size;
    border-radius: 50%;
    background: $lds-grid-color;
    @include animation('lds-grid 1.2s linear infinite');

    &:nth-child(1) {
      top: $lds-point-1;
      left: $lds-point-1;
      @include css3-prefix('animation-delay', '0s');
    }

    &:nth-child(2) {
      top: $lds-point-1;
      left: $lds-point-2;
      @include css3-prefix('animation-delay', '-0.4s');
    }

    &:nth-child(3) {
      top: $lds-point-1;
      left: $lds-point-3;
      @include css3-prefix('animation-delay', '-0.8s');
    }

    &:nth-child(4) {
      top: $lds-point-2;
      left: $lds-point-1;
      @include css3-prefix('animation-delay', '-0.4s');
    }

    &:nth-child(5) {
      top: $lds-point-2;
      left: $lds-point-2;
      @include css3-prefix('animation-delay', '-0.8s');
    }

    &:nth-child(6) {
      top: $lds-point-2;
      left: $lds-point-3;
      @include css3-prefix('animation-delay', '-1.2s');
    }

    &:nth-child(7) {
      top: $lds-point-3;
      left: $lds-point-1;
      @include css3-prefix('animation-delay', '-0.8s');
    }

    &:nth-child(8) {
      top: $lds-point-3;
      left: $lds-point-2;
      @include css3-prefix('animation-delay', '-1.2s');
    }

    &:nth-child(9) {
      top: $lds-point-3;
      left: $lds-point-3;
      @include css3-prefix('animation-delay', '-1.6s');
    }
  }
}

@include keyframes('lds-grid') {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
