@import "../styles/common";

@font-face {
  font-family: 'password';
  font-style : normal;
  font-weight: 400;
  src        : url(../assets/images/password.ttf);
}


.layout-setting-modal.change-core-modal {

  .line-height-32 {
    line-height: 32px !important;
  }

  .title-16-700 {
    font-family: 'Helvetica Neue Bold' !important;
    font-weight: 700 !important; // Heading 6 - B (6)
    font-size  : 16px !important;
  }

  @include themify($themes) {
    .modal-header {
      background : themed("n_layout_bg_block");
      height     : 44px !important;
      padding-top: 5px !important;
      background : themed("n_layout_bg_header_block") !important;

      .modal-title {
        color: themed("n_layout_text_active");
      }

      .btn-icon-fm {
        margin-right: 10px;

        &[disabled] {
          cursor: not-allowed;
        }
      }

      button {
        color: themed("n_layout_text_active");

        &:hover {
          background: themed("n_layout_bg_block");
        }
      }
    }

    .modal-body {
      color     : themed("n_layout_text_active");
      background: themed("n_layout_bg_block");

      border-bottom-left-radius : 4px;
      border-bottom-right-radius: 4px;

      line-height: 32px !important;
      text-align: justify;

      .widget-icon {
        .st0 {
          fill: themed("n_layout_text_active");
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    width    : 90% !important;
    max-width: 90% !important;
  }

  @include media-breakpoint-up(lg) {
    width    : 650px !important;
    max-width: 650px !important;
  }

  .modal-header {
    @include themify($themes) {
      height                 : 30px;
      padding                : 0;
      border                 : none;
      // border-radius: 0;
      border-top-left-radius : 4px;
      border-top-right-radius: 4px;

      .modal-title {
        padding    : 0 10px;
        font-size  : $font-size-big;
        line-height: 30px;
      }

      button {
        width      : auto;
        height     : 30px;
        border     : none;
        padding    : 0 10px;
        line-height: 30px;
        text-align : center;
        background : transparent;
        font-size  : $font-size-medium;

        i {
          line-height: 30px;
        }

        .fa-times {
          font-size: $font-size-big + 2px;
        }
      }
    }
  }

  .modal-body {
    @include themify($themes) {
      padding: 20px 30px 20px 30px;

      .form-check-input {
        margin-top : -8px;
        margin-left: -20px;
        cursor     : pointer;
      }

      .widget-row {
        padding-bottom: 10px;
      }

      .widget-icon {
        width       : 30px;
        height      : auto;
        margin-right: 20px;
        cursor      : pointer;
      }

      .widget-title {
        font-size: $font-size-medium;
        cursor   : pointer;
      }

      .widget-description {
        color    : themed("n_layout_text_active");
        font-size: $font-size-regular;
      }

      .widget-permission {
        color    : themed("n_layout_text_active");
        font-size: $font-size-regular;
      }
    }
  }

  .modal-footer {
    height       : 48px;
    padding      : 0;
    border       : none;
    border-radius: 0;
  }

  .flat-button {
    margin       : 10px 10px 10px 0;
    padding      : 0 15px;
    height       : 28px;
    border       : none;
    font-size    : 12px;
    line-height  : 28px;
    border-radius: 0;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  .btn-container button {
    @include themify($themes) {

      width     : 100% !important;
      height    : 40px !important;
      background: themed('n_header_btn_primary');
    }

    border       : none;
    height       : 26px;
    margin-bottom: 10px;
    margin-left  : 10px;
    width        : 100px;
    margin-top   :40px;

    &[disabled] {
      opacity: 0.5;
      cursor : not-allowed;
    }

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 0.75;
    }

    &.btn-logout {
      @include themify($themes) {
        background-color: themed("n_layout_bg_block");
        // color: themed('BtnSubmit-label-color');
        border-radius   : 5px;
      }
    }
  }

  .change-core-modal-retry {
    &.retryable-content.loading {
      .retryable-content-body {
        opacity: 0.8 !important;
      }
    }
  }
}