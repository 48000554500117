@import "common";

body.fss {
  @include themifyBody($themes) {
    .block-table {
      border: 1px solid themed("n_table_border");

      .basic-table {
        // transition        : all 2s;
        // -webkit-transition: all 2s;
        border: none;
        width : 100%;

        thead {
          tr {
            position  : sticky;
            top       : 0;
            background: themed("n_table_header");

            th {
              padding        : 18px 0px;
              // border-top  : 1px solid themed("n_table_border");
              border-left    : 1px solid themed("n_table_border");
              border-right   : 1px solid themed("n_table_border");
            }

            // th:first-child {
            //   border-left: none;
            // }

            // th:last-child {
            //   border-right: none;
            // }

          }
        }

        tbody {
          tr {
            background: themed("n_table_row_even");
            border    : 1px solid themed("n_table_border");

            td {
              padding      : 5px 5px;
              border-bottom: 1px solid themed("n_table_border");
              border-left  : 1px solid themed("n_table_border");
              border-right : 1px solid themed("n_table_border");
            }
          }

          tr:nth-child(odd) td {
            background: themed("n_table_row");
          }

          tr:nth-child(even) td {
            background: themed("n_table_row");
          }
        }
      }
    }
  }
}