@import "../../../styles/common";

.screen-voucher-register {
    padding: 0 0 10px 0;

    @include themify($themes) {

        .no-data {
            font-size    : 16px;
            font-weight  : 700;
            width        : 100%;
            height       : 280px;
            border-radius: 5px;

            display        : flex;
            justify-content: center;
            align-items    : center;

            background-color: themed(n_form_bg);
        }

        .promote-voucher {
            min-height   : 300px;
            padding      : 10px;
            border-radius: 5px;

            background-color: themed(n_layout_bg_block);

            .promote-voucher-header {
                padding-bottom: 15px;
                font-size     : 16px;
                font-weight   : 700;
            }

            .promote-voucher-body {
                display  : flex;
                flex-wrap: wrap;
                margin   : 0 !important;
                gap      : 10px;
            }
        }

        .in-effect-voucher {
            //min-height   : 300px;
            padding         : 10px;
            margin-top      : 10px;
            border-radius   : 5px;
            margin-bottom   : 5px;
            background-color: themed(n_layout_bg_block);

            .in-effect-voucher-header {
                padding-bottom: 15px;
                font-size     : 16px;
                font-weight   : 700;
            }

            .in-effect-voucher-body {
                display  : flex;
                flex-wrap: wrap;
                margin   : 0 !important;
                gap      : 10px;
            }
        }

    }
}