@import "../../../../../styles/common";

.company-event-history-symbol-container {
    @include themify($themes) {
        height    : 100%;
        background: themed("n_layout_bg_block");
        width     : 100%;

        .company-event-history-symbol-content {
            padding       : 10px;
            height        : 100%;
            width         : 100%;
            display       : flex;
            flex-direction: column;
            gap           : 10px;

            .action-container {
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                flex-wrap      : nowrap;

                .btn-search {
                    background: themed("n_form_bg") !important;
                    padding   : 2px 10px !important;
                    border    : 1px solid transparent;

                    &:active {
                        border: none;
                    }
                }

                .container-select {
                    gap     : 4px;
                    width   : 100vw;
                    overflow: auto
                }

                .custom-form-group-n {
                    .dropdown-item {
                        background: transparent;
                        // color: ;
                    }
                }
            }

            .company-event-history-symbol {
                height: calc(100% - 40px);
                width : 100%;

                .table-company-event-history-symbol {
                    height: 100%;
                    width : 100%;
                }
            }
        }
    }
}