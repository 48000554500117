@import "../../../../styles/common";

.rules-modal {
    @include themify($themes) {

        .custom-body {
            background    : themed("n_layout_bg_block");
            display       : flex;
            flex-direction: column;
            align-items   : center;
            overflow-y    : "auto";
            border-bottom : 1px solid themed("n_layout_border");

            .content {
                max-height: 40vh;
                overflow  : auto;
                margin    : 0 auto;
            }

            .container-btn {
                margin-top: 16px;
                display   : flex;
                gap       : 8px;
                flex-wrap : nowrap;
                width     : 100%;
            }
        }

        .custom-footer {
            background     : themed('n_layout_bg');
            height         : 80px;
            color          : themed('n_layout_text_active');
            width          : 100%;
            align-items    : center;
            display        : flex;
            padding        : 0px 20px;
            justify-content: space-between;

            .btn {
                width  : 100%;
                padding: 0px 34px;
            }
        }


        // từ 991px trở xuống
        @include media-breakpoint-down(md) {}

        // từ 1199px trở xuống
        @include media-breakpoint-down(lg) {}
    }
}