@import "../../../../styles/common.scss";

.market-breadth {
    @include themify($themes) {
        height    : 100%;
        min-height: 400px;

        .market-breadth-content {
            height : 100%;
            padding: 10px;

            .action-container {
                margin: 0px 0px 10px 0px;

                .list-select-exchange {
                    display: flex;
                    gap: 10px;

                    .select-exchange-type {
                        padding      : 5px 15px;
                        background   : themed("n_layout_btn_bg");
                        border-radius: $border_radius;
                        cursor       : pointer;
                    }
                }
            }

            .container-total {
                padding: 10px 0;
                width  : 100%;

                .total-bar {
                    display: flex;
                    width  : 100%;
                    gap    : 10px;
                    height : 10px;

                    &.no-gap {
                        gap: unset;
                    }

                    .total-bar-item {}
                }

                .progress-desc {
                    display        : flex;
                    width          : 100%;
                    justify-content: space-between;
                    padding        : 10px 0px 0px 0px;
                }
            }
        }
    }
}