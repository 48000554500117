
.retryable-content {
  position: relative;

  .prevent-action-block {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
  }

  &.loading, &.fail {
    .retryable-content-body {
      // transition: opacity 0.5s;
      opacity: 0.3;
    }
  }

  &.loading-order{
    .retryable-content-body {
      // transition: opacity 0.5s;
      opacity: 1;
    }
  }

  &.fail.disable-retry {
    .retryable-content-body {
      opacity: 1;
    }
  }

  &.loading {
    &:after {
      content: "";
      position: absolute;
      top: 1.7692307692rem;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      animation: fadeIn 1s;
      background: url(../assets/images/throbber-invert.gif) no-repeat 50% center;
      opacity: 1;
      height: 20px;
      width: 80px;
    }
  }

  &.fail {
    .retryable-content-retry {
      display: flex;
    }
  }

  .retryable-content-retry {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .wrapper {
      text-align: center;
    }
  }
}