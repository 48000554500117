@import "common";

.effect-rotate-360 {
  &.effect-loading {
    -webkit-animation-name           : rotate360;
    -webkit-animation-duration       : 0.25s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  }

  &.effect-loaded {
    opacity: 0.4;
  }
}

@-webkit-keyframes rotate360 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.effect-rotate-90 {
  &.effect-forward {
    animation          : rotate90 0.1s linear;
    animation-fill-mode: forwards;
  }
}

@-webkit-keyframes rotate90 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(90deg);
  }
}



.animation-rung-lac {
  animation: animation-rung-lac 2s ease infinite
}

@-webkit-keyframes animation-rung-lac {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }
}