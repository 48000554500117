@import "../../../../styles/common";

.settings-information-main {
    @include themify($themes) {
        .settings-information-header {
            display: flex;
            justify-content: space-between;
            .btn-edit {
                cursor: pointer;
            }
        }
        .title {
            font-size  : 16px;
            font-weight: 700;
        }

        .body {
            margin: 10px 0;

            .property-info {
                font-size      : 14px;
                border-top     : 1px solid themed('n_layout_border');
                padding        : 15px 0;
                display        : flex;
                justify-content: space-between;

                .label-property {
                    color: rgba(themed('n_header_text_active'), 0.5);
                    width: 40%;
                }
                .value-property-textarea {
                    width: 10px;
                    word-wrap: break-word;
                }
                .combo-input {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    width          : 60%;


                    .custom-form-group {
                        // min-width: 250px;
                        border: unset;
                        height: 40px !important;
                        textarea {
                            resize: none;
                        }
                        .custom-form-control {
                            text-align: start;

                            padding      : 0;
                            padding-right: 5px;
                            &:focus {
                                border: none !important;
                            }
                        }
                    }

                    .edit {
                        display: flex;
                        gap    : 8px;

                        svg {
                            cursor: pointer;
                        }
                    }
                }

                .value-property {
                    color: rgba(themed('n_header_text_active'), 0.5);
                    width: 60%;
                    word-wrap: break-word;
                    // display        : flex;
                    justify-content: space-between;

                    .edit {
                        svg {
                            cursor: pointer;
                        }
                    }

                    span {
                        svg {
                            cursor: pointer;
                        }
                    }
                }
            }

            .container-btn {
                margin-top: 20px;
                justify-content: center;
                button {
                    min-width: 80px;
                }
                .btn-confirm {
                    margin-left: 15px;
                }
            }
        }

        .custom-body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 10px 16px;
            height        : 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }


            .content {
                max-height: 40vh;
                overflow  : auto;
                margin    : 0 auto;
            }

            // .container-btn {
            //     margin-top: 16px;
            //     display   : flex;
            //     gap       : 8px;
            //     flex-wrap : nowrap;
            //     width     : 100%;
            // }
        }
    }

}