// #fc0390 // màu có thể xóa
// #5807db // màu có thể xóa

$font-size-small   : 14px;
$font-size-regular : 14px;
$font-size-medium  : 16px;
$font-size-big     : 18px;
$font-size-very-big: 26px;

// Bootstrap overrides

$min-widget-height       : 200px; // chiá»u cao tá»‘i thiá»ƒu cá»§a widget trĂªn mobile
$border_radius           : 4px;
$font-size-14            :14px;
// Dark
$theme_01_common_primary : #F27801;
$theme_01_common_ref     : #F27801;
$theme_01_common_ceil    : rgba(242, 58, 255, 1);
$theme_01_common_floor   : rgba(0, 201, 255, 1);
$theme_01_common_increase: rgba(11, 223, 57, 1);
$theme_01_common_warning : rgba(253, 255, 18, 1);
$theme_01_common_reduce  : #FF0017;

$theme_01_common_text_active     : rgb(255, 255, 255); //000000
$theme_01_common_input_active     : #ECECEC; //000000
$theme_01_common_text_near_active: rgba(255, 255, 255, 0.7); //000000
$theme_01_common_text_not_active : rgba(255, 255, 255, 0.4); //000000
$theme_01_common_checkbox : rgba(50, 48, 48, 0.4); //000000
$theme_01_common_text_hover      : rgb(255, 255, 255); //000000

$theme_01_common_bg           :rgba(2, 2, 16, 1); //#1D1A29
$theme_01_common_block        :#0e091d; //#1D1A29
$theme_01_common_header_block :rgba(29, 26, 41, 1); //#282B30
$theme_01_common_stroke       :rgba(45, 44, 53, 1); //#3F4247
$theme_01_common_hover        :rgb(85, 87, 90); //#55575A
$theme_01_common_header       :#343443;
$theme_01_common_active       :rgb(85, 87, 90); //#55575A
$theme_01_common_table1       :rgba(23, 18, 37, 1); //#36383D
$theme_01_common_table2       :rgba(5, 4, 14, 1); //#2F3136
$theme_01_common_table        :rgba(5, 4, 14, 1); //#282B30

// $theme_01_common_button_primary      :linear-gradient(to right, #CA7826, #F27801);
$theme_01_common_button_primary      : #F27801;
// $theme_01_common_button_buy       :linear-gradient(to right, #26E07C, #00B854);
// $theme_01_common_button_sell      :linear-gradient(to right, #FF4444, #C20606);
$theme_01_common_button_buy          :#00B854;
$theme_01_common_button_sell         :#C20606;
$theme_01_common_button_sacondary    :rgb(41, 38, 52); //404347
$theme_01_common_button_sacondary1   :#343443;
$theme_01_common_background_secondary   :#322325;

// Yêu cầu riêng issue BSCWEBEXT-158
$theme_01_common_green_custom :#21DB77; //checkmark
$theme_01_common_red_custom   :#F93E3E;


// $theme_01_common_secondary1    :rgba(64, 235, 213, 1);
$theme_01_common_secondary1    :#F27801;
$theme_01_common_secondary2    :rgba(242, 100, 212, 1);
$theme_01_common_bg_box_shadow : #000000;

$theme_01_common_header_bg         : #262332;
$theme_01_common_white_title       : #FFF;
$theme_01_common_white_text_body   : rgba(255, 255, 255, 0.70);
$theme_01_common_border            : #2D2C35;
$theme_01_common_white_sub         : rgba(255, 255, 255, 0.40);
$theme_01_common_ck_dark_tng       : #F27801;
$theme_01_common_ck_dark_tng1      : rgba(175, 117, 0, 0.2);
$theme_01_common_ck_dark_gim       : #FF0017;
//EKYC riêng APG không theo figma
$theme_01_common_border_input_ekyc : #787878;
// Light


//Line
$theme_01_common_line: rgba(255, 255, 255, 0.2); //000000

// Light

$theme_02_common_primary : rgb(227, 151, 37);
$theme_02_common_ref     : rgb(227, 151, 37); //#E39725
$theme_02_common_ceil    : rgb(217, 52, 200); //#D934C8
$theme_02_common_floor   : rgb(38, 180, 225); //#26B4E1
$theme_02_common_increase: rgb(0, 174, 74); //#00AE4A
$theme_02_common_reduce  : rgb(249, 38, 38); //#F92626
$theme_02_common_warning : rgba(253, 255, 18, 1);

$theme_02_common_text_active     : #161F2C;
$theme_02_common_text_near_active: #454B56;
$theme_02_common_text_not_active : #7E8186;
$theme_02_common_checkbox : #7E8186;
$theme_02_common_text_hover      : #161F2C;

$theme_02_common_bg           :rgb(230, 231, 237); //#E6E7ED
$theme_02_common_block        :rgb(255, 255, 255); //#FFFFFF
$theme_02_common_header_block :rgb(242, 242, 242); //##F2F2F2
$theme_02_common_stroke       :rgb(232, 232, 232); //#E8E8E8
$theme_02_common_hover        :rgba(0, 0, 0, 0.1); //000000
$theme_02_common_header        :rgba(0, 0, 0, 0.1); //000000
$theme_02_common_active       :rgba(0, 0, 0, 0.1); //000000
$theme_02_common_table1       :rgb(246, 246, 246); //#F6F6F6
$theme_02_common_table2       :rgb(250, 250, 250); //#FAFAFA
$theme_02_common_table        :rgb(255, 255, 255); //#FFFFFF

$theme_02_common_button_primary    :linear-gradient(to right, #265CAB, #2681E0);
$theme_02_common_button_buy        :linear-gradient(to right, #26E07C, #00B854);
$theme_02_common_button_sell       :linear-gradient(to right, #FF4444, #C20606);
$theme_02_common_button_sacondary  :rgb(238, 238, 238); // #EEEEEE
$theme_02_common_button_sacondary2 : #D0D3DA;
$theme_02_common_button_sacondary1 :#343443; //EKYC
$theme_02_common_background_secondary: #322325;

// Yêu cầu riêng issue BSCWEBEXT-158
$theme_02_common_green_custom :#21DB77;
$theme_02_common_red_custom   :#F93E3E;

$theme_02_common_secondary1    :#F27801; //#32C3AD
$theme_02_common_secondary2    :rgb(225, 183, 84); //#E1B754
$theme_02_common_bg_box_shadow : rgb(162, 165, 178); //a2a5b2

// Animation Priceboard Price
$theme_02_common_animation_price_priceboard:#4879c033;


//Line
$theme_02_common_line              : rgba(0, 0, 0, 0.2); //000000
$theme_02_common_header_bg         : #262332;
$theme_02_common_white_title       : #FFF;
$theme_02_common_white_text_body   : rgba(255, 255, 255, 0.70);
$theme_02_common_border            : #2D2C35;
$theme_02_common_white_sub         : rgba(255, 255, 255, 0.40);
$theme_02_common_ck_dark_tng       : #F27801;
$theme_02_common_ck_dark_tng1      : rgba(176, 117, 9, 0.2);
$theme_02_common_ck_dark_gim       : #FF0017;
//EKYC riêng APG không theo figma
$theme_02_common_border_input_ekyc : #787878;


$themes                          : (dark: ( // COMMON
            //START
            // n_price_ceil_text:#f0f,
            // n_price_ref_text:#f7ff31,
            // n_price_floor_text:#0abde3,

            n_price_ceil_text :$theme_01_common_ceil,
            n_price_ref_text:$theme_01_common_ref,
            n_price_floor_text:$theme_01_common_floor,

            common_icon_dropdown_img: url("../assets/images/down-dark-arrow.svg"),
            // common_bg_img_quickorder: url("../../../assets/images_new/bg_quickoder.png"),

            n_form_bg : $theme_01_common_button_sacondary,
            n_background_icon_bg: $theme_01_common_background_secondary,

            // Header
            n_header_bg:$theme_01_common_block,
            n_header_bg_block: $theme_01_common_block,
            n_header_text_not_active: $theme_01_common_text_not_active,
            n_header_text_active: $theme_01_common_text_active,
            n_header_text_hover: $theme_01_common_text_hover,
            n_header_line: $theme_01_common_line,
            n_header_border: $theme_01_common_stroke,
            n_header_btn_primary: $theme_01_common_button_primary,

            n_header_bg_tab_hover:linear-gradient($theme_01_common_hover, $theme_01_common_bg),

            //Sidebar
            n_menu_sidebar_bg:$theme_01_common_block,
            n_menu_sidebar_text_not_active: $theme_01_common_text_not_active,
            n_menu_sidebar_text_active: $theme_01_common_text_active,
            n_menu_sidebar_text_hover: $theme_01_common_text_hover,
            n_menu_sidebar_line: $theme_01_common_line,
            n_menu_sidebar_border: $theme_01_common_stroke,
            n_menu_active: $theme_01_common_text_active,
            n_menu_border_right: rgba(255, 255, 255, 0.1),


            //Layout
            n_layout_primary:$theme_01_common_primary,
            n_layout_text_secondary_1:$theme_01_common_secondary1,
            n_layout_bg:$theme_01_common_bg,
            n_layout_bg_block:$theme_01_common_block,
            n_layout_bg_header_block:$theme_01_common_header_block,
            n_layout_bg_body_block:$theme_01_common_block,
            n_layout_text_not_active: $theme_01_common_text_not_active,
            n_layout_text_near_active: $theme_01_common_text_near_active,
            n_layout_text_active: $theme_01_common_text_active,
            n_layout_text_hover: $theme_01_common_text_hover,
            n_layout_bg_hover: $theme_01_common_hover,
            n_layout_bg_header: $theme_01_common_header,
            n_layout_line: $theme_01_common_line,
            n_layout_border: $theme_01_common_stroke,
            n_layout_btn_bg:$theme_01_common_button_sacondary,
            n_layout_btn_bg2:$theme_01_common_button_sacondary,
            n_layout_box_shadow_bg:$theme_01_common_bg_box_shadow,

            n_layout_increase_bg:$theme_01_common_increase,
            n_layout_increase_text:$theme_01_common_increase,
            n_layout_reduce_bg:$theme_01_common_reduce,
            n_layout_reduce_text:$theme_01_common_reduce,
            n_layout_warning_text:$theme_01_common_warning,

            n_layout_buy_bg: $theme_01_common_button_buy,
            n_layout_buy_text: $theme_01_common_button_buy,
            n_layout_sell_bg: $theme_01_common_button_sell,
            n_layout_sell_text: $theme_01_common_button_sell,
            n_layout_green_custom: $theme_01_common_green_custom,
            n_layout_red_custom: $theme_01_common_red_custom,

            n_layout_bg_secondary_2:$theme_01_common_secondary2,

            n_layout_active_bg: rgba(255, 255, 255, 0.05),

            //Layout Table
            n_table_bg: $theme_01_common_block,
            n_table_input_filter_bg: rgba(255, 255, 255, 0.08),
            n_table_header: $theme_01_common_table1,
            n_table_row_odd: $theme_01_common_table1,
            n_table_row_even: $theme_01_common_table2,
            n_table_row: $theme_01_common_table,
            n_table_text_not_active: $theme_01_common_text_near_active,
            n_table_text_active: $theme_01_common_text_active,
            n_table_text_hover: $theme_01_common_text_near_active,
            n_table_bg_hover: $theme_01_common_hover,
            n_table_bg_active: $theme_01_common_active,
            n_table_line: $theme_01_common_line,
            n_table_border: $theme_01_common_stroke,
            n_table_footer: $theme_01_common_table1,
            n_table_footer_text: $theme_01_common_ref,
            n_table_filter_bg: $theme_01_common_table2,
            n_table_check_box: $theme_02_common_checkbox,
            n_table_tick_box: $theme_01_common_text_active,
            n_table_btn_bg: $theme_01_common_secondary2,
            n_sum_group_color: $theme_01_common_secondary2,

            //Hightchart
            n_hightchart_item_bg: $theme_01_common_block,
            n_hightchart_item_detail_bg: $theme_01_common_button_sacondary,

            //Price Board
            n_price_board_bg: $theme_01_common_block,
            //Mã màu không có trong figma (custom theo APG)
            n_price_board_oled_bg: #061b12,
            n_price_board_oddlot_classic_bg: #171226,
            n_price_board_oddlot_oled_bg: #162927,
            n_price_board_selected_classic_bg: #353641,
            n_price_board_selected_oled_bg: #1d1a29,
            n_price_board_hightlight_classic_bg: #292536,
            n_price_board_hightlight_classic_bg_even: #211d2e,
            n_price_board_hightlight_oled_bg: #18322a,
            n_price_board_border_classic_bg: #2D2C35,
            n_price_board_border_oled_bg: #2D4A46,
            n_price_board_header_classic_bg: $theme_01_common_block,
            n_price_board_header_oled_bg: #162927,
            n_btn_icon_bg: $theme_01_common_button_sacondary,
            n_btn_active: $theme_01_common_secondary1,
            n_price_board: $theme_01_common_table1,
            n_pt_header: $theme_01_common_block,
            n_highligh_row:rgba(255, 255, 255, 0.05),
            n_highligh_header: rgb(59, 62, 65),
            n_putthrough_sumline: #FFD572,
            n_label_watchlist:#40EBD5,
            n_highligh_row_animation: $theme_01_common_text_active,

            //indexes chart
            n_add_chart_outer_circle: rgba(255, 255, 255, 0.1),
            n_add_chart_inner_circle: rgba(255, 255, 255, 0.5),
            n_index_title: #FFFFFF,
            n_bg_tooltip: #46494E,

            //tooltip
            n_tooltip_bg : $theme_01_common_stroke,

            //toast
            n_toast_noti_bg: $theme_01_common_button_sacondary,

            //footer
            n_footer_bg: $theme_01_common_block,
            n_footer_title: #FFFFFF,

            //block
            n_bg_block: $theme_01_common_block,
            //voucher
            n_voucher_img_bg: #20262F,
            //END

            // KhĂ´ng dĂ¹ng tá»« Ä‘Ă¢y trá»Ÿ xuá»‘ng     

            //DRAGABLE-SKYLIGHT
            //HIGHT-CHART
            highcharts-background: $theme_01_common_block,
            highcharts-plot-border: $theme_01_common_stroke,
            highcharts-grid-line: $theme_01_common_stroke,
            highcharts-plot-lines: $theme_01_common_ref,
            //abc
            PopupListChart-skylight-dialog-bg : $theme_01_common_block,
            PopupListChart-skylight-dialog-color: $theme_01_common_text_active,
            PopupListChart-btn-btn-add : $theme_01_common_increase,
            PopupListChart-btn-btn-remove : $theme_01_common_reduce,
            PopupListChart-btn-btn-hover : $theme_01_common_hover,
            PopupListMarket-btn-color : $theme_01_common_primary,
            //Scroll
            carbon-bg                : url("../../assets/images/bg-pattern4.svg") repeat center / (6px 6px), // ok
            scrollbar-track-bg       : rgba(255, 255, 255, 0.10), // ok
            scrollbar-thumb-bg       : rgba(255, 255, 255, 0.4), //ok
            scrollbar-thumb-hover-bg : rgba(255, 255, 255, 0.8), //ok
            // DATE PICKER
            date-picker-bg           : $theme_01_common_block,
            date-picker-border:$theme_01_common_stroke,
            date-picker-arrow-hover:$theme_01_common_hover,
            date-picker-month-fg: $theme_01_common_text_active,
            date-picker-month-bg: $theme_01_common_block,
            date-picker-weekdays-fg: $theme_01_common_text_active,
            date-picker-weekdays-bg: transparent,
            date-picker-day-fg: $theme_01_common_text_active,
            date-picker-day-hover-bg: $theme_01_common_bg,
            date-picker-today-border: $theme_01_common_text_active,
            date-picker-today-bg: $theme_01_common_text_active,
            date-picker-today-fg:$theme_01_common_block,
            date-picker-day-selected-fg: $theme_01_common_text_active,
            date-picker-day-selected-bg:$theme_02_common_secondary1,
            //NOTIFICATION

            //Custom 
            input_hover_border_bottom: $theme_01_common_text_active,
            logo_background_custom: $theme_01_common_text_active,
            n_text_custom: $theme_01_common_text_active,
            n_text_custom_btn_refresh: $theme_01_common_text_active,

            form-control-select-bg-img: url("../assets/images/down-dark-arrow.svg"),
            form-control-select-bg-img1: url("../../assets/images/down-dark-arrow.svg"),
            form-control-border-focus: $theme_01_common_text_active,
            date-picker-icon: url("../../assets/icons/new/DatetimeIcon.svg"),
            input_border_normal : rgba(255, 255, 255, 0.4),
            btn_background_custom: rgba(255, 255, 255, 0.2),
            tooltip-bg-custom: rgb(108, 105, 123),

            //EKYC
            n-ekyc-app-bg: $theme_01_common_bg,
            n-ekyc-header-bg: $theme_01_common_header_bg,
            n-ekyc-text-title: $theme_01_common_white_title,
            n-ekyc-text-body: $theme_01_common_white_text_body,
            n-ekyc-text-primary: $theme_01_common_secondary2,
            n-ekyc-section-border: $theme_01_common_border,
            n-ekyc-section-input-border: $theme_01_common_border_input_ekyc,
            n-ekyc-text-dissable:rgba(255, 255, 255, 0.25),
            n-ekyc-text-dissable-title: $theme_01_common_white_sub,
            n-ekyc-radio-input-bg:$theme_01_common_secondary2,
            n-ekyc-button-bg-back:$theme_01_common_button_sacondary1,
            n-ekyc-text-step-completed-bg: $theme_01_common_ck_dark_tng,
            n-ekyc-btn-capture-bg: $theme_01_common_button_sacondary1,
            n-ekyc-section-bg: $theme_01_common_block,
            n-ekyc-input-dissable: rgba(255, 255, 255, 0.05),
            n-ekyc-checkbox-input-bg:$theme_01_common_secondary2,
            n-ekyc-text-note:$theme_01_common_ck_dark_gim,
            n-ekyc-common-bg-success:$theme_01_common_ck_dark_tng1,

            //Animation Price Priceboard
            color_animation_price_priceboard : $theme_02_common_animation_price_priceboard),
      light : ( // COMMON
            n_price_ceil_text:$theme_02_common_ceil,
            n_price_ref_text:$theme_02_common_ref,
            n_price_floor_text:$theme_02_common_floor,

            common_icon_dropdown_img: url("../assets/images/down-light-arrow.svg"),
            // common_bg_img_quickorder: url("../../../assets/images_new/bg_quickoder.png"),

            n_form_bg : $theme_02_common_bg,
            n_background_icon_bg : $theme_01_common_button_sacondary,

            // Header
            n_header_bg:$theme_02_common_bg,
            n_header_bg_block:$theme_02_common_secondary1,
            n_header_text_not_active: $theme_02_common_text_not_active,
            n_header_text_active: $theme_02_common_text_active,
            n_header_text_hover: $theme_02_common_text_near_active,
            n_header_line: $theme_02_common_line,
            n_header_border: $theme_02_common_stroke,
            n_header_btn_primary: linear-gradient(to right, #17B19D, #2B80E4),

            n_header_bg_tab_hover:linear-gradient(rgba(255, 255, 255, 0.7), $theme_02_common_secondary1),

            //Sidebar
            n_menu_sidebar_bg:$theme_02_common_block,
            n_menu_sidebar_text_not_active: $theme_02_common_text_not_active,
            n_menu_sidebar_text_active: $theme_02_common_text_active,
            n_menu_sidebar_text_hover: $theme_02_common_text_near_active,
            n_menu_sidebar_line: $theme_02_common_line,
            n_menu_sidebar_border: $theme_02_common_text_active,
            n_menu_active: $theme_02_common_text_active,
            n_menu_border_right: rgba(255, 255, 255, 0.1),

            //Layout
            n_layout_primary:$theme_02_common_primary,
            n_layout_text_secondary_1:$theme_02_common_secondary1,
            n_layout_bg:$theme_02_common_bg,
            n_layout_bg_block:$theme_02_common_block,
            n_layout_bg_header_block:$theme_02_common_header_block,
            n_layout_bg_body_block:$theme_02_common_block,
            n_layout_text_not_active: $theme_02_common_text_not_active,
            n_layout_text_near_active: $theme_02_common_text_near_active,
            n_layout_text_active: $theme_02_common_text_active,
            n_layout_text_hover: $theme_02_common_text_hover,
            n_layout_bg_hover: $theme_02_common_hover,
            n_layout_bg_header: $theme_02_common_header,
            n_layout_line: $theme_02_common_line,
            n_layout_border: $theme_02_common_stroke,
            n_layout_btn_bg:$theme_02_common_button_sacondary,
            n_layout_btn_bg2:$theme_02_common_button_sacondary,
            n_layout_box_shadow_bg:$theme_02_common_bg_box_shadow,

            n_layout_increase_bg:$theme_02_common_increase,
            n_layout_increase_text:$theme_02_common_increase,
            n_layout_reduce_bg:$theme_02_common_reduce,
            n_layout_reduce_text:$theme_02_common_reduce,
            n_layout_warning_text:$theme_02_common_warning,

            n_layout_buy_bg: $theme_02_common_button_buy,
            n_layout_buy_text: $theme_02_common_button_buy,
            n_layout_sell_bg: $theme_02_common_button_sell,
            n_layout_sell_text: $theme_02_common_button_sell,

            n_layout_green_custom: $theme_02_common_green_custom,
            n_layout_red_custom: $theme_02_common_red_custom,

            n_layout_bg_secondary_2:$theme_01_common_secondary2,

            n_layout_active_bg: rgba(0, 0, 0, 0.05),

            //Layout Table
            n_table_bg: $theme_02_common_block,
            n_table_input_filter_bg: rgba(255, 255, 255, 0.08),
            n_table_header : $theme_02_common_table1,
            n_table_row_odd: $theme_02_common_table1,
            n_table_row_even: $theme_02_common_table2,
            n_table_row: $theme_02_common_table,
            n_table_text_not_active: $theme_02_common_text_near_active,
            n_table_text_active: $theme_02_common_text_active,
            n_table_text_hover: $theme_02_common_text_near_active,
            n_table_bg_hover: $theme_02_common_hover,
            n_table_bg_active: rgba(0, 0, 0, 0.1),
            n_table_line:$theme_02_common_line,
            n_table_border: $theme_02_common_stroke,
            n_table_footer: $theme_02_common_table1,
            n_table_footer_text: $theme_02_common_ref,
            n_table_filter_bg: $theme_02_common_line,
            n_table_check_box: $theme_01_common_checkbox,
            n_table_tick_box: $theme_01_common_text_active,
            n_table_btn_bg: $theme_01_common_secondary2,
            n_sum_group_color: $theme_02_common_secondary2,

            //Hightchart
            n_hightchart_item_bg: $theme_02_common_block,
            n_hightchart_item_detail_bg: $theme_02_common_button_sacondary,
            n_btn_active: $theme_02_common_secondary1,

            //Price Board
            n_price_board_bg: $theme_02_common_block,
            //Mã màu không có trong figma (custom theo APG)
            n_price_board_oled_bg: #f1f2f4,
            n_price_board_oddlot_classic_bg: #f6f6f6,
            n_price_board_oddlot_oled_bg: #f6f6f6,
            n_price_board_selected_classic_bg: #F1F2F4,
            n_price_board_selected_oled_bg: #e8e8e8,
            n_price_board_hightlight_classic_bg: #F1F2F4,
            n_price_board_hightlight_classic_bg_even: #F1F2F4,
            n_price_board_hightlight_oled_bg: #F1F2F4,
            n_price_board_border_classic_bg: #dadfe6,
            n_price_board_border_oled_bg: #e8e8e8,
            n_price_board_header_classic_bg: $theme_02_common_block,
            n_price_board_header_oled_bg: #dadfe6,
            n_btn_icon_bg: $theme_02_common_button_sacondary,
            n_price_board: $theme_02_common_table1,
            n_pt_header: $theme_02_common_table1,
            n_highligh_row:#F1F2F4,
            n_highligh_header: rgb(238, 238, 238),
            n_putthrough_sumline: #E1B754,
            n_label_watchlist:#40EBD5,
            n_highligh_row_animation: #F1F2F4,
            //indexes chart
            n_add_chart_outer_circle: rgba(0, 0, 0, 0.1),
            n_add_chart_inner_circle: rgba(0, 0, 0, 0.4),
            n_index_title: #000000,
            n_bg_tooltip: #FFFFFF,

            //tooltip
            n_tooltip_bg: $theme_02_common_stroke,

            //toast
            n_toast_noti_bg: $theme_02_common_button_sacondary,

            //footer
            n_footer_bg: $theme_02_common_block,
            n_footer_title: $theme_02_common_text_not_active,

            //block
            n_bg_block: $theme_02_common_block,
            //voucher
            n_voucher_img_bg: #20262F,

            //HIGHT-CHART
            highcharts-background: $theme_02_common_block,
            highcharts-plot-border: $theme_02_common_stroke,
            highcharts-grid-line: $theme_02_common_stroke,
            highcharts-plot-lines: $theme_02_common_ref,
            //abc
            PopupListChart-skylight-dialog-bg : $theme_02_common_block,
            PopupListChart-skylight-dialog-color: $theme_02_common_text_active,
            PopupListChart-btn-btn-add : $theme_02_common_increase,
            PopupListChart-btn-btn-remove : $theme_02_common_reduce,
            PopupListChart-btn-btn-hover : $theme_02_common_hover,
            PopupListMarket-btn-color : $theme_02_common_primary,
            //Scroll
            carbon-bg                : url("../../assets/images/bg-pattern4.svg") repeat center / (6px 6px), // ok
            scrollbar-track-bg       : rgba(0, 0, 0, 0.10), // ok
            scrollbar-thumb-bg       : rgba(0, 0, 0, 0.4), //ok
            scrollbar-thumb-hover-bg : rgba(0, 0, 0, 0.8), //ok
            // DATE PICKER
            date-picker-bg           : $theme_02_common_block,
            date-picker-border:$theme_02_common_stroke,
            date-picker-arrow-hover:$theme_02_common_hover,
            date-picker-month-fg: $theme_02_common_text_active,
            date-picker-month-bg: $theme_02_common_block,
            date-picker-weekdays-fg: $theme_02_common_text_active,
            date-picker-weekdays-bg: transparent,
            date-picker-day-fg: $theme_02_common_text_active,
            date-picker-day-hover-bg: $theme_02_common_bg,
            date-picker-today-border: $theme_02_common_text_active,
            date-picker-today-bg: $theme_02_common_text_active,
            date-picker-today-fg:$theme_02_common_block,
            date-picker-day-selected-fg: $theme_02_common_text_active,
            date-picker-day-selected-bg:$theme_02_common_secondary1,

            //Custom 
            input_hover_border_bottom: $theme_01_common_input_active,
            logo_background_custom: $theme_01_common_text_active,
            n_text_custom: $theme_01_common_text_active,
            n_text_custom_btn_refresh: #6B6969,
            form-control-select-bg-img: url("../assets/images/dropdown-light.svg"),
            form-control-select-bg-img1: url("../../assets/images/dropdown-light.svg"),
            form-control-border-focus: $theme_02_common_text_active,
            date-picker-icon: url("../../assets/icons/new/DatetimeIcon_Dark.svg"),
            input_border_normal: rgba(0, 0, 0, 0.4),
            btn_background_custom: rgba(255, 255, 255, 0.2),
            tooltip-bg-custom: rgb(108, 105, 123),
            //EKYC
            n-ekyc-app-bg: $theme_02_common_bg,
            n-ekyc-header-bg: $theme_02_common_header_bg,
            n-ekyc-text-title: $theme_02_common_white_title,
            n-ekyc-text-body: $theme_02_common_white_text_body,
            n-ekyc-text-primary: $theme_02_common_secondary2,
            n-ekyc-section-border: $theme_02_common_border,
            n-ekyc-section-input-border: $theme_02_common_border_input_ekyc,
            n-ekyc-text-dissable:rgba(255, 255, 255, 0.25),
            n-ekyc-text-dissable-title: $theme_02_common_white_sub,
            n-ekyc-radio-input-bg:$theme_02_common_secondary2,
            n-ekyc-button-bg-back:$theme_02_common_button_sacondary1,
            n-ekyc-text-step-completed-bg: $theme_02_common_ck_dark_tng,
            n-ekyc-btn-capture-bg: $theme_02_common_button_sacondary1,
            n-ekyc-section-bg: $theme_02_common_block,
            n-ekyc-input-dissable:rgba(255, 255, 255, 0.05),
            n-ekyc-checkbox-input-bg:$theme_02_common_secondary2,
            n-ekyc-text-note:$theme_02_common_ck_dark_gim,
            n-ekyc-common-bg-success:$theme_02_common_ck_dark_tng1,

            //Animation Price Priceboard
            color_animation_price_priceboard : $theme_02_common_animation_price_priceboard,

      ));





// the :export directive is the magic sauce for webpack
// export để sử dụng scss variables trong js file
:export {

      //DARK----------------------------------------
      theme_dark_index_chart_increase: $theme_01_common_increase;
      theme_dark_index_chart_reduce  : $theme_01_common_reduce;
      theme_dark_index_chart_ref     : rgba(255, 204, 0, 0.95);
      theme_dark_index_chart_volumn  : rgba(96, 160, 255, 0.75);

      //LIGHT----------------------------------------
      theme_light_index_chart_increase: $theme_02_common_increase;
      theme_light_index_chart_reduce  : $theme_02_common_reduce;
      theme_light_index_chart_ref     : rgba(255, 204, 0, 0.95);
      theme_light_index_chart_volumn  : rgba(96, 160, 255, 0.75);
}