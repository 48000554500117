@import "../../styles/common.scss";

.selector-account-customer {

  

  &.account-select-block{
    // max-width: 100px;
  }

  &.isForBroker {
    // border-bottom: unset !important;
  }

  @include themify($themes) {
    .custom-form-control {
      height     : 100%;
      align-items: center;

      &[disabled] {
        cursor: not-allowed;
      }

      &:focus {
        border: none !important;
      }
    }
  }


  @include themify($themes) {
    .react-autosuggest__container {
      position: relative;
      height  : 100%;

      .react-autosuggest__input {
        text-overflow   : ellipsis;
        padding-right   : 10px;
        padding-left   : 5px;

        height          : 100%;
        width           : 100%;
        font-size       : inherit;
        text-transform  : uppercase;
        background-color: themed('n_layout_bg_block');
        color           : themed("n_layout_text_active");
        outline         : unset;
        // border          : none;
        // border-bottom   : 1px solid themed("n_layout_border");
        border          : 1px solid themed("input_border_normal");
        border-radius   : 4px;

        &:hover {
          // border-bottom: 1px solid themed("input_hover_border_bottom");
          border: 1px solid themed("input_hover_border_bottom") !important;
        }
      }

      .react-autosuggest__input:focus {
        // border-bottom: 1px solid themed("input_hover_border_bottom");
        border: 1px solid themed("input_hover_border_bottom") !important;
      }

      .react-autosuggest__suggestions-container {
        width           : 100%;
        z-index         : 200;
        position        : absolute;
        max-height      : 200px;
        overflow-y      : auto;
        overflow-x      : hidden;
        background-color: themed('n_layout_bg_block');


        .react-autosuggest__suggestions-list {
          padding         : 0px;
          width           : calc(100% - 7px);
          background-color: themed('n_layout_bg_block');
          margin-bottom   : 0px !important;
        }

        .react-autosuggest__suggestion {
          width        : 100%;
          height       : 26px;
          padding      : 2px 2px 2px 5px;
          text-align   : left;
          white-space  : nowrap;
          border-bottom: 1px solid themed('n_layout_border');

          &:hover,
          &.react-autosuggest__suggestion--highlighted {
            cursor          : pointer;
            color           : themed('n_layout_text_secondary_1');
            background-color: themed("n_layout_active_bg");
          }
        }
      }
    }
  }
}