@import "../../../styles/common";

.symbol-detail-information {

  @include themify($themes) {
    .additional-information-container {
      border-left: none;

      .symbol-matched-price,
      .low-high-price,
      .foreign-room {
        color: themed("n_layout_text_active");

        .label {
          color: themed("n_layout_text_active");
        }
      }
    }
  }

  .action-container {
    padding   : 10px;
    display   : block !important;
    align-self: center;

    span {
      margin-right: 20px;
      cursor      : pointer;
    }
  }

  .table-container {
    height            : calc(100% - 50px) !important;
    //@at-root calc('height', '100% + 60px');
    max-width         : 100%;
    overflow-y        : scroll;
    overflow-x        : hidden !important;
    padding-bottom    : 5px;
    width             : 100%;
    // div:first-child {
    //   margin-bottom: 60px;
    // }

    .track-horizontal {
      display: none;
    }
  }

  .adition-info-container {
    height          : 100%;
    max-width       : 100%;
    //overflow: auto;
    //padding-bottom: 5px;
    width           : 100%;
    padding         : 0px;
  }

  .symbol-container {
    min-height : 62px;
    align-items: center;
    display    : flex;
  }

  .company-name-container {
    overflow   : hidden;
    min-height : 62px;
    align-items: center;
    display    : flex;
  }

  .symbol-info-item {
    margin-top        : 15px;
    min-width         : 100px;
    float             : left;
    height            : 30px;
    //background-color: black;
    float             : left;
    margin-left       : 20px;

    &.minimize {
      min-width: 60px;
    }

    .title {
      width             : 100%;
      height            : fit-content;
      //background-color: purple;
      //padding: 10px;
    }

    .value {
      font-weight: bold;
    }
  }

  // .text-ceil-price{
  //   font-weight: bold;
  // }

  // .text-floor-price{
  //   font-weight: bold;
  // }

  // .text-ref-price{
  //   font-weight: bold;
  // }

  .end-line {
    height: 25px;
  }

  .symbol-name {
    margin  : 0 10px;
    padding : 0 10px 0 10px;
    position: relative;
  }

  .additional-information-container {
    padding: 0 10px;

    .symbol-price,
    .symbol-matched-price,
    .low-high-price,
    .foreign-room {
      overflow     : hidden;
      text-overflow: ellipsis;
      white-space  : nowrap;
    }
  }

  @include media-breakpoint-up(sm) {
    @include themify($themes) {
      .additional-information-container {
        border-left: 1px solid themed("n_layout_border");
      }
    }

    .symbol-name {
      // font-size: 24px;

      // .fa-search {
      //   font-size: $font-size-big;
      //   left: 6px;
      //   top: 10px;
      // }
    }

    .additional-information-container {
      padding: 10px;
    }
  }

  .tab-container {
    padding-left: 25px;
    height      : 26px;

    .tab-item {
      cursor       : pointer;
      padding-left : 8px;
      padding-right: 8px;

      &.active {
        @include themify($themes) {
          color        : themed(n_layout_text_secondary_1);
          border-bottom: 2px solid themed(n_layout_text_secondary_1);
        }
      }
    }
  }
}

.widget-sidebar {
  .symbol-main-information {
    .additional-information-container {
      border-left: none;
    }
  }
}

.choose-symbol-short {
  height: 28px;

  .btn-symbol-short-name {
    font-size: 18px;
    height   : 28px;
    width    : 85%;

    .fa-search-symbol-short-name {
      left: 5px;
      top : 5px;
    }
  }
}

.fss.isMobile {
  .symbol-detail-information {
    .company-name-container {
      width      : 100%;
      margin-left: 20px;
    }
  }
}