@import "../../../styles/common.scss";

.modal-dialog.corpbond-detail-modal {
    @include media-breakpoint-down(xl) {
        max-width: 1200px !important;
        width: 1200 !important;
    }

    .dx-header-row > td > .dx-datagrid-text-content {  
        white-space: normal!important;  
        display: contents !important;
    }

    .tab {
        @include themify($themes) {
            display: flex;
            gap: 10px;
            margin: 10px 0px;

            .sub-tab {
                cursor: pointer;
                padding: 4px 8px;
                border-radius: 4px;
                background: themed("n_btn_icon_bg");

                &.active {
                    border: 1px solid themed("n_btn_active");
                    padding: 3px 7px;
                }
            }
        }
    }

    .modal-content {
        @include themify($themes) {
            .company-name-container {
                overflow: hidden;
                min-height: 40px;
                align-items: center;
                display: flex;
                padding-left: 20px;

                .additional-information-container {
                    border-left: 1px solid themed("n_layout_border");
                    padding: 0px 10px;

                    &:first-child {
                        border-left: unset;
                        padding-left: 0px;
                    }
                }
            }

            .body-symbol-detail-modal {
                padding: 0px 10px 10px 10px;
                background: themed("n_layout_bg");

                .main {
                    // height  : 538px;
                    height: 70vh;
                    position: relative;
                    overflow: auto;

                    .overview {
                        height: 100%;
                        width: 100%;
                        display: none;
                        gap: 10px;

                        position: absolute;
                        z-index: 50;

                        &.show {
                            display: flex;
                            z-index: 51;
                        }

                        background: themed("n_layout_bg");

                        .tv-chart {
                            height: inherit;
                            width: 660px;
                            background: themed("n_layout_bg_block");

                            border-radius: 4px;

                            .price-chart {
                                .TVChartContainer {
                                    iframe {
                                        border-radius: 4px !important;
                                    }
                                }
                            }
                        }

                        .recent-trade {
                            background: themed("n_layout_bg_block");

                            border-radius: 4px;

                            width: calc(100% - 660px - 10px);

                            .title {
                                height: 40px;
                                padding: 10px;
                                border-bottom: 1px solid themed("n_table_border");
                            }

                            .recent-trades {
                                height: calc(100% - 40px);
                            }
                        }

                        .corpbond-detail-item {
                            border-radius: 4px;

                            background: themed("n_layout_bg_block");

                            .title {
                                height: 40px;
                                padding: 10px;
                                border-bottom: 1px solid themed("n_table_border");
                            }
                        }

                        .info-container {
                            height: calc(100% - 40px);
                            overflow-y: auto;
                            .info-item {
                                margin-top: 10px;
                                padding: 5px;
                                margin-right: 0px !important;
                                margin-left: 0px !important;
                                div {
                                    padding: 0px
                                }
                            }
                        }
                    }

                    .processional {
                        height: 100%;
                        width: 100%;
                        display: none;
                        gap: 10px;
                        z-index: 50;

                        position: absolute;

                        &.show {
                            display: flex;
                            z-index: 51;
                        }

                        background: themed("n_layout_bg");

                        .match-in-day {
                            height: 100%;
                            width: 100%;
                            // border       : 1px solid white;
                            border-radius: 4px;

                            background: themed("n_layout_bg_block");

                            .title {
                                height: 40px;
                                padding: 10px;
                                border-bottom: 1px solid themed("n_table_border");
                            }

                            .action-container {
                                display        : flex;
                                flex-direction : row;
                                justify-content: space-between;
                                padding-bottom : 10px;
                                margin-top: 10px;
                                padding-left: 10px;
                    
                                .btn-confirm {
                                    min-width: 80px
                                }

                                .container-select{
                                    gap: 10px;
                                }
                            }

                            .h-block-table{
                                height: calc(100% - 100px);
                            }
                        }

                        .match-in-day {
                            height: calc(100% - 1px) !important;

                            .chart-matched-inday {
                                height: calc(100% - 40px) !important;
                            }
                        }

                        .order-match {
                            div:nth-child(2) {
                                visibility: unset !important;
                                height: calc(100% - 40px) !important;

                                .chart-matched-info {
                                    // height: calc(100% - 40px) !important;
                                }
                            }
                        }

                        .surplus {
                            .block-info-container {
                                width: 100%;
                                padding-left: 10px;
                                padding-right: 10px;

                                .block-info-symbol {
                                    margin-top: 10px;
                                    padding: 6px 8px;
                                    background-color: themed("n_layout_btn_bg");
                                    width: 100%;
                                    border-radius: $border_radius;
                                    height: auto;
                                    min-height: 50px;

                                    .info-symbol {
                                        width: 100%;

                                        .custom-row {
                                            display: flex;
                                            justify-content: space-between;
                                        }
                                    }
                                }
                            }

                            .resizesensor-wrapper {
                                height: 180px !important;
                                overflow: auto;

                                .dual-orderbook {
                                    .table-container {
                                        .container-detail {
                                            margin-top: 0;

                                            .buy,
                                            .sell {
                                                .header > div {
                                                    gap: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        max-width: 700px !important;
        width: 700px !important;

        .modal-content {
            @include themify($themes) {
                .body-symbol-detail-modal {
                    .main {
                        .overview {
                            height: 100%;
                            width: 100%;
                            display: none;
                            flex-direction: column;
                            gap: 10px;

                            position: absolute;

                            display: none;

                            &.show {
                                display: flex;
                            }

                            background: themed("n_layout_bg");

                            .tv-chart {
                                height: 600px;
                                width: 100%;

                                .price-chart {
                                    .TVChartContainer {
                                        iframe {
                                            border-radius: 4px !important;
                                        }
                                    }
                                }
                            }

                            .recent-trade {
                                background: themed("n_layout_bg_block");
                                width: 100%;
                                height: 500px;

                                .title {
                                    height: 40px;
                                    padding: 10px;
                                    border-bottom: 1px solid themed("n_table_border");
                                }

                                .recent-trades {
                                    height: calc(100% - 40px);
                                }
                            }
                        }

                        .processional {
                            height: auto;
                            width: 100%;
                            display: none;
                            flex-direction: column;
                            gap: 10px;
                            z-index: 50;

                            display: none;

                            &.show {
                                display: flex;
                            }

                            background: themed("n_layout_bg");

                            .match-in-day,
                            .surplus,
                            .order-match {
                                width: 100%;
                                height: 500px;
                                // border       : 1px solid white;
                                border-radius: 4px;

                                background: themed("n_layout_bg_block");

                                .title {
                                    height: 40px;
                                    padding: 10px;
                                    border-bottom: 1px solid themed("n_table_border");
                                }
                            }

                            .match-in-day {
                                height: calc(100% - 1px);
                            }

                            .order-match {
                                div:nth-child(2) {
                                    visibility: unset !important;
                                    height: calc(100% - 40px) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        max-width: 95% !important;
        width: 95% !important;

        .modal-content {
            .dragHandler.header {
                height: auto;
                padding: 5px 10px;
            }
        }
    }
}

// .symbol-detail-static {
//     height: 100%;

//     .symbol-detail-static-header {
//         @include themify($themes) {
//             margin-top: 2px;
//             padding   : 0 10px;
//             background: themed("n_layout_bg_header_block");
//         }
//     }

//     @include media-breakpoint-down(xl) {
//         width: 100% !important;
//     }

//     @include themify($themes) {
//         .company-name-container {
//             overflow    : hidden;
//             min-height  : 40px;
//             align-items : center;
//             display     : flex;
//             padding-left: 20px;

//             .additional-information-container {
//                 border-left: 1px solid themed("n_layout_border");
//                 padding    : 0px 10px;

//                 &:first-child {
//                     border-left : unset;
//                     padding-left: 0px;
//                 }
//             }
//         }

//         .body-symbol-detail-modal {
//             height    : calc(100% - 40px);
//             padding   : 0px 10px 10px 10px;
//             background: themed("n_layout_bg");

//             .tab {
//                 display: flex;
//                 gap    : 10px;
//                 margin : 10px 0px;

//                 .sub-tab {
//                     cursor       : pointer;
//                     padding      : 4px 8px;
//                     border-radius: 4px;
//                     background   : themed("n_btn_icon_bg");

//                     &.active {
//                         border : 1px solid themed('n_btn_active');
//                         padding: 3px 7px;
//                     }
//                 }
//             }

//             .main {
//                 height  : 100%;
//                 overflow: auto;

//                 .overview {
//                     height : 100%;
//                     width  : 100%;
//                     display: flex;
//                     gap    : 10px;
//                     display: none;

//                     &.show {
//                         display: flex;
//                     }

//                     background : themed("n_layout_bg");

//                     .tv-chart {
//                         height    : inherit;
//                         width     : 60%;
//                         background: themed("n_layout_bg_block");

//                         border-radius: 4px;

//                         .price-chart {
//                             .TVChartContainer {
//                                 iframe {
//                                     border-radius: 4px !important;
//                                 }
//                             }
//                         }
//                     }

//                     .recent-trade {
//                         background: themed("n_layout_bg_block");

//                         border-radius: 4px;

//                         width: calc(100% - 60% - 10px);

//                         .title {
//                             height       : 40px;
//                             padding      : 10px;
//                             border-bottom: 1px solid themed("n_table_border");
//                         }

//                         .recent-trades {
//                             height: calc(100% - 40px);
//                         }
//                     }
//                 }

//                 .processional {
//                     height : 100%;
//                     width  : 100%;
//                     display: flex;
//                     gap    : 10px;
//                     z-index: 50;
//                     display: none;

//                     &.show {
//                         display: flex;
//                     }

//                     background : themed("n_layout_bg");

//                     .match-in-day,
//                     .surplus,
//                     .order-match {
//                         height          : 100%;
//                         width           : calc((100% - 2 * 10px) / 3);
//                         // border       : 1px solid white;
//                         border-radius   : 4px;

//                         background: themed("n_layout_bg_block");

//                         .title {
//                             height       : 40px;
//                             padding      : 10px;
//                             border-bottom: 1px solid themed("n_table_border");
//                         }
//                     }

//                     .match-in-day {
//                         height: calc(100% - 1px) !important;

//                         .chart-matched-inday {
//                             height: calc(100% - 40px) !important;
//                         }
//                     }

//                     .order-match {
//                         div:nth-child(2) {
//                             visibility: unset !important;
//                             height    : calc(100% - 40px) !important;

//                             .chart-matched-info {
//                                 // height: calc(100% - 40px) !important;
//                             }
//                         }
//                     }

//                     .surplus {

//                         .block-info-container {
//                             width        : 100%;
//                             padding-left : 10px;
//                             padding-right: 10px;

//                             .block-info-symbol {
//                                 margin-top      : 10px;
//                                 padding         : 6px 8px;
//                                 background-color: themed("n_layout_btn_bg");
//                                 width           : 100%;
//                                 border-radius   : $border_radius;
//                                 height          : auto;
//                                 min-height      : 50px;

//                                 .info-symbol {
//                                     width: 100%;

//                                     .custom-row {
//                                         display        : flex;
//                                         justify-content: space-between;
//                                     }
//                                 }
//                             }
//                         }

//                         .resizesensor-wrapper {
//                             height  : 180px !important;
//                             overflow: auto;

//                             .dual-orderbook {
//                                 .table-container {
//                                     .container-detail {
//                                         margin-top: 0;

//                                         .buy,
//                                         .sell {
//                                             .header>div {
//                                                 gap: 10px
//                                             }
//                                         }
//                                     }
//                                 }

//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     @include media-breakpoint-down(md) {
//         width: 100%;

//         @include themify($themes) {
//             .body-symbol-detail-modal {
//                 .main {
//                     .overview {
//                         height        : 100%;
//                         width         : 100%;
//                         display       : flex;
//                         flex-direction: column;
//                         gap           : 10px;

//                         display: none;

//                         &.show {
//                             display: flex;
//                         }

//                         background : themed("n_layout_bg");

//                         .tv-chart {
//                             height: 600px;
//                             width : 100%;

//                             .price-chart {
//                                 .TVChartContainer {
//                                     iframe {
//                                         border-radius: 4px !important;
//                                     }
//                                 }
//                             }
//                         }

//                         .recent-trade {
//                             background: themed("n_layout_bg_block");
//                             width     : 100%;
//                             height    : 500px;

//                             .title {
//                                 height       : 40px;
//                                 padding      : 10px;
//                                 border-bottom: 1px solid themed("n_table_border");
//                             }

//                             .recent-trades {
//                                 height: calc(100% - 40px);
//                             }
//                         }
//                     }

//                     .processional {
//                         height        : auto;
//                         width         : 100%;
//                         display       : flex;
//                         flex-direction: column;
//                         gap           : 10px;
//                         z-index       : 50;

//                         display: none;

//                         &.show {
//                             display: flex;
//                         }

//                         background : themed("n_layout_bg");

//                         .match-in-day,
//                         .surplus,
//                         .order-match {
//                             width           : 100%;
//                             height          : 500px;
//                             // border       : 1px solid white;
//                             border-radius   : 4px;

//                             background: themed("n_layout_bg_block");

//                             .title {
//                                 height       : 40px;
//                                 padding      : 10px;
//                                 border-bottom: 1px solid themed("n_table_border");
//                             }
//                         }

//                         .match-in-day {
//                             height: calc(100% - 1px);
//                         }

//                         .order-match {
//                             div:nth-child(2) {
//                                 visibility: unset !important;
//                                 height    : calc(100% - 40px) !important;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     @include media-breakpoint-down(sm) {
//         width: 100% !important;

//         .symbol-detail-static-header {
//             height : auto;
//             padding: 5px 10px;

//             .company-name-container {
//                 justify-content: center;
//                 align-items    : center;
//             }
//         }
//     }
// }
