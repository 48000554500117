@import "../styles/common";

.button-tooltip {
  @include themify($themes) {
    border    : none;
    padding   : 0px;
    background: transparent;

    color: themed("n_layout_text_secondary_1");

    &:hover {
      cursor: pointer;
    }

    &:not([disabled]) {
      cursor: pointer;
    }

    i {
      font-size: 14px;
      border   : 4px solid transparent;
      display  : inline-block;

      &:hover {
        cursor: pointer !important;
      }
    }
  }
}