@import "../styles/common.scss";

.error-boundary-modal {
  @include themify($themes) {
    position: relative;
    margin  : 0 auto;
    height  : 100vh;

    .error-boundary-wrapper {
      background    : themed("n_layout_bg_block");
      border-radius : 0px 0px 4px 4px !important;
      display       : flex;
      flex-direction: column;
      align-items   : center;
      padding       : 30px 24px;

      .error-boundary-content {
        max-width : 100%;
        text-align: center;

        .content {
          .check-error-deboundary {
            .content-text {
              overflow  : auto;
              max-height: 200px;
            }
          }
        }

        .btn {
          width: 100%;
        }

        .container-btn {
          margin-top: 16px;
          display   : flex;
          gap       : 8px;
          flex-wrap : nowrap;
          width     : 100%;

          .bg-btn-login {
            background: themed('n_header_btn_primary');
          }
        }
      }
    }
  }
}