@import "../../styles/common";

.rc-input-number-price {
  @include themify($themes) {
    overflow           : hidden;
    display            : block;
    margin             : 0;
    padding            : 0;
    width              : 100%;
    height             : 32px;
    vertical-align     : middle;
    background-color   : themed("n_layout_bg_block");
    border             : 1px solid themed("n_layout_border");
    // transition      : all .3s;
    position           : relative;
    border             : none;
    // border-bottom      : 1px solid themed("n_layout_btn_bg");
    border             : 1px solid themed("n_layout_btn_bg");
    border-radius: 4px;



    &:hover {
      border-bottom: 1px solid themed("input_hover_border_bottom") !important;

      .rc-input-number-price-handler {
        i {
          display: inline-block;
        }
      }
    }

    &-focused {
      border-bottom: 1px solid themed("input_hover_border_bottom");
    }

    // &-focused {
    //   border-color: themed("n_layout_border");
    //   box-shadow  : 0 0 0 2px rgba(themed("n_layout_box_shadow_bg"), 0.2);
    // }

    &-disabled {
      cursor    : not-allowed;
      background: transparent;

      &:hover {
        border-color: themed("n_layout_border");
      }

      .rc-input-number-price-input {
        cursor: not-allowed;
      }

      .rc-input-number-price-handler {
        opacity: 0.4;
      }
    }

    .rc-input-number-price-handler {
      text-align  : center;
      line-height : 13px;
      height      : 13px;
      overflow    : hidden;
      display     : block;
      touch-action: none;

      i {
        display: none;
      }
    }

    .rc-input-number-price-handler-up-inner,
    .rc-input-number-price-handler-down-inner {
      color              : themed("n_layout_text_active");
      user-select        : none;
      -webkit-user-select: none;

      &:hover {
        color: themed("n_layout_border");
      }
    }

    .rc-input-number-price-handler-down-inner {
      padding-top: 1.5px;
    }

    .rc-input-number-price-disabled:hover {
      border-color: themed("n_layout_border");
    }

    .rc-input-number-price-input-wrap {
      overflow: hidden;
      height  : 100%;
    }

    .rc-input-number-price-input {
      width              : 100%;
      outline            : none;
      -moz-appearance    : textfield;
      line-height        : 26px;
      text-align         : center;
      color              : themed("n_layout_text_active");
      height             : 100%;
      border             : 0;
      border-radius      : 0;
      padding            : 0 5px;
      background-color   : themed("n_layout_bg_block");
      // background-color: transparent;
      // transition      : all .3s;
    }

    .rc-input-number-price-handler-wrap {
      float        : right;
      width        : 20px;
      height       : 100%;
      // transition: all .3s;
    }

    .rc-input-number-price-handler-up {
      padding-top   : 1px;
      // transition : all .3s;
    }

    .rc-input-number-price-handler-down {
      // transition: all .3s;
    }

    .rc-input-number-price-handler-down-disabled,
    .rc-input-number-price-handler-up-disabled {
      opacity: 0.4;
    }

    .btn-plus {
      position: absolute;
      right    : 2px;
      top: 2px;
    }

    .btn-minus {
      position: absolute;
      left    : 2px;
      top: 2px;
    }
  }
}