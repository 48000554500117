@import "common";

body.fss {
  @include themifyBody($themes) {
    .text-red {
      color: themed('n_layout_reduce_text') !important;
    }

    .text-yellow-warning {
      color: themed('n_layout_warning_text') !important;
    }


    .text-green {
      color: themed('n_layout_increase_text') !important;
    }

    .text-yellow {
      color: themed('n_price_ref_text') !important;
    }

    .text-secondary-1 {
      color: themed('n_layout_text_secondary_1') !important;
    }

    .bg-red {
      background: themed('n_layout_reduce_text') !important;
    }

    .bg-green {
      background: themed('n_layout_increase_text') !important;
    }

    .bg-red-custom {
      background: themed('n_layout_red_custom') !important;
    }

    .bg-green-custom {
      background: themed('n_layout_green_custom') !important;
    }

    .text-red-custom {
      color: themed('n_layout_red_custom') !important;
    }

    .text-green-custom {
      color: themed('n_layout_green_custom') !important;
    }

    .bg-yellow {
      background: themed('n_price_ref_text') !important;
    }

    .bg-primary {
      background: themed('n_layout_primary') !important;
    }

    .bg-secondary-2 {
      background: themed('n_layout_bg_secondary_2') !important;
    }

    .bg-buy {
      background: themed('n_layout_buy_bg') !important;
    }

    .bg-sell {
      background: themed('n_layout_sell_bg') !important;
    }

    .text-active {
      color: themed('n_layout_text_active');
    }

    .text-not-active {
      color: themed('n_layout_text_not_active');
    }

    .text-near-active {
      color: themed('n_layout_text_near_active');
    }

    .text-custom {
      color: themed('n_text_custom') !important;
    }

    .text-uppercase {
      text-transform: uppercase !important;
    }

    .bg-secondary-1 {
      background: themed('n_layout_primary') !important;
    }

    .text-redirect {
      text-decoration: underline !important;

      &:hover {
        opacity: 0.9;
      }
    }

    .text-ceil-price {
      color: themed("n_price_ceil_text");
    }

    .text-ref-price {
      color: themed("n_price_ref_text");
    }

    .text-floor-price {
      color: themed("n_price_floor_text");
    }

    .text-font-600 {
      font-weight: 600 !important;
    }
  }


  @for $i from 1 through 4 {
    .p-#{$i * 5} {
      padding: $i * 5px !important;
    }

    .p-h-#{$i * 5} {
      padding-left : $i * 5px;
      padding-right: $i * 5px;
    }

    .p-v-#{$i * 5} {
      padding-bottom: $i * 5px;
      padding-top   : $i * 5px;
    }
  }

}