@import "../../styles/common";

.fss.isMobile {
    .quick-settings {
        .setting-container {
            top: 50px;
        }
    }
}

.quick-settings {
    @include themify($themes) {
        .setting-container {
            z-index           : 1000;
            background-color  : themed("n_layout_bg_block");
            box-shadow        : 0px 4px 20px themed("n_layout_box_shadow_bg");
            -webkit-box-shadow: 0px 4px 20px themed("n_layout_box_shadow_bg");
            padding           : 10px;
            position          : absolute;
            right             : 10px;
            top               : 50px;
            width             : 300px;
            border-radius     : 0px 0px $border_radius $border_radius;

            .quick-setting-mini-title {
                font-weight : bold;
                //width: 100%;
                padding-left: 0px;
            }

            .lang-icon {
                font-size: 30px;
                height   : 30px;
                width    : 30px;
            }

            .def-container {
                font-weight: 500;
            }

            input {
                &[type="radio"] {
                    height    : 18px;
                    margin-top: 5px;
                    width     : 18px;
                }
            }

            span {
                padding-left: 10px;
            }

            .seperate-line {
                background-color: themed("n_layout_bg_block");
                height          : 1px;
                margin-top      : 5px;
                width           : 100%;
            }

            .info-container {
                background-color: themed("n_layout_bg_block");
                margin-top      : 20px;
                max-height      : 250px; // hard code
                padding-bottom  : 2px;
                padding-top     : 35px;
                position        : relative;
                width           : 100%;
                @include border-radius(5px);

                .avatar-holder {
                    background-color: themed("n_layout_bg_block");
                    border          : 1px solid themed("n_layout_border");
                    color           : themed("n_layout_text_active");
                    font-size       : 30px;
                    height          : 40px;
                    left            : 45%;
                    overflow        : hidden;
                    position        : absolute;
                    top             : -15px;
                    width           : 40px;
                    @include border-radius(25px);
                }

                .info-content {
                    p {
                        &.full-name {
                            font-size  : $font-size-medium;
                            font-weight: bold;
                        }
                    }
                }
            }


            .aditional-info-container {
                background-color: themed("n_layout_bg_block");
                max-height      : 250px;
                padding         : 10px;
                width           : 100%;
                @include border-radius(5px);
            }

            .logout-btn-container {
                width: 100%;

                .logout-btn {
                    background-color: themed("n_layout_bg_block");
                    border          : none;
                    height          : 40px;
                    line-height     : 40px;
                    margin          : auto;
                    padding         : 5px;
                    text-align      : center;
                    width           : 50%;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}