@import "../styles/common";

.sso {
  @include themify($themes) {
    .login-processing {
      .message {
        color: themed("n_layout_text_active");
      }
    }
  }

  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 100%;
  width          : 100%;

  .login-processing {
    .loading {
      height: 100px;
    }

    .message {
      margin-top: 10px;
      font-size : $font-size-big;
      text-align: center;
    }
  }
}