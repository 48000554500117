@import "../styles/common";

.screen-confirm {
    @include themify($themes) {
  
      background    : themed("n_layout_bg_block");
      border-radius : 4px !important;
      padding       : 10px !important;
      display       : flex;
      flex-direction: column;
  
      .title {
        height       : 34px;
        border-bottom: 1px solid themed("n_table_border");
      }
  
      .label {
        display      : inline-block;
        margin-bottom: 0px;
        font-weight  : 400; // Body Medium - Regular
        font-size    : 14px;
        line-height  : 20px;
        color        : themed('n_layout_text_not_active');
      }
  
      .btn {
        width: 100%;
      }
  
      .container-btn {
        margin-top: 16px;
        display   : flex;
        gap       : 8px;
  
        .btn-refresh, .btn-confirm{
          &:focus{
            border       : 1px solid themed('n_layout_text_active') !important;
          }
        }
      }
  
      .row-item {
        height         : 48px;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        border-bottom  : 1px solid themed('n_layout_border');
      }
  
      .icon-container{
        margin-bottom: 10px;
      }
    }
  
    // từ 991px trở xuống
    @include media-breakpoint-down(md) {}
  
    // từ 1199px trở xuống
    @include media-breakpoint-down(lg) {}
  }