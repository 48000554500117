@import "../../../styles/common";

.recent-trades {
  @include themify($themes) {
    height: 100%;

    .text-priceboard-green {
      color: themed("n_layout_increase_text") !important;
    }

    .text-priceboard-red {
      color: themed("n_layout_reduce_text") !important;
    }

    .table-header-container {
      height  : 0;
      overflow: visible;
      position: sticky;
      top     : 0;
      width   : 100%;
      z-index : 2;

      .table-header {
        padding: 0px 10px;

        .table-cell {
          white-space     : nowrap;
          // border-bottom: 1px solid themed("n_table_border");
          position        : sticky;
          top             : 0;
          z-index         : 10;
          background      : themed("n_table_bg");
          display         : grid;
          align-items     : center;
        }
      }
    }

    .table-row {
      border    : 1px solid transparent;
      cursor    : pointer;
      background: themed("n_table_bg");
      padding   : 0px 10px;

      &:hover {
        background: themed("n_layout_bg_block");
      }
    }

    .table-header,
    .table-row {
      // padding       : 0px 10px;
      display          : flex;
      flex-direction   : row;
      min-width        : 230px;
      width            : 100%;
      // border-bottom : 1px solid themed("n_table_border");

      .table-cell {
        // flex       : 1 1 0;
        height     : 40px;
        line-height: 40px;
        min-width  : 40px;
        display    : grid;
        align-items: center;

        &.col-time {
          // padding-left: 4px;
          // min-width   : 70px;
          width: 20%;
        }

        &.col-price {
          width: 20%;
        }

        &.col-change {
          width       : 20%;
          // flex     : 1.5 1.5 0;
          // min-width: 50px;
        }

        &.col-volume {
          width          : 20%;
          // width       : 20%; // 20% nếu hiển thị cột M/B
          // flex     : 1.5 1.5 0;
          // min-width: 50px;
        }

        &.col-total-volume {
          // flex     : 2 2 0;
          // min-width: 70px;
        }

        &.col-side {
          width           : 20%;
          // flex         : 1 1 0;
          // min-width    : 40px;
        }

        // span {
        //   display: block;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
        //   width: 100%;
        // }
      }
    }

    .custom-scrollbar {

      .track-horizontal,
      .track-vertical {
        z-index: 4;
      }
    }

    .isNewRow {
      animation: 0.5s ease-out highlight;
      position : relative;
      z-index  : 0;

      &:nth-of-type(odd) {
        animation: 0.5s ease-out highlightAlt;
      }
    }

    .isNewRowDisable:before {
      animation: none;
      content  : none;
    }

  }
}