@import "../../../styles/common";

.portfolio-quick-order-container {
    @include themify($themes) {
        .block-1 {
            // background-image   : url("../../../assets/images_new/bg_quickoder.png");
            margin-top: 10px;
            // background   : linear-gradient(180deg, #3A4FAC 0%, #9E44E4 100%);
            background: #F27801;
            background-position: right top;
            background-repeat  : no-repeat;
            display            : flex;
            flex-direction     : column;
            justify-content    : space-between;
            position           : relative;
            background-size    : cover;
            height: 150px;
            border-radius: 4px;

            &.show-chart{
                height: 352px;
            }

            .row-block-1 {
                display        : flex;
                justify-content: space-between;
                padding        : 8px 10px;
                color          : themed("n_text_custom");
            }

            .info-chart {
                padding: 0px 10px 10px 10px;

                .button-container {
                    width      : 100%;
                    // padding-top: 2px;

                    .btn-action {
                        background   : themed("n_layout_bg_block");
                        width        : 100%;
                        border-radius: 0px 0px 4px 4px;
                    }

                    .btn-show-chart {
                        border-radius: $border_radius;
                    }
                }
            }


            .block-wrap {
                background-color: themed("n_layout_bg_block");
                border-radius   : 15px 15px 0px 0px;

                .block-content {

                    // padding: 10px 10px 0px 10px;
                    .block-2 {
                        margin: 10px 0px 0px 0px;
                    }

                    .block-3 {
                        margin: 10px 0px 0px 0px;

                        .info-portfolio-header {
                            & > div{
                                width: 17.5%;
                            }

                            .container-profit-loss-header {
                                width: 30%;
                            }

                            display        : flex;
                            justify-content: space-between;
                            border-bottom  : 1px solid themed("n_table_border");
                            margin         : 0px 10px;
                            padding        : 5px 0px 0px 0px;
                        }

                        .block-3-scroll-container {
                            height: calc(100vh - 380px) !important;
                        }

                        .info-portfolio {
                            .info-portfolio-body {
                                padding: 0px 10px 10px 10px;

                                .info-general {
                                    cursor       : pointer;
                                    padding      : 10px 0px;
                                    border-bottom: 1px solid themed("n_table_border");

                                    .info-row {
                                        display        : flex;
                                        justify-content: space-between;

                                        // 1 line
                                        span{
                                            max-width  : 17.5%;
                                            width: 17.5%;
                                            padding-top: 10px;
                                        }

                                        .info-row-1-left {
                                            display    : flex;
                                            gap        : 10px;
                                            white-space: nowrap;
                                            max-width  : 70%;

                                            .btn-tpsl {
                                                padding      : 0px 4px;
                                                border       : 1px solid themed("n_table_border");
                                                color        : themed("n_table_border");
                                                border-radius: $border_radius;
                                                cursor       : pointer;

                                                &.active {
                                                    color : themed("n_layout_text_active");
                                                    border: 1px solid themed("n_layout_text_active");
                                                }
                                            }
                                        }

                                        .info-row-1-right {
                                            display: flex;
                                        }

                                        .info-row-2-right {
                                            display: flex;
                                        }

                                        .container-profit-loss {
                                            max-width: 30%;
                                            width: 30%;
                                        }
                                    }
                                }

                                .info-detail {
                                    border-radius: 10px;

                                    &.active {}

                                    &.not-active {
                                        display: none;
                                    }

                                    padding: 5px 0px;

                                    .wrap-content {
                                        width        : 100%;
                                        display      : flex;
                                        background   : themed("n_layout_active_bg");
                                        border-radius: $border_radius;

                                        .content-order {
                                            width  : 50%;
                                            padding: 0px 8px;

                                            .row-content {
                                                display        : flex;
                                                padding        : 2px 0px;
                                                justify-content: space-between;
                                                border-bottom  : 1px solid themed("n_table_border");
                                            }
                                        }
                                    }

                                    .action-order {
                                        margin-top: 10px;

                                        .container-btn {
                                            display: flex;
                                            gap    : 10px;

                                            .btn-action {
                                                width: 50%;
                                                color: themed("n_layout_text_active");

                                                &.btn-fix {
                                                    background: themed("n_layout_primary");
                                                }

                                                &.btn-cancel {
                                                    background: themed("n_layout_reduce_bg");
                                                }

                                                &:disabled {
                                                    background: themed("n_layout_btn_bg");
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }

            #chart-container-pie {
                width        : 100%;
                height       : 200px;
                background   : themed("n_layout_bg_block");
                border-radius: 4px 4px 0px 0px;

                &.hide-chart {
                    display: none;
                }
            }
        }

    }
}