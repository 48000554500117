@import "../../../styles/common";

.advance-sale-modal {
    @include themify($themes) {

        .body {
            background    : themed("n_layout_bg_block");
            border-radius : 4px !important;
            padding       : 10px !important;
            display       : flex;
            flex-direction: column;

            .title {
                height       : 34px;
                border-bottom: 1px solid themed("n_layout_border");
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 16px;
                display   : flex;
                gap       : 8px;
            }

            .row-item {
                height         : 48px;
                display        : flex;
                align-items    : center;
                justify-content: space-between;
                border-bottom  : 1px solid themed("n_table_border");
            }
        }

        .confirm-end {
            background    : themed("n_layout_bg_block");
            border-radius : 4px !important;
            display       : flex;
            flex-direction: column;
            align-items   : center;

            .title {
                margin: 16px 0px 4px 0px;
                color : #FFFFFF;
            }

            .content {
                color     : themed('n_layout_text_near_active');
                margin    : 10px 20px 20px 20px;
                text-align: center;
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 16px;
                display   : flex;
                gap       : 8px;
                flex-wrap : nowrap;
                width     : 100%;
            }
        }

        // từ 991px trở xuống
        @include media-breakpoint-down(md) {}

        // từ 1199px trở xuống
        @include media-breakpoint-down(lg) {}
    }
}