@import "../../styles/common";

//huy.quang: custom lai input text thanh input password
//begin
@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/images/password.ttf);
}

input.key {
  font-family: "password";
  width: 100px;
  // height     : 16px;
}

//end

.pin-input {
  @include themify($themes) {
    &:hover {
      border-color: themed("n_layout_border");
    }

    // &:focus {
    //   border-color: #fc0390;
    //   box-shadow: 0 0 0 2px rgba(#fc0390, 0.2);
    // }
  }
}

.input-matrix-row {
  margin-left: -5px !important;
  margin-right: -5px !important;
  height: 45px;
  padding-bottom: 10px;

  input {
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &.question-container {
    margin-top: 10px;
    height: fit-content;
  }
}
