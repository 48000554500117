@import "../../../styles/common";

.screen-service-register {
    @include themify($themes) {

        .block {
            background   : themed('n_bg_block');
            border-radius: 4px;
            height       : 100%;

            .active {
                background   : themed('n_form_bg');
                // color     : themed('n_layout_text_secondary_1');
            }

            .title {
                padding: 16px;
            }
        }

        .h-method {
            height    : 48px;
            margin-top: 10px;
        }

        .style-auto-tranfer {
            justify-content: space-between;
            padding        : 0 5px;
        }

        .block-right {
            background   : themed('n_bg_block');
            border-radius: 4px;
            padding      : 0px 16px 10px 16px;
            height       : 100%;
            width        : 100%;
            overflow     : auto;

            &.no-bg{
                background: none;
            }

            &.no-pd {
                padding-left: 0px;
                padding-right: 0px;
            }

            .btn-custom {
                width     : 100%;
                margin-top: 20px;
            }
        }

        .server-property {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            height    : 80px;
            width     : 100%;
            background: themed('n_layout_bg_body_block');
            padding   : 16px;
            cursor    : pointer;

            .header-service {
                display    : flex;
                align-items: center;

                .icon {
                    width : 40px;
                    height: 40px;

                    background-color: themed('n_form_bg');

                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    border-radius  : 15px;
                }

                .content {
                    padding-left  : 12px;
                    display       : flex;
                    flex-direction: column;

                    // .title {
                    //     font-size  : 16px;
                    //     font-weight: 500;
                    // }

                    // .description {
                    //     font-size: 14px;
                    //     color    : rgba($color: #FFF, $alpha: .6);
                    // }
                }
            }

            .extra {
                .service-register-switch {

                    &.custom-control .custom-control-input:checked~.custom-control-label::before {
                        background: themed('n_header_btn_primary');
                        border    : none;
                        box-shadow: none;
                        outline   : none;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::before {
                        cursor: pointer;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::after {
                        cursor: pointer;
                    }

                }
            }

        }

        .sms-server-property {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            height: 80px;
            width : 100%;

            background   : themed('n_layout_bg_body_block');
            border-bottom: 1px solid themed('n_layout_border');

            .extra {
                .service-register-switch {

                    &.custom-control .custom-control-input:checked~.custom-control-label::before {
                        background: themed('n_header_btn_primary');
                        border    : none;
                        box-shadow: none;
                        outline   : none;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::before {
                        cursor: pointer;
                    }

                    &.custom-control .custom-control-input~.custom-control-label::after {
                        cursor: pointer;
                    }

                }
            }
        }

        .margin-register {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            height: 80px;
            width : 100%;

            background   : themed('n_layout_bg_body_block');
            border-bottom: 1px solid themed('n_layout_border');

            .combo-action {

                display: flex;

                gap: 10px;

                .btn-confirm {
                    height : unset;
                    padding: 4px 12px;
                }

                .icon {
                    width : 32px;
                    height: 32px;

                    &:hover {
                        cursor: pointer;
                    }

                    background-color: themed('n_form_bg');

                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    border-radius  : 4px;
                }
            }
        }

        .financial-product{
            height: 100%;

            .title-tab {
                margin-bottom: 12px;
                display: flex;
                flex-direction: row;
                gap: 16px;
                cursor: pointer;
    
                font-family: "Helvetica Neue Bold";
                font-weight: 700; // Heading 6 - B (6)
                font-size: 16px;
                line-height: 24px;
                padding-top: 10px;
                padding-left: 10px;
                position: relative;
                background: themed('n_bg_block');
                padding-bottom: 10px;
    
                .close-btn {
                    position: absolute;
                    right: 10px;
                }

                .active {
                    color: themed("n_layout_text_active");
                }
    
                .not-active {
                    color: themed("n_layout_text_not_active");
                }
            }

            .action-container{

                &.account-select-block{
                    background   : themed('n_bg_block');
                    border-radius: 4px;
                }
            }

            .custom-form-group-n{
                &.applying-product{
                    width: calc(100% - 167px);
                    position: relative;
                    input {
                        min-width: 140px !important;
                        max-width: unset !important;
                        width: calc(100% - 175px);
                    }
                }

                .accountId-select > select {
                    min-width: 45px;
                    padding: 0px 0px 0px 5px;
                }

                .custom-form-control{
                    &.icon-control{
                        margin-left: 10px;
                        padding-right: 40px;
                    }
                }
            }

            .register-product{
                height: calc(100% - 108px);
                border-radius: 4px;
                margin-top: 10px;
                padding: 10px;
                background   : themed('n_bg_block');

                input[type=radio] {
                    // margin-right: 5px;
                    width: 16px;
                    height: 22px;
                    vertical-align: text-bottom;
                    accent-color: themed("n-ekyc-radio-input-bg") !important;
                    background: initial;
                }

                input[type=checkbox] {
                    vertical-align: middle;
                    padding-top: 5px;
                    height: 16px;
                    accent-color: themed("n-ekyc-checkbox-input-bg") !important;
                    background: initial;
                }

                .product-list-container{
                    height: calc(100% - 125px);
                    // overflow-y: scroll;
                }

                .custom-label-radio {
                    vertical-align: middle;
                    padding-left: 10px;
                }

                .item-product{
                    padding: 10px;
                    border: none;
                    border-bottom: 1px solid themed("n_layout_btn_bg");
                    height: fit-content;
                    border-radius: 0px;
                    position: relative;
                    .detail{
                        color: themed("n-ekyc-checkbox-input-bg") !important;
                        position: absolute;
                        top: 10px;
                        right: 0px;
                        cursor: pointer;
                    }

                    &.no-tick{
                        padding-left: 25px !important;
                    }
                    input{
                        &:disabled{
                            cursor: not-allowed;
                        }
                    }
                }

                .item-agree{
                    padding: 10px 10px 0px 13px;
                    border: none;
                    height: fit-content;
                    border-radius: 0px;
                    color: themed("n_layout_text_near_active") !important;
                    .custom-label-radio{
                        display: inline-block !important;
                        margin-bottom: 0px !important;
                    }
                }

                .item-warning{
                    padding: 10px;
                    border: none;
                    height: fit-content;
                    border-radius: 0px;

                    .warnning-box{
                        background-color: themed('n_form_bg');
                        border-radius: 4px;
                        width: calc(100% - 100px);
                        padding: 5px;
                        display: inline-block;
                        margin-right: 10px;

                        .label-warnning{
                            color: themed("n_layout_text_near_active") !important;
                        }
                    }

                    .btn-confirm{
                        display: inline-block;
                        padding-left: 10px;
                        padding-right: 10px;
                        height: 32px !important;
                    }
                }

                .sub-title {
                    padding: 5px 5px 5px 0px;
                    border-bottom: 1px solid themed("n_layout_btn_bg");;
                    margin-bottom: 10px;
                }
            }

            .register-product-history{
                height: calc(100% - 46px);
                border-radius: 4px;
                margin-top: 10px;
                padding: 10px;
                background   : themed('n_bg_block');

                .btn-search{
                    border: 1px solid transparent !important;
                }

                .sub-title {
                    padding: 5px 5px 5px 0px;
                    border-bottom: 1px solid themed("n_layout_btn_bg");;
                    margin-bottom: 10px;
                }

                .h-table{
                    height: calc(100% - 110px) !important;
                }
            }
        }

    }
}