@import "../../../styles/common";

.trading-money-bidv {
    @include themify($themes) {
        // width    : 500px !important;
        max-width: 500px !important;

        .body {
            background    : themed('n_layout_bg_block');
            border-top    : 1px solid themed('n_layout_border');
            display       : flex;
            flex-direction: column;
            padding       : 32px 30px;
            height        : 100%;
            border-radius : 0 !important;

            .icon-hotline {
                height         : 48px;
                width          : 48px;
                border-radius  : 30px;
                background     : themed('n_background_icon_bg');
                cursor         : pointer;
                display        : flex;
                flex-direction : row;
                justify-content: center;
                align-items    : center;
                padding-top    : 1px;
            }

            .info {
                gap           : 8px;
                display       : flex;
                flex-direction: column;
                padding-left  : 10px;

                .sub-title {
                    color: #bbbabf;
                    font-size: 12px;
                }
            }

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
                ;
            }

            .container-btn {
                margin-top: 13px;
            }


            .content {
                max-height: 40vh;
                overflow  : auto;
                margin    : 0 auto;
            }

            // .container-btn {
            //     margin-top: 16px;
            //     display   : flex;
            //     gap       : 8px;
            //     flex-wrap : nowrap;
            //     width     : 100%;
            // }
        }

        .footer-contact {
            padding   : 16px !important;
            border-top: 1px solid themed('n_layout_border');
            background: themed('n_layout_bg_block');

            .btn-back-contact {
                width  : 100%;
                padding: 8px 0px;
            }
        }

        .custom-footer {
            background: themed('n_layout_bg_block');

            height         : 80px;
            color          : themed('n_layout_text_active');
            width          : 100%;
            align-items    : center;
            display        : flex;
            padding        : 0px 20px;
            justify-content: space-between;

            .btn {
                width  : 100%;
                padding: 0px 34px;
            }
        }

        .custom-icon-reload {
            cursor  : pointer;
            position: absolute;
            right   : 5px;
            top     : -5px;
        }

    }
}