@import "../../styles/common";


.fss.isMobile {
    .setting-containercustomer-container {
        width: 100% !important;
    }

    .login-account-selector {
        .setting-container {
            top: 50px;
        }
    }
}

.login-account-selector {
    @include themify($themes) {
        #btn-login-account-selector-id {
            cursor: pointer;
        }

        .setting-container {
            max-height        : 560px; // Đủ nhìn được tối đa 5 tài khoản (nếu nhiều hơn => scroll)
            overflow-x        : hidden;
            overflow-y        : auto;
            z-index           : 1000;
            background-color  : themed("n_layout_bg_block");
            box-shadow        : 0px 4px 20px themed("n_layout_box_shadow_bg");
            -webkit-box-shadow: 0px 4px 20px themed("n_layout_box_shadow_bg");
            padding           : 10px 10px 0px 10px;
            position          : absolute;
            right             : 10px;
            top               : 50px;
            width             : 400px;
            height            : fit-content;
            border-radius     : 0px 0px $border_radius $border_radius;

            .quick-setting-mini-title {
                font-weight : bold;
                //width: 100%;
                padding-left: 0px;
            }

            .lang-icon {
                font-size: 30px;
                height   : 30px;
                width    : 30px;
            }

            .def-container {
                font-weight: 500;
            }

            input {
                &[type="radio"] {
                    height    : 18px;
                    margin-top: 5px;
                    width     : 18px;
                }
            }

            span {
                padding-left: 10px;
            }

            .seperate-line {
                background-color: themed("n_layout_bg_block");
                height          : 1px;
                margin-top      : 5px;
                width           : 100%;
            }

            .info-container {
                // background-color: themed("n_layout_bg_block");
                max-height    : 250px; // hard code
                padding-bottom: 2px;
                padding-top   : 20px;
                position      : relative;
                width         : 100%;
                @include border-radius(5px);

                .avatar-holder {
                    background-color: themed("n_layout_bg_block");
                    border          : 1px solid themed("n_layout_border");
                    color           : themed("n_layout_text_active");
                    font-size       : 30px;
                    height          : 40px;
                    left            : 45%;
                    overflow        : hidden;
                    position        : absolute;
                    top             : -15px;
                    width           : 40px;
                    @include border-radius(25px);
                }

                .info-content {
                    p {
                        &.full-name {
                            font-size  : $font-size-medium;
                            font-weight: bold;
                        }
                    }
                }
            }

            .aditional-info-container {
                background-color: themed("n_layout_bg_block");
                max-height      : 250px;
                padding         : 10px;
                width           : 100%;
                @include border-radius(5px);
            }

            .logout-btn-container {
                width: 100%;

                .logout-btn {
                    background-color: themed("n_layout_bg_block");
                    border          : none;
                    height          : 40px;
                    line-height     : 40px;
                    margin          : auto;
                    padding         : 5px;
                    text-align      : center;
                    width           : 50%;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .custom-form-group {
                border: none;

                .account-selector {
                    padding      : 5px;
                    border-radius: 5px;
                    max-width    : 180px;
                    margin       : auto;
                }

                .login-account-item {
                    height: 26px;
                    cursor: pointer;
                }
            }

            .add-account {
                cursor: pointer;
            }

            .select-account-item {
                height: 70px;

                &.active {
                    background-color: themed("n_layout_active_bg");
                }

                .icon-container {
                    padding-right: 5px;
                    float        : right;
                    text-align   : right;
                    padding-top  : 20px;
                }

                .status-container {
                    padding-top  : 13px;
                    padding-right: 3px;
                    cursor       : pointer;

                    p {
                        margin: 0px !important;
                    }

                    .name-display {
                        font-size: $font-size-medium;
                    }

                    .info-display {
                        font-size: $font-size-regular;
                        color    : themed("n_layout_text_not_active");
                    }
                }

                &:hover {
                    .function-container {
                        display: flex;
                    }
                }

                .function-container {
                    display     : none;
                    padding-left: 0;
                    padding-top : 20px;

                    svg {
                        cursor        : pointer;
                        margin-right  : 5px;
                        vertical-align: middle;
                    }
                }
            }

            .add-login-account {
                cursor: pointer;
                height: 70px;

                .icon-container {
                    padding-right: 0px;
                    float        : right;
                    text-align   : right;
                    padding-top  : 20px;
                }

                .txt-container {
                    padding-top: 22px;
                    font-size  : $font-size-medium;
                }

                position        : sticky;
                bottom          : 60px; // Chieu cao cua nut dang xuat all
                background-color: themed("n_layout_bg_block");
            }

            .logout-all-account {
                position        : sticky;
                bottom          : 0px;
                background-color: themed("n_layout_bg_block");
                height          : 60px;

                button {
                    background   : themed("n_layout_btn_bg");
                    border-radius: $border-radius;
                    width        : 100%;
                    height       : 48px;
                }
            }
        }

        .select-account-header {
            font-style    : normal;
            font-weight   : 400;
            font-size     : $font-size-medium;
            line-height   : 150%;
            width         : 100%;
            height        : 64px;
            vertical-align: middle;
            padding-top   : 10px;
            padding-left  : 27px;
            color         : themed("n_layout_text_active");
        }
    }
}