@import "../../../../styles/common";

.cash-transfer {
    @include themify($themes) {
        .wizard{
            margin: 10px auto 15px auto !important;
        }

        .custom-form-group-effect{
            overflow: hidden !important;
        }


        .cashtran-container {
            margin: 0px auto 0px auto;
            // max-width: 650px;
            width: 600px;

            .custom-txt-color{
                color: themed("n_sum_group_color");
            }


            .rc-input-number-new{
                padding: 0px;
            }
            
            .custom-form-group-effect {
                input.custom-form-control {
                    padding-left: 8px;
                }
            }

            .custom-form-group-textarea {
                height: 70px;
                textarea {
                    resize: none;
                }
            }

            &.external {
                width: 832px;
            }

            .external-info-container {
                width: 100%;
                margin-bottom: 24px;
                margin-left: 0px !important;
                margin-right: 0px !important;

                .left-block{
                    padding-left: 0px;
                    padding-right: 0px;
                }

                .right-block{
                    padding-left: 0px;
                    padding-right: 0px;
                }

                @media (min-width: 992px){
                    .left-block{
                        padding-left: 0px !important;
                        padding-right: 10px !important;;
                    }

                    .right-block{
                        padding-left: 10px !important;;
                        padding-right: 0px !important;;
                    }
                }
            }

            .right-block-external{
                padding-left: 40px;
            }

            .block {
                .custom-form-group {
                    flex: 3;
                    height: 32px;
                    border-bottom: none;
                    .rc-input-number-new {
                        height: 100%;
                    }
                    .custom-form-control {
                        border: 1px solid themed("n_layout_btn_bg");
                        padding-left: 8px;
                        border-radius: 4px;
                        height: 100%;
                        // background: red !important;
                    }
                    input:read-only {
                        background: themed("n_layout_btn_bg");
                    }
                }
                .custom-form-group-select {
                    .custom-form-select {
                        border: 1px solid themed("n_layout_btn_bg");
                        border-radius: 4px;
                        padding-left: 8px;
                        padding-right: 8px;
                        background-position: 98%;
                    }
                }
            }
        }

        .success-container {
            background: themed("n_bg_block");
            border-radius: 4px;
            margin: 10px auto 0px auto;
            width: 442px;
            padding: 24px;

            .success-title {
                width: fit-content;
                margin: 10px auto 0px auto;
            }

            button {
                padding: 10px 34px;
                margin: 10px auto 0px auto;
            }

            .icon-container {
                width: 80px;
                height: 80px;
                margin: 10px auto 0px auto;
                position: relative;
                border-radius: 60px;
                background: rgba(7, 192, 92, 0.1) !important;
                .icon {
                    height: 56px;
                    width: 56px;
                    padding: 12px;
                    border-radius: 60px;
                    background: themed("n_layout_buy_bg") !important;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);

                    i {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .using-keyboard :focus {
            outline: auto 5px blue;
        }

        .using-mouse :focus {
            outline: none;
        }

        .guide-line {
            margin-top: 24px;
            margin-bottom: 24px;
            p {
                margin-bottom: 0px;
                font-style: italic !important;
            }
        }

        .title {
            margin-bottom: 16px;
            font-family: "Helvetica Neue Bold";
            font-weight: 700; // Heading 6 - B (6)
            font-size: 16px;
            line-height: 24px;
        }

        .block {
            background: themed("n_bg_block");
            border-radius: 4px;
            padding: 10px;
            height: 100%;

            .h-row {
                height: 48px;
            }

            .btn {
                width: 100%;
            }

            .container-btn {
                margin-top: 13px;
            }
        }

        .block-right {
            background: themed("n_bg_block");
            border-radius: 4px;
            padding: 10px;
            height: 100%;
            width: 100%;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            .container-block {
                display: flex;
                flex-direction: row;
                // gap           : 10px;    //bỏ

                .block-item {
                    height: auto;
                    // width : 50%;         //bỏ
                }

                .row-title {
                    height: 36px;
                    display: flex;
                    // align-items    : center;
                    justify-content: space-between;
                    border-bottom: 1px solid themed("n_layout_border");
                }

                .row-item {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .row-item-flex {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .border-block {
                    border: 1px solid themed("n_layout_border");
                    padding: 10px;
                    border-radius: 4px;
                }
            }
        }
    }
}
