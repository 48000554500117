@import "../../../styles/common";

.modal-check-register-voucher {
    @include themify($themes) {
        max-width: 550px;
        margin   : auto;
        height   : auto;
        right    : 0px;

        .body {
            background: themed(n_layout_bg_block);
            padding   : 20px;

            .modal-property {
                height: 48px;

                .label {
                    margin: 0;
                }

                .btn-refresh,
                .btn-confirm {
                    width: 100%;
                }
            }
        }
    }
}