@import "../../../styles/common";

.market-info-block {
    @include themify($themes) {

        .txt-center {
            text-align: center !important;
        }

        .volume-info-container {
            width  : 100%;
            padding: 10px;

            .volume-row {
                margin-left : 0px;
                margin-right: 0px;

                &>div {
                    padding: 0px;
                }

                // margin-top: 4px;
                // display: flex;
                // justify-content: space-between;
            }
        }

        .market-symbol-info {
            width     : 100%;
            border-top: 1px solid themed("n_layout_btn_bg");

            .info-market-col {
                vertical-align: text-top;
                display       : inline-block;
                width         : 50%;
                padding       : 0px 10px 10px 10px;
            }

            .row-market {
                margin-top     : 4px;
                display        : flex;
                justify-content: space-between;

                .price-change {
                    cursor: pointer;
                }
            }
        }

        .market-info-tprl {
            padding: 0px 10px 10px 10px;

            .line {
                height          : 1px;
                background-color: themed("n_layout_line");
            }

            .info-detail {
                .row-item {
                    padding        : 5px 0px;
                    display        : flex;
                    gap            : 10px;
                    justify-content: space-between;
                }
            }
        }
    }
}