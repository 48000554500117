@import "../../styles/common";

.summary-order-matching-action {

    .custom-form-group-n{
        &.fullname{
            background: transparent !important;
            padding: 10px 2px 10px 2px !important;

            // & > div{
            //     display: block !important;
            //     max-width: 140px;
            //     white-space: nowrap;
            //     overflow: hidden;
            //     text-overflow: ellipsis;
            // }
        }
    }

    .symbol-select-input-suggest-new {
        max-width: 135px !important;
        min-width: 135px !important;
    }
    @include themify($themes) {
        //height       : 10%;
        background: themed("n_bg_block");
        border-radius: 4px;
        .title {
            padding-left: 10px;
        }
    }
}

.summary-order-matching-all {
    @include themify($themes) {
        .TableDevExtreme {
            min-height: 150px !important;
            .dx-row {
                td {
                    font-size: 14px !important;
                }
                td > .text-near-active {
                    span {
                        color: themed("n_layout_text_active") !important;
                    }
                }
            }

            // .dx-data-row {
            //     td {
            //         span {
            //             color: themed("n_layout_text_active") !important;
            //         }
            //     }
            // }
        }

        //height: 43%;
        background: themed("n_bg_block");
        border-radius: 4px;
        .title {
            padding-left: 10px;
        }
        margin-top: 10px;
        .p-table-10 {
            padding-top: 10px;
        }
        .text-right {
            padding-right: 5px;
        }
    }
}

.summary-order-matching-detail {
    @include themify($themes) {
        .TableDevExtreme {
            height: 500px !important;

            .dx-row {
                td {
                    font-size: 14px !important;
                }
                td .text-near-active {
                    color: themed("n_layout_text_active") !important;
                }
            }
        }

        //height: 43%;
        background: themed("n_bg_block");
        border-radius: 4px;
        .title {
            padding-left: 10px;
        }
        margin-top: 10px;
        .p-table-10 {
            padding-top: 10px;
        }
        .text-right {
            padding-right: 5px;
        }
    }
}
