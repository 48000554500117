@import "../../../../../styles/common";

.modal-comfirm-place-order-tpsl {
    @include themify($themes) {
        // max-width: 600px;
        // margin: 0 auto;
        // height: auto;
        // right : 0px;

        .body {
            background: themed("n_layout_bg_block");
            padding   : 20px;


            .wrap-body {}
        }
    }
}